import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, valueIsNumInRange } from '../../common'
import { Container, Segment, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorComponentProps} from './ConfigEditor'

class ConfigEditorResCorpus extends Component<ConfigEditorComponentProps> {

    render() {
        const { formik, user, meta, editing, locale, t } = this.props

        return (
            <>
            <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}><div className="label pad">{t('Tokens')}</div></Grid.Column>
                    <Grid.Column width={14}><Input name="params.size_tokens" inputProps={{disabled: !editing, type: "number"}} validate={v => valueIsNumInRange(v, 0, NaN)}/></Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorResCorpus)