import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import TopMenu from './components/TopMenu'
import LoginForm from './components/LoginForm'
import Search from './components/Search'
import axios from 'axios'
import { Meta, UserInfo } from './common'
import './App.css'
import CNCToolbar from './components/CNCToolbar'
import Config from './components/Config'
import View from './components/View'
import Edit from './components/Edit'
import ConfigLoader from './components/ConfigLoader'
import Comp from './components/Comp'

//const baseApiUrl = 'https://db.korpus.cz/api/'
const baseApiUrl = process.env.API_URL
const defaultUrl = '/search/forms/'
axios.defaults.withCredentials = true
const http = axios.create({
  baseURL: baseApiUrl
})

interface AppProps {}

interface AppState {
  meta: Meta|null,
  loading: boolean,
  user: UserInfo|null,
  meta_ts: string
}

class App extends Component<AppProps, AppState> {

  state : AppState = {
    meta: null,
    loading: false,
    user: null,
    meta_ts: ''
  }

  componentDidMount() {
    //this.reloadMeta()
  }

  reloadMeta() {
    this.setState({loading: true, meta: {cats: {}, uis: {}, resources: {}}})
    http.get('meta/')
        .then(response => {
            let user = response.data.data[0]['user']
            user['groupmap'] = response.data.data[0]['groupmap']
            this.setState(
              { meta:
                {
                  cats: response.data.data[0]['cats'], 
                  uis: response.data.data[0]['uis'],
                  resources: response.data.data[0]['resources']
                },
                user: user, 
                loading: false,
                meta_ts: response.data._meta_ts
              }
            )
        }).catch(err => {
          this.setState({loading: false})
          throw err
        })
  }

  checkUpdates(data: any) {
    if (data._meta_ts > this.state.meta_ts) this.reloadMeta()
  }

  render() {
    const { loading, meta, user } = this.state

    //if (!meta) return(<ConfigLoader/>)
    
    return (
      <>
      {defaultUrl ? <Route exact path="/" render={() => (
          <Redirect to={defaultUrl}/>
      )}/> : null }
      <Route path="/" render={(props) => <CNCToolbar {...props} http={http} setUser={() => this.reloadMeta()}/>} />
      {meta && <Route path="/" render={(props) => <TopMenu {...props} meta={meta} http={http} user={this.state.user} nolang={true} setUser={() => this.reloadMeta()}/>} />}
      { loading || !meta ?
          <ConfigLoader/>
        :
          <Switch>
            <Route path="/view" render={(props) => <View {...props} meta={meta} http={http}/>} />
            <Route path="/edit" render={(props) => <Edit {...props} meta={meta} http={http}/>} />
            <Route path="/comp" render={(props) => <Comp {...props} meta={meta} http={http}/>} />
            <Route path="/search" render={(props) => <Search {...props} meta={meta} http={http} checkUpdates={(data) => this.checkUpdates(data)}/>} />
            <Route path="/config" render={(props) => <Config {...props} meta={meta} http={http} user={user} reload={() => this.reloadMeta()}/>} />
            <Route path="/login" render={(props) => <LoginForm {...props} http={http} reload={() => this.reloadMeta()}/>} />
          </Switch>
      }
      </>
    )
  }
}

export default App
