//import * as crypto from 'crypto-browserify'
import i18n from 'i18next'
import { DropdownItemProps } from 'semantic-ui-react';

export type Meta = {
    cats: {},
    uis: {},
    resources: {}
}

export const featureValueTypes = {
    undefined: {label: '', value: null, prefix: null, methods: ['defined'], ci: false},
    sstr: {label: 'text (keyword)', value: 'sstr', prefix: '_s_', methods: ['exact', 'prefix', 'regexp', 'defined'], ci: true},
    str: {label: 'text (line)', value: 'str', prefix: '_s_', methods: ['exact', 'fulltext', 'prefix', 'regexp', 'defined'], ci: true},
    lstr: {label: 'text (multiline)', value: 'lstr', prefix: '_s_', methods: ['exact', 'fulltext', 'prefix', 'regexp', 'defined'], ci: true},
    text: {label: 'text (long)', value: 'text', prefix: '_s_', methods: ['exact', 'fulltext', 'prefix', 'regexp', 'defined'], ci: true},
    enum: {label: 'enumeration', value: 'enum', prefix: '_s_', methods: ['anyflag', 'defined']},
    flags: {label: 'flags (multiple from enumeration)', value: 'flags', prefix: '_s_', multi: true, methods: ['anyflag', 'allflags', 'defined']},
    int: {text: 'number (integer)', value: 'int', prefix: '_i_', methods: ['eq', 'lte', 'lt', 'gte', 'gt', 'defined']},
    float: {text: 'number (float)', value: 'float', prefix: '_f_', methods: ['eq', 'lte', 'lt', 'gte', 'gt', 'defined']},
    geopoint: {label: 'geographical point', value: 'geopoint', prefix: '_g_', methods: ['defined']},
    ref: {label: 'reference or relation', value: 'ref', prefix: '_s_', methods: []},
    multiref: {label: 'sequence of references or relations', value: 'multiref', prefix: '_s_', multi: true, methods: []}
}

export const methodNames = {
    defined: {label: 'set'},
    exact: {label: 'exactly'},
    fulltext: {label: 'fulltext'/*, description: 'search by keywords'*/},
    prefix: {label: 'starts'/*, description: 'search by initial letters'*/},
    regexp: {label: 'reg.exp.'/*, description: 'search by regular expression'*/},
    eq: {label: '='/*, description: 'search exact value only'*/},
    lte: {label: '<='},
    lt: {label: '<'},
    gte: {label: '>='},
    gt: {label: '>'},
    anyflag: {label: 'any of (OR)'},
    allflags: {label: 'all (AND)'}
}

export type LocalizedLabels = {
    [lang: string]: string
}

export type CustomParams = {
    [lang: string]: string
}

export type NameQueryParams = {
    hidden?: boolean,
    method: String[],
    ci?: boolean,
    neg?: boolean,
    icon?: string,
    label?: {}
}

export type FeatureQueryParams = {
    hidden?: boolean,
    all_features: {
        cunit?: boolean,
        slot?: boolean,
        filler?: boolean
    },
    defaults?: {
        value?: string,
        type?: string,
        method?: string,
        ci?: boolean,
        neg?: boolean
    }
    options: [
        {
            type: string,
            method: String[],
            allow_spec?: boolean,
            ci?: boolean,
            neg?: boolean,
            icon?: string,
            label?: {}
        }
    ]
}

export type FillerQueryParams = {
    features: FeatureQueryParams
}

export type SlotQueryParams = {
    multi?: {
        min: number,
        max: number,
        default: number,
    },
    features: FeatureQueryParams,
    fillers: FillerQueryParams
}

export interface Query {
    type?: string,
    slots?: Slot[],
    feats?: FeatureQuery[],
    _id?: string|string[]
}

export interface Feature {
    type: string,
    value: any,
    [key: string]: any,
    updated_on?: string,
    updated_by?: string
}

export interface FeatureQuery {
    type?: string,
    value?: string,
    __not?: FeatureQuery,
    __or?: FeatureQuery[],
    ci?: boolean
}

export interface Filler {
    type: string,
    name: string,
    feats: Feature[],
    updated_on: string,
    updated_by: string
}

export interface Slot {
    pos?: number,
    type: string,
    name: string,
    fillers: Filler[],
    feats: Feature[],
    updated_on: string,
    updated_by: string
}

export interface Cunit {
    name: string,
    type: string,
    slots: Slot[],
    feats: Feature[],
    updated_on: string,
    updated_by: string
}

export interface CUView {
    name: string,
    type: string,
    [key: string]: any
}

export interface RatatoskPage {
    from: number,
    size: number
}

export interface RatatoskRequest {
    query: Query,
    page: RatatoskPage,
    sort?: {}[],
    field?: {},
    feats?: Array<String>
}

export interface RatatoskResponse {
    code: number,
    message: string,
    data: Array<Cunit>,
    total?: {
        value: number,
        relation: string
    },
    query?: {},
    page?: RatatoskPage
}

export interface updateRequest {
    query?: Query,
    delete_features: {target: string, type: string}[],
    delete_fillers: {target: string}[],
    delete_slots: {target: string}[], 
    update_names: {target: string, name: string}[], 
    update_types: {target: string, type: string}[],
    add_slots: {pos: number, type: string, name: string}[],
    add_fillers: {target: string, type: string, name: string}[],
    update_features: {target: string, type: string, value: any}[],
    update_slot_order: string[]
}

export interface UserInfo {
    firstname: string,
    surname: string,
    authorized: boolean,
    username: string,
    group?: string,
    groups: string[],
    config: boolean
    [key: string]: any
}

export function getmeta (meta: Meta, cls: string, type: string) {
    if (!type) {
        return null
    }
    let path = type
    while (path.length>0 && !meta.cats[cls][path]) {
        path = path.slice(0, path.lastIndexOf(':'))
    }
    if (meta.cats[cls][path]) {
        return meta.cats[cls][path]
    } else {
        return null
    }
}

export function getLocalizedLabel(labels: {}, language: string) {
    if (language in labels) {
        return labels[language]
    } else {
        return labels[Object.keys(labels)[0]]
    }
}

export function createDefaultFeatureLabel(meta, ftype: string, showType = false, fullDesc = false) {
    let fmeta = getmeta(meta, 'feature', ftype)
    //if (!fmeta) console.log("Unknown feature", ftype)
    let labels = fmeta.label
    const subspec = ftype.substring(fmeta.type.length+1)
    let flabel = getLocalizedLabel(labels, i18n.language)
    const sslen = fmeta.params.subspecs ? fmeta.params.subspecs.length : 0
    let sublabels: string[] = []
    let sscnt = 0
    if (subspec) {
        for (let ss of subspec.split(':')) {
            if (sslen>sscnt)
                sublabels.push(getLocalizedLabel(fmeta.params.subspecs[sscnt].label, i18n.language)+" "+ss)
            else if (fmeta.params.aggregate && !(sscnt>=sslen+1)) 
                sublabels.push(getLocalizedLabel(fmeta.params.aggregate.label, i18n.language)+" "+ss)
            else sublabels.push(i18n.t('subspec.')+" "+ss)
            sscnt += 1
        }
    }
    if (sublabels.length) {
        if (fullDesc) { flabel += ' (' + sublabels.join(', ') + ')' } else { flabel = sublabels.pop() }
    }
    if (showType) flabel += ` [${ftype}]`
    return flabel
}

export function valueByFormikPath(path: string, values) {
    let val = values
    path.split('.').forEach(element => {
        if (!val) return undefined
        val = val[element]
    });
    return val
}

function prefixFilter(list: string[], prefix: string) {
    let out: string[] = []
    for (let str of list) {
        if (str.startsWith(prefix+'.')) out.push(str.substring(prefix.length+1))
    }
    return out
}

export function removeEmptyValues(object: {}, exceptions: string[] = []) {
    for (let k of Object.keys(object)) {
        if (exceptions.includes(k)) continue
        const i = object[k]
        if (typeof i === 'object' && i !== null) object[k] = removeEmptyValues(object[k], prefixFilter(exceptions, k))
        else if (i === '') delete object[k]
    }
    return object
}

export interface targetFilterParams {
    doc?: boolean,
    slot?: boolean,
    filler?: boolean
}

const filterTargets = (comps: DropdownItemProps, params: targetFilterParams, t) => {
    let targets: DropdownItemProps[] = []
    if (params.doc) targets.push({key: '', text: t('Unit'), value: ''})
    if (params.slot) {
        targets = targets.concat(params.filler ? comps : comps.filter(x => !x.key.includes('/')))
    } else if (params.filler)
        targets = targets.concat(comps.filter(x => x.key.includes('/')))
    return targets
}

export function resolveReferences(value, fmeta, meta, cunitValues, noReferences=false) {
    if (fmeta.params.value_type == 'ref' || fmeta.params.value_type == 'multiref') {
        if (noReferences) return null
        value = (Array.isArray(value) ? value : [value]).map(ref => {
            if (ref.includes('/')) {
                const {lsid, lfid} = ref.split('/')
                return createFillerDescription(meta, cunitValues, lsid, lfid, noReferences)
            } else {
                return createSlotDescription(meta, cunitValues, ref, noReferences).join('/')
            }
        }).join(' ')
    }
    return value
}

export function createFillerDescription(meta, cunitValues, sid, fid, noReferences=false) {
    const flid = sid+'/'+fid
    const slot = cunitValues.slots.filter(s => s._id === sid || s.name === sid)[0]
    const filler = slot.fillers.filter(fl => fl._id === fid || fl.name === fid)[0]
    return getFillerDescription(filler, flid, meta, cunitValues, noReferences)
}

export function getFillerDescription(filler, flid, meta, cunit, noReferences=false) {
    //const flid = filler._id || filler.name
    const flmeta = getmeta(meta, 'filler', filler._type || filler.type)
    const deffeats = flmeta.params && flmeta.params['def'] && flmeta.params['def']['feats'] ? flmeta.params['def']['feats'] : []

    // description from user defined features
    if (flmeta.params['label_feature']) {
        // first test if we have a full unit, not a view
        if (filler.feats) {
            const found = filler.feats.filter(f => flmeta.params['label_feature'].includes(f.type))
            if (found.length) {
                const fmeta = getmeta(meta, 'feature', found[0].type)
                return resolveReferences(found[0].value, fmeta, meta, cunit, noReferences)
            }
        }
        // fallback: we have a view
        for (let labelFeature in flmeta.params['label_feature']) {
            const udfmeta = getmeta(meta, 'feature', labelFeature)
            const basetype = udfmeta.type
            const fgpath = "feats."+basetype+"."+flid
            const fgroup = valueByFormikPath(fgpath, cunit)
            let fi = Array.isArray(fgroup) ? fgroup.findIndex(x => x._type === labelFeature) : -1
            if (fi >= 0) {
                const ftype = fgroup[fi].type || fgroup[fi]._type
                const fmeta = getmeta(meta, 'feature', ftype)
                return resolveReferences(fgroup[fi].value, fmeta, meta, cunit, noReferences)
            }
        }
    }

    // fallback: default description from filler defining features
    let deffeatvalues: string[] = []
    deffeats.map(fdef => {
        const fmeta = getmeta(meta, 'feature', fdef.type)
        // first test if we have a full unit, not a view
        if (filler.feats) {
            const found = filler.feats.filter(f => f.type === fdef.type)
            if (found.length)
                deffeatvalues.push(resolveReferences(found[0].value, fmeta, meta, cunit, noReferences))
        } else {
            const basetype = fmeta.type
            const fgpath = "feats."+basetype+"."+flid
            const fgroup = valueByFormikPath(fgpath, cunit)
            let fi = Array.isArray(fgroup) ? fgroup.findIndex(x => x._type === fdef.type) : -1
            if (fi >= 0) {
                const value = resolveReferences(fgroup[fi].value, fmeta, meta, cunit, noReferences)
                deffeatvalues.push(value)
            }
        }
    })
    const all = deffeatvalues.filter(d => d !== null)
    return all.length ? all.join('_') : null
}

export function getSlotDescription(slot, meta, cunit, noReferences=false) {
    const sid = slot._id || slot.name
    return slot.fillers.map(filler => {
        const fid = filler._id || filler.name
        const flid = sid+'/'+fid
        return getFillerDescription(filler, flid, meta, cunit, noReferences)
    }).filter(d => d !== null)
}

export function createSlotDescription(meta, cunitValues, sid, noReferences=false) {
    const slot = cunitValues.slots.filter(s => s._id === sid || s.name === sid)[0]
    return getSlotDescription(slot, meta, cunitValues, noReferences)
}

export function descFiller(meta, cunitValues, sid, fid, t, short=false) {
    const slot = cunitValues.slots.filter(s => s._id === sid || s.name === sid)[0]
    if (!slot) return t('no filler')
    const filler = slot.fillers.filter(fl => fl._id === fid || fl.name === fid)[0]
    if (!filler) return t('no filler')
    if (!short)
        return '['+slot.name+'/'+filler.name+'] '+createFillerDescription(meta, cunitValues, sid, fid)
        //return t('Filler')+' '+slot.name+'/'+filler.name+' ['+createFillerDescription(meta, cunitValues, sid, fid)+']'
    else
        return slot.name+'/'+filler.name
}

export function descSlot(meta, cunitValues, sid, t, short=false) {
    const slot = cunitValues.slots.filter(s => s._id === sid || s.name === sid)[0]
    if (!slot) return t('no slot')
    if (!short)
        return '['+slot.name+'] '+createSlotDescription(meta, cunitValues, sid).join('/')
    else
        return slot.name
}

export function getRefTargets (meta, cunitValues, params: targetFilterParams, t, short=false) {
    let comps: DropdownItemProps[] = []
    for (let s of cunitValues.slots) {
        const sdesc = descSlot(meta, cunitValues, s._id, t, short)
        comps.push({key: s._id, text: sdesc, value: s._id})
        for (let f of s.fillers) {
            const fdesc = descFiller(meta, cunitValues, s._id, f._id, t, short)
            const compid = s._id+'/'+f._id
            comps.push({key: compid, text: fdesc, value: compid})
        }
    }
    return filterTargets(comps, params, t)
}

export function valueNotEmpty(str: string|undefined) {
    if (!str) return i18n.t("Value cannot be empty.")
}

export function subspecUnique(v: any, a: any[], subParts: string[]=[]) {
    if (v==='')
        return('Subspecification required.')
    if (a.includes(v)) return i18n.t("Duplicate value.")
    const parts = v.split(':')
    if (parts.length < subParts.length)
        return i18n.t('minSpecRequired', {count: subParts.filter(x => !x.startsWith('(')).length})+' '+subParts.join(', ')
    if (parts.some(p => p.length===0))
        return i18n.t('Empty subspecifications not allowed.')
    if (/\s/.test(v))
        return i18n.t("Whitespace not allowed.")
    if (/\//.test(v))
        return i18n.t("Slash sign not allowed.")
}

export function valueUnique(v: any, a: any[], canBeEmpty=false, isIdentifier=true) {
    if (!canBeEmpty && !v) return i18n.t("Value cannot be empty.")
    if (a.includes(v)) return i18n.t("Duplicate value.")
    if (isIdentifier) {
        return valueIsID(v)
    }
}

export function valuePathPrefix(v: string, arr: string[], canBeEmpty=false) {
    if (!canBeEmpty && !v) return i18n.t("Value cannot be empty.")
    if (!arr.some((e,i,a) => (e===v || v.startsWith(e+':')))) 
        return i18n.t("Such path is not defined.")
}

export function valueIsIntNumber(v: string) {
    if (typeof(v) === 'undefined' || !RegExp('^[0-9]+$').test(String(v)))
        return i18n.t("Not valid number.")
}

export function valueIsID(v: string) {
    if (/\s/.test(v))
        return i18n.t("Whitespace not allowed.")
    //if (/\./.test(v))
    //    return i18n.t("Dot sign not allowed.")
    if (/:/.test(v))
        return i18n.t("Colon sign not allowed.")
    if (/\//.test(v))
        return i18n.t("Slash sign not allowed.")
}

export function valueIsJson(str: string) {
    try {
        JSON.parse(str)
    } catch (e) {
        if (e instanceof Error) {
            return i18n.t("Invalid JSON.")+" "+ e.message
        } else {
            return i18n.t("Invalid JSON.")+" "+ String(e)
        }
    }
}

export function valueIsNumInRange(n: number, min: number, max: number) {
    if (!isNaN(min) && n < min)
        return i18n.t("Must be at least")+' '+min
    if (!isNaN(max) && n > max)
        return i18n.t("Must be at most")+' '+max
}

export function valueIsSimpleId(str: string) {
    if (!/^[a-zA-Z0-9_-]+$/.test(str)) 
        return i18n.t("Not a valid identifier.")
}

export function valueInOptions(v: string, options: DropdownItemProps[]) {
    if (!options.map(o => o.value).includes(v))
        return(i18n.t('Value not allowed.'))
}

export function collectFeatureOptions(meta: Meta, cunitTypes: string[]|null = null, slotTypes: string[]|null = null, fillerTypes: string[]|null = null,
        cunitFeatures: boolean = true, slotFeatures: boolean = true, fillerFeatures: boolean = true) {
    /* xxTypes: []|null = no restriction */
    return Object.keys(meta.cats['feature']).reduce((res: any, type) => {
        if (type==='') return res
        const u = meta.cats['feature'][type]
        
        /* check owner object restrictions */
        let acceptedByOwnerRestrictions = true
        if (u.params?.restr_owner) {
            /* exclude features of either cunits/slots/fillers if not desired */
            const ownerObjectAccepted =
                        (cunitFeatures && u.params.restr_owner.cunit)
                        || (slotFeatures && u.params.restr_owner.slot)
                        || (fillerFeatures && u.params.restr_owner.filler)
            /* exclude features unsuitable for the given combination of cunit/slot/feature types */
            /* this is rather complex (and may be buggy?) 
                - do we really need to count with prefix overlaps from both sides? in query form config we probably do (at least for cunit types as prefixes) */
            const cunitTypeAccepted = (!cunitTypes || !u.params.restr_owner.cunit_type || u.params.restr_owner.cunit_type.filter((t: string) => 
                (cunitTypes.includes(t) || cunitTypes.filter(x => x.startsWith(t+':')).length>0 || cunitTypes.filter(x => t.startsWith(x+':')).length>0)
                ).length>0)
            const slotTypeAccepted = (!slotTypes || !u.params.restr_owner.slot_type || u.params.restr_owner.slot_type.filter((t: string) => 
                (slotTypes.includes(t) || slotTypes.filter(x => x.startsWith(t+':')).length>0 || slotTypes.filter(x => t.startsWith(x+':')).length>0)
                ).length>0)
            const fillerTypeAccepted = (!fillerTypes || !u.params.restr_owner.filler_type || u.params.restr_owner.filler_type.filter((t: string) => 
                (fillerTypes.includes(t) || fillerTypes.filter(x => x.startsWith(t+':')).length>0 || fillerTypes.filter(x => t.startsWith(x+':')).length>0)
                ).length>0)
            const ownerTypeAccepted = cunitTypeAccepted && slotTypeAccepted && fillerTypeAccepted

            acceptedByOwnerRestrictions = ownerObjectAccepted && ownerTypeAccepted
        }

        const fvalueType = featureValueTypes[String(u.params?.value_type)]
        /* exclude also feature groups (i.e. where subdef is required) and features with no available search methods */
        if (!u.subdef_required && acceptedByOwnerRestrictions && fvalueType.methods.length)  
            res.push({type: type, neg: true, ci: !!fvalueType.ci, allow_spec: true})
        
        return res
    }, [])
}

export function defaultFeatureQueryParams(meta: Meta, params: any) {
    const fmeta = getmeta(meta, 'feature', params.type)
    const fvaluetype = featureValueTypes[String(fmeta.params.value_type)]
    let methods: string[] = []
    let neg = true
    let ci = false
    if (fvaluetype) {
        ci = Boolean(fvaluetype.ci)
        methods = fvaluetype.methods.filter(m => (params.method === undefined || params.method.includes(m)))
    }
    if (!params.neg) neg = false
    if (!params.ci ) ci = false
    return {type: params.type, method: methods, ci: ci, neg: neg, label: params.label, icon: params.icon, allow_spec: params.allow_spec, defaults: params.defaults}
}

// Tree structures

function createNodesFromSlots(slots, params, meta) {
    var nodes = {}
    const colors = params['color_feature'] ? getmeta(meta, 'feature', params['color_feature'])['params']['enum'] : null
    for (let si in slots) {
        let slot = slots[si]
        if (params['included_types'].filter(t => slot['type'].startsWith(t)).length>0) {
            const name = slot._id || slot.name
            let node = {'i': si, 'node_data': slot, 'node_type': 'slot', children: [], name: name}
            // add colour from configured enum feature
            const colorFeature = colors ? slot.feats.filter(f => f.type === params['color_feature']) : []
            if (colorFeature.length) {
                const cfvalue = colorFeature[0].value
                const evalue = colors.filter(i => i['value']===cfvalue)
                if (evalue.length) {
                    node['color'] = evalue[0]['color']
                    node['colorDesc'] = evalue[0]['label']
                }
            }
            // add title from configured feature value
            const titleFeature = params['label_feature'] ? slot.feats.filter(f => f.type === params['label_feature']) : []
            if (titleFeature.length) {
                node['label'] = titleFeature[0].value
            }
            nodes[si] = node
        }
    }
    return nodes
}

function nodeWidth(node) {
    let w = 0
    for (let c of node['children']) {
        if (!c['node_width']) c['node_width'] = nodeWidth(c)
        if (c['node_type'] !== 'filler') 
            w += c['node_width']
        else if (c['node_width'] > w)
            w = c['node_width']

    }
    return w || 1
}

export function createSlotTreeFromRefs(slots, params, meta) {
    let slotmap = {}
    for (let si in slots) {
        const slot = slots[si]
        slotmap[slot['name']] = si
    }
    // build linear base for tree
    let tree = createNodesFromSlots(slots, params, meta)
    // link slots under their parents
    for (let si in slots) {
        let slot = slots[si]
        if (!(si in tree)) continue
        // first check for parent pointer feature configuration
        if (params['parent_ptr']) {
            const ptrs = slot.feats.filter(f => f.type === params['parent_ptr'])
            if (ptrs.length === 1) { // no support for several parent pointers!
                const ptr = ptrs[0]
                const pidx = slotmap[ptr.value]
                if (tree[pidx]) {
                    if (!tree[pidx]['children'])
                        tree[pidx]['children'] = []
                    tree[pidx]['children'].push(tree[si])
                    tree[si]['to_be_removed'] = true
                }
            }
        }
        // now check for presence of embedded children
        let fillercount = slot['fillers'] ? slot['fillers'].length : 0
        //console.log("SLOT", slot)
        for (let filler of slot['fillers']) {
            //console.log("FILLER", filler)
            // find if there are filler defining features of slot reference type
            const flmeta = getmeta(meta, 'filler', filler.type)
            if (!flmeta.params && flmeta.params.def && flmeta.params.def.feats) continue
            const reffeats = flmeta.params.def.feats.filter(f => {
                const fmeta = getmeta(meta, 'feature', f.type)
                return (fmeta.params.value_type === 'ref' || fmeta.params.value_type === 'multiref') 
                    && fmeta.params.ref_targets && fmeta.params.ref_targets.includes('slot')
            })
            let ref = filler.feats.filter(f => reffeats.filter(rf => rf.type === f.type).length > 0)
            //console.log("- references:", ref)
            if (ref.length) {
                let children = ref.map(rf => {
                        const references = Array.isArray(rf.value) ? rf.value : [rf.value]
                        return references.reduce((col,rs) => {
                            const refslot = tree[slotmap[rs]]
                            if (refslot
                                // parent pointers override any children to be embedded in the tree!
                                && !(params['parent_ptr'] && refslot.node_data.feats.filter(f => f.type === params['parent_ptr']).length > 0)) {
                                refslot['to_be_removed'] = true
                                col.push(refslot)
                            }
                            return col
                        }, [])
                    }).flat() // at the moment, we do not really expect multiple reference features
                //console.log("CHILDREN ACQUIRED", children)
                //if (fillercount > 1) {
                    // variation: multiple fillers need insertion of intermediate "filler" nodes in the slot tree
                    const name = filler._id || filler.name
                    const node = {'node_type': 'filler', 'node_data': filler, 'children': children, name: name}
                    tree[si]['children'].push(node)
                //} else {
                //    tree[si]['children'] = children
                //}
            }
        }
    }
    // remove subordinated slots from the linear base and compute node width for UI
    let nodes: any[] = []
    for (let i in tree) {
        if (tree[i]['to_be_removed']) {
            delete tree[i]['to_be_removed']
            delete tree[i]
        } else {
            tree[i]['node_width'] = nodeWidth(tree[i])
            nodes.push(tree[i])
        }
    }
    return {'node_type': 'virtual', 'node_data': {'label':'virtuální kořen'}, 'children': nodes}
}

/*
export function createSlotTreeFromPtrs(slots, params, slotmap) {
    var parentptr = params['parent_pointer'];
    // build linear base for tree
    var tree = $scope.createNodesFromSlots(slots, params);
    // link slots under their parents
    for (var idx in slots) {
        var slot = slots[idx];
        var f = $scope.getFeat(slot['feats'], parentptr);
        if (f && idx in tree) {
            var pidx = slotmap[f['strval']];
            if (pidx in tree) {
                if (!('children' in tree[pidx]))
                    tree[pidx]['children'] = [];
                tree[pidx]['children'].push(tree[idx]);
                tree[idx]['to_be_removed'] = true;
            }
        }
        if (slot['type']==':variants') {
            for (var fi in slot['fillers']) {
                var filler = slot['fillers'][fi];
                if ('ref' in filler && filler['ref'].length) {
                    var children = [];
                    for (var ri in filler['ref']) {
                        var rlabel = filler['ref'][ri];
                        var ridx = slotmap[rlabel];
                        if (!tree[ridx] || tree[ridx]['to_be_removed'] || $scope.getFeat(tree[ridx]['node_data']['feats'], parentptr)!==null)
                            continue;
                        children.push(tree[ridx]);
                        tree[ridx]['to_be_removed'] = true;
                    }
                    if (!('children' in tree[idx]))
                        tree[idx]['children'] = [];
                    var node = {'node_type':'filler', 'node_data': filler, 'children': children};
                    node.node_label = filler.label;
                    tree[idx]['children'].push(node);
                }
            }
        }
    }
    // remove subordinated slots from the linear base
    nodes = [];
    for (var i in tree) {
        if (tree[i]['to_be_removed']) {
            delete tree[i]['to_be_removed'];
            delete tree[i];
        } else {
            nodes.push(tree[i]);
        }
    }
    return {'node_type':'virtual', 'node_data': {'label':'virtuální kořen'}, 'children': nodes};
}*/
