import React, {Component} from 'react'
import {Form, Checkbox as SCheckbox, FormCheckboxProps} from 'semantic-ui-react'
import {FastField, Field, getIn} from 'formik'
import ErrorMessage from './ErrorMessage'
import {InputRef} from './InputRef'
import { FormikComponentProps, getFieldError, setFieldValue} from './FormikHelpers'

export interface CheckboxComponentProps extends FormikComponentProps {
    value?: string;
    inputProps?: FormCheckboxProps
    inputRef?: (el: HTMLInputElement) => void
}

class Checkbox extends Component<CheckboxComponentProps> {

  id: string

  constructor(props) {
    super(props)
    const {id, name, value} = props
    this.id = id || `field_checkbox_${name}_${value}`
  }

  render() {
    const {
      name,
      label,
      validate,
      value,
      inputProps = {},
      fieldProps = {},
      errorComponent = ErrorMessage,
      inputRef,
      fast
    } = this.props;
    const {onChange, ...safeInputProps} = inputProps
    const DesiredField = fast === true ? FastField : Field
    return (
      <DesiredField name={name} value={value} type="checkbox" validate={validate}>
        {({field, form, meta}) => {
          const error = getFieldError(field, form)
          return (
            <Form.Field error={!!error} {...fieldProps}>
              <InputRef inputRef={inputRef}>
                <SCheckbox
                  {...safeInputProps}
                  id={this.id}
                  label={label}
                  name={name}
                  className={ ((Array.isArray(meta.initialValue) ? field.checked!==meta.initialValue.includes(field.value) :  Boolean(field.value) !== Boolean(meta.initialValue))) ? 'changed' : ''}
                  checked={field.checked}
                  onChange={(e, {name, checked}) => {
                    if (Array.isArray(meta.value)) {
                      let new_value: string[] = []
                      meta.value.forEach(v => {
                        if (checked || v !== field.value) {
                          new_value.push(v)
                        }
                      })
                      if (checked) new_value.push(field.value)
                      setFieldValue(form, name, new_value, true)
                    } else {
                      setFieldValue(form, name, checked, true)
                    }
                    Promise.resolve().then(() => {
                      onChange && onChange(e, {name, value: Number(checked)})
                    });
                  }}
                />
              </InputRef>
              {error && (
                React.createElement(errorComponent, { message: getIn(form.errors, name) })
              )}
            </Form.Field>
          );
        }}
      </DesiredField>
    );
  }
}

export default Checkbox