import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueIsIntNumber, descFiller, descSlot, getRefTargets, collectFeatureOptions, Cunit, Feature } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, Dropdown as PDropdown, DropdownItemProps, Grid, Header, Icon, Label, Message, Segment, SemanticWIDTHS, Tab } from 'semantic-ui-react'
import { ViewCompProps } from '../View'
import ShowFeatureValue from './ShowFeatureValue'

export function collectObjFeatures(ftype: string, obj: any) {
    return obj['feats'].reduce((col, f) => {
        if (f.type.startsWith(ftype)) {
            const subspec = f.type.substr(ftype.length).replace(/^:+/,'')
            col[subspec] = f
        }
        return col
    }, {})
}

export function collectFeatures(cunit: Cunit, params: any) {
    const ftype = params.feature
    let fgroups = {}
    if (params['doc']!==false) {
        const features = collectObjFeatures(ftype, cunit)
        if (Object.keys(features).length)
            fgroups[''] = features 
    }
    if (params['slot'] || params['filler']) {
        for (let s of cunit.slots) {
            if (params['slot']) {
                const features = collectObjFeatures(ftype, s)
                if (Object.keys(features).length)
                    fgroups[s.name] = features
            }
            if (params['filler']) {
                for (let fl of s.fillers)  {
                    if (params['filler']) {
                        const features = collectObjFeatures(ftype, fl)
                        if (Object.keys(features).length)
                            fgroups[s.name+'/'+fl.name] = features
                    }
                }
            }
        }
    }
    return fgroups
}

class FeatureViewTable extends Component<ViewCompProps> {

    componentDidMount() {
    }

    render() {
        const { config, meta, data, t, i18n } = this.props
        const params = config.params
        const columns = params['columns'] || 2
        const colwidth = Math.floor(16/columns)
        const itemcount = params['features'].length
        const percolumn = Math.ceil(itemcount/columns)
        const titleWidth = params['title_width'] || 8
        const contWidth = 16 - titleWidth
        let n = 0

        return(
            <Segment>
            <Header as="h2">{getLocalizedLabel(config.label, i18n.language)}</Header>
            <Grid>
                {Array.from(Array(columns).keys()).map(col => {
                    return(
                        <Grid.Column width={colwidth as SemanticWIDTHS}>
                        <Grid>
                        {Array.from(Array(percolumn).keys()).map(row => {
                        if (n>=itemcount) return (null)
                        const item = params['features'][n]
                        n++
                        const fmeta = getmeta(meta, 'feature', item.feature)
                        const fgroups = collectFeatures(data, item)
                        const groupkeys = Object.keys(fgroups)
                        return (
                            <Grid.Row>
                                <Grid.Column width={titleWidth as SemanticWIDTHS}>
                                    <strong>{getLocalizedLabel(fmeta.label, i18n.language)}</strong>
                                </Grid.Column>
                                <Grid.Column width={contWidth as SemanticWIDTHS}>
                                { !groupkeys.length ?
                                    <ShowFeatureValue feature={null} fmeta={fmeta} meta={meta} data={data}/>
                                : groupkeys.map(group => {
                                    const flist = fgroups[group]
                                    if (!flist || !Object.keys(flist).length) return null
                                    const [slot,filler] = group.split('/')
                                    return (
                                        <>
                                        {slot ?
                                            filler ?
                                                <strong>{descFiller(meta, data, slot, filler, t)}</strong>
                                            :
                                                <strong>{descSlot(meta, data, slot, t)}</strong>
                                        : 
                                            params['slot'] || params['filler'] ? <strong>{t('Unit')}</strong> : null
                                        }
                                        {Object.keys(flist).map((subspec: string) => {
                                            const f = flist[subspec]
                                            return (
                                                <p>
                                                    <ShowFeatureValue feature={f} prepend={subspec ? `(${subspec}) ` : ''} fmeta={fmeta} meta={meta} data={data}/>
                                                </p>
                                            )
                                        })}
                                        </>
                                    )
                                })}
                                </Grid.Column>
                            </Grid.Row>
                        )
                        })}
                    </Grid>
                    </Grid.Column>
                    )
                })}
            </Grid>
            </Segment>
        )
    }

}

export default withTranslation()(FeatureViewTable)