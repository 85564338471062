import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RatatoskResponse, Meta, getLocalizedLabel, getmeta, createDefaultFeatureLabel, CUView } from '../../common'
import { Button, Container, Dimmer, Grid, Modal, Pagination, Table, Checkbox, Menu, Header, Loader } from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router'
import { PageInfo, ResultListProps } from '../Search'
import NoResults from './NoResults'
import DetailView from './DetailView'
import ShowFeatureValue from './ShowFeatureValue'
import { Link } from 'react-router-dom'
import logoTACR from '../../img/logo_TACR.svg'
import "./AcPhraseList.css"
import { ReactSVG } from 'react-svg'

interface AcPhraseListState {
    currentCat: string
}

class AcPhraseList extends Component<ResultListProps, AcPhraseListState> {

    constructor(props: ResultListProps) {
        super(props)
        let pref = {
            currentCat: ''
        }
        const storedPref = localStorage.getItem('pref_acphraselist')
        if (storedPref) {
            pref = JSON.parse(storedPref)
        }
        this.state = {
            currentCat: pref.currentCat
        }
    }

    static getDerivedStateFromProps(props, state) {
        const pref = {
            currentCat: state.currentCat
        }
        localStorage.setItem('pref_acphraselist', JSON.stringify(pref))
        return null
    }

    render() {
        let { response, pageInfo, i18n, params, meta, history, directRequest, selectId, selectedIds, t, loading } = this.props
        let { currentCat } = this.state

        if (!(meta && meta.cats && meta.cats['cunit'] && meta.resources)) return null

        const aboutPage = (
            <>
            <Dimmer.Dimmable dimmed={loading} as={Container}>
            <Dimmer active={loading} inverted>
                <Loader inverted>{t('Loading...')}</Loader>
            </Dimmer>
                <Header as="h2">Frázová banka</Header>
                <Container className='about'>
                    <p>Frázová banka akademické češtiny poskytuje informace o nejběžnějších <strong>akademických frázích</strong>, tj. spojeních dvou a více slov, která se typicky vyskytují ve vědeckých textech a jsou součástí výkladové a argumentační struktury textu. Materiálovým zdrojem je <a href="https://wiki.korpus.cz/doku.php/cnk:veda" target="_blank">Korpus akademické češtiny</a>, obsahující česky psané nepřekladové texty vydané po roce 2010 v 21 vědeckých časopisech indexovaných v databázích Web of Science nebo Scopus, příp. EBSCO.</p>
                    <p>Frázová banka je rozdělena do čtyř částí podle čtyř částí vědeckého textu: <i>Úvod, Metody, Analýza a výsledky</i> a <i>Diskuse a závěr</i>. V každé části je uveden seznam rétorických <strong>kroků</strong>, tj. segmentů odborného textu, které v této části plní nějakou dílčí funkci (např. v části <i>Úvod</i> je jedním z kroků <i>Představení cíle nebo tématu studie</i>), a seznam <strong>frází</strong>, kterými jsou tyto kroky realizovány (zmíněný krok je realizován např. frází <i>Studie má za cíl přinést NĚCO</i>).</p>
                    <p>Seznam kroků je uveden v levé části stránky, po kliknutí na příslušný krok se vpravo otevře seznam frází. Po kliknutí na příslušnou frází se zobrazí její úplný popis s příklady z Korpusu akademické češtiny. Kliknutím na odkaz „Hledat příklady“ pak lze vstoupit do Korpusu akademické češtiny a sledovat užití dané fráze v původním kontextu.</p>
                    <p>Frázová banka akademické češtiny vznikla v rámci projektu Frázová banka akademické češtiny a její využití ve výzkumu a výuce akademického psaní, podpořeného Technologickou agenturou České republiky (č. projektu TL05000309). Více o projektu na <a href="https://korpus.cz/frazova-banka">domovské stránce Frázové banky</a>.</p>
                    <p><a href="https://www.tacr.cz/"><img src={logoTACR} style={{width:"10em"}}/></a></p>
                </Container>
            </Dimmer.Dimmable>
            </>
        )
        
        if (!(response && response.data.length)) return (aboutPage)

        const changeCat = (e, data) => this.setState({ currentCat: data.name })
        
        const viewCunit = (path: string) => {
            history.push('/view/'+path)
        }

        const categories: Map<string, any> = response.data.reduce((a, v) => {
            const cat = v[':note:acphrase:aim']
            if (!a.has(cat)) a.set(cat, [])
            a.get(cat)!.push(v)
            return a
        }, new Map())

        const catmeta = getmeta(meta, 'feature', ':note:acphrase:aim')

        if (!categories.has(currentCat)) currentCat = response.data[0][':note:acphrase:aim']
        
        return(
            <>
            <Dimmer.Dimmable dimmed={loading} as={Container}>
            <Dimmer active={loading} inverted>
                <Loader inverted>{t('Loading...')}</Loader>
            </Dimmer>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <strong>{t('Steps')}</strong>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <strong>{t('Phrases')}</strong>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Menu vertical fluid inverted className="acphraselist_menu">
                            {Array.from(categories).map(([cat, v]) => {
                                const enumitem = catmeta.params.enum.filter(i => i.value===cat)
                                const label = enumitem.length ? getLocalizedLabel(enumitem[0].label, i18n.language) : t('?')
                                return(
                                    <Menu.Item name={cat} active={currentCat===cat} onClick={changeCat}><strong>{label}</strong></Menu.Item>
                                )
                            })}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Menu vertical fluid>
                        {categories.get(currentCat).map(i =>
                            <><Menu.Item onClick={() => viewCunit(i._path)} >{i._name}</Menu.Item></>
                        )}
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Dimmer.Dimmable>
            </>
        )
    }

}

export default withTranslation()(AcPhraseList)
