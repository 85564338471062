import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, valueNotEmpty, valueUnique, valuePathPrefix, getLocalizedLabel, valueByFormikPath, valueInOptions } from '../../common'
import { Container, Segment, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorComponentProps} from './ConfigEditor'
import Button from '../form/Button'
import InputColor from '../form/InputColor'
import { setFieldValue } from '../form/FormikHelpers'

class ConfigEditorCatFeature extends Component<ConfigEditorComponentProps> {

    lastRestrOwner = {cunit: false, slot: false, filler: false, cunit_type: [], slot_type: [], filler_type: []}

    toggleRestrOwner(e, d) {
        const { formik } = this.props
        if (d.checked) {
            formik.setFieldValue('params.restr_owner', this.lastRestrOwner)
        } else {
            this.lastRestrOwner = formik.values.params.restr_owner
            formik.setFieldValue('params.restr_owner', undefined)
        }
    }

    lastAggregate = { default_spec: '', label: {}}

    toggleAggregate(e, d) {
        const { formik } = this.props
        if (d.checked) {
            formik.setFieldValue('params.aggregate', this.lastAggregate)
        } else {
            this.lastAggregate = formik.values.params.aggregate
            formik.setFieldValue('params.aggregate', undefined)
        }
    }

    render() {
        const { formik, user, meta, editing, locale, t, i18n } = this.props
        const lang = i18n.language
        
        const valueTypeOptions = [
            {key: 'none', text: t('none'), value: undefined},
            {key: 'sstr', text: t('text (keyword)'), value: 'sstr'},
            {key: 'str', text: t('text (line)'), value: 'str'},
            {key: 'lstr', text: t('text (multiline)'), value: 'lstr'},
            {key: 'text', text: t('text (long)'), value: 'text'},
            {key: 'enum', text: t('enumeration'), value: 'enum'},
            {key: 'flags', text: t('flags (multiple from enumeration)'), value: 'flags'},
            {key: 'int', text: t('number (integer)'), value: 'int'},
            {key: 'float', text: t('number (float)'), value: 'float'},
            {key: 'geopoint', text: t('geographical point'), value: 'geopoint'},
            {key: 'ref', text: t('reference or relation'), value: 'ref'},
            {key: 'multiref', text: t('sequence of references or relations'), value: 'multiref'}
        ]

        const strFormatOptions = [
            {key: 'none', text: t('plain'), value: undefined},
            {key: 'markdown', text: t('mark-down'), value: 'markdown'},
            {key: 'html', text: t('HTML'), value: 'html'},
        ]

        let newEnum = {value: '', label: {}}
        newEnum['label'][locale] = ''

        if (formik.values.params.restr_owner) {
            if (!formik.values.params.restr_owner.cunit_type) formik.values.params.restr_owner.cunit_type = []
            if (!formik.values.params.restr_owner.slot_type) formik.values.params.restr_owner.slot_type = []
            if (!formik.values.params.restr_owner.filler_type) formik.values.params.restr_owner.filler_type = []
        }

        const cunitTypes = Object.keys(meta.cats['cunit']).map(type => {
            const u = meta.cats['cunit'][type]
            return {key: type, value: type, text: getLocalizedLabel(u.label, lang)+' ['+type+']'}
        })

        const slotTypes = Object.keys(meta.cats['slot']).map(type => {
            const u = meta.cats['slot'][type]
            return {key: type, value: type, text: getLocalizedLabel(u.label, lang)+' ['+type+']'}
        })

        const fillerTypes = Object.keys(meta.cats['filler']).map(type => {
            const u = meta.cats['filler'][type]
            return {key: type, value: type, text: getLocalizedLabel(u.label, lang)+' ['+type+']'}
        })
        
        const restrOwnerChanged = Boolean(formik.values.params.restr_owner) !== Boolean(formik.initialValues.params.restr_owner) ? 'changed' : ''
        const aggregateChanged = Boolean(formik.values.params.aggregate) !== Boolean(formik.initialValues.params.aggregate) ? 'changed' : ''

        return (
            <>
            <Segment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2}><div className="label pad">{t('Value')}</div></Grid.Column>
                        <Grid.Column width={14}><Dropdown name="params.value_type" inputProps={{disabled: !editing}} options={valueTypeOptions} placeholder={t('none')}/></Grid.Column>
                    </Grid.Row>
                    { formik.values.params.value_type === "str" || formik.values.params.value_type === "lstr" || formik.values.params.value_type === "text" ? 
                        <Grid.Row>
                            <Grid.Column width={2}><div className="label pad">{t('Format')}</div></Grid.Column>
                            <Grid.Column width={14}>
                                <Dropdown name="params.format" inputProps={{disabled: !editing}}
                                    options={strFormatOptions} validate={v => valueInOptions(v, strFormatOptions)}/>
                            </Grid.Column>
                        </Grid.Row>
                    : null }
                    { formik.values.params.value_type === "float" && 
                        <Grid.Row>
                            <Grid.Column width={2}><div className="label pad">{t('Multiplier')}</div></Grid.Column>
                            <Grid.Column width={14}><Input name="params.multiplier" inputProps={{disabled: !editing, type: "number"}}/></Grid.Column>
                        </Grid.Row>
                    }
                    { (formik.values.params.value_type === "enum" || formik.values.params.value_type === "flags")  ?
                        <>
                        <Grid.Row>
                            <Grid.Column width={2}><div className="label">{t('Options')}</div></Grid.Column>
                            <Grid.Column width={3}>{t('Value')}</Grid.Column>
                            <Grid.Column width={6}>{t('Label')}</Grid.Column>
                            <Grid.Column width={1}>{t('Colour')}</Grid.Column>
                            <Grid.Column width={3}></Grid.Column>
                        </Grid.Row>
                        <FieldArray name='params.enum'
                            render={(subformik) => (
                            <>
                            {formik.values.params.enum && formik.values.params.enum.map((item: any, index: number) => {
                                    return (
                                        <Grid.Row>
                                            <Grid.Column width={2}></Grid.Column>
                                            <Grid.Column width={3}>
                                                <Input name={"params.enum."+index+".value"} inputProps={{disabled: !editing}}
                                                    validate={(v) => valueUnique(v, formik.values.params.enum.map((x, i) => { if (i !== index) return x.value} ))}/>
                                            </Grid.Column>
                                            <Grid.Column width={6}>
                                                <Input name={"params.enum."+index+".label."+locale} inputProps={{disabled: !editing}}
                                                    validate={(v) => valueUnique(v, formik.values.params.enum.map((x, i) => { if (i !== index) return x.label[locale]} ), false, false)}/>
                                            </Grid.Column>
                                            <Grid.Column width={1}>
                                                <InputColor name={"params.enum."+index+".color"} disabled={!editing}/>
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <Button type="button" icon="angle up" color="blue" size="mini"
                                                    disabled={!editing || index === 0}
                                                    onClick={(e,d) => subformik.swap(index, index-1)}/>
                                                <Button type="button" icon="angle down" color="blue" size="mini"
                                                    disabled={!editing || index === formik.values.params.enum.length-1}
                                                    onClick={(e,d) => subformik.swap(index, index+1)}/>
                                                <Button type="button" icon="delete" color="red" size="mini"
                                                    disabled={!editing}
                                                    onClick={(e,d) => subformik.remove(index)}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })
                            }
                            <Grid.Row>
                                <Grid.Column width={2}></Grid.Column>
                                <Grid.Column width={4}>
                                    <Button icon="plus" type="button" size='mini' color='blue' 
                                        onClick={(e,d) => subformik.push(newEnum)} disabled={!editing}/>
                                </Grid.Column>
                            </Grid.Row>
                            </>
                            )}/>
                        </>
                    : null}
                    { (formik.values.params.value_type === "ref" || formik.values.params.value_type === "multiref")  ?
                        <>
                        <Grid.Row>
                            <Grid.Column width={2}><div className="label">{t('Targets')}</div></Grid.Column>
                            <Grid.Column width={14}></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={2}></Grid.Column>
                            <Grid.Column width={4}><Checkbox name="params.ref_targets" label={t('units')} value="cunit" inputProps={{disabled: !editing}}/></Grid.Column>
                            <Grid.Column width={4}><Checkbox name="params.ref_targets" label={t('slots')} value="slot" inputProps={{disabled: !editing}}/></Grid.Column>
                            <Grid.Column width={6}><Checkbox name="params.ref_targets" label={t('fillers')} value="filler" inputProps={{disabled: !editing}}/></Grid.Column>
                        </Grid.Row>
                        </>
                    : null}
                </Grid>
            </Segment>
            <Segment>
                <Grid>
                    <Grid.Row className={aggregateChanged}>
                        <Grid.Column width={4}><div className="label">{t('Aggregation')}</div></Grid.Column>
                        <Grid.Column width={12}>
                            <PureCheckbox toggle key={true && formik.values.params.aggregate} checked={Boolean(formik.values.params.aggregate)}
                            onChange={(e,d) => this.toggleAggregate(e,d)} disabled={!editing}/>
                        </Grid.Column>
                    </Grid.Row>
                    { formik.values.params.aggregate  ?
                    <>
                        <Grid.Row className={aggregateChanged}>
                            <Grid.Column width={4}><div className="label pad">{t('Default specification')}</div></Grid.Column>
                            <Grid.Column width={12}><Input name="params.aggregate.default_spec" inputProps={{disabled: !editing, placeholder: t('undefined')}}/></Grid.Column>
                        </Grid.Row>
                        <Grid.Row className={aggregateChanged}>
                            <Grid.Column width={4}><div className="label pad">{t('Label for specification')}</div></Grid.Column>
                            <Grid.Column width={12}><Input name={"params.aggregate.label."+locale} inputProps={{disabled: !editing}}/></Grid.Column>
                        </Grid.Row>
                    </>
                    : null }
                </Grid>
            </Segment>
            <Segment>
                <Grid>
                    <Grid.Row className={restrOwnerChanged}>
                        <Grid.Column width={4}><div className="label">{t('Owner restrictions')}</div></Grid.Column>
                        <Grid.Column width={12}>
                            <PureCheckbox toggle key={true && formik.values.params.restr_owner} checked={Boolean(formik.values.params.restr_owner)} 
                                onChange={(e,d) => this.toggleRestrOwner(e,d)} disabled={!editing} className={this.lastRestrOwner === formik.values.params.restr_owner ? 'changed' : ''}/>
                        </Grid.Column>
                    </Grid.Row>
                    { formik.values.params.restr_owner  ?
                    <>
                        <Grid.Row className={restrOwnerChanged}>
                            <Grid.Column width={2}></Grid.Column>
                            <Grid.Column width={4}><Checkbox name="params.restr_owner.cunit" label={t('units')} inputProps={{disabled: !editing}}/></Grid.Column>
                            <Grid.Column width={4}><Checkbox name="params.restr_owner.slot" label={t('slots')} inputProps={{disabled: !editing}}/></Grid.Column>
                            <Grid.Column width={6}><Checkbox name="params.restr_owner.filler" label={t('fillers')} inputProps={{disabled: !editing}}/></Grid.Column>
                        </Grid.Row>
                        <Grid.Row className={restrOwnerChanged}>
                            <Grid.Column width={2}><div className="label">{t('Units')}</div></Grid.Column>
                            <Grid.Column width={14}>{t('type restriction')}</Grid.Column>
                        </Grid.Row>
                        <FieldArray name='params.restr_owner.cunit_type'
                            render={(subformik) => (
                            <>
                            {formik.values.params.restr_owner.cunit_type.map((item: any, index: number) => {
                                    return (
                                        <Grid.Row className={restrOwnerChanged ? restrOwnerChanged+' condensed' : 'condensed'}>
                                            <Grid.Column width={2}></Grid.Column>
                                            <Grid.Column width={11}>
                                                <Dropdown name={"params.restr_owner.cunit_type."+index} inputProps={{disabled: !editing}}
                                                    options={cunitTypes}
                                                    validate={v => valuePathPrefix(v, Object.keys(meta['cats']['cunit']))}/>
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <Button type="button" icon="angle up" color="blue" size="mini"
                                                    disabled={!editing || index === 0}
                                                    onClick={(e,d) => subformik.swap(index, index-1)}/>
                                                <Button type="button" icon="angle down" color="blue" size="mini"
                                                    disabled={!editing || index === formik.values.params.restr_owner.cunit_type.length-1}
                                                    onClick={(e,d) => subformik.swap(index, index+1)}/>
                                                <Button type="button" icon="delete" color="red" size="mini"
                                                    disabled={!editing}
                                                    onClick={(e,d) => subformik.remove(index)}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })
                            }
                            <Grid.Row className={restrOwnerChanged ? restrOwnerChanged+' condensed' : 'condensed'}>
                                <Grid.Column width={2}></Grid.Column>
                                <Grid.Column width={4}>
                                    <Button icon="plus" type="button" size='mini' color='blue' 
                                        onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                                </Grid.Column>
                            </Grid.Row>
                            </>
                            )}/>
                        <Grid.Row className={restrOwnerChanged}>
                            <Grid.Column width={2}><div className="label">{t('Slots')}</div></Grid.Column>
                            <Grid.Column width={14}>{t('type restriction')}</Grid.Column>
                        </Grid.Row>
                        <FieldArray name='params.restr_owner.slot_type'
                            render={(subformik) => (
                            <>
                            {formik.values.params.restr_owner.slot_type.map((item: any, index: number) => {
                                    return (
                                        <Grid.Row className={restrOwnerChanged ? restrOwnerChanged+' condensed' : 'condensed'}>
                                            <Grid.Column width={2}></Grid.Column>
                                            <Grid.Column width={11}>
                                                <Dropdown name={"params.restr_owner.slot_type."+index} inputProps={{disabled: !editing}}
                                                    options={slotTypes}
                                                    validate={v => valuePathPrefix(v, Object.keys(meta['cats']['slot']))}/>
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <Button type="button" icon="angle up" color="blue" size="mini"
                                                    disabled={!editing || index === 0}
                                                    onClick={(e,d) => subformik.swap(index, index-1)}/>
                                                <Button type="button" icon="angle down" color="blue" size="mini"
                                                    disabled={!editing || index === formik.values.params.restr_owner.slot_type.length-1}
                                                    onClick={(e,d) => subformik.swap(index, index+1)}/>
                                                <Button type="button" icon="delete" color="red" size="mini"
                                                    disabled={!editing}
                                                    onClick={(e,d) => subformik.remove(index)}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })
                            }
                            <Grid.Row className={restrOwnerChanged ? restrOwnerChanged+' condensed' : 'condensed'}>
                                <Grid.Column width={2}></Grid.Column>
                                <Grid.Column width={4}>
                                    <Button icon="plus" type="button" size='mini' color='blue' 
                                        onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                                </Grid.Column>
                            </Grid.Row>
                            </>
                            )}/>
                        <Grid.Row className={restrOwnerChanged}>
                            <Grid.Column width={2}><div className="label">{t('Fillers')}</div></Grid.Column>
                            <Grid.Column width={14}>{t('type restriction')}</Grid.Column>
                        </Grid.Row>
                        <FieldArray name='params.restr_owner.filler_type'
                            render={(subformik) => (
                            <>
                            {formik.values.params.restr_owner.filler_type.map((item: any, index: number) => {
                                    return (
                                        <Grid.Row className={restrOwnerChanged ? restrOwnerChanged+' condensed' : 'condensed'}>
                                            <Grid.Column width={2}></Grid.Column>
                                            <Grid.Column width={11}>
                                                <Dropdown name={"params.restr_owner.filler_type."+index} inputProps={{disabled: !editing}}
                                                    options={fillerTypes}
                                                    validate={v => valuePathPrefix(v, Object.keys(meta['cats']['filler']))}/>
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <Button type="button" icon="angle up" color="blue" size="mini"
                                                    disabled={!editing || index === 0}
                                                    onClick={(e,d) => subformik.swap(index, index-1)}/>
                                                <Button type="button" icon="angle down" color="blue" size="mini"
                                                    disabled={!editing || index === formik.values.params.restr_owner.filler_type.length-1}
                                                    onClick={(e,d) => subformik.swap(index, index+1)}/>
                                                <Button type="button" icon="delete" color="red" size="mini"
                                                    disabled={!editing}
                                                    onClick={(e,d) => subformik.remove(index)}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })
                            }
                            <Grid.Row className={restrOwnerChanged ? restrOwnerChanged+' condensed' : 'condensed'}>
                                <Grid.Column width={2}></Grid.Column>
                                <Grid.Column width={4}>
                                    <Button icon="plus" type="button" size='mini' color='blue' 
                                        onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                                </Grid.Column>
                            </Grid.Row>
                            </>
                            )}/>
                    </>
                    : null }
                </Grid>
            </Segment>
            </>
        )
    }

}

export default withTranslation()(ConfigEditorCatFeature)