import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Meta, RatatoskRequest, RatatoskResponse } from '../common'
import { Container, Message, Segment } from 'semantic-ui-react'
import Loader from './Loader'
import { json } from 'stream/consumers'
import CNCDict from './CNCDict'

interface CompProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance
}
const 
customComponents = {
    cncdict: CNCDict
}

class Comp extends Component<CompProps> {


    render() {
        const { meta, http, t, i18n, history, location } = this.props

        const m = location.pathname.match(/^\/?comp\/([^\/]+)/)
        
        if (!m) return(null)

        const Comp = customComponents[m[1].toLowerCase()]
        
        return(
            <Comp {...this.props}/>
        )
    }

}

export default withTranslation()(Comp)