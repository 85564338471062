import React, { Component, ReactNode } from 'react'
import { Grid, Icon, Label, Menu, StrictGridColumnProps } from 'semantic-ui-react'
import { FeatureQueryParams, Meta, getmeta, getLocalizedLabel, createDefaultFeatureLabel, valueByFormikPath, valueNotEmpty, collectFeatureOptions, defaultFeatureQueryParams, LocalizedLabels } from '../../common'
import { FieldArray } from "formik"
import { withTranslation, WithTranslation } from 'react-i18next'
import { setFieldValue } from './FormikHelpers'
import Input from './Input'
import Dropdown, { DropdownComponentProps } from './Dropdown'
import Checkbox from './Checkbox'
import Button from './Button'

interface MenuQueryParams {
    options: {label: LocalizedLabels, value: string}[],
    autoQuery: boolean
}

interface MenuQueryProps extends WithTranslation {
    prefix: string,
    label: string|null,
    params: MenuQueryParams,
    meta: Meta,
    formik: any,
    clear: () => void
}

interface MenuQueryState {
    selected: string
}

class MenuQuery extends Component<MenuQueryProps, MenuQueryState> {

    public static HIDE_SUBMIT = true


    constructor(props: MenuQueryProps) {
        super(props)
        this.state = {
            selected: valueByFormikPath(`${this.props.prefix}.feats.0.value`, this.props.formik.values)
        }
    }

    withPrefix(fieldName: string) {
        return `${this.props.prefix}.${fieldName}`
    }

    trigger = (value: string) => {
        const { formik, params } = this.props
        if (value !== this.state.selected) {
            this.setState({selected: value})
            if (!value.startsWith('__')) {
                setFieldValue(formik, this.withPrefix('feats.0.value'), value, false)
                formik.submitForm()
            } else this.props.clear()
        }
    }

    /*componentDidMount() {
        const { formik } = this.props
        this.trigger(valueByFormikPath(this.withPrefix('feats.0.value'), formik.values))
    }*/

    render () {
        const { meta, formik, prefix, params, i18n, t } = this.props
        const { selected } = this.state
        
        const items = params.options.map((v, i) => {
            const label = getLocalizedLabel(v.label, i18n.language)
            return (
                { 
                key: i, 
                name: v.value,
                content: label.startsWith('__') ?
                    <Icon name={label.substring(2)}/>
                    : <strong>{label}</strong>,
                active: v.value === selected,
                onClick: (e, d) => this.trigger(v.value)
                }
            )
        })

        return (
            <Menu fluid inverted items={items}/>
        )
    }
}

// dirty hack for statics by https://react.i18next.com/latest/withtranslation-hoc#hoist-non-react-statics
const Extended = withTranslation()(MenuQuery)
Extended['HIDE_SUBMIT'] = MenuQuery.HIDE_SUBMIT

export default Extended