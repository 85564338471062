import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, getmeta } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Checkbox from '../form/Checkbox'
import {ConfigEditorUiSearchCompProps} from './CEUiSearch'
import { setFieldValue } from '../form/FormikHelpers'

class ConfigEditorUiSearchCompNameQuery extends Component<ConfigEditorUiSearchCompProps> {

    render() {
        const { meta, editing, locale, t, i18n, params, formik, formikprefix } = this.props
        const lang = i18n.language

        if (!(meta && meta.cats && meta.cats['feature'])) return null

        if (params.options === undefined) {
            setFieldValue(formik, formikprefix+'.options', [], false)
            return null
        }

        const allFeatOptions = Object.keys(meta.cats['feature']).map((v, i, arr) => {
            const name = getLocalizedLabel(meta.cats['feature'][v]['label'], lang)+" ["+v+"]"
            return {key: v, text: name, value: v}
        })

        const newOption = {type: '', value: '', method: ['exact']}
        
        return (
            <>
            <Grid.Row>
                <Grid.Column width={2}>
                    <div className="">{t('Methods')}</div>
                </Grid.Column>
                <Grid.Column width={9}>
                    <Checkbox name={formikprefix+".method"} label={t('fulltext')} value="fulltext" fieldProps={{className: "compactfield inlineblock"}} inputProps={{disabled: !editing}}/>
                    <Checkbox name={formikprefix+".method"} label={t('exactly')} value="exact" fieldProps={{className: "compactfield inlineblock"}} inputProps={{disabled: !editing}}/>
                    <Checkbox name={formikprefix+".method"} label={t('starts')} value="prefix" fieldProps={{className: "compactfield inlineblock"}} inputProps={{disabled: !editing}}/>
                    <Checkbox name={formikprefix+".method"} label={t('reg.exp.')} value="regexp" fieldProps={{className: "compactfield inlineblock"}} inputProps={{disabled: !editing}}/>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Checkbox name={formikprefix+".ci"} label={t('A=a')} fieldProps={{className: "compactfield"}} inputProps={{disabled: !editing}}/>
                </Grid.Column>
            </Grid.Row>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiSearchCompNameQuery)