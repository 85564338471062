import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueIsIntNumber, descFiller, descSlot, getRefTargets, Feature, valueNotEmpty, collectFeatureOptions, createDefaultFeatureLabel, valueInOptions } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, Dropdown as PDropdown, DropdownItemProps, Grid, Header, Icon, Label, Message, Segment, Tab } from 'semantic-ui-react'
import { EditFormProps, addFeature } from '../Edit'
import Input from './Input'
import {FormikComponentProps, getFieldError, setFieldValue} from './FormikHelpers'
import { FieldArray } from 'formik'
import TextArea from './TextArea'
import Dropdown from './Dropdown'
import Checkbox from './Checkbox'
import InputFeatureValue from './InputFeatureValue'
import InputFeatureType from './InputFeatureType'


interface BatchUpdateFeatureProps extends WithTranslation {
    meta: Meta,
    formik: any,
    f: Feature,
    index: number,
    prefix: string,
    cunitTypes: string[]|null
}

class BatchUpdateFeature extends Component<BatchUpdateFeatureProps> {

    componentDidMount() {
    }

    render() {
        const { f, index, cunitTypes, prefix, formik, meta, t, i18n } = this.props

        if (!meta['cats']) return null

        const fmeta = getmeta(meta, 'feature', f.type)
        const features = collectFeatureOptions(meta, cunitTypes, null, null, true, true, true)
        const typeOptions = features.map(f => ({key: f.type, text: createDefaultFeatureLabel(meta, f.type, true), value: f.type}))
        const speccnt = !fmeta || f.type.length === fmeta.type.length ? 0 : f.type.substring(fmeta.type.length+1).split(':').length
        const allowSubspec = fmeta && speccnt === 0 && !fmeta.params.aggregate
        let conflictOptions = [
            {key: 'override', text: t('override'), value: 'override'},
            {key: 'skip', text: t('skip'), value: 'skip'}
        ]
        if (allowSubspec) conflictOptions.push({key: 'subspec', text: t('add number'), value: 'subspec'})
        return(
            <>
            <Grid.Row className="condensed">
                {/*!f.type ?
                (
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Dropdown name={prefix+'.type'} options={featureTypes}
                                validate={valueNotEmpty} inputProps={{compact: true}}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                        </Grid.Column>
                    </Grid.Row>
                )
                :
                (

                )
                */}
                <InputFeatureType name={prefix+'.type'} fmeta={fmeta} options={typeOptions} widthCols={10}/>
                
                <Grid.Column width={5}>
                    { f.type &&
                    <InputFeatureValue cunitValues={null} feature={f} fvaluepath={prefix+'.value'} fmeta={fmeta} meta={meta} disabled={false} nonEmpty noHighlighting
                        userMap={{}}/>
                    }
                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Button icon="delete" type="button" size='mini' color='red' 
                        onClick={(e,d) => formik.remove(index)}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="condensed">
                <Grid.Column width={2}>
                    <div className="label pad">{t('Conflicts')}</div>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Dropdown name={prefix+'.conflict'} options={conflictOptions} noHighlighting validate={v => valueInOptions(v, conflictOptions)}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            </>
        )
    }

}

export default withTranslation()(BatchUpdateFeature)