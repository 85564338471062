import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { Icon } from 'semantic-ui-react'
import { Cunit, descFiller, descSlot, Feature, featureValueTypes, getLocalizedLabel, Meta } from '../../common'
import './ShowFeatureValue.css'
import supersub from 'remark-supersub'

interface ShowFeatureValueProps extends WithTranslation {
    feature: Feature|null,
    fmeta: any,
    meta: Meta,
    data: Cunit,
    prepend?: string
}

function getReferenceDescription(ref: string, fmeta, meta, data, t){
    if (fmeta.params.ref_targets.includes('filler') && ref.split("/").length===2) {
        const [slot, filler] = ref.split("/")
        return descFiller(meta, data, slot, filler, t)
    }
    if (fmeta.params.ref_targets.includes('slot'))
        return descSlot(meta, data, ref, t)
}

function html2md(contents: string) {
    contents = contents.replaceAll(/<\/?i>/g, '*')
    contents = contents.replaceAll(/<\/?emph>/g, '*')
    contents = contents.replaceAll(/<\/?hi( [^>]+)?>/g, '*')
    contents = contents.replaceAll('**', '')
    contents = contents.replaceAll(/<\/?b>/g, '**')
    contents = contents.replaceAll('****', '')
    contents = contents.replaceAll(/<\/?sub>/g, '~')
    contents = contents.replaceAll(/<\/?sup>/g, '^')
    return contents
}

class ShowFeatureValue extends Component<ShowFeatureValueProps> {

    componentDidMount() {
    }

    render() {
        const { feature, fmeta, meta, data, prepend, t, i18n } = this.props
        const valueType = fmeta.params.value_type
        const prep = prepend || ''
        if (!valueType) {
            if (feature)
                return(<>{prep}<Icon name="check" color="blue" size="small"/> {t('Yes')}</>)
            else
                return(<>{prep}<Icon name="delete" color="red" size="small"/> {t('No')}</>)
        } else if ((valueType==='enum' || valueType==='flags')  && feature) {
            const values = valueType==='enum' ? [feature.value] : feature.value
            const labels = values.map(v => {
                const match = fmeta.params.enum.filter(i => i.value===v)
                return match.length ? getLocalizedLabel(match[0].label, i18n.language) : t('?')
            })
            return(prep+labels.join(", "))
        } else if ((valueType==='ref' || valueType==='multiref')  && feature) {
            const values = valueType==='enum' ? [feature.value] : feature.value
            const items = values.map(v => getReferenceDescription(v, fmeta, meta, data, t))
            return(prep + items.join(" + "))
        } else {
            if (feature) {
                if (fmeta.params.format && fmeta.params.format == 'markdown')
                    return(<ReactMarkdown remarkPlugins={[supersub]}>{prep+feature.value}</ReactMarkdown>)
                if (fmeta.params.format && fmeta.params.format == 'html')
                    return(<ReactMarkdown remarkPlugins={[supersub]}>{prep+html2md(feature.value)}</ReactMarkdown>)
                else
                    return prep + feature.value
            }
            else
                return(<>{prep}<Icon name="minus" color="grey" size="small"/> {t('Not set')}</>)
        }
    }

}

export default withTranslation()(ShowFeatureValue)