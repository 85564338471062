import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueIsIntNumber, descFiller, descSlot, getRefTargets, subspecUnique } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, Dropdown as PDropdown, DropdownItemProps, Grid, Header, Icon, Label, Message, Segment, Tab } from 'semantic-ui-react'
import { EditFormProps, addFeature, lastChangeLabel } from '../Edit'
import Input from './Input'
import {FormikComponentProps, getFieldError, setFieldValue} from './FormikHelpers'
import { FieldArray } from 'formik'
import TextArea from './TextArea'
import Dropdown from './Dropdown'
import Checkbox from './Checkbox'
import InputFeatureValue from './InputFeatureValue'

class EditFeatureForm extends Component<EditFormProps> {

    componentDidMount() {
    }

    render() {
        const { params, formik, meta, t, i18n, userMap, history, location } = this.props

        if (!meta['cats']) return null

        const parentIsDeleted = (sid, fid) => {
            if (!sid) return false
            const slot = formik.values.slots.filter(s => s._id === sid)[0]
            if (!slot) return true
            if (!fid || slot.delete) return slot.delete
            const filler = slot.fillers.filter(fl => fl._id === fid)[0]
            if (!filler) return true
            return filler.delete
        }

        return(
            <Grid>
                {params['features'].map(item => {
                    const fmeta = getmeta(meta, 'feature', item.feature)
                    const basetype = fmeta.type
                    const fgroups = formik.values.feats[basetype] || {}
                    return (
                    <>
                    <Grid.Row>
                        <Grid.Column width={16} title={basetype}>
                            <Header as="h3" style={{display: 'inline-block', marginRight: '1em'}}>{getLocalizedLabel(fmeta.label, i18n.language)}</Header>
                            {/* item.slot || item.filler || (item.doc && (typeof fgroups[""] === 'undefined' || fgroups[''].length!=1 || fgroups[''][0].subspec)) ? */
                                item.slot || item.filler ?
                                    <PDropdown options={getRefTargets(meta, formik.values, item, t)}
                                        trigger={(<span><Icon name="plus"/>{t('Add for...')}</span>)}
                                        scrolling={true} button={true} className="blue mini"
                                        selectOnNavigation={false} selectOnBlur={false}
                                        onClick={(e,d) => {d.value=''}}
                                        onChange={(e,d) => addFeature(formik, meta, d.value, basetype)}/>
                                :
                                    <Button type="button" color="blue" size="mini" icon="plus" title={t('Add')}
                                        onClick={(e,d) => addFeature(formik, meta, '', basetype)}/>
                            /* : null */}
                        </Grid.Column>
                    </Grid.Row>
                    {Object.keys(fgroups).map(group => {
                        const flist = fgroups[group]
                        if (!flist.length) return
                        const myGroupCount = flist.length
                        const [slot,filler] = group.split('/')
                        const parentDeleted = parentIsDeleted(slot, filler)
                        if ((slot && item.slot && !filler) || (filler && item.filler) || (!slot && !filler && item.doc)) {
                            return (
                                <>
                                {slot ?
                                    filler ?
                                        <Grid.Row className={parentDeleted ? "deletedField" : ''}>
                                            <Grid.Column width={16}><strong>{descFiller(meta, formik.values, slot, filler, t)}</strong></Grid.Column>
                                        </Grid.Row>
                                    :
                                        <Grid.Row className={parentDeleted ? "deletedField" : ''}>
                                            <Grid.Column width={16}><strong>{descSlot(meta, formik.values, slot, t)}</strong></Grid.Column>
                                        </Grid.Row>
                                : 
                                    item.slot || item.filler ? <Grid.Row><Grid.Column width={16}><strong>{t('Unit')}</strong></Grid.Column></Grid.Row> : null
                                }
                                <FieldArray name={"feats["+basetype+"]["+group+"]"}
                                    render={(subformik) => (
                                    <>
                                    {flist.map((f, fi) => {
                                        const finitial = (formik.initialValues.feats[basetype] && formik.initialValues.feats[basetype][group] && formik.initialValues.feats[basetype][group][fi]) ? 
                                            formik.initialValues.feats[basetype][group][fi] : null
                                        const fpath = "feats['"+basetype+"']['"+group+"']."+fi
                                        const fvalue = fpath+".value"
                                        const fspec = fpath+".subspec"
                                        const fdelete = fpath+".delete"
                                        const flastchange = lastChangeLabel(f, userMap, false)
                                        const deleted = f.delete || parentDeleted
                                        const changed = finitial === null || finitial.value !== f.value || finitial.subspec !== f.subspec || deleted
                                        let subspecParts = fmeta.subspec_required ? 
                                            fmeta.params.subspecs?.map((subsp: any) => getLocalizedLabel(subsp.label, i18n.language)) || ['?'] 
                                            : []
                                        if (fmeta.params?.aggregate) {
                                            const label = getLocalizedLabel(fmeta.params.aggregate.label, i18n.language)
                                            subspecParts.push(fmeta.params.aggregate.default_spec ? `(${label})`: label)
                                        }
                                        return (
                                            <>
                                            <Grid.Row className={deleted ? "deletedField condensed" : changed ? "changed condensed" : "condensed"} title={flastchange}>
                                                <Grid.Column width="2">
                                                    { myGroupCount>1 || f.subspec !== null ?
                                                        <Input name={fspec} inputProps={{disabled: deleted}}
                                                            validate={(v) => subspecUnique(v, flist.map((x, i) => { if (i !== fi && !x.delete) return x.subspec} ), subspecParts)}/>
                                                    : !deleted ? 
                                                        <Button type="button" size="mini" icon="ellipsis horizontal" title={t('Add specification')}
                                                            onClick={(e,d) => {setFieldValue(formik, fspec, String(fi+1), true);e.preventDefault()}}/>
                                                    : null
                                                    }
                                                </Grid.Column>
                                                {/* using checkboxes allows to highlight individually changed options, but it is probably less appealing generally
                                                 valueType === 'flags' ?
                                                    <Grid.Column width="12">
                                                        <Dropdown name={fvalue} options={options} inputProps={{disabled: deleted, multiple: true, selection: true}}/>
                                                    </Grid.Column>
                                                    [[1,2,3,4].map(col => {
                                                        const first = (col-1)*flaggroupsize
                                                        const next = col*flaggroupsize
                                                        const itemgroup = fmeta.params.enum.slice(first,next)
                                                        return (
                                                            <Grid.Column width={3}>
                                                                {itemgroup.map(o => {
                                                                    return <Checkbox name={fvalue} inputProps={{disabled: deleted}}
                                                                                label={getLocalizedLabel(o.label, i18n.language)} value={o.value} 
                                                                                fieldProps={{className: "compactfield"}}/>
                                                                })}
                                                            </Grid.Column>
                                                        )
                                                    })]
                                                :*/
                                                <Grid.Column width="12">
                                                    <InputFeatureValue cunitValues={formik.values} feature={f} fvaluepath={fvalue} fmeta={fmeta} meta={meta} disabled={deleted} nonEmpty
                                                        userMap={userMap}/>
                                                </Grid.Column>
                                                }
                                                <Grid.Column width={2} textAlign="right">
                                                    {changed && finitial !== null && !parentDeleted ?
                                                        <Button type="button" color="yellow" icon="undo alternate" size="mini" title={t('undo')}
                                                            onClick={(e,d) => {
                                                                setFieldValue(formik, fvalue, finitial.value, false)
                                                                setFieldValue(formik, fspec, finitial.subspec, false)
                                                                setFieldValue(formik, fdelete, false, false)
                                                            }}/>
                                                    : !parentDeleted ?
                                                        <Button type="button" color="red" icon="delete" size="mini" title={t('delete')}
                                                            onClick={(e,d) => {
                                                                if (finitial)
                                                                    setFieldValue(formik, fdelete, true, false)
                                                                else
                                                                    subformik.remove(fi)
                                                            }}/>
                                                    : null }
                                                </Grid.Column>
                                            </Grid.Row>
                                            </>
                                        )
                                    })}
                                    </>)
                                }/>
                                </>
                            )
                        }
                    })}
                    </>
                    )
                }
                )}
            </Grid>
        )
    }

}

export default withTranslation()(EditFeatureForm)