/*
 * from https://github.com/turner-industries/formik-semantic-ui/
 */

import React, { Component, Fragment } from 'react'
import { Form, Ref, TextArea as STextArea, FormTextAreaProps } from 'semantic-ui-react'
import { FastField, Field, getIn } from 'formik'
import ErrorMessage from './ErrorMessage'
import { NullRef } from './InputRef'
import { FormikComponentProps, getFieldError, setFieldValue } from './FormikHelpers'

export interface TextAreaComponentProps extends FormikComponentProps {
    inputProps?: FormTextAreaProps
    inputRef?: (el: HTMLTextAreaElement) => void,
    rows?: number
    noHighlighting?: boolean 
}

class TextArea extends Component<TextAreaComponentProps> {

    id: string

    constructor(props) {
        super(props)
        const {id, name} = props
        this.id = id || `field_textarea_${name}`
    }

  render() {
    const {
      name,
      label,
      validate,
      inputProps = {},
      fieldProps = {},
      errorComponent = ErrorMessage,
      inputRef,
      fast,
      rows,
      noHighlighting,
    } = this.props

    const {onChange, ...safeInputProps} = inputProps
    const RefWrapper = inputRef ? Ref : NullRef
    const DesiredField = fast === true ? FastField : Field
    return (
      <DesiredField name={name} validate={validate}>
        {({field, form, meta}) => {
          const error = getFieldError(field, form)
          return (
            <Form.Field error={!!error} {...fieldProps}>
              {!!label && <label htmlFor={this.id}>{label}</label>}
              <RefWrapper innerRef={inputRef || null}>
                <STextArea
                  id={this.id}
                  name={name}
                  rows={rows || 4}
                  {...safeInputProps}
                  value={field.value}
                  className={[!noHighlighting && (field.value !== meta.initialValue) && ( field.value !== '' && !meta.initalValue) ? 'changed' : '', safeInputProps.className].filter(Boolean).join(' ')}
                  onChange={(e, {name, value}) => {
                    setFieldValue(form, name, value, true)
                    Promise.resolve().then(() => {
                      onChange && onChange(e, {name, value})
                    });
                  }}
                  onBlur={form.handleBlur}
                />
              </RefWrapper>
              {error && (
                React.createElement(errorComponent, { message: getIn(form.errors, name) })
              )}
            </Form.Field>
          )
        }}
      </DesiredField>
    )
  }
}

export default TextArea