import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueIsIntNumber, descFiller, descSlot, getRefTargets, collectFeatureOptions, Cunit, Feature, getSlotDescription, createSlotTreeFromRefs } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, Dropdown as PDropdown, DropdownItemProps, Grid, Header, Icon, Label, Message, Segment, Tab } from 'semantic-ui-react'
import { ViewCompProps } from '../View'
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic'
import "./ComponentTerminalView.css"

class ComponentTerminalView extends Component<ViewCompProps> {

    componentDidMount() {
    }

    render() {
        const { config, meta, data, t, i18n } = this.props
        const params = config.params
        const tree = createSlotTreeFromRefs(data.slots, params, meta)
        const defaultSlotHeaderColor = '#3876BF'

        const pickTextColorBasedOnBgColorSimple = (bgColor, lightColor, darkColor) => {

            if (bgColor.startsWith('rgba')) {
                // Get RGB value between parenthesis, and remove any whitespace
                let rgb = bgColor.split(/\(([^)]+)\)/)[1].replace(/ /g, '');

                // map RGB values to variables
                var r = parseInt(rgb.split(',')[0], 10),
                    g = parseInt(rgb.split(',')[1], 10),
                    b = parseInt(rgb.split(',')[2], 10),
                    a;

                // if RGBA, map alpha to variable (not currently in use)
                if (rgb.split(',')[3] !== null) {
                    a = parseInt(rgb.split(',')[3], 10);
                }
            } else {
                var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
                var r = parseInt(color.substring(0, 2), 16); // hexToR
                var g = parseInt(color.substring(2, 4), 16); // hexToG
                var b = parseInt(color.substring(4, 6), 16); // hexToB
            }
            //console.log(bgColor, ((r * 0.299) + (g * 0.587) + (b * 0.114)));
            return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 170) ?
              darkColor : lightColor;
        }

        const slots2columns = (slots, count=8) => {
            const colwidth = Math.floor(16/count)
            return (
                <Grid>
                {slots.map(s => {
                    const childcount = s.children.length>1 ? s.children.length : 1
                    const width = colwidth * s['node_width']
                    const color = s['color'] || defaultSlotHeaderColor
                    const desc = s['colorDesc'] ? getLocalizedLabel(s['colorDesc'], i18n.language) : ''
                    const title = s['label'] || ''
                    const style = {backgroundColor: color, color: pickTextColorBasedOnBgColorSimple(color, '#F3F0CA', 'black')}
                    return (
                        <Grid.Column width={width as SemanticWIDTHS}>
                        <div className="slotHeader" style={style} title={desc}>[{s.name}] <span className="right">{title}</span></div>
                            {childcount>1 ? (
                                <>
                                {s.children.map(f => (
                                    <Segment className="fillerNode">
                                        <p className="fillerHeader">{f.name}</p>
                                        {slots2columns(f.children, s['node_width'])}
                                    </Segment>
                                ))}
                                </>
                            ) : (
                                <>
                                    {getSlotDescription(s.node_data, meta, data).map(label => <Segment className="termNode">{label}</Segment>)}
                                </>
                            )}
                        </Grid.Column>
                    )
                })}
                </Grid>
            )
        }

        return(
            <Segment>
            <Header as="h2">{getLocalizedLabel(config.label, i18n.language)}</Header>
            <div className="terminalView">
            {slots2columns(tree['children'])}
            </div>
            </Segment>
        )
    }

}

export default withTranslation()(ComponentTerminalView)