import React, { Component, ReactNode } from 'react'
import { Grid, Label, StrictGridColumnProps } from 'semantic-ui-react'
import { FeatureQueryParams, Meta, getmeta, getLocalizedLabel, createDefaultFeatureLabel, valueByFormikPath, valueNotEmpty, NameQueryParams } from '../../common'
import { FieldArray } from "formik"
import { withTranslation, WithTranslation } from 'react-i18next'
import Input from './Input'
import Dropdown from './Dropdown'
import Checkbox from './Checkbox'

export const addDefaultName = (query, params) => {
    if (!query.name) query.name = {}
    if (params.method && params.method.length) query.name.method = params.method[0]
    query.name.value = params.value || ''
    query.name.ci = params.ci
}

interface QueryProps extends WithTranslation {
    prefix: string,
    label: string|null,
    params: NameQueryParams,
    meta: Meta,
    formik: any
}

type FeatureQueryProps = QueryProps;

class NameQuery extends Component<FeatureQueryProps> {

    withPrefix(fieldName: string) {
        return `${this.props.prefix}.${fieldName}`
    }

    render () {
        const { meta, formik, prefix, params, i18n, t } = this.props

        if (params.hidden) return null

        const methods = [
            {key: 'exact', text: t('exactly'), value: 'exact'},
            {key: 'prefix', text: t('starts'), value: 'prefix'},
            {key: 'regexp', text: t('reg.exp.'), value: 'regexp'},
            {key: 'fulltext', text: t('fulltext'), value: 'fulltext'}
        ]
        
        let values = valueByFormikPath(prefix, formik.values)
        const mymethods = methods.filter(i => params.method && params.method.includes(i.value))

        let inputProps = {}

        let label = null
        if (typeof params.label !== 'undefined') {
            if (params.label) {
                label = getLocalizedLabel(params.label, i18n.language)
            }
        } else {
            label = t('Unit name')
        }
        if (params.icon) {
            inputProps['icon'] = params.icon
        }

        let columns = 0
        let method: ReactNode = null
        if (mymethods.length > 1) {
            method = (
                <Grid.Column width={2}>
                <Dropdown name={this.withPrefix('name.method')} options={mymethods} inputProps={{defaultValue: mymethods[0].value, compact: true}}/>
                </Grid.Column>
            )
            columns += 2
        }
        let ci: ReactNode = null
        if ('ci' in params) {
            ci = (
                <Grid.Column width={2}>
                <Checkbox name={this.withPrefix('name.ci')} label={t('A=a')}/>
                </Grid.Column>
            )
            columns += 2
        }
        let labelComp: ReactNode = null
        if (label) {
            labelComp = (
                <Grid.Column width={2}>
                <Label htmlFor={this.withPrefix('name.value')} className="fieldlabel">{label}</Label>
                </Grid.Column>
            )
            columns += 2
        }
        
        const width = 16 - columns
        return (
            <React.Fragment>
                <Grid.Row verticalAlign="middle">
                    {labelComp}
                    {method}
                    <Grid.Column width={width as StrictGridColumnProps['width']}>
                    <Input name={this.withPrefix('name.value')} /*validate={(value) => this.validate(value)}*/ inputProps={inputProps}/>
                    </Grid.Column>
                    {ci}
                </Grid.Row>
            </React.Fragment>
        )
    }
}

export default withTranslation()(NameQuery)