import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import StatsCatChart, { colors, extractAttrValues, ErrorList, StatsChartProps } from './StatsCatChart'
import { getLocalizedLabel } from '../../common'
import * as d3 from "d3"
import mapCZ from '../../img/mapCZ.min.svg'
import { ReactSVG } from 'react-svg'

const areaParams = {
    'pohraničí_české': {gid: '#naCPO-g', posAdjust: [0,0]},
    'pohraničí_moravské': {gid: '#naMPO-g', posAdjust: [0,-90]},
    'středočeská': {gid: '#naSTR-g', posAdjust: [0,0]},
    'jihočeská': {gid: '#naJIC-g', posAdjust: [0,30]},
    'západočeská': {gid: '#naZAC-g', posAdjust: [0,-20]},
    'severovýchodočeská': {gid: '#naSVC-g', posAdjust: [0,0]},
    'česko-moravská': {gid: '#naCMO-g', posAdjust: [0,-50]},
    'slezská': {gid: '#naSLE-g', posAdjust: [0,0]},
    'východomoravská': {gid: '#naVYM-g', posAdjust: [0,0]},
    'středomoravská': {gid: '#naSTM-g', posAdjust: [0,50]}
}

const createBarChart = (width, height, data, domain, posAdjust) => {
    const margin = 20
    const xAdjust = -(width / 2) + posAdjust[0]
    const yAdjust = -(height / 2) + posAdjust[1]
    const chart = d3.create('svg:g')
                        .attr("transform", "translate(" + xAdjust + ", " + yAdjust + ")")
                        
    chart.append("rect")
        .attr("width", width)
        .attr("height", height)
        .style("fill", "white")
        .style("fill-opacity", 0.65)

    let x = d3.scaleBand()
        .domain(d3.range(data.length))
        .range([margin, width - margin])
        .padding(0.05)
    
    let y = d3.scaleLinear()
        //.domain([0, d3.max(data, d => d.value)]).nice()
        .domain(domain)
        .range([height - margin, margin])

    chart.selectAll("bar")
            .data(data)
          .enter().append("rect")
            .style("fill", (d) => d.color)
            .attr("x", (d, i) => x(i))
            .attr("width", x.bandwidth())
            .attr("y", (d) => y(d.value))
            .attr("height", (d) => y(0) - y(d.value))

    /*chart.selectAll("label")
            .data(data)
          .enter().append("text")
            .text((d) => d.form)
            .attr("x", (d, i) => x(i))
            .attr("y", (d) => y(0))
            .style("text-anchor", "start")
            .style("font-size", "40px")
            .style("opacity", "1").attr("fill","black")*/

    /*chart.node().appendChild(
        <foreignObject>
            <body>
                <FqTooltip absstats={absolute} values={data} language={i18n.language} t={t}/>
            </body>
        </foreignObject>
    )*/

    return chart
}

const insertCharts = (svg, width, height, data, domain, cunames, valuedesc, i18n) => {

    const catMax = (cat) => {
        let max = 0
        for (let k of Object.keys(cat)) {
            if (k !== 'name' &&  cat[k] > max) max = cat[k]
        }
        return max
    }
    
    const maxval = Math.max(...data.map(catMax))
    if (domain[1] === 'auto' || maxval > domain[1])
        domain = maxval > 26 ? [0, Math.ceil(maxval/10)*10] : [0, Math.ceil(maxval)]
        
    for (let area of Object.keys(valuedesc)) {
        if (!areaParams[area]) continue
        const container = svg.querySelector(areaParams[area].gid)
        if (!container) {
            console.log("Not found in map SVG:", areaParams[area].gid)
            continue
        }
        //const label = getLocalizedLabel(valuedesc[area]['label'], i18n.language)
        const abbr = getLocalizedLabel(valuedesc[area]['abbr'], i18n.language)
        const catdata = data.filter(x => x.name === abbr)[0]
        let chartdata: Array<{}> = []
        let sum = 0
        for (let i in cunames) {
            let form = cunames[i]
            chartdata.push({form: form, value: catdata[form], color: colors[i]})
            sum += catdata[form]
        }
        if (sum > 0) {
            const chart = createBarChart(width, height, chartdata, domain, areaParams[area].posAdjust)
            container.appendChild(chart.node())
        }
    }
}

class StatsCZMapChart extends Component<StatsChartProps> {

    fqlist = Array<{}>()
    absolute: {}|false = false
    cunames: string[] = []
    errors: string[] = []

    constructor(props: StatsChartProps) {
        super(props)
        const { cunits, meta, params, corpus, attribute, relative, directData, directAbsData, directSelect, i18n } = props
        const valuedesc = props.values

        if (directData && directAbsData) {
            this.cunames = directSelect ? directSelect : Object.keys(directData[0]).filter(x => x !== 'name')
            this.fqlist = directData
            this.absolute = directAbsData
        } else if (cunits && corpus && attribute) {
            let values = extractAttrValues(cunits, params, corpus, attribute, meta, valuedesc, relative, i18n)
            this.cunames = values.cunames
            this.fqlist = values.fqlist
            this.absolute = values.absolute
            this.errors = values.errors
        }
    }

    render() {
        const { relative, domain, t, i18n, values, width, height, meta } = this.props

        //const unit = relative ? " i.p.m." : ""
        let mydomain = domain ? domain : [0, 'auto']

        if (this.errors.length) return ErrorList(this.errors, t)
        
        //let chartWidth = width ? width : "100%"
        //let chartHeight = height ? height : 150

        let subwidth = 500
        let subheight = 300

        return (
            <>
                <StatsCatChart legend='bottom' values={values} directData={this.fqlist} directAbsData={this.absolute} directSelect={this.cunames} domain={domain} meta={meta}
                    relative={relative} width={width} height={height}/>
                <ReactSVG src={mapCZ} style={{marginTop: '20px'}} 
                    afterInjection={(error, svg) => insertCharts(svg, subwidth, subheight, this.fqlist, mydomain, this.cunames, values, i18n)}/>
            </>
        )
    }
}

export default withTranslation()(StatsCZMapChart)