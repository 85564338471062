import React from "react"
import { Container, Dimmer, Loader as SLoader, Segment } from "semantic-ui-react"
import { withTranslation, WithTranslation } from 'react-i18next'

function ConfigLoader({ t }) {
    return (
        <Dimmer active inverted>
            <SLoader>{t('Loading configuration...')}</SLoader>
        </Dimmer>
    )
}

export default withTranslation()(ConfigLoader)