import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, valueIsNumInRange, getmeta } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiViewCompProps} from './CEUiView'
import Button from '../form/Button'

class ConfigEditorUiViewCompStrucChart extends Component<ConfigEditorUiViewCompProps> {

    render() {
        const { meta, editing, locale, formik, cunitTypes, t, i18n, comp } = this.props
        const lang = i18n.language
        const prefix = this.props.formikprefix

        if (!(meta && meta.cats && meta.cats['feature'])) return null

        // TODO: filter out also slot-irrelevant features
        const featureOptions = Object.keys(meta.cats['feature']).reduce((res: DropdownItemProps[], type) => {
            const u = meta.cats['feature'][type]
            if (!u.subdef_required
                /* this is really complex and may be buggy: we needed to count with prefix overlaps from both sides? */
                    && (!cunitTypes || !u.params.restr_owner || !u.params.restr_owner.cunit_type || u.params.restr_owner.cunit_type.filter((t: string) => 
                        (cunitTypes.includes(t) || cunitTypes.filter(x => x.startsWith(t+':')).length>0 || cunitTypes.filter(x => t.startsWith(x+':')).length>0)
                        ).length>0)
                    ) {
               res.push({key: type, value: type, text: getLocalizedLabel(u.label, lang)+' ['+type+']'})
            }
            return res
        }, [])

        const ptrFeatureOptions = featureOptions.reduce((res: DropdownItemProps[], f) => {
            const u = meta.cats['feature'][f.key]
            if (u.params.value_type==='ref' && u.params.ref_targets.includes("slot")) res.push(f)
            return res
        }, [])

        // TODO: filter out irrelevant slot types(?)
        const slotTypes: DropdownItemProps[] = Object.keys(meta.cats['slot']).reduce((res: DropdownItemProps[], type) => {
            const s = meta.cats['slot'][type]
            res.push({key: type, value: type, text: getLocalizedLabel(s.label, lang)})
            return res
        }, [])
        
        return (
            <>
            <Grid.Row>
                <Grid.Column width={2}><div className="label pad">{t('Label')}</div></Grid.Column>
                <Grid.Column width={14}><Input key={prefix+".label."+locale} name={prefix+".label."+locale} inputProps={{disabled: !editing, placeholder: t('undefined')}}/></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}>
                <div className="label pad">{t('Included slots')}</div>
                </Grid.Column>
                <Grid.Column width={13}>
                    <Dropdown name={prefix+".params.included_types"}
                        inputProps={{disabled: !editing, multiple: true}} options={slotTypes}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}>
                <div className="label pad">{t('Label by')}</div>
                </Grid.Column>
                <Grid.Column width={13}>
                    <Dropdown name={prefix+".params.label_feature"}
                        inputProps={{disabled: !editing, placeholder: t('undefined')}} options={featureOptions}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}>
                <div className="label pad">{t('Parent pointer')}</div>
                </Grid.Column>
                <Grid.Column width={13}>
                    <Dropdown name={prefix+".params.parent_ptr"}
                        inputProps={{disabled: !editing, placeholder: t('undefined')}} options={ptrFeatureOptions}/>
                    <div><p>{t('(for dependency trees; overrides any relation to explicitly embedded children)')}</p></div>
                </Grid.Column>
            </Grid.Row>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiViewCompStrucChart)