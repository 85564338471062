import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, getmeta } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiSearchCompProps} from './CEUiSearch'
import PathSpec from '../form/PathSpec'
import { setFieldValue } from '../form/FormikHelpers'
import Button from '../form/Button'

class ConfigEditorUiSearchRenderResultList extends Component<ConfigEditorUiSearchCompProps> {

    render() {
        const { meta, editing, locale, t, i18n, params, formik, formikprefix } = this.props
        const lang = i18n.language

        if (!(meta && meta.cats && meta.cats['feature'])) return null

        if (params.columns === undefined) {
            setFieldValue(formik, formikprefix+'.columns', [], false)
            return null
        }

        if (params.detail_view === undefined) {
            setFieldValue(formik, formikprefix+'.detail_view', [], false)
            return null
        }

        const baseAttributes: DropdownItemProps[] = [
            {key: '_name', text: t('Unit name'), value: '_name'}
        ]

        const allFeatOptions = baseAttributes.concat(Object.keys(meta.cats['feature']).reduce((res: DropdownItemProps[], type) => {
            const u = meta.cats['feature'][type]
            if (type && !u.subdef_required) {
                res.push({key: type, text: getLocalizedLabel(u['label'], lang)+" ["+type+"]", value: type})
            }
            return res
        }, []))

        const allViewOptions = Object.keys(meta.uis['view']).reduce((res: DropdownItemProps[], type) => {
            const u = meta.uis['view'][type]
            if (type && !u.subdef_required) {
                res.push({key: type, text: getLocalizedLabel(u['label'], lang)+" ["+type+"]", value: type})
            }
            return res
        }, [])

        const newColumn = {label: {}, path: ''}
        
        return (
            <>
            <Grid.Row>
                <Grid.Column width={4}>
                    <Checkbox name={formikprefix+".view"} label={t('link to view')} inputProps={{disabled: !editing}}/>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Checkbox name={formikprefix+".edit"} label={t('link to edit')} inputProps={{disabled: !editing}}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}><div className="label">{t('Columns')}</div></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}><div className="">{t('label')}</div></Grid.Column>
                <Grid.Column width={2}><div className="">{t('target')}</div></Grid.Column>
                <Grid.Column width={11}><div className="">{t('feature')}</div></Grid.Column>
            </Grid.Row>
            <FieldArray name={formikprefix+".columns"}
                render={(subformik) => (
                    <>
                    {params.columns.map((column: any, cindex: number) => {
                        const fmeta = column.path.startsWith('_') || column.path == '' ? {type: column.path} : getmeta(meta, 'feature', column.path)
                        return (
                            <Grid.Row className="condensed">
                                <Grid.Column width={3}>
                                    <Input name={formikprefix+".columns."+cindex+".label."+locale} inputProps={{disabled: !editing}}/>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Input name={formikprefix+".columns."+cindex+".target"} inputProps={{disabled: !editing}}/>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <PathSpec name={formikprefix+".columns."+cindex+".path"}
                                        basePath={fmeta.type} options={allFeatOptions}
                                        inputProps={{disabled: !editing}} validate={valueNotEmpty}/>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    { editing ?
                                    <>
                                    <Button type="button" icon="angle up" color="blue" size="mini"
                                        disabled={!editing || cindex === 0}
                                        onClick={(e,d) => subformik.swap(cindex, cindex-1)}/>
                                    <Button type="button" icon="angle down" color="blue" size="mini"
                                        disabled={!editing || cindex === params.columns.length-1}
                                        onClick={(e,d) => subformik.swap(cindex, cindex+1)}/>
                                    <Button type="button" icon="delete" color="red" size="mini"
                                        disabled={!editing}
                                        onClick={(e,d) => subformik.remove(cindex)}/>
                                    </> : null }
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }
                    )}
                    { editing ? 
                    <Grid.Row className="condensed">
                        <Grid.Column width="16">
                            <Button icon="plus" type="button" size="tiny" color='blue' 
                                onClick={(e,d) => subformik.push(newColumn)} disabled={!editing}/>
                        </Grid.Column>
                    </Grid.Row> 
                    : null}
                </>
                )}/>
            <Grid.Row>
                <Grid.Column width={4}><div className="label">{t('Details')}</div></Grid.Column>
            </Grid.Row>
            <FieldArray name={formikprefix+".detail_view"}
                render={(subformik) => (
                    <>
                    {params.detail_view.map((view: any, index: number) => {
                        return (
                            <Grid.Row className="condensed">
                                <Grid.Column width={13}>
                                    <Dropdown name={formikprefix+".detail_view."+index} options={allViewOptions}
                                        inputProps={{disabled: !editing}}/>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    { editing ?
                                    <>
                                    <Button type="button" icon="angle up" color="blue" size="mini"
                                        disabled={!editing || index === 0}
                                        onClick={(e,d) => subformik.swap(index, index-1)}/>
                                    <Button type="button" icon="angle down" color="blue" size="mini"
                                        disabled={!editing || index === params.detail_view.length-1}
                                        onClick={(e,d) => subformik.swap(index, index+1)}/>
                                    <Button type="button" icon="delete" color="red" size="mini"
                                        disabled={!editing}
                                        onClick={(e,d) => subformik.remove(index)}/>
                                    </> : null }
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }
                    )}
                    { editing ? 
                    <Grid.Row className="condensed">
                        <Grid.Column width="16">
                            <Button icon="plus" type="button" size="tiny" color='blue' 
                                onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                        </Grid.Column>
                    </Grid.Row> 
                    : null}
                </>
                )}/>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiSearchRenderResultList)
