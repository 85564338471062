import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Meta, RatatoskRequest, RatatoskResponse, valueByFormikPath, valueNotEmpty } from '../common'
import { Container, Grid, Message, Segment, Loader } from 'semantic-ui-react'
import { json } from 'stream/consumers'
import Form from './form/Form'
import Input from './form/Input'
import Checkbox from './form/Checkbox'
import './CNCDictForm.css'
import { response2groups } from './CNCDict'

interface CNCDictFormProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance,
    form: string,
    units: any[],
    prefix: string,
    formik: any
}

interface CNCDictFormState {
    extHomonyms: any[],
    loading: boolean,
    errorMsg: string
}

class CNCDictForm extends Component<CNCDictFormProps, CNCDictFormState> {

    state = {
        extHomonyms: [] as any[],
        loading: false,
        errorMsg: ''
    }

    componentDidMount() {
        const { prefix, form, units, formik } = this.props
        //this.componentDidUpdate(this.props, this.state)
        const sublemma = units[0].sublemma
        let request: RatatoskRequest = {
            query: {
                'type': ':cncdict:form',
                'feats': [
                    {type: ':form:attr:cnc:w:word', value: form, ci: true},
                    {__not: {type: ':form:attr:cnc:w:sublemma', value: sublemma}}
                ]
            },
            page: { from: 0, size: 1000 },
            feats: [':form:attr:cnc:w', ':form:cncdict:domain']
        }
        request['_client'] = import.meta.env.APP_NAME + '/' + import.meta.env.APP_VERSION
        this.setState({loading: true})
        const url = 'cunits/_view'
        this.props.http.post(url, request)
            .then(response => {
                const groups = response2groups(response.data.data)
                const units = Object.keys(groups).reduce((arr: any[], grp: string) => { 
                    groups[grp].forEach(u => arr.push(u))
                    return arr
                }, [])
                this.setState({extHomonyms: units, errorMsg: '', loading: false})
            }).catch(err => {
                this.setState({loading: false})
                if (err.response) {
                    this.setState({errorMsg: err.response.data.message, loading: false})
                }
            })
    }

    componentDidUpdate(prevProps: Readonly<CNCDictFormProps>, prevState: Readonly<CNCDictFormState>, snapshot?: any): void {
        if (this.props!==prevProps)
            this.componentDidMount()
    }

    render() {
        const { prefix, formik, form, units, t } = this.props
        const { extHomonyms, loading } = this.state

        /*const active = valueByFormikPath(prefix+'.active', formik.values)
        const form = valueByFormikPath(prefix+'.form', formik.values)
        const tag = valueByFormikPath(prefix+'.tag', formik.values)
        const innerHomonyms = valueByFormikPath(prefix.replace(/\.[0-9]+$/,''), formik.values).reduce((res, u) => {
            if (u.form===form && u.tag!==tag) res.push(u)
            return res
        }, [])*/

        return(
            <Segment className='grey'>
            <Grid>
            <Grid.Row verticalAlign='top'>
                <Grid.Column width={2}>
                    {form}
                </Grid.Column>
                <Grid.Column width={8}>
                    { units.map(u => {
                        const myprefix = prefix+'.'+u.index
                        return (
                            <Container className='single-item'>
                            <Checkbox name={myprefix+".active"} label={u.tag} fieldProps={{className: "compactfield"}} inputProps={{}}/>
                            <Checkbox name={myprefix+".domain.written"} label={t('written')} fieldProps={{className: "compactfield"}} inputProps={{}}/>
                            <Checkbox name={myprefix+".domain.spoken"} label={t('spoken')} fieldProps={{className: "compactfield"}} inputProps={{}}/>
                            </Container>        
                        )
                    })
                    }
                </Grid.Column>
                <Grid.Column width={4}>
                    { loading ? (<Loader active inline/>) 
                    : (
                        <ul>
                            {extHomonyms.map(u => (
                                <li><span className="ext-homonym">{u.lemma}/{u.sublemma} {u.tag}</span></li>
                            ))}
                        </ul>
                    )}
                </Grid.Column>
            </Grid.Row>
            </Grid>
            </Segment>
        )
    }

}

export default withTranslation()(CNCDictForm)