import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
//import Backend from "i18next-xhr-backend"
import Backend from "i18next-http-backend"
import { initReactI18next } from 'react-i18next'
//import translationCs from "./locales/cs/translation.json"
//import translationEn from "./locales/en/translation.json"

i18n
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    debug: false,
    load: 'languageOnly',
    react: {useSuspense: false},
    lng: navigator.language.split('-')[0],
    fallbackLng: "en", // use en if detected lang is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // not needed for react
    },
    /*resources: {
      en: {
        translation: require('./locales/en/translation.json')
      },
      cs: {
        translation: require('./locales/cs/translation.json')
      }
    },*/
    backend: {
      loadPath: import.meta.env.BASE_URL+"locales/{{lng}}/{{ns}}.json"
    },

    /*resources: {
     en: { translations: translationEn },
     cs: { translations: translationCs }
    },*/
    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation"
  })

export default i18n
