import React, { Component } from 'react'
import { FormikHelpers } from 'formik'
import { Segment, Grid, Message, Header } from 'semantic-ui-react'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import Form from './form/Form'
import Input from './form/Input'
import Button from './form/Button'
import { Redirect } from 'react-router-dom'

interface LoginFormProps extends WithTranslation {
    http: AxiosInstance,
    location: any,
    reload: () => void
}

interface LoginFormState {
    username: string,
    authorized: boolean,
    errorMsg: string
}

interface LoginFormValues {
    username: string,
    password: string
}

class LoginForm extends Component<LoginFormProps, LoginFormState> {

    state : LoginFormState = {
        username: "",
        authorized: false,
        errorMsg: ""
    }

    requireValue (value: any) : string {
        const { t } = this.props
        let error: string = ""
        if (!value) {
            error = t("Value required")
        }
        return error
    }

    login (values: LoginFormValues, formik: FormikHelpers<LoginFormValues> ) {
        const { t } = this.props
        this.setState({username: values.username})
        this.props.http.post('login', {'username': values.username, 'password': values.password})
            .then(response => {
                formik.setSubmitting(false)
                this.setState({authorized: true})
                this.props.reload()
            }
            ).catch(err => {
                console.log(err)
                if (err.response) {
                    // UNAUTHORIZED
                    if (err.response.status===401) {
                        //this.setState({authorized: false, errorMsg: 'Invalid username or password.'})
                        formik.setFieldError('password', t('Invalid username or password.'))
                    } else {
                        this.setState({errorMsg: err.response.data.message})
                        //formik.setFieldError('password', 'Error: '+ this.state.errorMsg)
                    }
                } else {
                    this.setState({errorMsg: t('Cannot connect to the server.')})
                    //formik.setFieldError('password', 'Error: '+ this.state.errorMsg)
                }
                formik.setSubmitting(false)
            })
    }

    render () {
        const { t } = this.props
        let { from } = this.props.location.state || { from: { pathname: "/" } }

        if (this.state.authorized) {console.log(from);return(<Redirect to={from} />)}

        return (
            <Grid textAlign='center'>
                <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2'>{t('User login')}</Header>
                { this.state.errorMsg && <Message error icon='exclamation triangle' header={t('Error')} content={this.state.errorMsg}/>}
                <Form className="error large"
                    initialValues = {{'username': this.state.username, 'password': ''}}
                    onSubmit={(values, formikApi) => {
                        this.login(values, formikApi)
                    }}>
                    <Segment stacked color="grey">
                        <Input name="username" validate={(value) => this.requireValue(value)} 
                            inputProps={{fluid: true, icon: 'user', iconPosition: 'left', placeholder: t('username')}}/>
                        <Input name="password" validate={(value) => this.requireValue(value)} 
                            inputProps={{type:"password", fluid: true, icon: 'lock', iconPosition: 'left', placeholder: t('password')}} />
                        <Button.Submit fluid size='large'>{t('Login')}</Button.Submit>
                    </Segment>
                </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

export default withTranslation()(LoginForm)
