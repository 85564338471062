import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, getmeta, valueIsNumInRange, valueByFormikPath } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiEditorCompProps} from './CEUiEditor'
import PathSpec from '../form/PathSpec'
import { setFieldValue } from '../form/FormikHelpers'
import Button from '../form/Button'

class ConfigEditorUiEditorCompSlotFillerForm extends Component<ConfigEditorUiEditorCompProps> {

    render() {
        const { meta, editing, locale, t, i18n, params, formikprefix, formik, cunitTypes } = this.props
        const lang = i18n.language

        const values = valueByFormikPath(formikprefix, formik.values)

        const [slotFeatureTypes, fillerFeatureTypes] = Object.keys(meta.cats['feature']).reduce((res: DropdownItemProps[][], type) => {
            const u = meta.cats['feature'][type]
            if (!u.subdef_required
                /* this is really complex and may be buggy: we needed to count with prefix overlaps from both sides? */
                    && (!cunitTypes || !u.params.restr_owner || !u.params.restr_owner.cunit_type || u.params.restr_owner.cunit_type.filter((t: string) => 
                        (cunitTypes.includes(t) || cunitTypes.filter(x => x.startsWith(t+':')).length>0 || cunitTypes.filter(x => t.startsWith(x+':')).length>0)
                        ).length>0)
                    ) {
                if (!u.params.restr_owner || u.params.restr_owner.slot)
                    res[0].push({key: type, value: type, text: getLocalizedLabel(u.label, lang)+' ['+type+']'})
                if (!u.params.restr_owner || u.params.restr_owner.filler)
                    res[1].push({key: type, value: type, text: getLocalizedLabel(u.label, lang)+' ['+type+']'})
            }
            return res
        }, [[],[]])

        const newFeature = {feature: ''}

        return (
            <>
            <Grid.Row>
                <Grid.Column width={16}><div className="label">{t('Slot features')}</div></Grid.Column>
            </Grid.Row>                
            <FieldArray name={formikprefix+".params.slot_features"}
                render={(subformik) => (
                    <>
                    {values.params.slot_features.map((item: any, findex: number) => {
                        const fmeta = getmeta(meta, 'feature', item.feature)
                        return (
                            <Grid.Row className="condensed">
                            <Grid.Column width={12}>
                                <Dropdown name={formikprefix+".params.slot_features."+findex+".feature"} validate={valueNotEmpty}
                                        inputProps={{disabled: !editing}} options={slotFeatureTypes}/>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right">
                                { editing ? 
                                <>
                                <Button type="button" icon="angle up" color="blue" size="mini"
                                    disabled={!editing || findex === 0}
                                    onClick={(e,d) => subformik.swap(findex, findex-1)}/>
                                <Button type="button" icon="angle down" color="blue" size="mini"
                                    disabled={!editing || findex === values.params.slot_features.length-1}
                                    onClick={(e,d) => subformik.swap(findex, findex+1)}/>
                                <Button type="button" icon="delete" color="red" size="mini"
                                    disabled={!editing}
                                    onClick={(e,d) => subformik.remove(findex)}/>
                                </> : null}
                            </Grid.Column>
                            </Grid.Row>
                        )
                    })}
                    <Grid.Row className="condensed">
                        <Grid.Column width={16}>
                        { editing ? <Button icon="plus" type="button" size="tiny" color='blue' 
                            onClick={(e,d) => subformik.push(newFeature)} disabled={!editing}/> : null}
                        </Grid.Column>
                    </Grid.Row>
                    </>
                    )
                }/>
            <Grid.Row>
                <Grid.Column width={16}><div className="label">{t('Filler features')}</div></Grid.Column>
            </Grid.Row>                
            <FieldArray name={formikprefix+".params.filler_features"}
                render={(subformik) => (
                    <>
                    {values.params.filler_features.map((item: any, findex: number) => {
                        const fmeta = getmeta(meta, 'feature', item.feature)
                        return (
                            <Grid.Row className="condensed">
                            <Grid.Column width={12}>
                                <Dropdown name={formikprefix+".params.filler_features."+findex+".feature"} validate={valueNotEmpty}
                                        inputProps={{disabled: !editing}} options={fillerFeatureTypes}/>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right">
                                { editing ? 
                                <>
                                <Button type="button" icon="angle up" color="blue" size="mini"
                                    disabled={!editing || findex === 0}
                                    onClick={(e,d) => subformik.swap(findex, findex-1)}/>
                                <Button type="button" icon="angle down" color="blue" size="mini"
                                    disabled={!editing || findex === values.params.filler_features.length-1}
                                    onClick={(e,d) => subformik.swap(findex, findex+1)}/>
                                <Button type="button" icon="delete" color="red" size="mini"
                                    disabled={!editing}
                                    onClick={(e,d) => subformik.remove(findex)}/>
                                </> : null}
                            </Grid.Column>
                            </Grid.Row>
                        )
                    })}
                    <Grid.Row className="condensed">
                        <Grid.Column width={16}>
                        { editing ? <Button icon="plus" type="button" size="tiny" color='blue' 
                            onClick={(e,d) => subformik.push(newFeature)} disabled={!editing}/> : null}
                        </Grid.Column>
                    </Grid.Row>
                    </>
                    )
                }/>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiEditorCompSlotFillerForm)