import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import i18n from 'i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorComponentProps} from './ConfigEditor'
import ConfigEditorUiEditorCompFeatureForm from './CEUiEditorCompFeatureForm'
import ConfigEditorUiEditorCompSlotFillerForm from './CEUiEditorCompSlotFillerForm'
import { setFieldValue } from '../form/FormikHelpers'
import Button from '../form/Button'

export interface ConfigEditorUiEditorCompProps extends ConfigEditorComponentProps {
    params: any,
    formikprefix: string,
    cunitTypes: string[]
}

class ConfigEditorUiEditor extends Component<ConfigEditorComponentProps> {

    render() {
        const { formik, user, meta, editing, locale, t, i18n } = this.props
        const lang = i18n.language

        if (!(meta && meta.resources && meta.cats && meta.cats['cunit'])) return null

        const newComponent = { comp: '', params: { corpus: '', attr: ''}}

        const compEditors = {
            FeatureForm: ConfigEditorUiEditorCompFeatureForm,
            SlotFillerForm: ConfigEditorUiEditorCompSlotFillerForm
        }
        
        const componentOptions = [
            {key: 'FeatureForm', text: t('Feature form'), value: 'FeatureForm'},
            {key: 'SlotFillerForm', text: t('Slot and filler form'), value: 'SlotFillerForm'}
        ]

        const unitTypes = Object.keys(meta.cats['cunit']).map(type => {
            const u = meta.cats['cunit'][type]
            return {key: type, value: type, text: getLocalizedLabel(u.label, lang)+' ['+type+']'}
        })

        return (
            <Segment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header size='medium'>{t('Unit types')}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Dropdown name={"params.cunit_type"}
                                inputProps={{disabled: !editing, multiple: true}} options={unitTypes}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header size='medium'>{t('Components')}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <FieldArray name={"params.components"}
                        render={(subformik) => (
                            <Grid.Row>
                            <Grid.Column width={16}>
                                {Array.isArray(formik.values.params.components) ?
                                 formik.values.params.components.map((comp: any, cindex: number) => {
                                    const CompEditor = compEditors[comp.comp] || React.Fragment
                                    return (
                                        <Segment className="grey">
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={1}>
                                                <div className="label pad">[{cindex+1}]</div>
                                                </Grid.Column>
                                                <Grid.Column width={11}>
                                                <Dropdown name={"params.components."+cindex+".comp"} validate={valueNotEmpty}
                                                    inputProps={{disabled: !editing}} options={componentOptions}/>
                                                </Grid.Column>
                                                <Grid.Column width={4} textAlign="right">
                                                    { editing ? 
                                                    <>
                                                    <Button type="button" icon="angle up" color="blue" size="mini"
                                                        disabled={!editing || cindex === 0}
                                                        onClick={(e,d) => subformik.swap(cindex, cindex-1)}/>
                                                    <Button type="button" icon="angle down" color="blue" size="mini"
                                                        disabled={!editing || cindex === formik.values.params.components.length-1}
                                                        onClick={(e,d) => subformik.swap(cindex, cindex+1)}/>
                                                    <Button type="button" icon="delete" color="red" size="mini"
                                                        disabled={!editing}
                                                        onClick={(e,d) => subformik.remove(cindex)}/>
                                                    </> : null}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={2}>
                                                <div className="label pad">{t('Label')}</div>
                                                </Grid.Column>
                                                <Grid.Column width={14}>
                                                    <Input key={"params.components."+cindex+".label."+locale} name={"params.components."+cindex+".label."+locale} 
                                                        inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <CompEditor {...this.props} comp={comp} formikprefix={"params.components."+cindex} cunitTypes={formik.values.params.cunit_type}/>
                                        </Grid>
                                        </Segment>
                                    )
                                }) : null}
                                { editing ? <Button icon="plus" type="button" size="tiny" color='blue' 
                                    onClick={(e,d) => subformik.push(newComponent)} disabled={!editing}/> : null}
                            </Grid.Column>
                            </Grid.Row>
                        )}
                    />
                </Grid>
            </Segment>
        )
    }
}

export default withTranslation()(ConfigEditorUiEditor)