import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo } from '../common'
import { Container, Tab, Header } from 'semantic-ui-react'
import ConfigTreeView from './ConfigTreeView'
import ConfigEditor from './ConfigEditor/ConfigEditor'
import { userInfo } from 'os'

export const defaultPath = "/config/cats/feature/"

interface ConfigProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance,
    user: UserInfo|null,
    reload: () => void
}


const classes = {
    cats: {
        feature: 'Features',
        cunit: 'Units',
        slot: 'Slots',
        filler: 'Fillers'
    },
    uis: {
        search: 'Search forms',
        view: 'Views',
        edit: 'Editors'
    },
    resources: {
        corpus: 'Corpora',
        struct_attr: 'Structure attributes'
    }
}

class Config extends Component<ConfigProps> {

    render() {
        const { location, history, meta, t, user } = this.props

        let urlparams = location.pathname.replace(/^\/config\//,"").split('/')
        let curtype = urlparams.shift() as string
        let cursubtype = urlparams.shift() as string
        const newType = urlparams[urlparams.length-1] === '_new' ? true : false
        if (newType) urlparams.pop()
        if (!classes[curtype]) return (<Redirect to={defaultPath}/>)
        if (!classes[curtype][cursubtype]) return (<Redirect to={'/config/'+curtype+'/'+Object.keys(classes[curtype])[0]}/>)
        if (!meta || !meta[curtype] || !meta[curtype][cursubtype]) return (null)
        //if (!(meta[curtype] && meta[curtype][cursubtype])) // && Object.keys(meta[curtype]).length
        //    return(<Redirect to={defaultPath}/>)
        let spectype = ''
        urlparams = urlparams.filter((x) => x !== '')
        if (urlparams.length) {
            urlparams[0] = urlparams[0].replace('_', '')
            spectype = urlparams.join(':')
            if (!newType && !meta[curtype][cursubtype][spectype])
                return(<Redirect to={defaultPath}/>)
        }

        let panes: object[] = []
        let activeIndex = 0
        let i = 0
        for (let subtype in classes[curtype]) {
            const label = classes[curtype][subtype]
            const list = meta[curtype][subtype]
            const key = curtype+'-'+subtype
            if (subtype===cursubtype) activeIndex = i
            i++

            panes.push({ menuItem: t(label), path: subtype, render: () =>
                    <Tab.Pane>
                        { spectype || newType ? <ConfigEditor {...this.props} otype={curtype} osubtype={cursubtype} spectype={spectype} newtype={newType}/> : (
                        <>
                        <Header size='large'>{t(label)}</Header>
                        <ConfigTreeView {...this.props} otype={curtype} osubtype={cursubtype} list={list}/>
                        </>
                        )}
                    </Tab.Pane>
                })
        }
        
        return (
            <Container>
            <Tab menu={{ fluid: true, vertical: true, tabular: false }} panes={panes} activeIndex={activeIndex}
                onTabChange={(e,d) => {
                    if (d.activeIndex !== undefined) history.push('/config/'+curtype+'/'+panes[d.activeIndex]['path'])
                }}/>
            </Container>
        )
    }

}

export default withTranslation()(Config)