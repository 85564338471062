import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueInOptions, valueByFormikPath, createDefaultFeatureLabel, valueNotEmpty, valueIsIntNumber } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, DropdownItemProps, Dropdown as PDropdown, Grid, Header, Message, Segment, Tab, Table } from 'semantic-ui-react'
import { addFeature, EditFormProps, lastChangeLabel } from '../Edit'
import { FieldArray } from 'formik'
import { setFieldValue } from './FormikHelpers'
import Input from './Input'
import Dropdown from './Dropdown'
import { Icon } from 'semantic-ui-react'
import ConfigEditorUiSearchCompSlotQuery from '../ConfigEditor/CEUiSearchCompSlotQuery'
import TextArea from './TextArea'
import InputFeatureValue from './InputFeatureValue'
import EditSlotFillerFormFeatures from './EditSlotFillerFormFeatures'
import EditFillerForm from './EditFillerForm'

class EditSlotForm extends Component<EditFormProps> {

    componentDidMount() {
    }

    render() {
        const { formik, meta, t, i18n, params, userMap, history, location } = this.props
        const slots = formik.values.slots
        const features = formik.values.feats

        const slotTypes: DropdownItemProps[] = Object.keys(meta.cats['slot']).reduce((res: DropdownItemProps[], type) => {
            const s = meta.cats['slot'][type]
            if (!s.subdef_required)
                res.push({key: type, value: type, text: getLocalizedLabel(s.label, i18n.language)})
            return res
        }, [])

        const fillerTypes: DropdownItemProps[] = Object.keys(meta.cats['filler']).reduce((res: DropdownItemProps[], type) => {
            const f = meta.cats['filler'][type]
            if (!f.subdef_required)
                res.push({key: type, value: type, text: getLocalizedLabel(f.label, i18n.language)})
            return res
        }, [])

        const filterFlTypes = (types: DropdownItemProps[], params) => {
            if (!(params['restr_contents'] && params['restr_contents']['filler_type']))
                return types
            const allowed = params['restr_contents']['filler_type']
            return (types.filter(o => {
                const value = o.value as string
                return allowed.includes(value) || allowed.some(t => value.startsWith(t+':'))
            }))
        }

        const newSlotNum = slots.reduce((max, s) => { 
            if (s._id.startsWith('__new_')) {
                const n = parseInt(s._id.substr(6))
                if (n > max) max = n
            }
            return max
        }, 0) + 1

        const newSlot = {
            _id: '__new_' + newSlotNum,
            _pos: slots.length+1,
            _type: '',
            name: String(slots.length + 1),
            type: '',
            fillers: [],
            delete: false
        }

        return(
            <div>
            <FieldArray name={"slots"} render={(subformik) => (
                    <>
                    {slots.map((s, si) => {
                        const spath = "slots."+si
                        const sdelete = spath+".delete"
                        const fillers = slots[si].fillers
                        const sdeleted = s.delete
                        const stype = "slot_"+s.type.replaceAll(':','_') + (sdeleted ? ' deleted' : '')
                        const smeta = getmeta(meta, 'slot', s.type)
                        const myFillerTypes = smeta ? filterFlTypes(fillerTypes, smeta.params) : []
                        const sIsNew = s._id.startsWith('__new_')

                        const newFillerNum = fillers.reduce((max, f) => { 
                            if (f._id.startsWith('__new_')) {
                                const n = parseInt(f._id.substr(6))
                                if (n > max) max = n
                            }
                            return max
                        }, 0) + 1
                        
                        const newFiller = {
                            _id: '__new_' + newFillerNum,
                            _type: '',
                            name: String(fillers.length + 1),
                            type: '',
                            delete: false
                        }

                        return (
                            <div className={"slot "+stype}>
                                <Table compact>
                                <Table.Body>
                                <Table.Row className="header">
                                    <Table.Cell>
                                        <strong>[{si+1}] {t('Slot')}&nbsp;</strong>
                                        <Input name={spath+".name"} inputProps={{disabled: sdeleted, size: 'mini'}}
                                            validate={(v) => valueUnique(v, slots.map((x, i) => { if (i !== si && !x.delete) return x.name} ))}/>
                                            &nbsp;
                                        <div className="buttons">
                                        <Button type="button" icon="angle right" color="yellow" size="mini" title={t('swap with the following')}
                                            disabled={si === slots.length-1}
                                            onClick={(e,d) => {subformik.swap(si, si+1);formik.setFieldTouched(spath, true, true)}}/>
                                        { s.delete ?
                                        <Button type="button" icon="undo alternate" color="red" size="mini" title={t('undo')}
                                            onClick={(e,d) => {
                                                setFieldValue(formik, sdelete, false, true)
                                            }}/>
                                        : sIsNew ?
                                        <Button type="button" icon="delete" color="red" size="mini" title={t('delete')}
                                            onClick={(e,d) => {subformik.remove(si)}}/>
                                        :
                                        <Button type="button" icon="delete" color="red" size="mini" title={t('delete')}
                                            onClick={(e,d) => {
                                                setFieldValue(formik, sdelete, true, true)
                                            }}/>
                                        }
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <Dropdown name={spath+".type"} options={slotTypes} inputProps={{disabled: sdeleted}}
                                            validate={v => valueInOptions(v, slotTypes)}/>
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                        <label className="fgroup_label">{t('Features')}</label>
                                    </Table.Cell>
                                </Table.Row>
                                <EditSlotFillerFormFeatures formik={formik} userMap={userMap} meta={meta} featureList={params['slot_features']} compId={s._id} compDeleted={sdeleted} />

                                <FieldArray name={"slots."+si+".fillers"} render={(subformik) => (
                                    <>
                                    <Table.Row>
                                        <Table.Cell>
                                            <label className="fgroup_label">{t('Fillers')}</label>
                                            <Button type="button" icon="plus" color="blue" size="mini" title={t('add new filler')}
                                                onClick={(e,d) => subformik.push(newFiller)}/>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                    <Table.Cell colspan="3">
                                    <div>
                                    {fillers.map((fl, fli) => (
                                        <EditFillerForm meta={meta} formik={formik} subformik={subformik} params={params} userMap={userMap} slotDeleted={sdeleted}
                                            prefix={"slots."+si+".fillers."+fli} filler={fl} fillerId={s._id+'/'+fl._id} fillerIndex={fli} fillers={fillers}
                                            fillerTypes={fillerTypes}/>
                                    ))}
                                    </div>
                                    </Table.Cell>
                                    </Table.Row>
                                    </>)
                                    }/>
                                </Table.Body>
                                </Table>
                            </div>
                        )
                    })}
                    <div className="slot">
                        <Button type="button" icon="plus" color="blue" size="mini" title={t('add new slot')}
                            onClick={(e,d) => subformik.push(newSlot)}/>
                    </div>
                    </>
                    )
                }/>
            </div>
        )
    }

}

/*<div><pre>{JSON.stringify(formik.values,null, 2)}</pre></div>*/

export default withTranslation()(EditSlotForm)