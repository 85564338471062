import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import i18n, { TFunction } from 'i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, valueIsJson } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorComponentProps} from './ConfigEditor'
import ConfigEditorUiViewCompChart from './CEUiViewCompChart'
import ConfigEditorUiSearchCompFeatureQuery from './CEUiSearchCompFeatureQuery'
import ConfigEditorUiSearchCompMenuQuery from './CEUiSearchCompMenuQuery'
import ConfigEditorUiSearchCompSlotQuery from './CEUiSearchCompSlotQuery'
import ConfigEditorUiSearchRenderResultList from './CEUiSearchRenderResultList'
import ConfigEditorUiSearchRenderCNCFormList from './CEUiSearchRenderCNCFormList'
import ConfigEditorUiSearchRenderCNCLemmaList from './CEUiSearchRenderCNCLemmaList'
import { setFieldValue } from '../form/FormikHelpers'
import ConfigEditorUiSearchCompNameQuery from './CEUiSearchCompNameQuery'
import Button from '../form/Button'

const apiUrlView = 'cunits/_view'

export interface ConfigEditorUiSearchCompProps extends ConfigEditorComponentProps {
    params: any,
    formikprefix: string,
    parentClass?: 'unit'|'slot'|'filler'
}

const featureQueryValues = (params: any, valuePrefix: string, labelPrefix: string, t: TFunction ) => {
    let list: DropdownItemProps = []
    list.push({key: valuePrefix+'.feats.0.value', text: labelPrefix+' > '+t('features')+' > 1 > '+t('value'), value: valuePrefix+'.feats.0.value'})
    list.push({key: valuePrefix+'.feats.0.type', text: labelPrefix+' > '+t('features')+' > 1 > '+t('type'), value: valuePrefix+'.feats.0.type'})
    return list
}

const slotQueryValues = (params: any, valuePrefix: string, labelPrefix: string, t: TFunction ) => {
    let list: DropdownItemProps = []
    return list
}

const nameQueryValues = (params: any, valuePrefix: string, labelPrefix: string, t: TFunction ) => {
    let list: DropdownItemProps = []
    list.push({key: valuePrefix+'.name.value', text: labelPrefix+' > '+t('name'), value: valuePrefix+'.name.value'})
    return list
}

class ConfigEditorUiSearch extends Component<ConfigEditorComponentProps> {

    render() {
        const { formik, user, meta, editing, locale, t, i18n } = this.props
        const lang = i18n.language

        if (!(meta && meta.cats && meta.cats['cunit'] && meta.uis && meta.uis['search'])) return null

        const newFormComponent = { comp: '', params: {}}
        const newSortItem = {feature: '', subspec: '', order: 'asc'}

        const formCompEditors = {
            NameQuery: ConfigEditorUiSearchCompNameQuery,
            FeatureQuery: ConfigEditorUiSearchCompFeatureQuery,
            SlotQuery: ConfigEditorUiSearchCompSlotQuery,
            MenuQuery: ConfigEditorUiSearchCompMenuQuery
        }

        const formCompValues = {
            NameQuery: nameQueryValues,
            FeatureQuery: featureQueryValues,
            SlotQuery: slotQueryValues
        }

        const renderComps = {
            ResultList: ConfigEditorUiSearchRenderResultList,
            CNCFormList: ConfigEditorUiSearchRenderCNCFormList,
            CNCLemmaList: ConfigEditorUiSearchRenderCNCLemmaList,
            AcPhraseList: null
        }
        
        const formCompOptions = [
            {key: 'NameQuery', text: i18n.t('Name query'), value: 'NameQuery'},
            {key: 'FeatureQuery', text: i18n.t('Feature query'), value: 'FeatureQuery'},
            {key: 'SlotQuery', text: i18n.t('Slot query'), value: 'SlotQuery'},
            {key: 'MenuQuery', text: i18n.t('Menu query'), value: 'MenuQuery'},
        ]
        
        const renderOptions = [
            {key: 'ResultList', text: i18n.t('Generic Result List'), value: 'ResultList'},
            {key: 'CNCFormList', text: i18n.t('CNC Form List'), value: 'CNCFormList'},
            {key: 'CNCLemmaList', text: i18n.t('CNC Lemma List'), value: 'CNCLemmaList'},
            {key: 'AcPhraseList', text: i18n.t('Academic Phrase List'), value: 'AcPhraseList'},
        ]
        
        const helpOptions = [
            {key: 'none', text: i18n.t('none'), value: undefined},
            {key: 'Generic', text: i18n.t('Generic Help'), value: 'Generic'},
            {key: 'CNCFormListHelp', text: i18n.t('CNC Form List Help'), value: 'CNCFormListHelp'},
            {key: 'CNCLemmaListHelp', text: i18n.t('CNC Lemma List Help'), value: 'CNCLemmaListHelp'},
        ]

        const apiUrlOptions = [
            {key: 'view', text: t('Quick view'), value: apiUrlView}
        ]

        const cunitOptions = Object.keys(meta.cats['cunit']).map((v, i, arr) => {
            const match = meta.cats['cunit'][v]['subdef_required'] ? v+':*' : v
            const name = getLocalizedLabel(meta.cats['cunit'][v]['label'], lang)+" ["+match+"]"
            return {key: match, text: name, value: match}
        })

        const allFeatOptions = Object.keys(meta.cats['feature']).map((v, i, arr) => {
            const name = getLocalizedLabel(meta.cats['feature'][v]['label'], lang)+" ["+v+"]"
            return {key: v, text: name, value: v}
        })

        const sortOrderOptions = [
            {key: 'asc', text: t('ascending'), value: 'asc'},
            {key: 'desc', text: t('descending'), value: 'desc'}
        ]

        const urlParamOptions: DropdownItemProps[] = formik.values.params.form.map((comp: any, cindex: number) => {
            const compValues = formCompValues[comp.comp]
            if (compValues) {
                return compValues(comp.params, String(cindex), String(cindex+1), t)
            } else return []
        }).flat()
        
        const Render = renderComps[formik.values.params.render.comp] || React.Fragment

        if (formik.values.params.request_url === apiUrlView && formik.values.params.request_defaults.feats === undefined) {
            setFieldValue(formik, 'params.request_defaults.feats', [], false)
            return null
        }

        return (
            <>
            <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <div className="label pad">{t('Order')}</div>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Input name="params.order" inputProps={{disabled: !editing, type: "number"}}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><div className="label pad">{t('Search')}</div></Grid.Column>
                    <Grid.Column width={14}>
                        <Dropdown name={"params.search"}
                            validate={valueNotEmpty}
                            inputProps={{disabled: !editing}} options={cunitOptions}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><div className="label">{t('Create')}</div></Grid.Column>
                    <Grid.Column width={14}>
                        <Checkbox name={"params.create"} inputProps={{disabled: !editing}}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>
            <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}><div className="label pad">{t('Method')}</div></Grid.Column>
                    <Grid.Column width={14}>
                        <Dropdown name={"params.request_url"}
                            validate={valueNotEmpty}
                            inputProps={{disabled: !editing}} options={apiUrlOptions}/>
                    </Grid.Column>
                </Grid.Row>
                {formik.values.params.request_url === apiUrlView ?
                <>
                <Grid.Row>
                    <Grid.Column width={1}>
                        <div className="label pad">{t('Retrieve')}</div>
                    </Grid.Column>
                </Grid.Row>
                <FieldArray name={"params.request_defaults.feats"}
                    render={(subformik) => (
                        <>
                        {formik.values.params.request_defaults.feats.map((val: any, vindex: number) => {
                        return (
                            <>
                            <Grid.Row className="condensed">
                            <Grid.Column width={1}>
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <Dropdown name={"params.request_defaults.feats."+vindex}
                                    validate={valueNotEmpty}
                                    inputProps={{disabled: !editing}} options={allFeatOptions}/>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                { editing ?
                                <Button type="button" icon="delete" color="red" size="mini"
                                    disabled={!editing}
                                    onClick={(e,d) => subformik.remove(vindex)}/>
                                : null }
                            </Grid.Column>
                            </Grid.Row>
                            </>
                        )})}
                        { editing ? <Grid.Row className="condensed">
                            <Grid.Column width={1}>
                            </Grid.Column>
                            <Grid.Column width={15}>
                            <Button icon="plus" type="button" size="tiny" color='blue' 
                                onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                            </Grid.Column>
                            </Grid.Row>
                        : null }
                        </>
                    )}/>
                </>
                : null}
                <Grid.Row>
                    <Grid.Column width={3}>
                        <div className="label pad">{t('Page size')}</div>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Input name="params.request_defaults.page_size" inputProps={{disabled: !editing, type: "number"}}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <div className="label pad">{t('Sort')}</div>
                    </Grid.Column>
                </Grid.Row>
                <FieldArray  name={"params.request_defaults.sort"}
                    render={(subformik) => (
                        <>
                        {formik.values.params.request_defaults.sort && formik.values.params.request_defaults.sort.map((item: any, sindex: number) => {
                            return (
                                <Grid.Row className="condensed">
                                <Grid.Column width={1}><div className="pad">{sindex+1}.</div></Grid.Column>
                                <Grid.Column width={6}>
                                    <Dropdown name={"params.request_defaults.sort."+sindex+".feature"}
                                        validate={valueNotEmpty}
                                        inputProps={{disabled: !editing}} options={allFeatOptions}/>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Input name={"params.request_defaults.sort."+sindex+".subspec"} 
                                        inputProps={{disabled: !editing}}/>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Dropdown name={"params.request_defaults.sort."+sindex+".order"}
                                        validate={valueNotEmpty}
                                        inputProps={{disabled: !editing, compact: true, fluid: true}} options={sortOrderOptions}/>
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    { editing ?
                                    <Button type="button" icon="delete" color="red" size="mini"
                                        disabled={!editing}
                                        onClick={(e,d) => subformik.remove(sindex)}/>
                                    : null }
                                </Grid.Column>
                                </Grid.Row>
                            )
                        })}
                        { editing ? <Grid.Row className="condensed">
                            <Grid.Column width={15}>
                            <Button icon="plus" type="button" size="tiny" color='blue' 
                                onClick={(e,d) => subformik.push(newSortItem)} disabled={!editing}/>
                            </Grid.Column>
                            </Grid.Row>
                        : null }
                        </>
                    )}/>
            </Grid>
            </Segment>
            { editing || formik.values.params.urlparams ?
            <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                    <div className="label">{t('URL argument mapping')}</div>
                    </Grid.Column>
                </Grid.Row>
                <FieldArray name={"params.urlparams"}
                    render={(subformik) => {
                        const urlparams: string[] = formik.values.params.urlparams || []
                        return(
                        <>
                        {urlparams.map((val: any, vindex: number) => {
                        return (
                            <Grid.Row className="condensed">
                            <Grid.Column width={1}><div className="pad">{vindex+1}.</div>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Dropdown key={"params.urlparams."+vindex}
                                    name={"params.urlparams."+vindex}
                                    options={urlParamOptions} validate={valueNotEmpty}
                                    inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                { editing ?
                                <>
                                <Button type="button" icon="angle up" color="blue" size="mini"
                                    disabled={!editing || vindex === 0}
                                    onClick={(e,d) => subformik.swap(vindex, vindex-1)}/>
                                <Button type="button" icon="angle down" color="blue" size="mini"
                                    disabled={!editing || vindex === formik.values.params.urlparams.length-1}
                                    onClick={(e,d) => subformik.swap(vindex, vindex+1)}/>
                                <Button type="button" icon="delete" color="red" size="mini"
                                    disabled={!editing}
                                    onClick={(e,d) => subformik.remove(vindex)}/>
                                </>: null }
                            </Grid.Column>
                            </Grid.Row>
                        )})}
                        { editing ? <Grid.Row className="condensed">
                            <Grid.Column width={15}>
                            <Button icon="plus" type="button" size="tiny" color='blue' 
                                onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                            </Grid.Column>
                            </Grid.Row>
                        : null }
                        </>
                    )}}/>
            </Grid>
            </Segment>
            : null }
            <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}><div className="label pad">{t('Form components')}</div></Grid.Column>
                </Grid.Row>
                <FieldArray name={"params.form"}
                    render={(subformik) => (
                        <Grid.Row>
                        <Grid.Column>
                            {formik.values.params.form.map((comp: any, cindex: number) => {
                                const CompEditor = formCompEditors[comp.comp] || React.Fragment
                                return (
                                    <Segment className="grey">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={1}>
                                            <div className="label pad">[{cindex+1}]</div>
                                            </Grid.Column>
                                            <Grid.Column width={9}>
                                            <Dropdown name={"params.form."+cindex+".comp"} validate={valueNotEmpty}
                                                inputProps={{disabled: !editing}} options={formCompOptions}/>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Checkbox name={"params.form."+cindex+".params.hidden"} label={t('hidden')} 
                                                    fieldProps={{className: "pad"}} inputProps={{disabled: !editing}}/>
                                            </Grid.Column>
                                            <Grid.Column width={4} textAlign="right">
                                                { editing ? 
                                                <>
                                                <Button type="button" icon="angle up" color="blue" size="mini"
                                                    disabled={!editing || cindex === 0}
                                                    onClick={(e,d) => subformik.swap(cindex, cindex-1)}/>
                                                <Button type="button" icon="angle down" color="blue" size="mini"
                                                    disabled={!editing || cindex === formik.values.params.form.length-1}
                                                    onClick={(e,d) => subformik.swap(cindex, cindex+1)}/>
                                                <Button type="button" icon="delete" color="red" size="mini"
                                                    disabled={!editing}
                                                    onClick={(e,d) => subformik.remove(cindex)}/>
                                                </> : null}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={2}>
                                            <div className="label pad">{t('Label')}</div>
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                                <Input key={"params.form."+cindex+".label."+lang} name={"params.form."+cindex+".label."+lang} 
                                                    inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <CompEditor {...this.props} params={comp.params} formikprefix={"params.form."+cindex+".params"} parentClass='unit'/>
                                    </Grid>
                                    </Segment>
                                )
                            })}
                            { editing ? <Button icon="plus" type="button" size="tiny" color='blue' 
                                onClick={(e,d) => subformik.push(newFormComponent)} disabled={!editing}/> : null}
                        </Grid.Column>
                        </Grid.Row>
                    )}
                />
            </Grid>
            </Segment>
            <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}><div className="label pad">{t('Results view')}</div></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Dropdown name={"params.render.comp"} validate={valueNotEmpty}
                            inputProps={{disabled: !editing}} options={renderOptions}/>
                    </Grid.Column>
                </Grid.Row>
                <Render {...this.props} params={formik.values.params.render.params} formikprefix={"params.render.params"}/>
            </Grid>
            </Segment>
            { editing || formik.values.params.urlparams ? 
            <>
            <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}><div className="label pad">{t('Help')}</div></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Dropdown name={"params.help.comp"}
                            inputProps={{disabled: !editing, placeholder: t('none')}} options={helpOptions}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>
            </>: null}
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiSearch)
