/*
 * from https://github.com/turner-industries/formik-semantic-ui/
 */

import React, { Component } from 'react'
import { Form, Dropdown as SDropdown, FormDropdownProps, DropdownItemProps } from 'semantic-ui-react'
import { FastField, Field, getIn } from 'formik'
import ErrorMessage from './ErrorMessage'
import { FormikComponentProps, getFieldError, setFieldValue } from './FormikHelpers'

export interface DropdownComponentProps extends FormikComponentProps {
    inputProps?: FormDropdownProps
    options?: DropdownItemProps[]
    placeholder?: string
    noHighlighting?: boolean 
}

class Dropdown extends Component<DropdownComponentProps> {

    id: string
    _dropdown: React.ReactNode

    constructor(props) {
        super(props)
        const {id, name} = props
        this.id = id || `field_dropdown_${name}`
    }

  render() {

    const {
        name,
        label,
        options,
        placeholder,
        validate,
        inputProps = {},
        fieldProps = {},
        errorComponent = ErrorMessage,
        fast,
        noHighlighting,
    } = this.props

    const {onChange, ...safeInputProps} = inputProps
    const DesiredField = fast === true ? FastField : Field
    return (
      <DesiredField name={name} validate={validate}>
        {({field, form, meta}) => {
          const error = meta.error //getFieldError(field, form)
          return (
            <Form.Field error={!!error} {...fieldProps}>
              {!!label && (
                <label htmlFor={this.id} /*onClick={() => this._dropdown && this._dropdown.open() }*/>
                  {label}
                </label>
              )}
              <SDropdown
                ref={el => (this._dropdown = el)}
                id={this.id}
                name={name}
                options={options}
                placeholder={placeholder}
                search
                deburr
                selectOnBlur={false}
                selectOnNavigation={false}
                selection
                {...safeInputProps}
                value={field.value}
                className={!noHighlighting && field.value !== meta.initialValue ? 'changed' : ''}
                onChange={(e, {name, value}) => {
                  setFieldValue(form, name, value, true)
                  Promise.resolve().then(() => {
                    onChange && onChange(e, {name, value})
                  });
                }}
              />
              {error && (
                React.createElement(errorComponent, { message: error /*getIn(form.errors, name)*/ })
              )}
            </Form.Field>
          );
        }}
      </DesiredField>
    );
  }
}

export default Dropdown