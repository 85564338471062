import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RatatoskResponse, Meta, Cunit } from '../../common'
import { Container, Accordion, Label, Checkbox, Menu, Select, Input, Dimmer } from 'semantic-ui-react'
import CNCLemmaView from './CNCLemmaView'
import CNCLemmaFqView from './CNCLemmaFqView'
import NoResults from './NoResults'
import { ResultListProps } from '../Search'

enum LemmaView {
    byForm,
    byFrequency
}

interface CNCLemmaListState {
    lemmas: {[key: string]: Cunit[]}|null,
    total: {value: number, relation: string}|null|undefined,
    stackvariants : boolean,
    equalize: boolean,
    viewMode: LemmaView,
    formFilter: number,
    showZeroFq: boolean,
    activeLemma: number
}

const groupLemmasByPoS = (cunits: Cunit[]) => {
    let groups = {}
    for (let cunit of cunits) {
        const flemma = cunit['_slots'][0]['_fillers'][0][':form:attr:cnc:w:lemma']
        const ftag = cunit['_slots'][0]['_fillers'][0][':form:attr:cnc:w:tag']
        const lemma = flemma ? flemma : ''
        const tag = ftag ? ftag : ''
        const group = lemma + "_" + tag.substring(0,1)
        if (!(group in groups)) {
            groups[group] = new Array<Cunit>()
        }
        groups[group].push(cunit)
    }
    return groups
}

class CNCLemmaList extends Component<ResultListProps, CNCLemmaListState> {

    constructor(props: ResultListProps) {
        super(props)
        let lemmas: {[key: string]: Cunit[]}|null = null
        let total: {value: number, relation: string}|null|undefined = null
        if (props.response) {
            total = props.response.total
            lemmas = groupLemmasByPoS(props.response.data)
        }
        const storedPref = localStorage.getItem('pref_cnclemmalist')
        let pref = {
            stackvariants: false,
            equalize: false,
            viewMode: LemmaView.byForm,
            formFilter: 0,
            showZeroFq: false
        }
        if (storedPref) {
            pref = JSON.parse(storedPref)
        }
        this.state = {
            lemmas: lemmas,
            total: total,
            stackvariants: pref.stackvariants,
            equalize: pref.equalize,
            viewMode: pref.viewMode,
            formFilter: pref.formFilter,
            showZeroFq: pref.showZeroFq,
            activeLemma: lemmas && Object.keys(lemmas).length === 1 ? 0 : -1
        }
    }

    handleAccordionTitleClick = (e, itemProps) => {
        const { index } = itemProps
        const { activeLemma } = this.state
        const newIndex = activeLemma === index ? -1 : index
        this.setState({ activeLemma: newIndex })
    }

    static getDerivedStateFromProps(props, state) {
        const { stackvariants, equalize, showZeroFq, viewMode, formFilter} = state
        const pref = {
            stackvariants: stackvariants,
            equalize: equalize,
            showZeroFq: showZeroFq,
            viewMode: viewMode,
            formFilter: formFilter
        }
        localStorage.setItem('pref_cnclemmalist', JSON.stringify(pref))
        return null
    }

    render() {
        let { params, t, meta, response, loading } = this.props
        let { lemmas, stackvariants, equalize, viewMode, formFilter, activeLemma, showZeroFq, total } = this.state

        if (!response || !lemmas) return (null)
        if (Object.keys(lemmas).length === 0) return (<NoResults/>)

        const viewModes = [
            {value: LemmaView.byForm, text: t('forms')},
            {value: LemmaView.byFrequency, text: t('frequency')}
        ]

        const panels = Object.keys(lemmas).map((lemma, index) => {
            return ({
                key: index,
                active: activeLemma === index ? true : false,
                title: {
                    content: (<Label color='blue' content={lemma.slice(0,-2)} detail={lemma.slice(-1,)} />)
                },
                content: {
                    content: ( lemmas && activeLemma === index ? 
                            ( viewMode === LemmaView.byForm ?
                                <CNCLemmaView key={index} name={lemma} forms={lemmas[lemma]} stackvariants={stackvariants} 
                                equalize={equalize} formFilter={formFilter} showZeroFq={showZeroFq} meta={meta} params={params}/> :
                                <CNCLemmaFqView key={index} name={lemma} forms={lemmas[lemma]}/>
                             ) : null )
                }
            })
        })

        let count = '?'
        if (total) {
            if (total.relation !== 'eq')
                count = t('too many...')
            else
                count = String(total.value)
        }

        const changeViewMode = (e, d) => { this.setState({viewMode: d.value}) }
        const changeFormFilter = (e, d) => { this.setState({formFilter: d.value}) }
        const toggleStacking = () => { this.setState((prevState) => ({ stackvariants: !prevState.stackvariants }))}
        const toggleEqualize = () => { this.setState((prevState) => ({ equalize: !prevState.equalize }))}
        const toggleShowZeroFq = () => { this.setState((prevState) => ({ showZeroFq: !prevState.showZeroFq }))}

        const filterLabel = t('filter level')+': '+(Number(formFilter)+1)

        return(
            <>
            <Menu text>
                <Menu.Item>{t('Forms')}: {count}</Menu.Item>
                <Menu.Item><Select fluid options={viewModes} value={viewMode} onChange={changeViewMode}/></Menu.Item>
                { viewMode === LemmaView.byForm ? (
                    <>
                    <Menu.Item>
                        <Input type="range" min={0} max={3}
                            label={filterLabel} className="slider"
                            value={formFilter} onChange={changeFormFilter}/>
                    </Menu.Item>
                    <Menu.Item><Checkbox toggle label={t('stack variants')} onChange={toggleStacking} checked={stackvariants} /></Menu.Item>
                    <Menu.Item><Checkbox toggle label={t('equalize charts')} onChange={toggleEqualize} checked={equalize} /></Menu.Item>
                    <Menu.Item><Checkbox toggle label={t('show unattested')} onChange={toggleShowZeroFq} checked={showZeroFq} /></Menu.Item>
                    </>
                ) : null
                }
            </Menu>
            <Dimmer.Dimmable loading={loading} dimmed={loading} as={Container} style={{paddingTop: 20}}>
                <Dimmer active={loading} inverted />
                <Accordion styled fluid panels={panels} activeIndex={activeLemma} onTitleClick={this.handleAccordionTitleClick}/>
            </Dimmer.Dimmable>
            </>
        )
    }

}

export default withTranslation()(CNCLemmaList)