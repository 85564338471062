import React, { Component } from 'react'
import { SlotQueryParams, Meta, valueByFormikPath } from '../../common'
import { FieldArray } from "formik"
import { withTranslation, WithTranslation } from 'react-i18next'
import { Grid, Segment, Button } from 'semantic-ui-react'
import FillerQuery, { addDefaultFiller } from './FillerQuery'
import Input from './Input'

export const addDefaultSlot = (query, params) => {
    if (!query.slots) query.slots = []
    const cnt = params.multi ? params.multi.default : 1
    for (let i = 0; i < cnt; i++) {
        let slot: any = {fillers: []}
        addDefaultFiller(slot, params.fillers)
        query.slots.push(slot)
    }
}

interface QueryProps extends WithTranslation {
    prefix: string,
    label: string,
    params: SlotQueryParams,
    meta: Meta,
    formik: any
}

type SlotQueryProps = QueryProps;

class SlotQuery extends Component<SlotQueryProps> {

    render () {
        const { meta, formik, prefix, params, t } = this.props
        
        let values = valueByFormikPath(prefix, formik.values)
        
        if (!(values && values.slots)) return null

        let newSlot: any = {fillers:[]}
        addDefaultFiller(newSlot, params.fillers)

        return (
            <React.Fragment>
                <FieldArray name={prefix+'.slots'}
                    render={(subformik) => (
                    <>
                        {values.slots.length ?
                        <Grid.Row className="condensed">
                            <Grid.Column width={16}>
                                {values.slots.map((item: any, index: number) => {
                                    return (
                                        <Segment key={index} inverted color='blue'>
                                            <Grid>
                                                <Grid.Row verticalAlign="middle">
                                                    <Grid.Column width={2}>
                                                        {t('Component')} {index+1}
                                                    </Grid.Column>
                                                    <Grid.Column width={13}>
                                                        {t('Position')}
                                                        <Input name={prefix+'.slots.'+index+'.pos'} fieldProps={{className: 'pos-query'}} inputProps={{type: "number", min: 0, size: "mini"}}/>
                                                        {t('(0 = any)')}
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                    { (params.multi && values.slots.length > params.multi.min) ? (
                                                            <Button icon="delete" type="button" size='mini' color='red' 
                                                                onClick={(e,d) => subformik.remove(index)}/>
                                                    ) : null }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Grid>
                                                <FillerQuery label={null} params={params.fillers} meta={meta} 
                                                    prefix={prefix+'.slots.'+index} formik={formik}/>
                                            </Grid>
                                        </Segment>
                                    )
                                })}
                            </Grid.Column>
                        </Grid.Row>
                        : null}
                        {(params.multi && values.slots.length < params.multi.max) ? (
                        <Grid.Row className="condensed">
                            <Grid.Column>
                                <Button icon="plus" type="button" size='mini' color='blue' 
                                    onClick={(e,d) => subformik.push(newSlot)}/>
                            </Grid.Column>
                        </Grid.Row>
                        ) : null}
                    </>
                )} />
            </React.Fragment>
        )
    }
}

export default withTranslation()(SlotQuery)
