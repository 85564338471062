import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueInOptions, valueByFormikPath, createDefaultFeatureLabel, valueNotEmpty, valueIsIntNumber } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, DropdownItemProps, Dropdown as PDropdown, Grid, Header, Message, Segment, Tab, Table } from 'semantic-ui-react'
import { addFeature, EditFormProps, UserMap, lastChangeLabel } from '../Edit'
import { FieldArray } from 'formik'
import { setFieldValue } from './FormikHelpers'
import Input from './Input'
import Dropdown from './Dropdown'
import { Icon } from 'semantic-ui-react'
import ConfigEditorUiSearchCompSlotQuery from '../ConfigEditor/CEUiSearchCompSlotQuery'
import TextArea from './TextArea'
import InputFeatureValue from './InputFeatureValue'

interface EditSlotFillerFormFeaturesProps extends WithTranslation {
    formik: any,
    meta: Meta,
    featureList: any[],
    compId: string,
    compDeleted: boolean,
    userMap: UserMap
}

class EditSlotFillerFormFeatures extends Component<EditSlotFillerFormFeaturesProps> {

    render() {
        const { formik, meta, featureList, compId, compDeleted, userMap, t, i18n } = this.props
        const slots = formik.values.slots
        const features = formik.values.feats

        return(
            <>
            { featureList.map(item => {
                const ftype = item.feature
                const fmeta = getmeta(meta, 'feature', ftype)
                const basetype = fmeta.type
                const fgpath = "feats."+basetype+"['"+compId+"']"
                const fgroup = formik.values['feats'][basetype] ? formik.values['feats'][basetype][compId] : null //valueByFormikPath(fgpath, formik.values)
                let fi = Array.isArray(fgroup) ? fgroup.findIndex(x => x._type === ftype) : -1
                const fpath = fi>=0 ? fgpath+"."+fi : null
                const f = fpath ? formik.values['feats'][basetype][compId][fi] : null //valueByFormikPath(fpath, formik.values) : null
                const flastchange =  lastChangeLabel(f, userMap)
                const fvaluepath = fpath+".value"
                const group = compId
                const finitial = fi>=0 
                    && (formik.initialValues.feats[basetype] 
                    && formik.initialValues.feats[basetype][group] 
                    && formik.initialValues.feats[basetype][group][fi]) ? 
                        formik.initialValues.feats[basetype][group][fi] : null
                const changed = (finitial === null && f !== null) 
                    || (finitial !== null && (finitial.value !== f.value || finitial.subspec !== f.subspec || fgroup[fi].delete))
                const deleted = compDeleted || (fi>=0 && fgroup[fi].delete)
                const label = createDefaultFeatureLabel(meta, ftype)
                return (
                    <FieldArray name={"feats["+basetype+"]["+group+"]"}
                        render={(subformik) => (
                        <>
                        <Table.Row className={deleted ? "deletedField condensed" : changed ? "changed condensed" : "condensed"} title={flastchange}>
                            <Table.Cell>
                                <InputFeatureValue cunitValues={formik.values} feature={f} fvaluepath={fvaluepath} fmeta={fmeta} meta={meta} disabled={deleted||!f}
                                    small={true} nonEmpty={!deleted&&!!f} exLabel={true} label={label} userMap={userMap}/>
                                <div className="fieldButton">
                                { !f ?
                                    <Button type="button" color="blue" size="mini" icon="plus" title={t('Add')}
                                        onClick={(e,d) => addFeature(formik, meta, compId, ftype)}/>
                                : changed && finitial !== null && !compDeleted ?
                                    <Button type="button" color="yellow" icon="undo alternate" size="mini" title={t('undo')}
                                        onClick={(e,d) => {
                                            setFieldValue(formik, fvaluepath, finitial.value, false)
                                            setFieldValue(formik, fpath+".subspec", finitial.subspec, false)
                                            setFieldValue(formik, fpath+".delete", false, false)
                                        }}/>
                                : !compDeleted ?
                                    <Button type="button" color="red" icon="delete" size="mini" title={t('delete')}
                                        onClick={(e,d) => {
                                            if (finitial) {
                                                setFieldValue(formik, fpath+".delete", true, false)
                                            } else {
                                                subformik.remove(fi)
                                            }
                                        }}/>
                                : null }
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        </>)} 
                    />
                )
            })}
            </>
        )
    }

}

export default withTranslation()(EditSlotFillerFormFeatures)