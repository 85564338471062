import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueInOptions, valueByFormikPath, createDefaultFeatureLabel, valueNotEmpty, valueIsIntNumber, Filler } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, DropdownItemProps, Dropdown as PDropdown, Grid, Header, Message, Segment, Tab, Table } from 'semantic-ui-react'
import { addFeature, EditFormProps, lastChangeLabel, UserMap } from '../Edit'
import { FieldArray } from 'formik'
import { setFieldValue } from './FormikHelpers'
import Input from './Input'
import Dropdown from './Dropdown'
import { Icon } from 'semantic-ui-react'
import ConfigEditorUiSearchCompSlotQuery from '../ConfigEditor/CEUiSearchCompSlotQuery'
import TextArea from './TextArea'
import InputFeatureValue from './InputFeatureValue'
import EditSlotFillerFormFeatures from './EditSlotFillerFormFeatures'

interface EditFillerFormProps extends WithTranslation {
    meta: Meta,
    params: {},
    formik: any,
    subformik: any,
    userMap: UserMap,
    prefix: string,
    fillerId: string,
    filler: any,
    fillerIndex: number,
    slotDeleted: boolean,
    fillers: any[],
    fillerTypes: DropdownItemProps[],
}

interface EditFillerFormState {
    defFeatures: any[]
}

class EditFillerForm extends Component<EditFillerFormProps, EditFillerFormState> {

    state = {
        defFeatures: [],
    }

    componentDidMount() {
        const {meta, filler} = this.props
        const flmeta = getmeta(meta, 'filler', filler.type)
        this.setState({defFeatures: (flmeta && flmeta.params['def'] ? flmeta.params['def']['feats'] : [])})
    }

    changeType(newType: string) {
        const { meta } = this.props
        const { defFeatures } = this.state
        const flmeta = getmeta(meta, 'filler', newType)
        let newDefFeatures = (flmeta && flmeta.params['def'] ? flmeta.params['def']['feats'] : []).map(x => { x.delete = false; return(x)})
        for (let oldf of defFeatures as any[]) {
            const found: any[] = newDefFeatures.filter((x: any) => x.type===oldf.type)
            if (!found.length) {
                oldf.delete = true
                newDefFeatures.push(oldf)
            }
        }
        this.setState({defFeatures: newDefFeatures})
    }

    render() {
        const { formik, meta, t, i18n, params, userMap, filler, fillerIndex, prefix, fillerId, subformik, slotDeleted, fillers, fillerTypes } = this.props
        const { defFeatures } = this.state
        const features = formik.values.feats
        
        const fldelpath = prefix+".delete"
        const fldeleted = filler.delete || slotDeleted
        const flmeta = getmeta(meta, 'filler', filler.type)
        const fltype = "filler_"+filler.type.replaceAll(':','_') + (fldeleted ? ' deleted' : '')
        const flIsNew = filler._id.startsWith('__new_')
        let flDefAvailableFeats: DropdownItemProps[] = []
        
        return (
            <div className={"filler "+fltype}>
                <Table compact>
                <Table.Body>
                <Table.Row className="header">
                    <Table.Cell>
                        <Input name={prefix+".name"} inputProps={{disabled: fldeleted, size: 'mini', compact: true}}
                            validate={(v) => valueUnique(v, fillers.map((x, i) => { if (i !== fillerIndex && !x.delete) return x.name} ))}/>
                        <div className="buttons">
                        { filler.delete && !flIsNew && !slotDeleted ?
                        <Button type="button" icon="undo alternate" color="yellow" size="mini" title={t('undo')}
                            onClick={(e,d) => {
                                setFieldValue(formik, fldelpath, false, true)
                            }}/>
                        : flIsNew && !slotDeleted ?
                        <Button type="button" icon="delete" color="red" size="mini" title={t('delete')}
                            onClick={(e,d) => {subformik.remove(fillerIndex)}}/>
                        : !slotDeleted ?
                        <Button type="button" icon="delete" color="red" size="mini" title={t('delete')}
                            onClick={(e,d) => {
                                setFieldValue(formik, fldelpath, true, true)
                            }}/>
                        : null}
                        </div>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Dropdown name={prefix+".type"} options={fillerTypes} inputProps={{disabled: fldeleted, onChange: (e, d) => this.changeType(d.value as string)}}
                            validate={v => valueInOptions(v, fillerTypes)}/>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        { defFeatures.length===1 ?
                            <label className="fgroup_label">{createDefaultFeatureLabel(meta, flmeta.params['def']['feats'][0].type)}</label>
                        :
                            <label className="fgroup_label">{t('Definition')}</label>
                        }
                    </Table.Cell>
                </Table.Row>
                { defFeatures.map((fdef: any) => {
                    const fmeta = getmeta(meta, 'feature', fdef.type)
                    const basetype = fmeta.type
                    const fgpath = "feats."+basetype+"['"+fillerId+"']"
                    let fgroup = formik.values['feats'] && formik.values['feats'][basetype] ? formik.values['feats'][basetype][fillerId] : null
                    //const fgroup = valueByFormikPath(fgpath, formik.values); console.log(flid, fgroup)
                    let fi = Array.isArray(fgroup) ? fgroup.findIndex(x => x._type === fdef.type) : -1
                    // missing defining feature which is not optional should be added to the form
                    if (fi<0 && !fdef.optional) {
                        fi = addFeature(formik, meta, fillerId, fdef.type)
                        return(null) // added features will be applied in the next refresh iteration
                    }
                    // undefined optional features which do not belong the currenty selected type anymore should be removed from the list directly
                    if (fi<0 && fdef.optional && fdef.delete) {
                        this.setState({defFeatures: defFeatures.filter((x: any) => x.type !== fdef.type)})
                    }
                    const label = defFeatures.length>1 ? createDefaultFeatureLabel(meta, fdef.type) : undefined
                    const finitial = (fi >= 0 
                                        && formik.initialValues.feats[basetype] 
                                        && formik.initialValues.feats[basetype][fillerId] 
                                        && formik.initialValues.feats[basetype][fillerId][fi]) ? 
                        formik.initialValues.feats[basetype][fillerId][fi] : null

                    const fpath = fi>=0 ? fgpath+"."+fi : null
                    const f = fgroup ? fgroup[fi] : null //valueByFormikPath(fpath, formik.values) : null
                    const fdelpath = fi>=0 ? fpath+".delete" : null
                    if (f && f.delete && !fdef.delete) setFieldValue(formik, fdelpath, false, false)
                    const deleted = f && (f.delete || fldeleted)
                    const changed = finitial === null || finitial.value !== f.value || finitial.subspec !== f.subspec || f.delete
                    const fvaluepath = fgpath+"."+fi+".value"
                    const flastchange = lastChangeLabel(f, userMap)
                    if (fdef.optional && fi < 0) {
                        flDefAvailableFeats.push({key: fdef.type, value: fdef.type, text: label})
                    } else {
                        return (
                        <Table.Row className={deleted ? "deletedField condensed" : changed ? "changed condensed" : "condensed"} title={flastchange}>
                            <Table.Cell>
                            <FieldArray name={fgpath} render={(subformik) => {
                                // if the feature is no longer defining for the currently selected filler type and it is not marked to be deleted yet...
                                if (f && !f.delete && fdef.delete) {
                                    // if the feature has no initialized value yet, we can just remove it from the form (it was created automatically on type change and never saved)
                                    if ((f.value === null || typeof f.value === 'undefined') && fmeta.params.value_type) {
                                        subformik.remove(fi)
                                        this.setState({defFeatures: defFeatures.filter((x: any) => x.type !== fdef.type)})
                                    // otherwise mark it for deletion
                                    } else
                                        setFieldValue(formik, fdelpath, true, false)
                                }
                                return(
                                <>
                                <InputFeatureValue cunitValues={formik.values} feature={f} fvaluepath={fvaluepath} fmeta={fmeta} meta={meta} disabled={deleted}
                                    small={true} nonEmpty={true} label={label} userMap={userMap}/>
                                { changed && finitial !== null && !fldeleted && !fdef.delete ?
                                    <Button type="button" color="yellow" icon="undo alternate" size="mini" title={t('undo')}
                                        onClick={(e,d) => {
                                            setFieldValue(formik, fpath+".value", finitial.value, false)
                                            setFieldValue(formik, fpath+".subspec", finitial.subspec, false)
                                            setFieldValue(formik, fdelpath, false, false)
                                        }}/>
                                : fdef.optional && !fldeleted ?
                                    <Button type="button" color="red" icon="delete" size="mini" title={t('delete')}
                                        onClick={(e,d) => {
                                            if (finitial)
                                                setFieldValue(formik, fdelpath, true, false)
                                            else if (fi >= 0)
                                                subformik.remove(fi)
                                        }}/>
                                : null}
                                </>)}} />
                            </Table.Cell>
                        </Table.Row>
                        )
                    }// else {console.log("Defining feature ", fdef.type, " not found in filler ", flid)}
                })}
                { flDefAvailableFeats.length ? 
                        <Table.Row className="condensed">
                            <Table.Cell>
                            <PDropdown options={flDefAvailableFeats}
                                trigger={(<span><Icon name="plus"/>{t('Add')}</span>)}
                                scrolling={true} button={true} className="blue mini"
                                selectOnNavigation={false} selectOnBlur={false}
                                onClick={(e,d) => {d.value=''}}
                                onChange={(e,d) => addFeature(formik, meta, fillerId, d.value)}/>
                            </Table.Cell>
                        </Table.Row>
                : null}


                <Table.Row>
                    <Table.Cell>
                        <label className="fgroup_label">{t('Features')}</label>
                    </Table.Cell>
                </Table.Row>
                <EditSlotFillerFormFeatures formik={formik} userMap={userMap} meta={meta} featureList={params['filler_features']} 
                    compId={fillerId} compDeleted={fldeleted} />

                </Table.Body>
                </Table>
            </div>
        )
    }

}

export default withTranslation()(EditFillerForm)