import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RatatoskResponse, Meta, getLocalizedLabel, getmeta, createDefaultFeatureLabel } from '../../common'
import { Button, Container, Dimmer, Grid, Modal, Pagination, Table, Checkbox, Loader } from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router'
import { PageInfo, ResultListProps } from '../Search'
import NoResults from './NoResults'
import DetailView from './DetailView'
import ShowFeatureValue from './ShowFeatureValue'
import { NavLink } from 'react-router-dom'

interface ResultListState {
    modalIsOpen: boolean,
    modalHeader: string,
    modalContent: React.ReactNode
}

class ResultList extends Component<ResultListProps, ResultListState> {

    public static ALLOW_BATCH = true

    state = {
        modalIsOpen: false,
        modalHeader: '',
        modalContent: null
    }

    target2path(target: string|undefined, path: string) {
        let comps: string[] = []
        if (target) {
            const names = target.split('/')
            comps.push('_slots.[_name='+names[0]+']')
            if (names.length>1)
                comps.push('_fillers.[_name='+names[1]+']')
            comps.push(path)
            return(comps.join('.'))
        } else
            return path
    }

    getValueByPath(path: string, cunit) {
        let val = cunit
        path.split('.').forEach(element => {
            if (!val) return null
            if (element.startsWith('[')) {
                const condition = element.substring(1, element.length-1)
                let [key,match] = condition.split('=')
                let found = val.filter(i => i[key] === match)
                if (found.length) val = found[0]
                else return null
            } else {
                val = val[element]
            }
        });
        return val
    }

    render() {
        let { response, pageInfo, i18n, params, meta, history, directRequest, selectId, selectedIds, t, loading } = this.props
        let { modalHeader, modalContent, modalIsOpen } = this.state

        if (!(meta && meta.cats && meta.cats['cunit'] && meta.resources)) return null

        const viewCunit = (path: string) => {
            history.push('/view/'+path)
        }

        const editCunit = (path: string) => {
            history.push('/edit/'+path)
        }

        const modalClose = () => { this.setState({ modalIsOpen: false }) }
        const modalOpen = (header: string, content: React.ReactNode) => {
            this.setState({ modalIsOpen: true })
            this.setState({ modalHeader: header, modalContent: content })
        }

        if (!response) return null
        
        if (!response.data.length) return (<NoResults/>)

        // show single result directly
        if (directRequest && response.data && response.data.length === 1 && params.view) {
            viewCunit(response.data[0]['_path'])
        }
        
        return(
            <>
            <Modal header={modalHeader} content={modalContent} open={modalIsOpen} onClose={modalClose} centered={false} closeIcon/>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                    { pageInfo.pagination ? (
                        <Pagination activePage={pageInfo.curPage} totalPages={pageInfo.totalPages} onPageChange={pageInfo.pageChange} />
                    ) : null }
                    </Grid.Column>
                    <Grid.Column width={6} className="pageRange">
                        <span>{`${pageInfo.pageStart}-${pageInfo.pageEnd} / ${pageInfo.total >= 0 ? pageInfo.total : t('...')}`}</span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Dimmer.Dimmable loading={loading} dimmed={loading} as={Container}>
                        <Dimmer active={loading} inverted>
                            <Loader inverted>{t('Loading...')}</Loader>
                        </Dimmer>
                        <Table>
                            <Table.Header>
                            <Table.Row>
                            { selectId !== null ?
                            <Table.HeaderCell>
                            </Table.HeaderCell>
                            : null}
                                {params.columns.map((colparams, column) => {
                                    const label = colparams.label ? getLocalizedLabel(colparams.label, i18n.language) : createDefaultFeatureLabel(meta, colparams.path, false, true)
                                    return (
                                        <Table.HeaderCell key={column}>{label}</Table.HeaderCell>
                                    )}
                                )}
                                { params.view || params.edit || (params['detail_view'] &&  params['detail_view'].length) ?
                                    <Table.HeaderCell width={2} textAlign="right">
                                        <Button type="button" icon="cog" color="grey" size="mini"
                                            onClick={this.props.viewOptionsOpen}/>
                                    </Table.HeaderCell>
                                : null }
                            </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            {response.data.map((cunit, row) => {
                                const mymeta = getmeta(meta, 'cunit', cunit['_type'])
                                const can_write = mymeta && mymeta['_can_write']
                                return (
                                    <Table.Row key={row}>
                                        { selectId !== null ?
                                        <Table.Cell width={1}>
                                            <Checkbox onChange={(e, {name, checked}) => selectId(cunit['_id'], checked || false)} checked={selectedIds.includes(cunit['_id'])}/>
                                        </Table.Cell>
                                        : null}
                                        {params.columns.map((colparams, column) => {
                                            const fmeta = getmeta(meta, 'feature', colparams.path)
                                            let value = this.getValueByPath(this.target2path(colparams.target, colparams.path), cunit)
                                            if (fmeta.params.multiplier) value = value * fmeta.params.multiplier
                                            if (fmeta.params.value_type==='float') value = Math.round(value*1000000)/1000000
                                            const valueRender = colparams.path.startsWith('_') ? value :
                                                <ShowFeatureValue feature={value !== undefined ? {type: colparams.path, value: value} : null} fmeta={fmeta} meta={meta} data={cunit}/>
                                            return (
                                                <Table.Cell key={column}>
                                                    { colparams.link ? (<NavLink to={colparams.link.replace(/\{value\}/, value)}>{valueRender}</NavLink>) : valueRender }
                                                </Table.Cell>
                                            )
                                        })}
                                        { params.view || params.edit || (params['detail_view'] &&  params['detail_view'].length) ?
                                            <Table.Cell width={2} textAlign="right">
                                                { (params['detail_view'] &&  params['detail_view'].length) ?
                                                <Button color='blue' icon='chart bar' size='mini'
                                                    onClick={(e,d) => modalOpen(t('Details'), (
                                                        <DetailView meta={meta} cunits={[cunit]} view={params['detail_view']}/>
                                                    ))}/>
                                                : null }
                                                {params.view && 
                                                <Button type="button" icon="eye" color="blue" size="mini"
                                                    onClick={(e,d) => viewCunit(cunit['_path'])}/>
                                                }
                                                {params.edit && can_write &&
                                                <Button type="button" icon="edit" color="orange" size="mini"
                                                    onClick={(e,d) => editCunit(cunit['_path'])}/>
                                                }
                                            </Table.Cell>
                                        : null }
                                    </Table.Row>
                                )
                            })}
                            </Table.Body>
                        </Table>
                        </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                { pageInfo.pagination ? (
                    <Grid.Row>
                        <Grid.Column>
                            <Pagination activePage={pageInfo.curPage} totalPages={pageInfo.totalPages} onPageChange={pageInfo.pageChange} />
                        </Grid.Column>
                    </Grid.Row>
                ) : null }
            </Grid>
            </>
        )
    }

}

// dirty hack for statics by https://react.i18next.com/latest/withtranslation-hoc#hoist-non-react-statics
const Extended = withTranslation()(ResultList)
Extended['ALLOW_BATCH'] = ResultList.ALLOW_BATCH

export default Extended

