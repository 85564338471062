import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueInOptions, valueByFormikPath, createDefaultFeatureLabel, valueNotEmpty, valueIsIntNumber, getRefTargets, Feature } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Form, Button, Container, DropdownItemProps, Dropdown as PDropdown, Grid, Header, Message, Segment, Tab, Table } from 'semantic-ui-react'
import { EditFormProps } from '../Edit'
import { Field, FieldArray } from 'formik'
import { FormikComponentProps, getFieldError, setFieldValue } from './FormikHelpers'
import Input from './Input'
import Dropdown from './Dropdown'
import { Icon } from 'semantic-ui-react'
import ConfigEditorUiSearchCompSlotQuery from '../ConfigEditor/CEUiSearchCompSlotQuery'
import TextArea from './TextArea'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import "./InputColor.css"

interface InputColorProps extends FormikComponentProps, WithTranslation {
    name: string,
    disabled: boolean
}

interface InputColorState {
    displayColorPicker: boolean
}

class InputColor extends Component<InputColorProps> {

    state = {
        displayColorPicker: false
    }
    
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    }

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    }

    void = () => {}

    render() {
        const { name, validate, fieldProps = {}, disabled, t, i18n } = this.props
        if (disabled && this.state.displayColorPicker)
            this.setState({displayColorPicker: false})

        return (
            <Field name={name} validate={validate}>
                {({field, form, meta}) => {
                    const error = getFieldError(field, form)
                    const value = field.value
                    const changed = (value === meta.initialValue) || (!meta.initalValue && (value === '')) ? '' : ' changedColor'
                    return(
                        <>
                        <Form.Field error={!!error} {...fieldProps}>
                            <div className={"colorPicker colorInput"+changed+(disabled ? '':' enabled')} style={{backgroundColor: value}} onClick={disabled ? this.void : this.handleClick}>&nbsp;</div>
                        </Form.Field>
                        { this.state.displayColorPicker ? <div className="colorPicker popover">
                        <div className="colorPicker cover" onClick={this.handleClose}/>
                            <SketchPicker color={value} 
                                onChangeComplete={(value) => {
                                    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`
                                    setFieldValue(form, name, rgba, true)
                            }} />
                        </div> : null }
                        </>
                    )
                }}
            </Field>
        )

    }
}

export default withTranslation()(InputColor)