/*
 * from https://github.com/turner-industries/formik-semantic-ui/
 */

import React from 'react'
//import hoistNonReactStatics from 'hoist-non-react-statics'
import {connect} from 'formik'
import {Button as SemanticButton, FormButtonProps} from 'semantic-ui-react'

/*class ButtonBase extends React.Component<OmitKeys<FormButtonProps, "type">> {
}*/

type OmitKeys<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export class Button extends React.Component<OmitKeys<FormButtonProps, "type">> {

    public static Submit = props => <SemanticButton primary {...props} type="submit" />

    public static Reset = connect(({formik: {handleReset}, ...props}) => (
        <SemanticButton basic {...props} type="button" onClick={handleReset} />
    ))

    render() {
         return(<SemanticButton {...this.props} type="button" />)
    }
}

/*
const Button = hoistNonReactStatics(
  props => <SemanticButton {...props} type="button" />,
  SemanticButton
)

Button.Submit = props => <SemanticButton primary {...props} type="submit" />

Button.Reset = connect(({formik: {handleReset}, ...props}) => (
  <SemanticButton basic {...props} type="button" onClick={handleReset} />
))
*/

export default Button
