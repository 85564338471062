import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Container } from 'semantic-ui-react'

interface NoResultsProps extends WithTranslation {}

class NoResults extends Component<NoResultsProps> {
    render() {
        const { t } = this.props
        return(
        <Container>
            <p>{t('No results.')}</p>
        </Container>
        )
    }
}

export default withTranslation()(NoResults)