import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Cunit, CUView } from '../../common'
import { Select } from 'semantic-ui-react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer, TooltipProps } from 'recharts'
import { cnctag2labels } from '../../CNC_common'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import './CNCLemmaFqView.css'

enum Frequency { Total, Bel, Odb, Pub, Spk, Dia }

interface CNCLemmaViewProps extends WithTranslation {
    name: string,
    forms: Array<Cunit|CUView>
}

interface CNCLemmaViewState {
    sortBy: Frequency
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip" style={{padding: '10px', background: 'rgb(240,240,180,0.8)', borderRadius: "5px"}}>
          <p className="label"><span style={{color: '#880000'}}>{`${payload[0].payload.word}`}</span>: {`${payload[0].value}`}</p>
          <p className="description"><span style={{color: '#008800'}}>{`${payload[0].payload.tag}`}</span>: {`${payload[0].payload.desc}`}</p>
        </div>
      );
    }
  
    return null;
}

class CNCLemmaFqView extends Component<CNCLemmaViewProps, CNCLemmaViewState> {

    state = {
        sortBy: Frequency.Total
    }

    changeSorting(event, data) {
        this.setState({sortBy: data.value})
    }

    componentDidMount() {
    }

    render() {
        let { forms, t } = this.props
        let { sortBy } = this.state

        const sortOptions = [
            {value: Frequency.Total, text: t('total')},
            {value: Frequency.Bel, text: t('fiction')},
            {value: Frequency.Odb, text: t('non-fiction')},
            {value: Frequency.Pub, text: t('news')},
            {value: Frequency.Spk, text: t('spoken')},
            {value: Frequency.Dia, text: t('diachronic')},
        ]

        let fqFeatures = {}
        fqFeatures[Frequency.Total] = ':stats:fq:abs:cnc:total'
        fqFeatures[Frequency.Bel] = ':stats:fq:abs:cnc:txtype_group-FIC'
        fqFeatures[Frequency.Odb] = ':stats:fq:abs:cnc:txtype_group-NFC'
        fqFeatures[Frequency.Pub] = ':stats:fq:abs:cnc:txtype_group-NMG'
        fqFeatures[Frequency.Spk] = ':stats:fq:abs:cnc:mode-SPK'
        fqFeatures[Frequency.Dia] = ':stats:fq:abs:cnc:mode-DIA'

        const selectedFq = fqFeatures[sortBy]

        let data = Array<{name: string, fq: number, word: string, tag: string, desc: string}>()
        for (let f of forms) {
            const word = f['_slots'][0]['_fillers'][0][':form:attr:cnc:w:word']
            const tag = f['_slots'][0]['_fillers'][0][':form:attr:cnc:w:tag']
            const description = cnctag2labels(tag).map(e => t(e)).join(' - ')
            const label = word+" ("+tag+")"
            let fq = 0
            if (selectedFq in f) {
                fq = f[selectedFq]
            }
            data.push({ name: label, fq: fq, word: word, tag: tag, desc: description })
        }

        data.sort((a,b) => { return b.fq - a.fq })

        return (
            <>
            <Select fluid options={sortOptions} value={sortBy}
                    onChange={(e, d) => {this.changeSorting(e, d)}}/>
            <ResponsiveContainer width='100%' height={forms.length*30+40}>
            <BarChart data={data} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" orientation="top"/>
                <YAxis dataKey="name" type="category" hide={true}/>
                <Tooltip content={CustomTooltip} />
                <Bar dataKey="fq" fill='#008888'>
                    <LabelList dataKey="name" position="insideLeft"/>
                </Bar>
            </BarChart>
            </ResponsiveContainer>
            </>
        )
    }

}

export default withTranslation()(CNCLemmaFqView)