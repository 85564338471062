import React, { Component, ReactNode } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, getLocalizedLabel, UserInfo } from '../common'
import { Container, Accordion, Button, Icon, Header, Grid } from 'semantic-ui-react'

interface ConfigTreeViewProps extends WithTranslation, RouteComponentProps {
    list: {},
    otype: string,
    osubtype: string,
    user: UserInfo|null
}

class ConfigTreeView extends Component<ConfigTreeViewProps> {

    /*tree = {}

    constructor(props: ConfigTreeViewProps) {
        super(props)
        this.tree = this.collectTree(props.list)
        console.log(this.tree)
    }*/

    collectTree(catlist) {
        let cattree = {children:{}}
        let lastparent = {'_can_read': false, '_can_write':false}
        for (var i in catlist) {
            var f = catlist[i]
            var curlevel = cattree
            var steps = f['type'].split(':')
            var curtype = '';
            for (var j in steps) {
                var step = steps[j]
                curtype += step+':'
                if (!(step in curlevel['children'])) {
                    curlevel['children'][step] = {
                        'id': step,
                        'children': {},
                        'path': curtype,
                        '_can_read': lastparent['_can_read'],
                        '_can_write': lastparent['_can_write'],
                        '_defined': false
                        }

                } else {
                    lastparent = curlevel['children'][step]
                }
                curlevel = curlevel['children'][step]
            }
            for (var k in f) {
                curlevel[k] = f[k]
                curlevel['_defined'] = true
            }
        }
        return cattree.children
    }


    redirectTo(e,d,type) {
        const { history, otype, osubtype } = this.props
        const path = type.replace(/^:/, '_:').split(':').join('/')
        history.push('/config/'+otype+'/'+osubtype+'/'+path)
        e.stopPropagation()
    }

    addSubitem(e,d,type) {
        const { history, otype, osubtype } = this.props
        const path = type.replace(/^:/, '_:').replaceAll(':', '/')
        history.push('/config/'+otype+'/'+osubtype+'/'+path+'/_new')
        e.stopPropagation()
    }

    buildTreeView(tree) {
        const { i18n, t } = this.props
        let panels: {}[] = []
        for (let key in tree) {
            const item = tree[key]
            const showicon = (
                <Button primary icon='eye' size='mini' onClick={(e,d) => this.redirectTo(e,d,item.type)} title={t('View')}/>
            )
            const addicon = (
                <Button primary icon='plus' size='mini' onClick={(e,d) => this.addSubitem(e,d,item.type)} title={t('New subtype')}/>
            )
            const show = (item['_defined'] && item['_can_read']) ? showicon : null
            const add = (item['_defined'] && item['_can_write']) ? addicon : null
            const title = (
                <Accordion.Title>
                    <Grid>
                        <Grid.Column width={13} onClick={Object.keys(item['children']).length===0 ? (e) => this.redirectTo(e,null,item.type) : (e) => {}}>
                        {Object.keys(item['children']).length ? <Icon name='dropdown' /> : <Icon name='genderless' />}
                        {(item['label'] ? getLocalizedLabel(item['label'], i18n.language)+' ' : '')+'['+key+']'}
                        </Grid.Column>
                        <Grid.Column width={3} textAlign="right">
                        {show}
                        {add}
                        </Grid.Column>
                    </Grid>
                </Accordion.Title>
            )
            let panel = {key: key, title: title}
            if (Object.keys(item['children']).length) {
                panel['content'] = { content: (<div>{this.buildTreeView(item['children'])}</div>) }
            } else {
                panel['active'] = true
            }
            panels.push(panel)
        }
        if (!panels.length) return null
        return (
            <Accordion panels={panels} exclusive={false} styled/>
        )
    }

    render() {
        const { list, user, i18n, t } = this.props
        const username = (user && user.username) ? user.username : null
        const tree = this.collectTree(list)
        const global = tree[''] ? tree[''].children : {}
        const curuser = username && tree[username] ? tree[username].children : {}
        const othusers = {}
        for (let u in tree) {
            if (u !== '' && u !== username) othusers[u] = tree[u]
        }
        
        return (
            <Container>
                <Header size='medium'>{t('Global')}</Header>
                {this.buildTreeView(global)}
                { list['']['_can_write'] ? 
                    <Button primary icon='plus' size='mini' className="marginTop" onClick={(e,d) => this.addSubitem(e,d,'_')} title={t('New type')}/>
                : null }
                { username ? (
                    <>
                        <Header size='medium'>{t('Private')}</Header>
                        {this.buildTreeView(curuser)}
                        <Button primary icon='plus' size='mini' className="marginTop" onClick={(e,d) => this.addSubitem(e,d,username)} title={t('New type')}/>
                    </>
                ) : null }
                <Header size='medium'>{t("Other users")}</Header>
                {this.buildTreeView(othusers)}
            </Container>
        )
    }

}

export default withTranslation()(ConfigTreeView)