import React, { Component } from 'react'
import { Container, Menu, Button, Icon, Flag } from 'semantic-ui-react'
import { NavLink, Redirect } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { RouteComponentProps } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Meta, UserInfo } from '../common'
import qs from 'qs'

declare var Toolbar: any

const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

interface CNCToolbarProps extends WithTranslation, RouteComponentProps {
  http: AxiosInstance,
  setUser: (UserInfo) => void
}

interface CNCToolbarState {
  user: UserInfo|null,
  loaded: boolean,
  inserted: boolean,
  html: string,
  css: string[],
  redirect: string
}

class CNCToolbar extends Component<CNCToolbarProps, CNCToolbarState> {
  state = {
    user: {
      firstname: '',
      surname: '',
      username: '',
      authorized: false,
      groups: [],
      config: false
    },
    loaded: false,
    inserted: false,
    html: "",
    css: Array<string>(),
    redirect: ""
  }

  Toolbar: any = null

  componentDidMount() {
    let state = JSON.parse(JSON.stringify(this.state))
    let { i18n } = this.props
    const params = {
        current: 'korpusdb',
        continue: window.location.href,
        //sid: getCookie('cnc_toolbar_sid'),
        //lang: getCookie('cnc_toolbar_lang'),
        //at: getCookie('cnc_toolbar_at'),
        //rmme: getCookie('cnc_toolbar_rmme')
    }
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    this.props.http.post('https://www.korpus.cz/toolbar/toolbar', qs.stringify(params), config)
        .then(response => {
            let lang = getCookie('cnc_toolbar_lang')
            if (lang !== i18n.language) i18n.changeLanguage(lang)
            state.user.firstname = response.data.user.firstName
            state.user.surname = response.data.user.surname
            state.user.username = response.data.user.user
            state.user.authorized = true
            state.html = response.data.html
            for (let i of Object.keys(response.data.styles)) {
                state.css.push(response.data.styles[i].url)
            }
            let deps: string[] = []
            let main = ''
            if (response.data.scripts.depends) {
              for (let i of Object.keys(response.data.scripts.depends)) {
                  deps.push(response.data.scripts.depends[i].url)
              }
            }
            main = response.data.scripts.main
            this.appendScripts(deps, main)
            state.loaded = true
            if (response.data.redirect) state.redirect = response.data.redirect
            else this.props.setUser(state.user)
            this.setState(state)
        }).catch(err => {
            state.loaded = true
            if (err.response) {
                // UNAUTHORIZED
                if (err.response.status===401) {
                    //this.setState({user: {authorized: false}})
                }
            }
            this.setState(state)
        })
  }

  componentDidUpdate(prevProps: CNCToolbarProps, prevState: CNCToolbarState) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(prevProps.location, this.props.location)
    }
  }


appendScripts(deps, main) {
  const url = deps.shift()
  const script = document.createElement("script")
  if (url) {
    script.src = url
    script.addEventListener("load", () => this.appendScripts(deps, main), false)
  } else {
    script.src = main
    script.addEventListener("load", () => {
      Toolbar.init()
      Toolbar.setContinueUrl(window.location.href)
      this.Toolbar = Toolbar
    }, false)
  }
  document.body.appendChild(script)
}

  onRouteChanged(prev, current) {
    if (this.Toolbar) this.Toolbar.setContinueUrl(window.location.href)
    //this.setState({html: this.state.html.replaceAll(encodeURIComponent(prev.pathname), encodeURIComponent(current.pathname))})
  }

  render() {
    const { i18n, location, t, history } = this.props
    const { html, css, loaded, redirect } = this.state
    if (!loaded) return null
    if (redirect) {
      window.location.href = redirect
      this.setState({redirect: ''})
    }

    return (
        <>
        {css.map((ss, i) => { return(
            <link key={"toolbar"+i} rel="stylesheet" type="text/css" href={ss} media="all"/>
        )})}
        <Container className="toolbar-contents" dangerouslySetInnerHTML={{__html: html}}></Container>
        </>
    )
  }
}

export default withTranslation()(CNCToolbar)