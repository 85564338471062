import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, removeEmptyValues, getLocalizedLabel, getmeta } from '../../common'
import { Container, Segment, Grid, Dropdown as PureDropdown, DropdownItemProps, Message, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import ConfigEditorCatFeature from './CECatFeature'
import ConfigEditorCatCunit from './CECatCunit'
import ConfigEditorCatSlot from './CECatSlot'
import ConfigEditorCatFiller from './CECatFiller'
import ConfigEditorResCorpus from './CEResCorpus'
import ConfigEditorResSAttr from './CEResSAttr'
import ConfigEditorUiView from './CEUiView'
import ConfigEditorUiSearch from './CEUiSearch'
import PathSpec from '../form/PathSpec'
import ConfigEditorUiEditor from './CEUiEditor'
import Form from '../form/Form'
import Button from '../form/Button'
import Loader from '../Loader'
import { FieldArray, Formik } from 'formik'

export interface ConfigEditorComponentProps extends WithTranslation, RouteComponentProps {
    user: UserInfo | null,
    formik: any,
    meta: Meta,
    locale: string,
    editing: boolean
}

interface ConfigEditorProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance,
    user: UserInfo | null,
    otype: string,
    osubtype: string,
    spectype: string,
    newtype: boolean,
    reload: () => void
}

interface ConfigEditorState {
    lang: string,
    editing: boolean,
    errorMsg: string,
    submitting: boolean
}

class ConfigEditor extends Component<ConfigEditorProps, ConfigEditorState> {

    state: ConfigEditorState = {
        lang: 'cs',
        editing: false,
        errorMsg: "",
        submitting: false
    }

    updateConfig(values, formikApi) {
        //const exceptions = ['user']
        //value = removeEmptyValues(value, exceptions)
        //console.log("Update:", values)
        //formikApi.setSubmitting(false)
        this.sendUpdate(values, formikApi)
    }

    sendUpdate(values, formikApi) {
        const { meta, otype, osubtype, spectype, newtype, t, history } = this.props
        let request = values
        //request['_client'] = packageJson.name + '/' + packageJson.version
        const spec = values.type
        this.setState({submitting: true})
        let url = 'meta/'+otype+'/'+osubtype+'/'+spec.replace(/^:/, '_:').split(':').join('/')
        this.props.http.post(url, request)
            .then(response => {
                this.setState({errorMsg: '', submitting: false})
                formikApi.setSubmitting(false)
                this.props.reload()
                history.push('/config/'+otype+'/'+osubtype+'/'+spec.replace(/^:/, '_:').split(':').join('/'))
            }).catch(err => {
                this.setState({submitting: false})
                formikApi.setSubmitting(false)
                if (err.response) {
                    // UNAUTHORIZED
                    if (err.response.status===401) {
                        //this.setState({authorized: false})
                        this.setState({errorMsg: t('Permission denied.')})
                    } else {
                        this.setState({errorMsg: t(err.response.data.message)})
                    }
                }
            })
    }

    render() {
        const { meta, user, otype, osubtype, spectype, newtype, t } = this.props
        const { lang, editing, submitting } = this.state

        if (newtype && !editing) this.setState({editing: true})

        if (submitting) return (<Loader message={t('Updating...')}/>)
        
        const localeOptions: DropdownItemProps['options'] = [
            {key: 'cs', text: t('Czech'), value: 'cs'},
            {key: 'en', text: t('English'), value: 'en'}
        ]

        const groupOptions: DropdownItemProps['options'] = [
            {key: '', value: '', text: t('none')}
        ]

        if (user) {
            for (let grp of user.groups) {
                const grpnames = user.groupmap
                groupOptions.push({key: grp, value: grp, text: grpnames[grp] ? grpnames[grp].name : grp})
            }
        }

        const newmeta = {
            type: spectype,
            group: !user ? '' : user.group || user.groups[0],
            label: {},
            description: {},
            subdef_required: false,
            subspec_required: false,
            perms: { group_read: true, group_write: true, public_read: true, public_write: false },
            params: {}
        }
        
        if (newtype) {
            if (otype==='resources' && osubtype==='struct_attr')
                newmeta['params'] = { values: [] }
            if (otype==='uis' && osubtype==='search')
                newmeta['params'] = { form: [], request_defaults: { sort: [] } , render: { comp: '', params: {} } }
            if (otype==='uis' && osubtype==='view')
                newmeta['params'] = { components: [] }
            if (otype==='uis' && osubtype==='editor')
                newmeta['params'] = { components: [] }
        }

        const mymeta = newtype ? newmeta : meta[otype][osubtype][spectype]
        const paramEditors = {
            cats: {
                feature: ConfigEditorCatFeature,
                cunit: ConfigEditorCatCunit,
                slot: ConfigEditorCatSlot,
                filler: ConfigEditorCatFiller
            },
            uis: {
                search: ConfigEditorUiSearch,
                view: ConfigEditorUiView,
                edit: ConfigEditorUiEditor
            },
            resources: {
                corpus: ConfigEditorResCorpus,
                struct_attr: ConfigEditorResSAttr
            }
        }
        const ParamEditor = paramEditors[otype] && paramEditors[otype][osubtype] ? paramEditors[otype][osubtype] : React.Fragment
        
        return (
            <>
            { this.state.errorMsg &&
            <Container>
                <Message error icon='exclamation triangle' header={t('Error')} content={this.state.errorMsg}/>
            </Container>}
            <Container>
                <Form initialValues={mymeta} className="editform"
                    onSubmit={(values: {}, formikApi) => this.updateConfig(values, formikApi)}>
                    {(formik) => (
                    <>
                    <Menu borderless>
                    <Menu.Item><Header size='medium'>{getLocalizedLabel(formik.values.label, lang)}</Header>&nbsp; [{formik.values.type}]</Menu.Item>
                    <Menu.Menu position='right'>
                        { formik.values._can_write && !editing ?
                        <Menu.Item>
                            <Button onClick={() => this.setState({editing: true})} color="blue" size="small" type="button">{t('Edit')}</Button>
                        </Menu.Item>
                        : null }
                        <Menu.Item>
                            {t('Localization')}:&nbsp;
                            <PureDropdown options={localeOptions} value={lang} onChange={(e,d) => this.setState({lang: d.value as string})}/>
                        </Menu.Item>
                    </Menu.Menu>
                    </Menu>
                    <Segment>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={2}><div className="label pad">{t('Type')}</div></Grid.Column>
                                <Grid.Column width={14}>
                                    { newtype ? 
                                        <PathSpec name="type" basePath={spectype} inputProps={{disabled: !editing}}/>
                                    :
                                        <Input name="type" inputProps={{disabled: true}}/>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={2}><div className="label pad">{t('Label')}</div></Grid.Column>
                                <Grid.Column width={14}><Input key={"label."+lang} name={"label."+lang} inputProps={{disabled: !editing, placeholder: t('undefined')}}/></Grid.Column>
                            </Grid.Row>
                            <Grid.Row verticalAlign="top">
                                <Grid.Column width={2}><div className="label pad">{t('Description')}</div></Grid.Column>
                                <Grid.Column width={14}><TextArea key={"description."+lang} name={"description."+lang} inputProps={{disabled: !editing, placeholder: t('undefined')}}/></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={2}><div className="label">{t('Requires')}</div></Grid.Column>
                                <Grid.Column width={3}><Checkbox name="subdef_required" label={t('subdefinition')} inputProps={{disabled: !editing}}/></Grid.Column>
                                <Grid.Column width={11}><Checkbox name="subspec_required" label={t('subspecification')} inputProps={{disabled: !editing}}/></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={2}>
                                    <div className={editing && user && user.groups.includes(formik.values['group']) ? 'pad label' : 'label'}>{t('Group')}</div>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    { editing && user && (formik.values['group']==='' || user.groups.includes(formik.values['group'])) ? 
                                        <Dropdown name="group" options={groupOptions}/> :
                                        <span>{formik.values['_group_name']}</span>
                                    }
                                </Grid.Column>
                                <Grid.Column width={11}></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={2}><div className="label">{t('Permissions')}</div></Grid.Column>
                                <Grid.Column width={3}><Checkbox name="perms.group_write" label={t('group write')} inputProps={{disabled: !editing}}/></Grid.Column>
                                <Grid.Column width={3}><Checkbox name="perms.group_read" label={t('group read')} inputProps={{disabled: !editing}}/></Grid.Column>
                                <Grid.Column width={3}><Checkbox name="perms.public_write" label={t('public write')} inputProps={{disabled: !editing || !user || !user.is_admin}}/></Grid.Column>
                                <Grid.Column width={5}><Checkbox name="perms.public_read" label={t('public read')} inputProps={{disabled: !editing || !user || !user.is_admin}}/></Grid.Column>
                            </Grid.Row>
                            { formik.values['subspec_required'] ?
                            <>
                            <Grid.Row>
                                <Grid.Column width={16}><div className="label">{t('Subspecifications')}</div></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={7}>{t('Label')}</Grid.Column>
                                <Grid.Column width={6}></Grid.Column>
                                <Grid.Column width={3}></Grid.Column>
                            </Grid.Row>
                            <FieldArray name={"params.subspecs"}
                                render={(subformik) => {
                                    const subspecs: string[] = formik.values['params'].subspecs || []
                                    return(
                                    <>
                                    {subspecs.map((item: any, i: number) => {
                                        return (
                                            <Grid.Row className="condensed">
                                            <Grid.Column width={7}>
                                                <Input key={"params.subspecs."+i+".label."+lang} name={"params.subspecs."+i+".label."+lang} 
                                                    inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                                            </Grid.Column>
                                            <Grid.Column width={6}>
                                            </Grid.Column>
                                            <Grid.Column width={3} textAlign='right'>
                                                { editing ?
                                                <>
                                                <Button type="button" icon="delete" color="red" size="mini"
                                                    disabled={!editing}
                                                    onClick={(e,d) => subformik.remove(i)}/>
                                                </>
                                                : null }
                                            </Grid.Column>
                                            </Grid.Row>
                                        )
                                    })}
                                    { editing ? <Grid.Row className="condensed">
                                        <Grid.Column width={16}>
                                        <Button icon="plus" type="button" size="tiny" color='blue' 
                                            onClick={(e,d) => subformik.push({label: {}})} disabled={!editing}/>
                                        </Grid.Column>
                                        </Grid.Row>
                                    : null }
                                    </>
                                )}}/>
                            </>
                            : null }
                        </Grid>
                    </Segment>
                    {formik.values.subdef_required || <ParamEditor {...this.props} formik={formik} locale={lang} editing={editing}/>}
                    { editing ? 
                    <>
                        <Button.Submit disabled={!(formik.dirty && formik.isValid)}>{t('Save changes')}</Button.Submit>
                        { !newtype ? <Button onClick={() => {this.setState({editing: false}); formik.resetForm()}} color="red" type="button">{t('Cancel editing')}</Button> : null }
                    </>
                    : null }
                    </>
                    )}
                </Form>
            </Container>
            </>
        )
    }

}

export default withTranslation()(ConfigEditor)
