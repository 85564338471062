import * as FileSaver from 'file-saver'
import { Dropdown } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import React from 'react'
//import { jsPDF } from 'jspdf'
//import 'svg2pdf.js'
import { default as svg2png } from 'd3-svg-to-png'

//export interface csvData { [key:string] : any}[]

export interface svgExportProps {
    svgId: string,
    baseName: string
}

function SVGExport(props: svgExportProps) {
    const { t } = useTranslation()
    const { svgId, baseName } = props

    /*const createStyleElementFromCSS = () => {
        // assume index.html loads only one CSS file in <header></header>
        const sheet = document.styleSheets[0]
      
        const styleRules = []
        for (let i = 0; i < sheet.cssRules.length; i++)
          styleRules.push(sheet.cssRules.item(i).cssText)
      
        const style = document.createElement('style')
        style.type = 'text/css'
        style.appendChild(document.createTextNode(styleRules.join(' ')))
      
        return style
    }*/

    /*const exportPDF =  (svgId: string, baseName: string) => {
        const fileExtension = '.pdf'
        const pdf = new jsPDF()

        const svg = document.querySelector('#'+svgId)
        if (!svg) return
        pdf.svg(svg)
        .then(() => {
            pdf.save(baseName + fileExtension)
        })
    }*/

    /*const exportPDF =  (svgId: string, baseName: string, scale: number = 1) => {
        const fileExtension = '.png'
        const svg = document.querySelector('#'+svgId)
        saveSvgAsPng(svg, baseName + fileExtension, {scale: scale})
    }*/


    const exportPNG =  (svgId: string, baseName: string, scale: number = 1) => {
        const svg = document.querySelector('#'+svgId)
        if (!svg) return
        const vBox = svg.attributes.getNamedItem('viewBox')
        const hasWidth = svg.attributes.getNamedItem('width')
        if (!hasWidth && vBox) {
            const vbarr = vBox.value.split(',')
            svg.setAttribute('width', vbarr[2])
            svg.setAttribute('height', vbarr[3])
        }
        svg2png('#'+svgId, baseName, { scale: scale, background: 'white' })
        if (!hasWidth) {
            svg.removeAttribute('width')
            svg.removeAttribute('height')
        }
    }

    const exportSVG = (svgId: string, baseName: string) => {
        const fileExtension = '.svg'
        const svg = document.querySelector('#'+svgId)

        if (!svg) return
        // CSS must be explicitly embedded
        //const style = createStyleElementFromCSS();
        //svg.insertBefore(style, svg.firstChild);
        
        const data = (new XMLSerializer()).serializeToString(svg);
        const svgBlob = new Blob([data], {
            type: 'image/svg+xml;charset=utf-8'
        });
        
        // remove the temporarily injected CSS
        //style.remove();
        FileSaver.saveAs(svgBlob, baseName + fileExtension)
    }

    return (
        <Dropdown button compact scrolling direction="left" icon='download' color="grey" className="icon" title={t('Export chart')}>
        <Dropdown.Menu>
            <Dropdown.Item onClick={(e, d) => exportSVG(svgId, baseName)}>{t('SVG (.svg)')}</Dropdown.Item>
            {/*<Dropdown.Item onClick={(e, d) => exportPDF(svgId, baseName)}>{t('PDF (.pdf)')}</Dropdown.Item>*/}
            <Dropdown.Item onClick={(e, d) => exportPNG(svgId, baseName, 1)}>{t('PNG (.png)')}</Dropdown.Item>
            <Dropdown.Item onClick={(e, d) => exportPNG(svgId, baseName, 2)}>{t('PNG large (.png)')}</Dropdown.Item>
            <Dropdown.Item onClick={(e, d) => exportPNG(svgId, baseName, 4)}>{t('PNG huge (.png)')}</Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
    )
}

export default SVGExport