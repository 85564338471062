import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, getmeta, valueIsNumInRange, valueByFormikPath } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiSearchCompProps} from './CEUiSearch'
import PathSpec from '../form/PathSpec'
import ConfigEditorUiSearchCompFeatureQuery from './CEUiSearchCompFeatureQuery'
import { setFieldValue } from '../form/FormikHelpers'

class ConfigEditorUiSearchCompSlotQuery extends Component<ConfigEditorUiSearchCompProps> {

    render() {
        const { meta, editing, locale, t, i18n, params, formikprefix, formik } = this.props
        const lang = i18n.language

        //if (!(meta && meta.cats && meta.cats['feature'])) return null
        
        if (params.multi === undefined) {
            setFieldValue(formik, formikprefix+'.multi', {min:0, max:1, default: 1}, false)
            return null
        }

        if (params.fillers === undefined) {
            setFieldValue(formik, formikprefix+'.fillers', {features: {options: []}}, false)
            return null
        }

        const values = valueByFormikPath(formikprefix, formik.values)

        return (
            <>
            <Grid.Row>
                <Grid.Column width={4}><div className="label">{t('Multi')}</div></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={1}>
                    <div className="pad">{t('min.')}</div>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Input name={formikprefix+".multi.min"} inputProps={{disabled: !editing, type: "number"}} validate={v => valueIsNumInRange(v, 0, values.multi.max)}/>
                </Grid.Column>
                <Grid.Column width={1}>
                    <div className="pad">{t('max.')}</div>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Input name={formikprefix+".multi.max"} inputProps={{disabled: !editing, type: "number"}} validate={v => valueIsNumInRange(v, values.multi.min, NaN)}/>
                </Grid.Column>
                <Grid.Column width={2}>
                    <div className="pad">{t('default')}</div>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Input name={formikprefix+".multi.default"} inputProps={{disabled: !editing, type: "number"}} validate={v => valueIsNumInRange(v, values.multi.min, values.multi.max)}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}><div className="label">{t('Fillers')}</div></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                <Segment color="grey">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}><div className="label">{t('Features')}</div></Grid.Column>
                                        </Grid.Row>
                                        <ConfigEditorUiSearchCompFeatureQuery {...this.props} params={params.fillers.features} 
                                            formikprefix={formikprefix+".fillers.features"} parentClass='filler'/>
                                        </Grid>
                                </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiSearchCompSlotQuery)