import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { FqTooltip, colors, extractAttrValues, ErrorList, legendStyle, legendWidth, StatsChartProps } from './StatsCatChart'
import { AxisDomain } from 'recharts/types/util/types'

const completeTimeLine = function(fqlist: Array<{}>) {
    let dataByYear = fqlist.reduce((obj, i) => {
        obj[i['name']] = i
        return obj
    }, {})
    let availableYears = Object.keys(dataByYear)
    let years = availableYears.map( d => parseInt(d) )
    let values = fqlist[0] ? Object.keys(fqlist[0]) : []
    let ret: Array<{}> = []
    for (let n = Math.min(...years); n <= Math.max(...years); n++) {
        let nstr = n.toString()
        if (availableYears.includes(nstr)) {
            ret.push(dataByYear[nstr])
        } else {
            let empty = {}
            values.forEach(v => {
                empty[v] = 0
            })
            empty['name'] = nstr
            ret.push(empty)
        }
    }
    return ret
}

class StatsTimeChart extends Component<StatsChartProps> {

    fqlist = Array<{}>()
    absolute: {}|false = false
    cunames: string[] = []
    errors: string[] = []

    constructor(props: StatsChartProps) {
        super(props)
        const { cunits, meta, params, corpus, attribute, relative, directData, directAbsData, directSelect, i18n } = props
        const valuedesc = props.values

        if (directData && directAbsData) {
            this.cunames = directSelect ? directSelect : Object.keys(directData[0]).filter(x => x !== 'name')
            this.fqlist = directData
            this.absolute = directAbsData
        } else if (cunits && corpus && attribute) {
            let values = extractAttrValues(cunits, params, corpus, attribute, meta, valuedesc, relative, i18n)
            this.cunames = values.cunames
            this.fqlist = completeTimeLine(values.fqlist)
            this.absolute = values.absolute
            this.errors = values.errors
        }
    }

    render() {
        const { relative, domain, t, i18n, values, width, height, legend } = this.props
        
        const unit = relative ? " i.p.m." : ""
        const mydomain = domain ? domain : [0, 'auto'] as AxisDomain

        if (this.errors.length) return ErrorList(this.errors, t)
        
        let chartWidth = width ? width : "100%"
        let chartHeight = height ? height : 150
        const legendPos = legend ? legend : 'bottom'
        let legendNode: React.ReactNode =  null
        if (this.cunames.length > 1) {
            if (legendPos === 'right') {
                legendNode = (<Legend layout="vertical" verticalAlign="top" align="right" wrapperStyle={legendStyle}/>)
            } else {
                legendNode = (<Legend />)
            }
        } else {
            if (legendPos === 'right' && typeof(chartWidth) === 'number') {
                chartWidth -= legendWidth
            }
        }

        if (relative) {
            return (
                <ResponsiveContainer width={chartWidth} height={chartHeight}>
                <LineChart data={this.fqlist}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis type="number" domain={mydomain}/>
                    <XAxis dataKey="name"/>
                    <Tooltip content={<FqTooltip absstats={this.absolute} values={values} language={i18n.language} t={t}/>}/>
                    {legendNode}
                    {this.cunames.map((name: string, index: number) => {
                        return (<Line key={index} dataKey={name} stroke={colors[index]} isAnimationActive={false} unit={unit}/>)
                    })}
                </LineChart>
                </ResponsiveContainer>
            )
        } else {
            return(
            <ResponsiveContainer width={chartWidth} height={chartHeight}>
            <BarChart data={this.fqlist}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis type="number" domain={mydomain}/>
                <XAxis dataKey="name" type="category"/>
                <Tooltip content={<FqTooltip absstats={this.absolute} values={values} language={i18n.language} t={t}/>}/>
                {legendNode}
                {this.cunames.map((name: string, index: number) => {
                    return (<Bar key={index} dataKey={name} stackId="a" fill={colors[index]} isAnimationActive={false} unit={unit}/>)
                })}
            </BarChart>
            </ResponsiveContainer>
            )
        }
    }
}

export default withTranslation()(StatsTimeChart)