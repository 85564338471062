/*
 * from https://github.com/turner-industries/formik-semantic-ui/blob/master/src/helpers.js
 */
import { getIn } from 'formik'
import { FormFieldProps } from 'semantic-ui-react'
import ErrorMessage from './ErrorMessage'

export interface FormikComponentProps {
  name: string;
  id?: string;
  label?: React.ReactNode;
  validate?: ((value: any) => string | Promise<void> | undefined);
  fieldProps?: FormFieldProps;
  fast?: boolean;
  errorComponent?: typeof ErrorMessage
}

export const getFieldError = (field, form) => {
  const { name } = field
  return getIn(form.errors, name)
  /*const { serverValidation } = form.status || {}
  const touched = getIn(form.touched, name)
  const checkTouched = serverValidation
    ? !touched
    : touched;
  return checkTouched && getIn(form.errors, name)*/
};

export const setFieldValue = (form, name, value, shouldValidate) => {
  form.setFieldValue(name, value, shouldValidate)
  form.setFieldTouched(name, true, shouldValidate)
}