import React, { Component, ReactNode } from 'react'
import { Grid, Icon, Label, StrictGridColumnProps } from 'semantic-ui-react'
import { FeatureQueryParams, Meta, getmeta, getLocalizedLabel, createDefaultFeatureLabel, valueByFormikPath, valueNotEmpty, collectFeatureOptions, defaultFeatureQueryParams } from '../../common'
import { FieldArray } from "formik"
import { withTranslation, WithTranslation } from 'react-i18next'
import { setFieldValue } from './FormikHelpers'
import Input from './Input'
import Dropdown, { DropdownComponentProps } from './Dropdown'
import Checkbox from './Checkbox'
import Button from './Button'

export const addDefaultFeature = (query, params) => {
    if (!query.feats) query.feats = []
    if (params.options && params.options.length) {
        let feature: any = {}
        const defaults = params.defaults || {}
        feature.type = defaults.type || params.options[0].type
        feature.value = defaults.value
        feature.method = defaults.method || params.options[0].method[0]
        feature.ci = defaults.ci || params.options[0].ci
        feature.neg = defaults.neg
        query.feats.push(feature)
    }
}

const TextInput = (args) => (
    <Input name={args.name} inputProps={args.inputProps}/>
)

const NumInput = (args) => (
    <Input name={args.name} inputProps={{type: "number", ...args.inputProps}}/>
)

const SelInput = (args) => (
    <Dropdown name={args.name} inputProps={{multiple: true, selection: true, compact: true, ...args.inputProps}} options={args.options}/>
)

const NoInput = (args) => (
    <Icon name="check" color="blue" size="big"/>
)

interface QueryProps extends WithTranslation {
    prefix: string,
    label: string|null,
    params: FeatureQueryParams,
    meta: Meta,
    formik: any,
    cunitTypes?: string[]|null,
    slotTypes?: string[]|null,
    fillerTypes?: string[]|null
}

type FeatureQueryProps = QueryProps;

class FeatureQuery extends Component<FeatureQueryProps> {

    withPrefix(fieldName: string) {
        return `${this.props.prefix}.${fieldName}`
    }

    render () {
        const { meta, formik, prefix, params, i18n, t } = this.props
        const { cunitTypes, slotTypes, fillerTypes} = this.props

        if (params.hidden) return null

        const methods = {
            defined: {key: 'defined', text: t('set'), value: 'defined', input: NoInput/*, description: 'only test for presence'*/},
            exact: {key: 'exact', text: t('exactly'), value: 'exact', input: TextInput/*, description: 'search exact value only'*/},
            fulltext: {key: 'fulltext', text: t('fulltext'), value: 'fulltext', input: TextInput/*, description: 'search by keywords'*/},
            prefix: {key: 'prefix', text: t('starts'), value: 'prefix', input: TextInput/*, description: 'search by initial letters'*/},
            regexp: {key: 'regexp', text: t('reg.exp.'), value: 'regexp', input: TextInput/*, description: 'search by regular expression'*/},
            eq: {key: 'eq', text: t('='), value: 'eq', input: NumInput/*, description: 'search exact value only'*/},
            lte: {key: 'lte', text: t('<='), value: 'lte', input: NumInput},
            lt: {key: 'lt', text: t('<'), value: 'lt', input: NumInput},
            gte: {key: 'gte', text: t('>='), value: 'gte', input: NumInput},
            gt: {key: 'gt', text: t('>'), value: 'gt', input: NumInput},
            anyflag: {key: 'anyflag', text: t('OR'), value: 'anyflag', input: SelInput},
            allflags: {key: 'allflags', text: t('AND'), value: 'allflags', input: SelInput}
        }

        if (!meta.cats['feature']) return null
        
        let values = valueByFormikPath(prefix, formik.values)
        const all = params.all_features
        const featureTypes = all && Object.keys(all).length ?
                collectFeatureOptions(meta, cunitTypes, slotTypes, fillerTypes, all.cunit, all.slot, all.filler)
            :
                params.options

        const featureMap = featureTypes.reduce((map, v) => {
            map[v.type] = v
            return map
        }, {})

        let available_types: string[] = Object.keys(featureMap)
        let newFeature : any = {
            value: '', //params.defaults && params.defaults.value ? params.defaults.value : '', 
            type: '', //params.defaults && params.defaults.type ? params.defaults.type : '', 
            method: undefined, //params.defaults && params.defaults.method ? params.defaults.method : undefined,
            ci: undefined, //params.defaults && params.defaults.ci ? params.defaults.ci : undefined,
            neg: undefined //params.defaults && params.defaults.neg ? params.defaults.neg : undefined
        }
        
        if (!values.feats) return null
    
        let unspecFeature = false

        return (
            <React.Fragment>
                <FieldArray name={prefix+'.feats'}
                    render={(subformik) => (
                        <>
                        {values.feats.map((f: any, index: number) => {
                            const fprefix = 'feats.'+index
                            const fmeta = getmeta(meta, 'feature', f.type)

                            if (!f.type) {
                                let newFeatureOptions: DropdownComponentProps['options'] = []
                                for (let t of available_types) {
                                    if (!featureMap[t]) continue
                                    let explLabel = featureMap[t].label
                                    let flabel =  explLabel ? getLocalizedLabel(explLabel, i18n.language) : createDefaultFeatureLabel(meta, t, true)
                                    newFeatureOptions.push({key: t, text: flabel, value: t})
                                }
                                unspecFeature = true
                                return (
                                    <Grid.Row verticalAlign="middle">
                                        <Grid.Column width={4}>
                                            <Dropdown name={this.withPrefix(fprefix+'.type')} options={newFeatureOptions}
                                                validate={valueNotEmpty} inputProps={{compact: true}}/>
                                        </Grid.Column>
                                        <Grid.Column width={12}>
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            }

                            let qparams = defaultFeatureQueryParams(meta, featureMap[f.type])

                            let columns = 0

                            if (!all) available_types = available_types.filter(i => i !== f.type)

                            const specCnt = f.type.length === fmeta.type.length ? 0 : f.type.substr(fmeta.type.length+1).split(':').length
                            const requiredSubspecCnt = fmeta.subspec_required && fmeta.params.subspecs ? fmeta.params.subspecs.length : 0
                            const needSubspecCnt = requiredSubspecCnt - specCnt
                            const needAggr = fmeta.params.aggregate && (specCnt < requiredSubspecCnt+1)
                            const allowSubspec = specCnt == 0 && qparams.allow_spec && !needAggr
                            
                            const valueType = fmeta.params.value_type
                            const options = valueType === 'enum' || valueType === 'flags' ? 
                                fmeta.params.enum.map(o => {
                                    return { key: o.value, text: getLocalizedLabel(o.label, i18n.language), value: o.value}}
                                ) : []

                            if (needSubspecCnt) {
                                if  (f.subspec === undefined || f.subspec === null) f.subspec = []
                                for (let i=0; i<needSubspecCnt; i++) {
                                    if (f.subspec[i]===undefined) setFieldValue(formik, this.withPrefix(fprefix+'.subspec.'+i.toString()), '', false)
                                }
                            }

                            // numeric values: set 0 as initial/default value
                            if ((valueType==='int' || valueType==='float') && f.value==='') {
                                setFieldValue(formik, this.withPrefix(fprefix+'.value'), 0, false)
                            }
                            
                            const subspecComps: ReactNode[] = (f.subspec !== undefined && f.subspec !== null) ?
                                f.subspec.map((v,i) => (
                                    <Grid.Column width={2}>
                                    <Input name={this.withPrefix(fprefix+'.subspec.'+i.toString())} /*validate={(value) => this.validate(value)}*/
                                        inputProps={{placeholder: fmeta.params.subspecs && fmeta.params.subspecs[i] ? 
                                            getLocalizedLabel(fmeta.params.subspecs[i].label, i18n.language) : t('specification')}}/>
                                    </Grid.Column>
                                ))
                            : allowSubspec ?
                                [(
                                    <Grid.Column width={2}>
                                    <Button type="button" size="mini" icon="ellipsis horizontal" title={t('Add specification')}
                                        onClick={(e,d) => {setFieldValue(formik, this.withPrefix(fprefix+'.subspec.0'), '', true);e.preventDefault()}}/>
                                    </Grid.Column>
                                )]
                            : null

                            if (subspecComps) columns += 2*subspecComps.length

                            let aggrspecComp: ReactNode = null
                            if (needAggr) {
                                aggrspecComp = (
                                    <Grid.Column width={2}>
                                    <Input name={this.withPrefix(fprefix+'.aggrspec')} /*validate={(value) => this.validate(value)}*/
                                        inputProps={{placeholder: getLocalizedLabel(fmeta.params.aggregate.label, i18n.language)}}/>
                                    </Grid.Column>

                                )
                                columns += 2
                            }
                            
                            const mymethods =  qparams.method.map(m => methods[m])
                            let inputProps = {}

                            let label = null
                            if (qparams.label && Object.keys(qparams.label)) {
                                label = getLocalizedLabel(qparams.label, i18n.language)
                            } else {
                                label = createDefaultFeatureLabel(meta, f.type)
                            }
                            if (qparams.icon) {
                                inputProps['icon'] = qparams.icon
                            }

                            let method: ReactNode = null
                            const defaultMethod = mymethods[0].value
                            if (valueByFormikPath(this.withPrefix(fprefix+'.method'), formik.values)===undefined)
                                setFieldValue(formik, this.withPrefix(fprefix+'.method'), defaultMethod, false)
                            if (mymethods.length > 1) {
                                method = (
                                    <Grid.Column width={2}>
                                    <Dropdown name={this.withPrefix(fprefix+'.method')} options={mymethods} inputProps={{defaultValue: defaultMethod, compact: true}}/>
                                    </Grid.Column>
                                )
                                columns += 2
                            }
                            let ci: ReactNode = null
                            if (qparams.ci) {
                                ci = (
                                    <Grid.Column width={1}>
                                    <Checkbox name={this.withPrefix(fprefix+'.ci')} label={t('A=a')}/>
                                    </Grid.Column>
                                )
                                columns += 1
                            }
                            let neg: ReactNode = null
                            if (qparams.neg) {
                                neg = (
                                    <Grid.Column width={1}>
                                    <Checkbox name={this.withPrefix(fprefix+'.neg')} label={t('NOT')}/>
                                    </Grid.Column>
                                )
                                columns += 1
                            }
                            let labelComp: ReactNode = null
                            if (label) {
                                labelComp = (
                                    <Grid.Column width={2}>
                                    <Label htmlFor={this.withPrefix(fprefix+'.value')} className="fieldlabel">{label}</Label>
                                    </Grid.Column>
                                )
                                columns += 2
                            }
                            let delComp: ReactNode = null
                            if (!params.options || params.options.length!==1) {
                                delComp = (
                                    <Grid.Column width={1}>
                                    <Button icon="delete" type="button" size='mini' color='red' 
                                            onClick={(e,d) => subformik.remove(index)}/>
                                    </Grid.Column>
                                )
                                columns += 1
                            }
                            const InputField = methods[f.method] ? methods[f.method].input : methods[defaultMethod].input
                            const width = 16 - columns
                            return (
                                <React.Fragment key={index}>
                                    <Grid.Row verticalAlign="middle">
                                        {labelComp}
                                        {subspecComps}
                                        {aggrspecComp}
                                        {neg}
                                        {method}
                                        <Grid.Column width={width as StrictGridColumnProps['width']}>
                                        <InputField name={this.withPrefix(fprefix+'.value')} inputProps={inputProps} options={options}/>
                                        </Grid.Column>
                                        {ci}
                                        {delComp}
                                    </Grid.Row>
                                </React.Fragment>
                            )
                        })}
                        {(!params.options || params.options.length!==1) && available_types.length && !unspecFeature ? (
                        <Grid.Row>
                            <Grid.Column>
                                <Button icon="plus" type="button" size='mini' color='blue' 
                                    onClick={(e,d) => subformik.push(newFeature)}/>
                            </Grid.Column>
                        </Grid.Row>
                        ) : null}
                        </>
                    )} />
            </React.Fragment>
        )
    }
}

export default withTranslation()(FeatureQuery)