import React, { Component } from 'react'
import { NavLink, RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, updateRequest, Cunit } from '../common'
import { AxiosInstance } from 'axios'
import { initReactI18next, withTranslation, WithTranslation } from 'react-i18next'
import { Container, Grid, Header, Message, Segment, Tab, Input as PInput, Confirm } from 'semantic-ui-react'
import EditFeatureForm from './form/EditFeatureForm'
import EditSlotForm from './form/EditSlotForm'
import { setFieldValue } from './form/FormikHelpers'
import { Modal } from 'semantic-ui-react'
import Input from './form/Input'
import { Dropdown } from 'semantic-ui-react'
import Form from './form/Form'
import Button from './form/Button'
import Loader from './Loader'
import { FieldArray } from 'formik'
import BatchUpdateFeature from './form/BatchUpdateFeature'
import { saveAs } from 'file-saver'
import { Http2ServerRequest } from 'http2'
import BatchDeleteFeature from './form/BatchDeleteFeature'

interface BatchProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance,
    batchId: string,
    query: Query,
    message: string,
    close: (reset: boolean) => void
}

interface BatchState {
    submitting: boolean,
    errorMsg: string,
    response: RatatoskResponse|null
}

class Batch extends Component<BatchProps, BatchState> {

    default_state: BatchState = {
        submitting: false,
        errorMsg: '',
        response: null
    }

    state = this.default_state

    saveForm(updates) {
        localStorage.setItem('form_batch_'+this.props.batchId, JSON.stringify(updates))
    }

    loadForm() {
        const form = localStorage.getItem('form_batch_'+this.props.batchId)
        return form ? JSON.parse(form) : false
    }

    runBatch(updates, formik) {
        const { query, http, t } = this.props
        this.saveForm(updates)
        let request = JSON.parse(JSON.stringify(updates))
        request['query'] = query
        request['_client'] = import.meta.env.APP_NAME + '/' + import.meta.env.APP_VERSION
        this.setState({submitting: true, errorMsg: '', response: null})
        const url = 'cunits/_update_by_query'
        if (formik) {
            formik.setSubmitting(true)
        }
        http.post(url, request)
            .then(response => {
                this.setState({response: response.data, errorMsg: '', submitting: false})
                if (formik) {
                    formik.setSubmitting(false)
                }
            }).catch(err => {
                if (err.response) {
                    // UNAUTHORIZED
                    if (err.response.status===401) {
                        this.setState({submitting: false, errorMsg: t('Permission denied'), response: null})
                    } else {
                        this.setState({submitting: false, errorMsg: t(err.response.data.message), response: null})
                    }
                }
                if (formik) {
                    formik.setSubmitting(false)
                }
            })
    }

    render() {
        const { meta, batchId, query, message, http, t, i18n, history, close, location } = this.props
        const { submitting, response, errorMsg } = this.state
        
        if (!meta) return null

        let updates: updateRequest = this.loadForm() || {
            delete_features: [],
            delete_fillers: [],
            delete_slots: [], 
            update_names: [], 
            update_types: [],
            add_slots: [],
            add_fillers: [],
            update_features: [],
            update_slot_order: []
        }

        const newFeatureDelete = { type: '' }
        const newFeatureUpdate = { type: '', conflict: 'override' }

        const cunitTypes = query['type'] ? [query['type']] : []

        const baseUrl = window.location.href.substring(0, window.location.href.length - location.pathname.length)

        const saveLog = () => {
            if (!response) return
            const lines = response.data.map((res) => [
                res['name'],
                baseUrl+"/view/"+res['_path'],
                (res['_error'].replace('\n', '; ') || t('Updated'))
                ].join("\t")
            )
            const blob = new Blob([lines.join("\n")+"\n"], {type: "text/plain;charset=utf-8"})
            const ts = new Date().toISOString().substring(0,19)
            saveAs(blob, 'update_log.'+ts+'.csv')
        }
        if (submitting) {
            return(<Loader message={t("Update in progress...")}/>)
        } else if (response !== null) {
            return(
                <Container>
                <Segment color="grey">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header as="h1">{t('Update log')}</Header>
                            { response.data.length ?
                                response.data.map(res => (
                                    <p><strong><NavLink to={'/view/'+res['_path']}>{res['name']}</NavLink></strong>: {res['_error'].replace("\n", '; ') || t('Updated')}</p>
                                ))
                            : (<p>{t("Nothing to update.")}</p>)
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={14}>
                            { response.data.length ?
                            <Button type="button" color="blue" onClick={saveLog}>{t('Save log')}</Button>
                            : null }
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="right">
                            <Button type="button" color="grey" onClick={() => close(response.data.length>0)}>{t("Close")}</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </Segment>
                </Container>
            )
        } else {
            return(
                <>
                <Container>
                    <Form initialValues={updates} className="editform"
                        onSubmit={(updates: {}, formikApi) => this.runBatch(updates, formikApi)}>
                    {(formik) => {
                        return(
                        <>
                        <Grid>
                            { errorMsg &&
                            <Grid.Row>
                                <Grid.Column>
                                <Message error visible icon='exclamation triangle' header={t('Error')} content={errorMsg}/>
                                </Grid.Column>
                            </Grid.Row>
                            }
                            <Grid.Row>
                                <Grid.Column>
                                <Message info header={t('Update of selected units')} content={message}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                            <Grid.Column width={16}>
                            <Segment color='grey'>
                            <Header as="h2">{t('Delete features')}</Header>
                            <Grid>
                            <FieldArray name={"delete_features"}
                                render={(subformik) => (
                                <>
                                {formik.values.delete_features.map((f, fi) => {
                                    return(<BatchDeleteFeature f={f} index={fi} cunitTypes={cunitTypes} prefix={"delete_features["+fi+"]"} formik={subformik} meta={meta}/>)
                                })}
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button icon="plus" type="button" size='mini' color='blue' 
                                            onClick={(e,d) => subformik.push(newFeatureDelete)}/>
                                    </Grid.Column>
                                </Grid.Row>
                                </>
                                )}/>
                            </Grid>
                            </Segment>
                            </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                            <Grid.Column width={16}>
                            <Segment color='grey'>
                            <Header as="h2">{t('Update features')}</Header>
                            <Grid>
                            <FieldArray name={"update_features"}
                                render={(subformik) => (
                                <>
                                {formik.values.update_features.map((f, fi) => {
                                    return(<BatchUpdateFeature f={f} index={fi} cunitTypes={cunitTypes} prefix={"update_features["+fi+"]"} formik={subformik} meta={meta}/>)
                                })}
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button icon="plus" type="button" size='mini' color='blue' 
                                            onClick={(e,d) => subformik.push(newFeatureUpdate)}/>
                                    </Grid.Column>
                                </Grid.Row>
                                </>
                                )}/>
                            </Grid>
                            </Segment>
                            </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={14}>
                                    <Button.Submit disabled={!formik.isValid}>{t('Update')}</Button.Submit>
                                </Grid.Column>
                                <Grid.Column width={2} textAlign="right">
                                    <Button type="button" color="grey" onClick={() => close(false)}>{t("Close")}</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        </>
                    )}}
                    </Form>
                </Container>
                </>
            )
        }
    }

}

export default withTranslation()(Batch)