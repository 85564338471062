export const caseLabels = {
    '-': '',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    'X': '',
}

export const fullCaseLabels = {
    '-': '',
    '1': 'nominative',
    '2': 'genitive',
    '3': 'dative',
    '4': 'accusative',
    '5': 'vocative',
    '6': 'locative',
    '7': 'instrumental',
    'X': '',
}

export const numLabels = {
    'S': 'singular',
    'D': 'dual',
    'P': 'plural',
    'W': 'singular feminine or plural neuter',
    'X': '',
    '-': ''
}

export const typeLabels = {
    'A': 'common adjective',
    'C': 'nominal form',
    'e': 'present transgressive', // transgressive
    'm': 'past transgressive',  // transgressive
    'p': 'past participle',
    'q': 'past participle (arch.)',
    'B': 'present/future',
    't': 'present/future (arch.)',
    'i': 'imperative',
    'f': 'infinitive',
    's': 'n-participle (passive)',
    'c': 'conditional ("to be")',
    '-': ''
}

export const negLabels = {
    'A': 'affirmative',
    'N': 'negative',
    '-': ''
}

export const persLabels = {
    '1': '1.p.',
    '2': '2.p.',
    '3': '3.p.',
    'X': '',
    '-': ''
}

export const modeLabels = {
    'A': 'active',
    'P': 'passive',
    '-': ''
}

export const tempLabels = {
    'F': 'future',
    'H': 'present or past',
    'P': 'present',
    'R': 'past',
    'X': '',
    '-': ''
}

export const degLabels = {
    '1': 'positive',
    '2': 'comparative',
    '3': 'superlative',
    '-': ''
}

export const genLabels = {
    'M': 'masculine animate',
    'I': 'masculine inanimate',
    'F': 'feminine',
    'N': 'neuter',
    'H': 'feminine or neuter',
    'Q': 'feminine sg. or neuter pl.',
    'T': 'masc. in. or fem.',
    'X': '',
    'Y': 'masculine',
    'Z': 'non-feminine',
    '-': ''
}

export function cnctag2labels(tag: string): string[]  {
    const [pos, type, gen, num, nCase, posGen, posNum, pers, time, deg, neg, mode, void1, void2, variant, aspect] = tag.split('')
    let labels: string[] = []
    if ( pos === 'N' ) {
        labels.push('noun')
        labels.push(genLabels[gen])
        labels.push(numLabels[num])
        labels.push(fullCaseLabels[nCase])
    } else if ( pos === 'A' ) {
        labels.push('adjective')
        if ( type === 'C' ) {
            labels.push(typeLabels[type])
        }
        labels.push(negLabels[neg])
        labels.push(degLabels[deg])
        labels.push(genLabels[gen])
        labels.push(numLabels[num])
        labels.push(fullCaseLabels[nCase])
    } else if ( pos === 'P' ) {
        labels.push('pronoun')
        labels.push(genLabels[gen])
        labels.push(numLabels[num])
        labels.push(fullCaseLabels[nCase])
    } else if ( pos === 'C' ) {
        labels.push('numeral')
        labels.push(genLabels[gen])
        labels.push(numLabels[num])
        labels.push(fullCaseLabels[nCase])
    } else if ( pos === 'V' ) {
        labels.push('verb')
        labels.push(negLabels[neg])
        labels.push(typeLabels[type])
        labels.push(genLabels[gen])
        labels.push(numLabels[num])
        labels.push(persLabels[pers])
        labels.push(fullCaseLabels[nCase])
    } else if ( pos === 'D' ) {
        labels.push('adverb')
        labels.push(negLabels[neg])
        labels.push(degLabels[deg])
    } else if ( pos === 'R' ) {
        labels.push('preposition')
    } else if ( pos === 'J' ) {
        labels.push('conjuction')
    } else if ( pos === 'T' ) {
        labels.push('particle')
    } else if ( pos === 'I' ) {
        labels.push('interjection')
    } else {
        labels.push('unknown form')
    }
    return labels.filter(e => e)
}