import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CUView, Meta, getLocalizedLabel } from '../../common'
import { Tab, Header } from 'semantic-ui-react'
import { Md5 } from 'ts-md5'
import StatsCatChart from './StatsCatChart'
import StatsTimeChart from './StatsTimeChart'
import StatsCZMapChart from './StatsCZMapChart'

interface DetailViewProps extends WithTranslation {
    cunits: CUView[],
    meta: Meta,
    view: string[]
}

class DetailView extends Component<DetailViewProps> {

    viewComponents = {
        StatsCatChart: StatsCatChart,
        StatsTimeChart: StatsTimeChart,
        StatsCZMapChart: StatsCZMapChart
    }

    render() {
        const { cunits, meta, view, i18n } = this.props
        const tabs = view.map(v => meta['uis']['view'][v])

        let panes: {menuItem: string, pane: React.ReactNode[]}[] = []
        for (let tab of tabs) {
            let components = tab['params']['components']
            let charts: React.ReactNode[] = []
            for (let comp of components) {
                const Comp = this.viewComponents[comp['comp']]
                if (!Comp) continue
                const params = comp['params']
                const attrtype = params['attr']
                const attrdef = meta['resources']['struct_attr'][attrtype]
                const attrparams = attrdef['params']
                const label = getLocalizedLabel(attrdef['label'], i18n.language)
                const attrname = attrparams['key']
                const values = attrparams['values'] && attrparams['values'].reduce(function(map, v) {
                    map[v.value] = v
                    return map
                }, {})
                const sum = Md5.hashStr(JSON.stringify(cunits))
                const key = attrname+sum
                charts.push(
                    <React.Fragment key={key}>
                        <Header as='h3'>{label}</Header>
                        <Comp legend='bottom' values={values}
                            cunits={cunits} meta={meta} corpus={params['corpus']} attribute={attrname} params={params} relative={true}/>
                    </React.Fragment>
                )
            }
            panes.push({menuItem: getLocalizedLabel(tab['label'], i18n.language), pane: charts})
        }
        
        return (
            <Tab panes={panes} renderActiveOnly={false} style={{padding: '20px'}}/>
        )
    }
}

export default withTranslation()(DetailView)