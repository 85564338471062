import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, getmeta, featureValueTypes, methodNames, collectFeatureOptions, createDefaultFeatureLabel } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header, FeedMeta } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiSearchCompProps} from './CEUiSearch'
import PathSpec from '../form/PathSpec'
import { setFieldValue } from '../form/FormikHelpers'
import Button from '../form/Button'

class ConfigEditorUiSearchCompFeatureQuery extends Component<ConfigEditorUiSearchCompProps> {

    render() {
        const { meta, editing, locale, t, i18n, params, parentClass, formik, formikprefix } = this.props
        const lang = i18n.language

        if (!(meta && meta.cats && meta.cats['feature'])) return null

        if (params.options === undefined) {
            setFieldValue(formik, formikprefix+'.options', [], false)
            return null
        }

        if (params.all_features === undefined) {
            setFieldValue(formik, formikprefix+'.all_features', {}, false)
            return null
        }

        if (params.defaults === undefined) {
            setFieldValue(formik, formikprefix+'.defaults', {}, false)
            return null
        }

        const allFeatOptions = Object.keys(meta.cats['feature']).reduce((res, v) => {
            const name = getLocalizedLabel(meta.cats['feature'][v]['label'], lang)+" ["+v+"]"
            if (v) res.push({key: v, text: name, value: v})
            return res
        }, [] as DropdownItemProps[])

        const newOption = {type: '', value: '', method: ['exact']}

        let availableDefaultOptions = [{key: '', value: '', text: ''}]
        availableDefaultOptions = availableDefaultOptions.concat( params.all_features.cunit || params.all_features.slot || params.all_features.filler ?
                // TODO: filter by params.search etc. like cunitTypes in Search.tsx
                collectFeatureOptions(meta, [formik.values.params.search], null, null, params.all_features.cunit, params.all_features.slot, params.all_features.filler).map(t => {
                    return {key: t.type, text: createDefaultFeatureLabel(meta, t.type, true), value: t.type}
                })
            :
                []
            )

        const defaultFmeta = getmeta(meta, 'feature', params.defaults.type)
        const defaultMethods = defaultFmeta ? featureValueTypes[String(defaultFmeta.params.value_type)].methods : ['exact', 'defined']
        const defaultMethodOptions = defaultMethods.map(m => {return {key: m, value: m, text: t(methodNames[m].label)}})
        
        return (
            <>
            <Grid.Row>
                <Grid.Column width={2}><div className="label">{t('All')}</div></Grid.Column>
                <Grid.Column width={14}>
                    <Checkbox name={formikprefix+".all_features.cunit"} label={t('unit')} fieldProps={{className: "compactfield inlineblock"}}
                        inputProps={{disabled: !editing || (parentClass && parentClass !== 'unit')}}/>
                    <Checkbox name={formikprefix+".all_features.slot"} label={t('slot')} fieldProps={{className: "compactfield inlineblock"}}
                        inputProps={{disabled: !editing || (parentClass && parentClass !== 'unit' && parentClass !== 'slot')}}/>
                    <Checkbox name={formikprefix+".all_features.filler"} label={t('filler')} fieldProps={{className: "compactfield inlineblock"}}
                        inputProps={{disabled: !editing}}/>
                </Grid.Column>
            </Grid.Row>
            {!params.all_features.cunit && !params.all_features.slot && !params.all_features.filler ?
            <>
            <Grid.Row>
                <Grid.Column width={4}><div className="label">{t('Selection')}</div></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                <FieldArray name={formikprefix+".options"}
                    render={(subformik) => (
                        <>
                        {params.options.map((option: any, oindex: number) => {
                            const fmeta = getmeta(meta, 'feature', option.type)
                            const fvalueType = fmeta ? featureValueTypes[String(fmeta.params.value_type)] : {}
                            if (option.type) availableDefaultOptions.push({key: option.type, value: option.type, text: createDefaultFeatureLabel(meta, option.type, true)})
                            const specCnt = !fmeta || option.type.length === fmeta.type.length ? 0 : option.type.substr(fmeta.type.length+1).split(':').length
                            const moreSpec = fmeta && (specCnt == 0 || (specCnt == 1 && fmeta.subspec_required && fmeta.params.aggregate))
                            return (
                                <Segment className="grey">
                                <Grid>
                                <Grid.Row>
                                    <Grid.Column width={11}>
                                    <PathSpec name={formikprefix+".options."+oindex+".type"}
                                        options={allFeatOptions}
                                        basePath={fmeta ? fmeta.type : ''}
                                        validate={valueNotEmpty}
                                        inputProps={{disabled: !editing}}/>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Checkbox name={formikprefix+".options."+oindex+".allow_spec"} label={t('subspec.')} fieldProps={{className: "compactfield pad"}} 
                                                inputProps={{disabled: !editing || !moreSpec}}/>
                                    </Grid.Column>
                                    <Grid.Column width={3} textAlign="right">
                                        { editing ?
                                        <>
                                        <Button type="button" icon="angle up" color="blue" size="mini"
                                            disabled={!editing || oindex === 0}
                                            onClick={(e,d) => subformik.swap(oindex, oindex-1)}/>
                                        <Button type="button" icon="angle down" color="blue" size="mini"
                                            disabled={!editing || oindex === params.options.length-1}
                                            onClick={(e,d) => subformik.swap(oindex, oindex+1)}/>
                                        <Button type="button" icon="delete" color="red" size="mini"
                                            disabled={!editing}
                                            onClick={(e,d) => subformik.remove(oindex)}/>
                                        </>: null }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={2}>
                                        <div className="pad">{t('Label')}</div>
                                    </Grid.Column>
                                    <Grid.Column width={9}>
                                        <Input name={formikprefix+".options."+oindex+".label"} inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Checkbox name={formikprefix+".options."+oindex+".neg"} label={t('!')} fieldProps={{className: "compactfield"}} 
                                            inputProps={{disabled: !editing}}/>
                                        <Checkbox name={formikprefix+".options."+oindex+".ci"} label={t('A=a')} fieldProps={{className: "compactfield"}} 
                                            inputProps={{disabled: (!editing || !fvalueType.ci)}}/>
                                    </Grid.Column>
                                </Grid.Row>
                                {fvalueType.methods && fvalueType.methods.length ?
                                <Grid.Row>
                                    <Grid.Column width={2}>
                                        <div className="">{t('Methods')}</div>
                                    </Grid.Column>
                                    <Grid.Column width={14}>
                                        {fvalueType.methods.map(m => {
                                            const label = methodNames[m].label
                                            return (
                                            <Checkbox name={formikprefix+".options."+oindex+".method"} label={t(label)} value={m} fieldProps={{className: "compactfield inlineblock"}} inputProps={{disabled: !editing}}/>
                                        )})}
                                    </Grid.Column>
                                </Grid.Row>
                                : null }
                                </Grid>
                                </Segment>
                            )
                        })}
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Button icon="plus" type="button" size='mini' color='blue' 
                                    onClick={(e,d) => subformik.push(newOption)} disabled={!editing}/>
                            </Grid.Column>
                        </Grid.Row>
                        </>
                    )}/>
                </Grid.Column>
            </Grid.Row>
            </>
            : null }
            <Grid.Row>
                <Grid.Column width={4}><div className="label">{t('Defaults')}</div></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={2}>
                    <div className="pad">{t('Type')}</div>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Dropdown name={formikprefix+".defaults.type"} inputProps={{disabled: !editing}} 
                        options={availableDefaultOptions}/>
                </Grid.Column>
                <Grid.Column width={2}>
                    <div className="pad">{t('Value')}</div>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Input name={formikprefix+".defaults.value"} inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={2}>
                    <div className="pad">{t('Method')}</div>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Dropdown name={formikprefix+".defaults.method"} inputProps={{disabled: !editing}} 
                        options={defaultMethodOptions}/>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Checkbox name={formikprefix+".defaults.neg"} label={t('NOT')} fieldProps={{className: "compactfield"}} 
                        inputProps={{disabled: !editing}}/>
                    <Checkbox name={formikprefix+".defaults.ci"} label={t('A=a')} fieldProps={{className: "compactfield"}} 
                        inputProps={{disabled: (!editing /*|| !fvalueType.ci*/)}}/>
                </Grid.Column>
            </Grid.Row>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiSearchCompFeatureQuery)