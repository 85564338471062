import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, updateRequest } from '../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Container, Grid, Header, Message, Segment, Tab, Input as PInput, Confirm, Icon } from 'semantic-ui-react'
import EditFeatureForm from './form/EditFeatureForm'
import EditSlotForm from './form/EditSlotForm'
import { setFieldValue } from './form/FormikHelpers'
import { Modal } from 'semantic-ui-react'
import Input from './form/Input'
import { Dropdown } from 'semantic-ui-react'
import Form from './form/Form'
import Button from './form/Button'
import Loader from './Loader'

interface EditProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance
}

export interface EditFormProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    params: {},
    http: AxiosInstance,
    formik: any,
    userMap: UserMap
}

export type UserMap = {[id: string]: string}

interface EditState {
    loading: boolean,
    submitting: boolean,
    errorMsg: string,
    response: RatatoskResponse,
    deleteDialogOpen: boolean,
    nameDialogOpen: boolean,
    createClone: boolean,
    newName: string,
    userMap: UserMap
}

type FeatureMap = {[type:string]: {[parent:string]: FeatureItem[]}}

interface CunitData {
    _id: string,
    _path: string,
    _type: string,
    name: string,
    slots: SlotItem[],
    feats: FeatureMap
}

interface FeatureItem {
    _slot: string|null,
    _filler: string|null,
    _type: string,
    _timestamp: string,
    updated_by: string,
    subspec: string|null,
    value: any,
    delete: boolean
}

interface SlotItem {
    _id: string,
    _pos: number,
    _type: string,
    name: string,
    type: string,
    fillers: FillerItem[],
    delete: boolean
}

interface FillerItem {
    _id: string,
    _type: string,
    name: string,
    type: string,
    delete: boolean
}

export const lastChangeLabel = (f, userMap, showType=true) => {
    if (!f) return(null)
    let info = ""
    if (f.updated_by) {
        info = userMap[f.updated_by] || '#'+f.updated_by
        if (f._timestamp) info += " / "+f._timestamp
    }
    if (showType && f._type) 
        return(f._type+" ("+info+")")
    else
        return(info || null)
}

export function addFeature(formik, meta, target, type) {
    const fmeta = getmeta(meta, 'feature', type)
    const basetype = fmeta.type
    const subspec = type.substr(basetype.length).replace(/^:+/,'') || (fmeta.subspec_required ? '' : null)
    const [sid, fid] = target.split('/')
    const slot = sid || null
    const filler = fid || null
    let newf = {
        _slot: slot,
        _filler: filler,
        _type: type,
        subspec: subspec,
        delete: false
    }

    if (fmeta.params.value_type) {
        const type = featureValueTypes[fmeta.params.value_type]
        newf['value'] = type.multi ? [] : (type.value==='int' || type.value==='float') ? 0 : null
    }
    if (typeof formik.values.feats[basetype] === 'undefined') {
        let newgrps = {}
        newgrps[target] = [newf]
        setFieldValue(formik,"feats['"+basetype+"']", newgrps, true)
    } else if (typeof formik.values.feats[basetype][target] === 'undefined') {
        setFieldValue(formik,"feats['"+basetype+"']['"+target+"']", [newf], true)
    } else {
        const cnt = formik.values.feats[basetype][target].length
        if (subspec===null && cnt>0) newf['subspec'] = String(cnt+1)
        setFieldValue(formik, "feats['"+basetype+"']['"+target+"']["+cnt+"]", newf, true)
        return cnt
    }
    return 0
}

/*const addFlDefFeature = (flid, type) => {
    const fmeta = getmeta(meta, 'feature', type)
    const basetype = fmeta.type
    const [slot, filler] = flid.split('/')
    const newFeature = {
        _slot: slot,
        _filler: filler,
        _type: type,
        subspec: type.substr(basetype.length).replace(/^:+/,''),
        value: featureValueTypes[fmeta.params.value_type]['multi'] ? [] : null,
        delete: false
    }
    if (!features[basetype]) {
        let val = {}
        val[flid] = [newFeature]
        setFieldValue(formik, "feats["+basetype+"]", val, true)
    } else if (!features[basetype][flid]) {
        setFieldValue(formik, "feats["+basetype+"]["+flid+"]", [newFeature], true)
    } else {
        const fcnt = features[basetype][flid].length
        setFieldValue(formik, "feats["+basetype+"]["+flid+"]["+fcnt+"]", newFeature, true)
        return fcnt
    }
    return 0
}*/

class Edit extends Component<EditProps, EditState> {

    state = {
        loading: true,
        submitting: false,
        errorMsg: '',
        response: {code: -1, message: '', data: []},
        deleteDialogOpen: false,
        nameDialogOpen: false,
        createClone: false,
        newName: '',
        userMap: {}
    }

    componentDidMount() {
        //this.componentDidUpdate(this.props, this.state)
        const { location, history, meta, t } = this.props

        const path = location.pathname.replace(/^\/edit\//,"")
        //const request = {'_client': packageJson.name + '/' + packageJson.version}
        if (path) {
            const url = 'cunits/'+path
            this.setState({loading: true})
            this.props.http.get(url)
                .then(response => {
                    this.setState({response: response.data, errorMsg: '', loading: false})
                }).catch(err => {
                    this.setState({loading: false})
                    if (err.response) {
                        this.setState({errorMsg: t(err.response.data.message), loading: false})
                    }
                })
        }
    }

    async fetchUserMap(userList: string[]) {
        const { http } = this.props
        let map = {}
        for (let uid of userList) {
            const res = await http.get('user/'+uid).then(response => response.data?.data.length ? response.data?.data[0] : null)
            if (res) map[uid] = [res.firstname, res.surname].join(" ")
        }
        this.setState({userMap: map})
    }

    mapFeatures(featHash: FeatureMap, userList: string[], feats, slot, filler) {
        const { meta } = this.props
        const parent = slot ? filler ? [slot, filler].join('/') : slot : ''
        for (let f of feats) {
            const editor = f.updated_by
            if (editor && !userList.includes(editor)) userList.push(editor)
            const fmeta = getmeta(meta, 'feature', f.type)
            if (typeof featHash[fmeta.type] === 'undefined') featHash[fmeta.type] = {}
            if (typeof featHash[fmeta.type][parent] === 'undefined') featHash[fmeta.type][parent] = []
            featHash[fmeta.type][parent].push({
                _slot: slot,
                _filler: filler,
                _type: f.type,
                _timestamp: new Date(f.updated_on.replace(' ','T')).toLocaleString(),
                updated_by: f.updated_by,
                subspec: f.type.substr(fmeta.type.length).replace(/^:+/,'') || null,
                value: f.value,
                delete: false
            })
        }
    }

    createCunitData(cunit) : [CunitData, string[]] {
        let slots: SlotItem[] = []
        let feats: FeatureMap = {}
        let userList: string[] = []
        let si = 0
        for (let s of cunit.slots) {
            let fillers: FillerItem[] = []
            for (let fl of s.fillers)  {
                fillers.push({
                    _id: fl.name,
                    _type: fl.type,
                    name: fl.name,
                    type: fl.type,
                    delete: false
                })
                this.mapFeatures(feats, userList, fl.feats, s.name, fl.name)
            }
            let slot = {
                _id: s.name,
                _pos: si+1,
                _type: s.type,
                name: s.name,
                type: s.type,
                fillers: fillers,
                delete: false
            }
            this.mapFeatures(feats, userList, s.feats, s.name, null)
            slots.push(slot)
            si++
        }
        this.mapFeatures(feats, userList, cunit.feats, null, null)
        return [{
            _id: cunit.name,
            _path: cunit._path,
            _type: cunit.type,
            name: cunit.name,
            slots: slots,
            feats: feats
        }, userList]
    }

    updateCunit(values, initialValues, formik) {
        const { t, location } = this.props
        let updates: updateRequest = {
            delete_features: [],
            delete_fillers: [],
            delete_slots: [], 
            update_names: [], 
            update_types: [],
            add_slots: [],
            add_fillers: [],
            update_features: [],
            update_slot_order: []
        }
        let orderChanged = false
        let targetMap: {[old:string]: string} = {}
        targetMap[''] = ''
        if (values.name !== values._id) updates.update_names.push({target: '', name: values.name})
        values.slots.map((s, si) => {
            // delete and rename applied first: need old target name/id
            if (s.delete) updates.delete_slots.push({target: s._id})
            else {
                targetMap[s._id] = s.name
                updates.update_slot_order.push(s.name)
                if (s._pos !== si+1) orderChanged = true
                if (s._id.startsWith('__new_'))
                    // yes, the "pos" argument is rather redundant here - reodering of slots will be triggered anyway if out-of-order,
                    // but the API provides this as independent feature
                    updates.add_slots.push({pos: si+1, type: s.type, name: s.name})
                else {
                    if (s.name !== s._id) updates.update_names.push({target: s._id, name: s.name})
                    if (s.type !== s._type) updates.update_types.push({target: s.name, type: s.type})
                }
                s.fillers.map((fl, fli) => {
                    const oldflid = s._id+'/'+fl._id
                    const newflid = s.name+'/'+fl.name
                    // delete and rename applied first: need old target name/id
                    if (fl.delete) updates.delete_fillers.push({target: oldflid})
                    else {
                        targetMap[oldflid] = newflid
                        if (fl._id.startsWith('__new_'))
                            updates.add_fillers.push({target: s.name, type: fl.type, name: fl.name})
                        else {
                            if (fl.name !== fl._id) updates.update_names.push({target: oldflid, name: fl.name})
                            if (fl.type !== fl._type) updates.update_types.push({target: newflid, type: fl.type})
                        }
                    }
                })
            }
        })
        Object.keys(values.feats).map((basetype) => {
            Object.keys(values.feats[basetype]).map((oldtarget) => {
                // ignore features from deleted targets, they should disappear with their parents automatically
                const target = targetMap[oldtarget]
                if (target !== undefined) {
                    values.feats[basetype][oldtarget].map((f, fi) => {
                        const finitial = initialValues.feats[basetype] 
                            && initialValues.feats[basetype][oldtarget] 
                            && initialValues.feats[basetype][oldtarget][fi] ? 
                                initialValues.feats[basetype][oldtarget][fi] : null
                        if (f.delete) updates.delete_features.push({target: target, type: f._type})
                        else {
                            if (finitial && f.subspec !== (f._type.substr(basetype.length).replace(/^:+/,'') || null)) {
                                // the (sub)specification has changed: we must delete the old feature and create a new one
                                updates.delete_features.push({target: oldtarget, type: f._type})
                                const newftype = basetype + (f.subspec ? ':'+f.subspec : '')
                                updates.update_features.push({target: target, type: newftype, value: f.value})
                            } else if (!finitial || f.value !== finitial.value) {
                                // just update the value of the old feature (or create a new one if it is new)
                                const ftype = finitial ? f._type : [basetype, f.subspec].filter(Boolean).join(':')
                                updates.update_features.push({target: target, type: ftype, value: f.value})
                            }
                        }
                    })
                }
            })
        })
        // do not update slot order if it has not changed
        if (!orderChanged) updates.update_slot_order = []
        //console.log(JSON.stringify(updates, null, 2))

        const path = location.pathname.replace(/^\/edit\//,"")
        const url = 'cunits/'+path
        this.setState({submitting: true})
        window.scrollTo(0, 0)
        this.props.http.post(url, updates)
            .then(response => {
                if (formik) formik.setSubmitting(false)
                this.setState({response: response.data, errorMsg: '', submitting: false})
                window.scrollTo(0, 0)
            }).catch(err => {
                this.setState({loading: false})
                if (formik) formik.setSubmitting(false)
                if (err.response) {
                    // UNAUTHORIZED
                    if (err.response.status===401) {
                        //this.setState({authorized: false})
                        this.setState({errorMsg: err.response.data.message, submitting: false})
                    } else {
                        console.log(err.response.data.message)
                        this.setState({errorMsg: err.response.data.message, submitting: false})
                    }
                }
            })
    }

    renameCUnitDialog(name: string) {
        this.setState({ nameDialogOpen: true, newName: name})
    }

    cloneCUnitDialog(name: string) {
        const { t } = this.props
        this.setState({ nameDialogOpen: true, createClone: true, newName: name+' ('+t('copy')+')'})
    }

    renameCUnit(formik) {
        setFieldValue(formik, "name", this.state.newName, true)
        this.setState({nameDialogOpen: false})
    }

    deleteCUnit() {
        const {location, history, t} = this.props
        this.setState({deleteDialogOpen:false})
        const path = location.pathname.replace(/^\/edit\//,"")
        const url = 'cunits/'+path
        this.setState({submitting: true})
        window.scrollTo(0, 0)
        this.props.http.delete(url)
            .then(response => {
                this.setState({errorMsg: '', submitting: false})
                history.push('/search/')
            }).catch(err => {
                this.setState({loading: false})
                if (err.response) {
                    // UNAUTHORIZED
                    if (err.response.status===401) {
                        //this.setState({authorized: false})
                        this.setState({errorMsg: t('Permission denied.'), submitting: false})
                    } else {
                        console.log(err.response.data.message)
                        this.setState({errorMsg: err.response.data.message, submitting: false})
                    }
                }
            })
    }

    createClone(formik) {
        const { history, t } = this.props
        const { newName, response } = this.state
        const url = 'cunits/_new'
        let newCUnit = {
            type: response.data[0]['type'],
            name: newName,
            slots: JSON.parse(JSON.stringify(response.data[0]['slots'])),
            feats: JSON.parse(JSON.stringify(response.data[0]['feats']))
        }
        this.setState({submitting: true})
        this.props.http.put(url, newCUnit)
            .then(response => {
                if (formik) formik.setSubmitting(false)
                //this.setState({response: response.data, errorMsg: '', loading: false})
                const newPath = response.data.data[0]['_path']
                history.push('/edit/'+newPath)
                this.setState({response: response.data, nameDialogOpen: false, createClone: false, newName: '', errorMsg: '', submitting: false})
            }).catch(err => {
                if (formik) formik.setSubmitting(false)
                if (err.response) {
                    // UNAUTHORIZED
                    if (err.response.status===401) {
                        //this.setState({authorized: false})
                        this.setState({nameDialogOpen: false, createClone: false, newName: '', errorMsg: t('Permission denied.'), submitting: false})
                    } else {
                        console.log(err.response.data.message)
                        this.setState({nameDialogOpen: false, createClone: false, newName: '', errorMsg: err.response.data.message, submitting: false})
                    }
                }
                window.scrollTo(0, 0)
            })
    }

    render() {
        const { meta, t, i18n, history, location } = this.props
        const { loading, submitting, response, errorMsg, nameDialogOpen, newName, userMap } = this.state
        
        if (!(meta['cats']['cunit']) || !(meta['uis']['edit'])) return null

        if (loading) return (<Container><Segment><Loader/></Segment></Container>)
        //if (submitting) return (<Container><Segment><Loader message={t('Updating...')}/></Segment></Container>)

        if (!(response.data && response.data.length)) {
            return (
                <Container>
                    <Message error icon='exclamation triangle' header={t('Error')} content={this.state.errorMsg}/>
                </Container>
            )
        }

        const [unitData, userList] = this.createCunitData(response.data[0])
        if (userList.some(u => !userMap[u])) this.fetchUserMap(userList)
        const mytype = unitData['_type'] as string
        const mymeta = getmeta(meta, 'cunit', mytype)

        const components = {
            FeatureForm: EditFeatureForm,
            SlotFillerForm: EditSlotForm
        }
        
        const redirectToView = (e, d) => {
            history.push(location.pathname.replace(/^\/edit\//,"/view/"))
            e.stopPropagation()
        }

        const closeNameDialog = () => { this.setState({ nameDialogOpen: false }) }

        const unknownComponent = <Message error icon='exclamation triangle' header={t('Error')} content={t('Component not implemented')} visible={true}/>

        const createPanes =  (formikApi)  => {
            let panes: {}[] = []
            for (let vtype of Object.keys(meta['uis']['edit'])) {
                const v = meta['uis']['edit'][vtype]
                if (v['_can_read'] && v.params?.cunit_type?.filter(x => mytype.startsWith(x)).length) {
                    const label = getLocalizedLabel(v['label'], i18n.language)
                    let comps: React.ReactNode[] = []
                    for (let c of v['params']['components']) {
                        const EditComponent = components[c.comp]
                        comps.push(EditComponent ? (
                            <Segment color="grey" clearing>
                                <Header as="h2">{getLocalizedLabel(c.label, i18n.language)}</Header>
                                <EditComponent {...this.props} userMap={userMap} params={c.params} formik={formikApi}/>
                            </Segment>
                            ) : unknownComponent)
                    }
                    panes.push({menuItem: label, render: () => <Tab.Pane>{comps}</Tab.Pane>})
                }
            }
            return panes
        }

        return(
            <>
            { this.state.errorMsg &&
            <Container>
                <Message error icon='exclamation triangle' header={t('Error')} content={this.state.errorMsg}/>
            </Container>}
            <Container>
                <Form initialValues={unitData} className="editform"
                    onSubmit={(values: object, formikApi) => this.updateCunit(values, unitData, formikApi)}>
                {(formik) => {
                    const panes = createPanes(formik)
                    return(
                    <>
                    <Modal open={nameDialogOpen}>
                        <Modal.Content>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                    <PInput label={t('Unit name')} defaultValue={this.state.newName} fluid={true}
                                        onChange={(e,d)=>this.setState({newName: d.value})}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color="red" onClick={closeNameDialog}>{t('Cancel')}</Button>
                            {this.state.createClone ?
                                <Button color="blue" onClick={()=>this.createClone(formik)}>{t('Create clone')}</Button>
                            :
                                <Button color="blue" onClick={()=>this.renameCUnit(formik)}>{t('Rename')}</Button>
                            }
                        </Modal.Actions>
                    </Modal>
                    <Grid>
                        { errorMsg &&
                        <Grid.Row>
                            <Grid.Column>
                            <Message error icon='exclamation triangle' header={t('Error')} content={this.state.errorMsg}/>
                            </Grid.Column>
                        </Grid.Row>}
                        { unitData &&
                        <>
                        <Grid.Row>
                            <Grid.Column width="12">
                            <Header as='h1' className={'inline ' + (formik.initialValues.name!==formik.values['name'] ? 'changed' : '')}>
                                <Header.Content>
                                    {formik.values['name']}
                                    <Header.Subheader style={{display:'inline-block',marginLeft:'.5em'}}>({getLocalizedLabel(mymeta['label'], i18n.language)})</Header.Subheader>
                                </Header.Content>
                            </Header>
                            { mymeta['_can_write'] &&
                                <Button type="button" icon="edit" color="blue" size="tiny" title={t('Rename')} className="rename"
                                    onClick={(e,d) => this.renameCUnitDialog(formik.values['name'])}/>
                            }
                            </Grid.Column>
                            <Grid.Column width="4" textAlign="right">
                            { mymeta['_can_write'] &&
                                <>
                                <Button onClick={()=>this.cloneCUnitDialog(formik.values['name'])} color="blue" size="small" type="button" icon="clone" title={t('Clone')}/>
                                <Button onClick={()=>this.setState({deleteDialogOpen:true})} color="red" size="small" type="button" icon="delete" title={t('Delete')}/>
                                <Confirm
                                    content={t('Are you sure you want to delete this unit completely?')}
                                    cancelButton={t("No")}
                                    confirmButton={t("Yes")}
                                    open={this.state.deleteDialogOpen}
                                    onCancel={()=>this.setState({deleteDialogOpen:false})}
                                    onConfirm={()=>this.deleteCUnit()}
                                    />
                                <Button onClick={redirectToView} color="yellow" size="small" type="button" icon="arrow left" title={t('Back')}/>
                                <Button.Submit disabled={!(formik.dirty && formik.isValid)} icon="save" title={t('Save changes')}/>
                                <Button onClick={() => formik.resetForm()} disabled={!(formik.dirty)} color="yellow" type="button" icon="undo" title={t('Reset changes')}/>
                                </>
                            }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                            { panes.length ? <Tab panes={panes}/> : null }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Button.Submit disabled={!(formik.dirty && formik.isValid)} icon><Icon name="save"/> {t('Save changes')}</Button.Submit>
                                <Button onClick={() => formik.resetForm()} disabled={!(formik.dirty)} color="yellow" type="button" icon><Icon name="undo"/> {t('Reset changes')}</Button>
                            </Grid.Column>
                        </Grid.Row>
                        </>
                        }
                    </Grid>
                    </>
                )}}
                </Form>
            </Container>
            </>
        )
    }

}

export default withTranslation()(Edit)