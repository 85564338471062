import React from "react"
import { Container, Dimmer, Loader as SLoader, Segment } from "semantic-ui-react"
import { withTranslation, WithTranslation } from 'react-i18next'

function Loader({ t, message='' }) {
    const msg = message!=='' ? message : t('Loading...')
    return (
    <Container style={{height: '10em'}}>
        <Dimmer active inverted>
            <SLoader inverted>{t(msg)}</SLoader>
        </Dimmer>
    </Container>
    )
}

export default withTranslation()(Loader)