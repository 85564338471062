import React, { Component, ReactNode } from 'react'
import { Menu, Button, Icon, Flag, Container } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { RouteComponentProps } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Meta, UserInfo } from '../common'
import logo from '../img/logo.svg'
import { ReactSVG } from 'react-svg'

interface TopMenuProps extends WithTranslation, RouteComponentProps {
  meta: Meta,
  http: AxiosInstance,
  nolang?: boolean,
  user: UserInfo|null,
  setUser: (UserInfo) => void
}

interface TopMenuState {
  user: UserInfo,
  loaded: boolean
}

type MenuItem = {
  key: string,
  label: string,
  path: string
}

class TopMenu extends Component<TopMenuProps, TopMenuState> {

  state = {
    user: {
      firstname: '',
      surname: '',
      username: '',
      authorized: false,
      group: '',
      groups: [],
      config: false
    },
    loaded: false
  }

  //handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  logout () {
    this.props.http.get('logout')
      .then(response => {
          this.setState({user:{firstname:'', surname: '', username: '', authorized: false, group: '', groups: [], config: false}, loaded: false})
          this.props.setUser(null)
      }).catch(err => {
          if (err.response) {
              // UNAUTHORIZED
              if (err.response.status===401) {
                  //this.setState({user: {authorized: false}})
              }
          }
      })
  }

  componentDidUpdate(prevProps: TopMenuProps, prevState: TopMenuState) {
    let { loaded } = this.state
    const state = this.state
    if (prevProps.location.pathname === '/login') {
      loaded = false
    }
    if (!loaded && !this.props.user) {
      this.props.http.get('user')
      .then(response => {
          state.user = response.data.data[0]
          state.user.authorized = true
          state.loaded = true
          this.setState(state)
      }).catch(err => {
        state.loaded = true
          if (err.response) {
              // UNAUTHORIZED
              if (err.response.status===401) {
                  //this.setState({user: {authorized: false}})
              }
          }
      })
    }
  }

  render() {
    const { i18n, meta, location, t } = this.props
    const { user } = this.state
    let items : MenuItem[] = []
    const config = location.pathname.startsWith('/config')

    if (!meta.uis['search']) return(null)

    if (!config) {
      // first two levels only (user and base type group)
      const uis: any[] = Object.values(meta.uis['search']).sort((a:any,b:any) => {
        const ua = a.type.split(':', 1)[0]
        const ub = b.type.split(':', 1)[0]
        if (ua != ub)
          return ua < ub ? -1 : 1
        else {
          if (!a.params?.order)
            return 1
          else if (!b.params?.order)
            return -1
          else
            return a.params.order < b.params.order ? -1 : b.params.order < a.params.order ? 1 : 0
        }
      })
      for (const i of uis) {
        //let i = meta.uis['search'][type]
        const parts = i['type'].split(':')
        if (parts.length === 2) {
          // default: base type group for global
          let key = parts[1]
          // non-global: concatenate user and base type group using underscore
          if (parts[0] !== '') {
            key = parts.join('_')
          }
          // localized label or the first one defined
          let label = i['label'][i18n.language]
          if (!label) {
            label = i.label[Object.keys(i.label)[0]]
          }
          const path = "/search/" + key
          items.push({key: key, label: label, path: path})
        }
      }
    } else {
      items = [
        {key: 'categories', label: t('Categories'), path: '/config/cats/'},
        {key: 'interfaces', label: t('Interfaces'), path: '/config/uis/'},
        {key: 'resources', label: t('Resources'), path: '/config/resources/'},
      ]
    }

    const menuitems = items.map((item, index: number) => (
      <Menu.Item key={item['key']} name={item['key']} active={location.pathname.startsWith(item['path']+'/') || location.pathname === item['path']}>
        <NavLink to={item['path']} style={{color:'black'}}>{item['label']}</NavLink>
      </Menu.Item>
    ))
    
    return (
      <Container>
      <Menu pointing secondary color="black" stackable style={{marginTop: '60px', marginBottom: '10px'}}>
        {config && 
        <Menu.Item key="search" name="search">
          <NavLink to="/search" style={{color:'black'}}><Icon name="chevron left" title={t('Search')}/></NavLink>
        </Menu.Item> 
        }
        {menuitems}
        {(!config && (this.state.user.config || this.props.user?.config)) && 
        <Menu.Item key="config" name="config">
          <NavLink to="/config" style={{color:'black'}}><Icon name="cog" title={t('Configuration')}/></NavLink>
        </Menu.Item> 
        }
        <Menu.Menu position='right'>
          {this.props.user ? null :
          <Menu.Item name='user'>
            {user.authorized ? (
            <div>
              <Button compact size='mini' icon labelPosition='right' onClick={() => this.logout()}>
                {user.firstname} {user.surname}
                <Icon name='sign out' />
              </Button>
            </div>
            ) : (
            <div>
              <NavLink to={{ pathname: "/login", state: { from: location } }}>{t('Login')}</NavLink>
            </div>
            )}
          </Menu.Item>
          }
          {this.props.nolang ? null :
          <Menu.Item name='lang'>
            { i18n.language.startsWith('cs') ? (
              <div><Flag name="uk" style={{cursor:'pointer'}} onClick={() => { i18n.changeLanguage('en')}} /></div>
            ) : (
              <div><Flag name="cz" style={{cursor:'pointer'}} onClick={() => { i18n.changeLanguage('cs')}} /></div>
            ) }
          </Menu.Item>
          }
          <Menu.Item name='logo' className="logo">
            <ReactSVG src={logo}/>
          </Menu.Item>
          </Menu.Menu>
      </Menu>
      </Container>
    )
  }
}

export default withTranslation()(TopMenu)