import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiViewCompProps} from './CEUiView'
import Button from '../form/Button'

class ConfigEditorUiViewCompChart extends Component<ConfigEditorUiViewCompProps> {

    render() {
        const { meta, editing, locale, t, i18n, comp } = this.props
        const lang = i18n.language
        const prefix = this.props.formikprefix

        if (!(meta && meta.resources && meta.resources['struct_attr'])) return null

        const corpusOptions = Object.keys(meta.resources['corpus']).reduce((filtered: {}[], v) => {
            if (v.split(':').length == 2) {
                const abbr = v.split(':')[0] === '' ? v.replace(':', '') : v.replace(':', '-')
                const name = getLocalizedLabel(meta.resources['corpus'][v]['label'], lang)+" ["+v+"]"
                filtered.push({key: abbr, text: name, value: abbr})
            }
            return filtered
        }, [])

        const sattrOptions = Object.keys(meta.resources['struct_attr']).map((v, i, arr) => {
            const name = getLocalizedLabel(meta.resources['struct_attr'][v]['label'], lang)+" ["+v+"]"
            return {key: v, text: name, value: v}
        })

        const sattr = meta.resources['struct_attr'][comp.params.attr]
        const valOptions: {}[] = sattr && sattr.params.values ?
            sattr.params.values.map((v, i) => {
                return({key: v.value, text: getLocalizedLabel(v.label, lang)+" ["+v.value+"]", value: v.value})
            }) : []

        return (<>
                <Grid.Row>
                    <Grid.Column width={2}>
                    <div className="label pad">{t('Corpus')}</div>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Dropdown name={prefix+".params.corpus"}
                            validate={valueNotEmpty}
                            inputProps={{disabled: !editing}} options={corpusOptions}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>
                    <div className="label pad">{t('Attribute')}</div>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Dropdown name={prefix+".params.attr"}
                            validate={valueNotEmpty}
                            inputProps={{disabled: !editing}} options={sattrOptions}/>
                    </Grid.Column>
                </Grid.Row>
                { editing || comp.params.constraints ?
                <>
                    <Grid.Row>
                        <Grid.Column width={2}>
                        <div className="label pad">{t('Constraints')}</div>
                        </Grid.Column>
                        <Grid.Column width={14}>
                        </Grid.Column>
                    </Grid.Row>
                    { editing || comp.params.constraints.min ?
                    <Grid.Row className="condensed">
                        <Grid.Column width={2}>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <div className="pad">{t('Min. value')}</div>
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Input key={prefix+".params.constraints.min"} 
                                name={prefix+".params.constraints.min"} 
                                inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                        </Grid.Column>
                    </Grid.Row>
                    : null}
                    { editing || comp.params.constraints.max ?
                    <Grid.Row className="condensed">
                        <Grid.Column width={2}>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <div className="pad">{t('Max. value')}</div>
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Input key={prefix+".params.constraints.max"} 
                                name={prefix+".params.constraints.max"} 
                                inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                        </Grid.Column>
                    </Grid.Row>
                    : null}
                    { editing || comp.params.constraints.exclude ?
                    <>
                    <Grid.Row className="condensed">
                        <Grid.Column width={2}>
                        </Grid.Column>
                        <Grid.Column width={14}>
                            <div className="pad">{t('Excluded values')}</div>
                        </Grid.Column>
                    </Grid.Row>
                    <FieldArray name={prefix+".params.constraints.exclude"}
                        render={(subformik) => {
                            const excludes: string[] = comp.params.constraints && comp.params.constraints.exclude ? 
                                comp.params.constraints.exclude : []
                            return(
                            <>
                            {excludes.map((val: any, vindex: number) => {
                            return (
                                <Grid.Row className="condensed">
                                <Grid.Column width={2}>
                                </Grid.Column>
                                <Grid.Column width={12}>
                                    {valOptions.length ?
                                    <Dropdown name={prefix+".params.constraints.exclude."+vindex}
                                        validate={valueNotEmpty}
                                        inputProps={{disabled: !editing}} options={valOptions}/>
                                    :
                                    <Input key={prefix+".params.constraints.exclude."+vindex}
                                        name={prefix+".params.constraints.exclude."+vindex}
                                        validate={valueNotEmpty}
                                        inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                                    }
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    { editing ?
                                    <Button type="button" icon="delete" color="red" size="mini"
                                        disabled={!editing}
                                        onClick={(e,d) => subformik.remove(vindex)}/>
                                    : null }
                                </Grid.Column>
                                </Grid.Row>
                            )})}
                            { editing ? <Grid.Row className="condensed">
                                <Grid.Column width={2}>
                                </Grid.Column>
                                <Grid.Column width={14}>
                                <Button icon="plus" type="button" size="tiny" color='blue' 
                                    onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                                </Grid.Column>
                                </Grid.Row>
                            : null }
                            </>
                        )}}/>
                    </>
                    : null }
                </> 
                : null }
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiViewCompChart)