import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, getmeta, featureValueTypes, methodNames, collectFeatureOptions, createDefaultFeatureLabel } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header, FeedMeta } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiSearchCompProps} from './CEUiSearch'
import PathSpec from '../form/PathSpec'
import { setFieldValue } from '../form/FormikHelpers'
import Button from '../form/Button'

class ConfigEditorUiSearchCompMenuQuery extends Component<ConfigEditorUiSearchCompProps> {

    render() {
        const { meta, editing, locale, t, i18n, params, parentClass, formik, formikprefix } = this.props
        const lang = i18n.language

        if (!(meta && meta.cats && meta.cats['feature'])) return null

        if (params.options === undefined) {
            setFieldValue(formik, formikprefix+'.options', [], false)
            return null
        }

        if (params.defaults === undefined) {
            setFieldValue(formik, formikprefix+'.defaults', {}, false)
            return null
        }

        const newOption = {label: {}, value: ''}

        const availableDefaultOptions = collectFeatureOptions(meta, [formik.values.params.search]).map(t => {
                    return {key: t.type, text: createDefaultFeatureLabel(meta, t.type, true), value: t.type}
                })

        const defaultFmeta = getmeta(meta, 'feature', params.defaults.type)
        const defaultMethods = defaultFmeta ? featureValueTypes[String(defaultFmeta.params.value_type)].methods : ['exact', 'defined']
        const defaultMethodOptions = defaultMethods.map(m => {return {key: m, value: m, text: t(methodNames[m].label)}})
        
        return (
            <>
            <Grid.Row>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={14}>
                    <Checkbox name={formikprefix+".autoQuery"} label={t('auto query')} fieldProps={{className: "compactfield inlineblock"}}
                        inputProps={{disabled: !editing}}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}><div className="label">{t('Defaults')}</div></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={2}>
                    <div className="pad">{t('Type')}</div>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Dropdown name={formikprefix+".defaults.type"} inputProps={{disabled: !editing}} 
                        options={availableDefaultOptions}/>
                </Grid.Column>
                <Grid.Column width={2}>
                    <div className="pad">{t('Value')}</div>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Input name={formikprefix+".defaults.value"} inputProps={{disabled: !editing, placeholder: t('undefined')}}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={2}>
                    <div className="pad">{t('Method')}</div>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Dropdown name={formikprefix+".defaults.method"} inputProps={{disabled: !editing}} 
                        options={defaultMethodOptions}/>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Checkbox name={formikprefix+".defaults.neg"} label={t('NOT')} fieldProps={{className: "compactfield"}} 
                        inputProps={{disabled: !editing}}/>
                    <Checkbox name={formikprefix+".defaults.ci"} label={t('A=a')} fieldProps={{className: "compactfield"}} 
                        inputProps={{disabled: (!editing /*|| !fvalueType.ci*/)}}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={2}><div className="label">{t('Options')}</div></Grid.Column>
                <Grid.Column width={7}>{t('Label')}</Grid.Column>
                <Grid.Column width={3}>{t('Value')}</Grid.Column>
                <Grid.Column width={3}></Grid.Column>
            </Grid.Row>
            <FieldArray name={formikprefix+".options"}
                render={(subformik) => (
                <>
                {params.options && params.options.map((item: any, index: number) => {
                        return (
                            <Grid.Row>
                                <Grid.Column width={2}></Grid.Column>
                                <Grid.Column width={6}>
                                    <Input name={formikprefix+".options."+index+".label."+locale} inputProps={{disabled: !editing}}
                                        validate={(v) => valueUnique(v, params.options.map((x, i) => { if (i !== index) return x.label[locale]} ), false, false)}/>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Input name={formikprefix+".options."+index+".value"} inputProps={{disabled: !editing}}
                                        validate={(v) => valueUnique(v, params.options.map((x, i) => { if (i !== index) return x.value} ))}/>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Button type="button" icon="angle up" color="blue" size="mini"
                                        disabled={!editing || index === 0}
                                        onClick={(e,d) => subformik.swap(index, index-1)}/>
                                    <Button type="button" icon="angle down" color="blue" size="mini"
                                        disabled={!editing || index === params.options.length-1}
                                        onClick={(e,d) => subformik.swap(index, index+1)}/>
                                    <Button type="button" icon="delete" color="red" size="mini"
                                        disabled={!editing}
                                        onClick={(e,d) => subformik.remove(index)}/>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    })
                }
                <Grid.Row>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={4}>
                        <Button icon="plus" type="button" size='mini' color='blue' 
                            onClick={(e,d) => subformik.push(newOption)} disabled={!editing}/>
                    </Grid.Column>
                </Grid.Row>
                </>
                )}/>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiSearchCompMenuQuery)