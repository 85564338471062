import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, valueIsNumInRange, getmeta } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiViewCompProps} from './CEUiView'
import Button from '../form/Button'

class ConfigEditorUiViewFeatureViewTable extends Component<ConfigEditorUiViewCompProps> {

    render() {
        const { meta, editing, locale, formik, cunitTypes, t, i18n, comp } = this.props
        const lang = i18n.language
        const prefix = this.props.formikprefix

        if (!(meta && meta.cats && meta.cats['feature'])) return null

        const featureOptions = Object.keys(meta.cats['feature']).reduce((res: DropdownItemProps[], type) => {
            const u = meta.cats['feature'][type]
            if (!u.subdef_required
                /* this is really complex and may be buggy: we needed to count with prefix overlaps from both sides? */
                    && (!cunitTypes || !u.params.restr_owner || !u.params.restr_owner.cunit_type || u.params.restr_owner.cunit_type.filter((t: string) => 
                        (cunitTypes.includes(t) || cunitTypes.filter(x => x.startsWith(t+':')).length>0 || cunitTypes.filter(x => t.startsWith(x+':')).length>0)
                        ).length>0)
                    ) {
               res.push({key: type, value: type, text: getLocalizedLabel(u.label, lang)+' ['+type+']'})
            }
            return res
        }, [])
        
        return (
            <>
            <Grid.Row>
                <Grid.Column width={2}><div className="label pad">{t('Label')}</div></Grid.Column>
                <Grid.Column width={14}><Input key={prefix+".label."+locale} name={prefix+".label."+locale} inputProps={{disabled: !editing, placeholder: t('undefined')}}/></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={2}>
                <div className="label pad">{t('Columns')}</div>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Input key={prefix+".params.columns"} 
                        name={prefix+".params.columns"} 
                        inputProps={{disabled: !editing, type: "number", placeholder: t('undefined')}}
                        validate={v => valueIsNumInRange(v, 1, 4)}/>
                </Grid.Column>
                <Grid.Column width={3} textAlign="right">
                <div className="label pad">{t('Title width')}</div>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Input key={prefix+".params.title_width"} 
                        name={prefix+".params.title_width"} 
                        inputProps={{disabled: !editing, type: "number", placeholder: t('undefined')}}
                        validate={v => valueIsNumInRange(v, 1, 15)}/>
                </Grid.Column>
                <Grid.Column width={5}>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="condensed">
                <Grid.Column width={16}>
                    <div className="label pad">{t('Features')}</div>
                </Grid.Column>
            </Grid.Row>
            <FieldArray name={prefix+".params.features"}
                render={(subformik) => {
                    const features: string[] = comp.params.features || []
                    return(
                    <>
                    {features.map((item: any, fi: number) => {
                        const fmeta = getmeta(meta, 'feature', item.feature)
                        return (
                            <Grid.Row className="condensed">
                            <Grid.Column width={10}>
                                <Dropdown name={prefix+".params.features."+fi+".feature"}
                                    /*validate={valueNotEmpty}*/
                                    inputProps={{disabled: !editing}} options={featureOptions}/>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Checkbox name={prefix+".params.features."+fi+".doc"} label={t('document')} fieldProps={{className: "compactfield"}} 
                                    inputProps={{disabled: !editing || !(fmeta && (!fmeta.params.restr_owner || fmeta.params.restr_owner.cunit))}}/>
                                <Checkbox name={prefix+".params.features."+fi+".slot"} label={t('slot')} fieldProps={{className: "compactfield"}} 
                                    inputProps={{disabled: !editing || !(fmeta && (!fmeta.params.restr_owner || fmeta.params.restr_owner.slot))}}/>
                                <Checkbox name={prefix+".params.features."+fi+".filler"} label={t('filler')} fieldProps={{className: "compactfield"}} 
                                    inputProps={{disabled: !editing || !(fmeta && (!fmeta.params.restr_owner || fmeta.params.restr_owner.filler))}}/>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                { editing ?
                                <>
                                <Button type="button" icon="angle up" color="blue" size="mini"
                                    disabled={!editing || fi === 0}
                                    onClick={(e,d) => subformik.swap(fi, fi-1)}/>
                                <Button type="button" icon="angle down" color="blue" size="mini"
                                    disabled={!editing || fi === features.length-1}
                                    onClick={(e,d) => subformik.swap(fi, fi+1)}/>
                                <Button type="button" icon="delete" color="red" size="mini"
                                    disabled={!editing}
                                    onClick={(e,d) => subformik.remove(fi)}/>
                                </>
                                : null }
                            </Grid.Column>
                            </Grid.Row>
                        )
                    })}
                    { editing ? <Grid.Row className="condensed">
                        <Grid.Column width={16}>
                        <Button icon="plus" type="button" size="tiny" color='blue' 
                            onClick={(e,d) => subformik.push({feature:''})} disabled={!editing}/>
                        </Grid.Column>
                        </Grid.Row>
                    : null }
                    </>
                )}}/>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiViewFeatureViewTable)