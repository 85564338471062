import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Meta, RatatoskRequest, RatatoskResponse, valueNotEmpty } from '../common'
import { Container, Dropdown, Grid, GridColumn, Message, Segment } from 'semantic-ui-react'
import Loader from './Loader'
import { json } from 'stream/consumers'
import Form from './form/Form'
import CNCDictForm from './CNCDictForm'
import Input from './form/Input'

interface CNCDictProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance
}

interface CNCDictState {
    sublemma: string,
    groups: {},
    selected_group: string,
    loading: boolean,
    submitting: boolean,
    errorMsg: string
}

export const domain2obj = (domain: string) => {
    return ({
        written: domain.includes('written'),
        spoken: domain.includes('spoken'),
    })
}

export const tag2mclass = (tag: string) => {
    return tag.substring(0,1)
}

export const response2groups = (data: any[]) => {
    const groups = data.reduce((acc, u: any) => {
        const lemma = u._slots[0]._fillers[0][':form:attr:cnc:w:lemma']
        const tag = u._slots[0]._fillers[0][':form:attr:cnc:w:tag']
        const mclass = tag2mclass(tag)
        const gclass = lemma+'_'+mclass
        if (!acc[gclass]) acc[gclass] = []
        acc[gclass].push({
            active: true,
            id: u._id,
            path: u._path,
            form: u._slots[0]._fillers[0][':form:attr:cnc:w:word'],
            tag: tag,
            lemma: lemma,
            sublemma: u._slots[0]._fillers[0][':form:attr:cnc:w:sublemma'],
            domain: domain2obj(u[':form:cncdict:domain'])
        })
        return acc
    }, {})
    return groups
}

class CNCDict extends Component<CNCDictProps, CNCDictState> {

    state = {
        groups: [],
        loading: false,
        submitting: false,
        errorMsg: '',
        selected_group: '',
        sublemma: ''
    }

    componentDidMount() {
        //this.componentDidUpdate(this.props, this.state)
        const { location, history, meta, t } = this.props

        const path = location.pathname.replace(/^\/comp\/cncdict\/?/,"")
        //const request = {'_client': packageJson.name + '/' + packageJson.version}
        if (path.startsWith("sublemma/")) {
            const [sublemma, ...gclassArr] = path.replace(/sublemma\//, "").split('_')
            let request: RatatoskRequest = {
                query: {
                    'type': ':cncdict:form',
                    'feats': [
                        {type: ':form:attr:cnc:w:sublemma', value: sublemma}
                    ]
                },
                page: { from: 0, size: 1000 },
                feats: [':form:attr:cnc:w', ':form:cncdict:domain']
            }
            request['_client'] = import.meta.env.APP_NAME + '/' + import.meta.env.APP_VERSION
            this.setState({loading: true})
            const url = 'cunits/_view'
            this.props.http.post(url, request)
                .then(response => {
                    const groups = response2groups(response.data.data)
                    let selected = gclassArr.join('_')
                    // is the class specified in URL available?
                    if (!groups[selected]) {
                        // no: is there another class with the same start (e.g. lemma) available?
                        const matching = Object.keys(groups).filter(x => x.startsWith(selected))
                        // if yes: take the first one found; if no: just take first group in the list
                        selected = matching.length ? matching[0] : Object.keys(groups)[0]
                    }
                    this.setState({sublemma: sublemma, groups: groups, selected_group: selected, errorMsg: '', loading: false})
                }).catch(err => {
                    this.setState({loading: false})
                    if (err.response) {
                        this.setState({errorMsg: err.response.data.message, loading: false})
                    }
                })
        }
    }

    update(values, initialValues, formik) {

    }

    changeSelection(gclass: string) {
        this.setState({selected_group: gclass})
    }


    render() {
        const { meta, t, i18n, history, location } = this.props
        const { sublemma, groups, selected_group, loading, submitting, errorMsg } = this.state
        
        if (!(meta['cats']['cunit']) || !(meta['uis']['edit'])) return null

        if (loading) return (<Container><Segment><Loader/></Segment></Container>)

        if (!Object.keys(groups).length) return null

        const lemmaOptions = Object.keys(groups).map(k => {
            const lemma = k //.split('_')[0]
            return ({key: lemma, value: lemma, text: lemma})
        })

        //const [lemma, mclass] = selected_group.split('_')

        /*const mclassOptions = Object.keys(groups).reduce((arr: any, k: string) => {
            const [l, cl] = k.split('_')
            if (lemma===l && !arr.includes(cl))
                arr.push({key: cl, value: cl, text: cl})
            return arr
        }, [])*/

        const currentGroup = groups[selected_group]

        if (!currentGroup) return null

        const unitsByForm = currentGroup.reduce((acc, u, ui) => {
            if (!acc[u.form]) acc[u.form] = []
            u.index = ui
            acc[u.form].push(u)
            return acc
        }, {})

        return(
            <>
            <>{this.state.errorMsg ?
              (
                <Container>
                    <Message error icon='exclamation triangle' header={t('Error')} content={this.state.errorMsg}/>
                </Container>
              ) : null}
            </>
            <Container>
                <Form initialValues={{groups: groups}} className="editform"
                    onSubmit={(values: object, formikApi) => this.update(values, groups, formikApi)}>
                {(formik) => {
                    return (
                        <>
                        <Segment>
                        <Grid>
                            <Grid.Row verticalAlign='middle'>
                                <Grid.Column width={2}><strong>{t('Sublemma')}</strong></Grid.Column>
                                <Grid.Column width={3}>
                                    {sublemma}
                                </Grid.Column>
                                <Grid.Column width={2}><strong>{t('Lemma')}</strong></Grid.Column>
                                <Grid.Column width={3}>
                                    <Dropdown options={lemmaOptions} value={selected_group} 
                                        onChange={(e, { value }) => this.changeSelection(value as string/*+'_'+mclass*/)} />
                                </Grid.Column>
                                {/*<Grid.Column width={2}><strong>{t('Class')}</strong></Grid.Column>
                                <Grid.Column width={3}>
                                    <Dropdown options={mclassOptions} value={mclass} 
                                        onChange={(e, { value }) => this.changeSelection(lemma+'_'+value)} />
                                </Grid.Column>*/}
                            </Grid.Row>
                        </Grid>
                        </Segment>
                        { Object.keys(unitsByForm).map((form: string) => {
                            const units = unitsByForm[form]
                            return(
                                <CNCDictForm {...this.props} prefix={`groups.${selected_group}`} formik={formik} form={form} units={units}/>
                            )
                        })
                        }
                        </>
                    )
                }}
                </Form>
            </Container>
            </>
        )
    }

}

export default withTranslation()(CNCDict)