import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, valueIsNumInRange, getmeta } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiViewCompProps} from './CEUiView'
import Button from '../form/Button'

class ConfigEditorUiViewAcPhraseView extends Component<ConfigEditorUiViewCompProps> {

    render() {
        const { meta, editing, locale, formik, cunitTypes, t, i18n, comp } = this.props
        const lang = i18n.language
        const prefix = this.props.formikprefix

        if (!(meta && meta.cats && meta.cats['feature'])) return null
        
        return (
            <>
            <Grid.Row>
                <Grid.Column width={2}><div className="label pad">{t('Label')}</div></Grid.Column>
                <Grid.Column width={14}><Input key={prefix+".label."+locale} name={prefix+".label."+locale} inputProps={{disabled: !editing, placeholder: t('undefined')}}/></Grid.Column>
            </Grid.Row>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiViewAcPhraseView)