import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueIsIntNumber, descFiller, descSlot, getRefTargets, collectFeatureOptions, Cunit, Feature, getSlotDescription, createSlotTreeFromRefs } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, Dropdown as PDropdown, DropdownItemProps, Grid, Header, Icon, Label, Message, Segment, Tab, Popup } from 'semantic-ui-react'
import { ViewCompProps } from '../View'
import * as d3 from 'd3'
import SVGExport from '../SVGExport'
import _uniqueId from 'lodash/uniqueId'

class ComponentStrucChart extends Component<ViewCompProps> {

    myRef
    contentsRef
    svgId
    rootEl
    margin
    width
    height
    svgbody
    svg
    tree_type
    data
    root
    maxdepth

    constructor(props){
        super(props)
        this.myRef = React.createRef()
        this.contentsRef = React.createRef()
        this.svgId = _uniqueId('chart-')
        this.margin = {top: 30, right: 20, bottom: 30, left: 20}
        this.width = 600
        this.height = 400
    }


    getDepth(obj) {
        let depth = 0
        if (obj.children) {
            obj.children.forEach((d) => {
                let tmpDepth = this.getDepth(d)
                if (tmpDepth > depth) {
                    depth = tmpDepth
                }
            })
        }
        return 1 + depth
    }

    updateChart(updatedNode) {
        const { meta, config } = this.props
        const cunit = this.props.data
        const params = config.params
        const duration = 750
        const varcolor = "#DAA520"

        this.tree_type = params['parent_ptr'] ? 'tree' : 'const'
        const level_distance = params.level_distance || this.tree_type == 'tree' ? 90 : 50
        const width = this.width
        const height = ((this.maxdepth - 1) * level_distance) + 20;
        const tree_type = this.tree_type

        //this.svg.selectAll('*').remove()

        const shortDesc = (node) => {
          if (node.short_desc !== undefined)
            return node.short_desc
          const slot = node.node_data
          let fdesc = getSlotDescription(slot, meta, cunit, true)
          if (fdesc.length>3) {
            fdesc.splice(2)
            fdesc.push('…')
          }
          node.short_desc = fdesc.join("/")
          return node.short_desc
        }

        const getLabelFeature = (slot) => {
          if (!config.params.label_feature) return ''
          return slot.feats.filter(f => f.type === config.params.label_feature).map(f => f.value).join('/')
        }

        this.svgbody.transition()
          .duration(duration)
          .attr("height", height + this.margin.top + this.margin.bottom)
        
        /*svg.append('rect')
          .attr("width", width)
          .attr("height", height)
          .attr("stroke","#000")
          .attr("fill", "none");*/

        
        //const nodeWidth = 90
        //const nodeHeight = 55
        // dendrogram or a tree?
        const treeBase = tree_type==='tree' ? d3.tree() : d3.cluster()
        const tree = treeBase
                .size([this.width, this.height])
                //.nodeSize([nodeWidth,nodeHeight])
                .separation(function(a, b) {
                  return a.parent == b.parent ? 1 : 1.25
                })

        // Assigns the x and y position for the nodes
        let treeData = tree(this.root)

        // Compute the new tree layout.
        let nodes = treeData.descendants()
        let links = treeData.descendants().slice(1).filter(d => d.parent.data.node_type !== 'virtual')

        let i = 0
        /*var diagonal = d3.linkVertical()
            .x(function(d) { return d.x; })
            .y(function(d) { return d.y; });*/

        // Normalize for fixed-depth.
        nodes.forEach((d) => {
            if (tree_type==='tree') {
               d.y = ((d.depth - 1) * level_distance) + this.margin.top;
            } else {
                if (d.children) {
                    d.y = ((d.depth - 1) * level_distance) + this.margin.top;
                } else {
                    d.y = ((this.maxdepth - 2) * level_distance)  + this.margin.top;
                }
            }
        })

        // Update the nodes…
        var node = this.svg.selectAll("g.node")
          .data(nodes, function(d: any) { return d.id || (d.id = ++i); })

        // Enter any new nodes at the parent's previous position.
        var nodeEnter = node.enter().append("g")
          //.attr("class", "node")
          .attr("class", function (d: any) { return "node "+d.data.node_type; })
          .attr("transform", function(d) { return "translate(" + updatedNode.x0 + "," + updatedNode.y0 + ")"; })

        nodeEnter.filter((d: any) => d.data.node_type === "slot")
          .on("click", (e, d) => this.click(d))
          .attr('cursor', 'pointer')

        nodeEnter.filter((d: any) => d.data.node_type === "slot").append("rect")
          .attr('class', 'node')
          .attr("x", -15)
          .attr("y", -10)
          .attr("width", 30)
          .style("fill", function(d: any) {
              const smeta = getmeta(meta, 'slot', d.data.node_data['type'])
              return d._children ? "lightsteelblue" : smeta.params && smeta.params.color ? smeta.params.color : '#ccc'
          })
          .style("fill-opacity", 1e-6)
          .attr("height", 20)
          .transition()
          .duration(duration)
          .style("fill-opacity", 1)

        nodeEnter.filter((d: any) => d.data.node_type === "filler").append("ellipse")
          .attr('class', 'node')
          .attr("rx", 15)
          .attr("ry", 10)
          .style("fill", "#dd3")
          .style("fill-opacity", 1e-6)
          .transition()
          .duration(duration)
          .style("fill-opacity", 1)

        nodeEnter.filter((d: any) => d.data.node_type !== "virtual").append("text")
          .attr('class', 'node_label')
          //.attr("x", function(d) { return d.children || d._children ? -13 : 13; })
          .attr("x", function(d) { return 0; })
          //.attr("dy", ".3em")
          .attr("dy", function (d: any) { return (!d.data.children || !d.data.children.length) ? "0" : ".3em"; })
          //.attr("text-anchor", function(d) { return d.children || d._children ? "end" : "start"; })
          .attr("text-anchor", function(d) { return "middle"; })
          .text(function(d: any) { return d.data.node_type === "filler" ? d.data.name : '['+d.data.name+"] "+getLabelFeature(d.data.node_data); })
          .style("fill-opacity", 1e-6)
          .style("font-family", "Helvetica,Arial,sans-serif")

        nodeEnter.filter((d: any) => d.data.node_type === "slot" && shortDesc(d.data)).append("text")
          .attr('class', 'node_desc')
          .attr("x", function(d) { return 0; })
          .attr("dy", "1.7em")
          .attr("text-anchor", function(d) { return "middle"; })
          .text(function(d: any) { return shortDesc(d.data); })
          .style("fill-opacity", 1e-6)
          .style("font-family", "Helvetica,Arial,sans-serif")

        nodeEnter.selectAll('rect')
          .attr("x", function(this: any, d) {return -1*((this.parentNode.getBBox().width+8)/2);})
          .attr("width", function(this: any, d) {return this.parentNode.getBBox().width+8;})
          .attr("y", function(this: any, d) {return -1*((this.parentNode.getBBox().height+4)/2);})
          .attr("height", function(this: any, d) {return this.parentNode.getBBox().height+4;})

        // Transition nodes to their new position.

        let nodeUpdate = nodeEnter.merge(node)

        nodeUpdate.transition()
          .duration(duration)
          .attr("transform", function(d: any) { return "translate(" + d.x + "," + d.y + ")"; })

        nodeUpdate.select("rect.node").transition()
          .duration(duration)
          .style("fill", function(d: any) {
            const smeta = getmeta(meta, 'slot', d.data.node_data['type'])
            return d._children ? "lightsteelblue" : smeta.params && smeta.params.color ? smeta.params.color : '#fff'
          })
          .style("fill-opacity", 1)

        nodeUpdate.select("ellipse.node").transition()
          .duration(duration)
          .style("fill", "#dd3")
          .style("fill-opacity", 1)

        nodeUpdate.select("text.node_label").transition()
          .duration(duration)
          .style("fill-opacity", 1)

        nodeUpdate.select("text.node_desc").transition()
          .duration(duration)
          .style("fill-opacity", 1)

        // Transition exiting nodes to the parent's new position.
        var nodeExit = node.exit()

        nodeExit.transition()
          .duration(duration)
          .attr("transform", function(d) { return "translate(" + updatedNode.x + "," + updatedNode.y + ")"; })
          .remove()

        nodeExit.select("rect").transition()
          .duration(duration)
          .style("fill-opacity", 1e-6)

        nodeExit.select("ellipse").transition()
          .duration(duration)
          .style("fill-opacity", 1e-6)

        nodeExit.select("text.node_label").transition()
          .duration(duration)
          .style("opacity", 1e-6)

        nodeExit.select("text.node_desc").transition()
          .duration(duration)
          .style("opacity", 1e-6)

        // Update the links…
        var link = this.svg.selectAll("path.link")
          .data(links, function(d: any) { return d.id; })

        // Enter any new links at the parent's previous position.
        var linkEnter = link.enter().insert("path", "g")
          .attr("class", "link")
          .attr("fill", "none")
          .attr("stroke-width", "1px")
          .style("opacity", 1e-6)
          .attr("d", function(d: any) {
            const o = {x: updatedNode.x0, y: updatedNode.y0, data: updatedNode}
            return tree_type==='const' || (d.data.node_type === 'filler') ? brokenLine(o, o) : diagonal(o, o)
          })

        linkEnter.transition()
          .duration(duration)
          .attr("stroke", function (d: any) { return (d.data.node_type === 'filler' || (tree_type!=='const' && d.parent.data.node_type === 'filler')) ?  varcolor : "#333"; })
          .attr("stroke-dasharray", function (d: any) { return (d.data.node_type === 'filler'|| (tree_type!=='const' && d.parent.data.node_type === 'filler')) ?  "5,5" : ""; })
          .style("opacity", 1)

        // UPDATE
        var linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate.transition()
          .duration(duration)
          .attr('d', function(d: any){ return tree_type==='const' || (d.data.node_type === 'filler') ? brokenLine(d, d.parent) : diagonal(d, d.parent) })
          .style("opacity", 1)


        // Transition exiting nodes to the parent's new position.
        link.exit().transition()
          .duration(duration)
          .attr("d", function(d: any) {
            const o = {x: updatedNode.x, y: updatedNode.y, data: updatedNode}
            return tree_type==='const' || (d.data.node_type === 'filler') ? brokenLine(o, o) : diagonal(o, o)
          })
          .style("opacity", 1e-6)
          .remove()

        // Stash the old positions for transition.
        nodes.forEach(function(d) {
            d.x0 = d.x
            d.y0 = d.y
        })

        // Creates a curved (diagonal) path from parent to the child nodes
        function diagonal(s, d) {
            const path = `M ${s.x} ${ d.data.node_desc ? s.y - 20 : s.y - 12}
                    C ${s.x} ${s.y - level_distance + 30},
                      ${d.x} ${(d.y + (level_distance-20)*0.7)},
                      ${d.x} ${ d.data.node_desc ? d.y + 31 : d.y + 10}`
            /*
            path = `M ${s.x} ${s.y - (s.getBBox().height/2)}
                    C ${s.x} ${(s.y - (s.getBBox().height/2) - 70)},
                      ${d.x} ${(d.y + (d.getBBox().height/2) + 70)},
                      ${d.x} ${d.y + (d.getBBox().height/2)}`
            path = `M ${s.x} ${s.y - (s.getBBox().height/2)}
                    L ${d.x} ${d.y - (s.getBBox().height/2)}}`*/
            /*path = `M ${s.x} ${ d.data.node_desc ? s.y - 20 : s.y - 12}
                    L ${d.x} ${ d.data.node_desc ? d.y + 31 : d.y + 10} Z`*/
            return path
        }

        // Creates a broken path from parent to the child nodes
        function brokenLine(s, d) {
            const path = `M ${s.x} ${ d.data.node_desc ? s.y - 20 : s.y - 12}
                    V ${(d.y + ((level_distance-20)*0.8))}
                    H ${d.x}
                    V ${ d.data.node_desc ? d.y + 31 : d.y + 10}`
            return path
        }
    }

    click(d) {
        if (d.children) {
          d._children = d.children
          d.children = null
        } else {
          d.children = d._children
          d._children = null
        }
        this.updateChart(d)
    }
    
    componentDidMount() {
        const { config, meta, data } = this.props
        this.rootEl = this.myRef.current
        this.width = this.rootEl.parentNode.clientWidth - this.margin.right - this.margin.left
        this.height = 100
        // set up initial svg object
        const fullWidth = this.width + this.margin.right + this.margin.left
        const fullHeight = this.height + this.margin.top + this.margin.bottom
        this.svgbody = d3.select(this.rootEl).append("svg")
            .attr("id", this.svgId)
            .attr("width", fullWidth)
            .attr("height", fullHeight)
            // needed when using nodeSize in tree/cluster
            //.attr("viewBox", (-1*Math.round(this.width/2))+" 0 "+this.width+" "+this.height)
        this.svg = this.svgbody.append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")")

        const tree = createSlotTreeFromRefs(data.slots, config.params, meta)
        this.root = d3.hierarchy(tree, function(d) { return d.children })
        this.root.x0 = this.width / 2
        this.root.y0 = 0
        this.maxdepth = this.getDepth(this.root)
        this.updateChart(this.root)
    }

    render() {
        const { config, meta, data, t, i18n } = this.props

        const contents = (
          <div ref={this.myRef}>
          </div>
        )

        return(
            <Segment>
            <Header as="h2">{getLocalizedLabel(config.label, i18n.language)}</Header>
            <div ref={this.contentsRef}>
              <Popup context={this.contentsRef} basic hoverable position='top right' offset={[-10,-80]} on="hover" trigger={contents}>
                <SVGExport svgId={this.svgId} baseName='component-chart'/>
              </Popup>
            </div>
            </Segment>
        )
    }

}

export default withTranslation()(ComponentStrucChart)