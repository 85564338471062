import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber } from '../../common'
import { Container, Segment, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorComponentProps} from './ConfigEditor'
import Button from '../form/Button'

class ConfigEditorResSAttr extends Component<ConfigEditorComponentProps> {

    render() {
        const { formik, user, meta, editing, locale, t } = this.props
        
        let newValue = {value: '', label: {}, abbr: {}}
        newValue['label'][locale] = ''
        newValue['abbr'][locale] = ''

        return (
            <>
            <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}><div className="label pad">{t('Attribute')}</div></Grid.Column>
                    <Grid.Column width={14}><Input name="params.key" inputProps={{disabled: !editing}}/></Grid.Column>
                </Grid.Row>
                <Grid.Row className="condensed">
                    <Grid.Column width={2}><div className="label">{t('Options')}</div></Grid.Column>
                    <Grid.Column width={3}>{t('value')}</Grid.Column>
                    <Grid.Column width={6}>{t('label')}</Grid.Column>
                    <Grid.Column width={2}>{t('abbr.')}</Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <FieldArray name='params.values'
                    render={(subformik) => (
                    <>
                    {formik.values.params.values ? formik.values.params.values.map((item: any, index: number) => {
                            return (
                                <Grid.Row className="condensed">
                                    <Grid.Column width={2}></Grid.Column>
                                    <Grid.Column width={3}>
                                        <Input name={"params.values."+index+".value"} inputProps={{disabled: !editing}}
                                            validate={(v) => valueUnique(v, formik.values.params.values.map((x, i) => { if (i !== index) return x.value} ), false, false)}/>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Input name={"params.values."+index+".label."+locale} inputProps={{disabled: !editing}}
                                            validate={(v) => valueUnique(v, formik.values.params.values.map((x, i) => { if (i !== index) return x.label[locale]} ), false, false)}/>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Input name={"params.values."+index+".abbr."+locale} inputProps={{disabled: !editing}}
                                            validate={(v) => valueUnique(v, formik.values.params.values.map((x, i) => { if (i !== index) return x.abbr[locale]} ), false, false)}/>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Button type="button" icon="angle up" color="blue" size="mini"
                                            disabled={!editing || index === 0}
                                            onClick={(e,d) => subformik.swap(index, index-1)}/>
                                        <Button type="button" icon="angle down" color="blue" size="mini"
                                            disabled={!editing || index === formik.values.params.values.length-1}
                                            onClick={(e,d) => subformik.swap(index, index+1)}/>
                                        <Button type="button" icon="delete" color="red" size="mini"
                                            disabled={!editing}
                                            onClick={(e,d) => subformik.remove(index)}/>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        })
                    : null }
                    <Grid.Row className="condensed">
                        <Grid.Column width={2}></Grid.Column>
                        <Grid.Column width={4}>
                            <Button icon="plus" type="button" size='mini' color='blue' 
                                onClick={(e,d) => subformik.push(newValue)} disabled={!editing}/>
                        </Grid.Column>
                    </Grid.Row>
                    </>
                    )}/>
            </Grid>
            </Segment>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorResSAttr)