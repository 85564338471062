import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, valueNotEmpty, valueUnique, valuePathPrefix } from '../../common'
import { Container, Segment, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorComponentProps} from './ConfigEditor'
import Button from '../form/Button'

class ConfigEditorCatCunit extends Component<ConfigEditorComponentProps> {

    lastRestrContents = { slot_type: [] }

    toggleRestrContents(e, d) {
        const { formik } = this.props
        if (d.checked) {
            formik.setFieldValue('params.restr_contents', this.lastRestrContents)
        } else {
            this.lastRestrContents = formik.values.params.restr_contents
            formik.setFieldValue('params.restr_contents', undefined)
        }
    }

    lastFixedSlots = []

    toggleFixedSlots(e, d) {
        const { formik } = this.props
        if (d.checked) {
            formik.setFieldValue('params.fixed_slots', this.lastFixedSlots)
        } else {
            this.lastFixedSlots = formik.values.params.fixed_slots
            formik.setFieldValue('params.fixed_slots', undefined)
        }
    }

    render() {
        const { formik, user, meta, editing, locale, t } = this.props

        const fixedSlotsChanged = Boolean(formik.values.params.fixed_slots) !== Boolean(formik.initialValues.params.fixed_slots) ? 'changed' : ''
        const restrContentsChanged = Boolean(formik.values.params.restr_contents) !== Boolean(formik.initialValues.params.restr_contents) ? 'changed' : ''

        return (
            <>
            <Segment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2}><div className="label">{t('Index')}</div></Grid.Column>
                        <Grid.Column width={14}><Checkbox name="params.index" inputProps={{disabled: !editing}}/></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment>
                <Grid>
                    <Grid.Row className={fixedSlotsChanged}>
                        <Grid.Column width={4}><div className="label">{t('Fixed slots')}</div></Grid.Column>
                        <Grid.Column width={12}>
                            <PureCheckbox toggle key={true && formik.values.params.fixed_slots} checked={Boolean(formik.values.params.fixed_slots)}
                            onChange={(e,d) => this.toggleFixedSlots(e,d)} disabled={!editing}/>
                        </Grid.Column>
                    </Grid.Row>
                    { formik.values.params.fixed_slots  ?
                    <FieldArray name='params.fixed_slots'
                        render={(subformik) => (
                        <>
                        {formik.values.params.fixed_slots.map((item: any, index: number) => {
                                return (
                                    <Grid.Row className={fixedSlotsChanged}>
                                        <Grid.Column width={2}></Grid.Column>
                                        <Grid.Column width={11}>
                                            <Input name={"params.fixed_slots."+index} inputProps={{disabled: !editing}} 
                                                validate={valueNotEmpty}/>
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Button type="button" icon="angle up" color="blue" size="mini"
                                                disabled={!editing || index === 0}
                                                onClick={(e,d) => subformik.swap(index, index-1)}/>
                                            <Button type="button" icon="angle down" color="blue" size="mini"
                                                disabled={!editing || index === formik.values.params.fixed_slots.length-1}
                                                onClick={(e,d) => subformik.swap(index, index+1)}/>
                                            <Button type="button" icon="delete" color="red" size="mini"
                                                disabled={!editing}
                                                onClick={(e,d) => subformik.remove(index)}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }
                        <Grid.Row className={fixedSlotsChanged}>
                            <Grid.Column width={2}></Grid.Column>
                            <Grid.Column width={4}>
                                <Button icon="plus" type="button" size='mini' color='blue' 
                                    onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                            </Grid.Column>
                        </Grid.Row>
                        </>
                        )}/>
                    : null }
                </Grid>
            </Segment>
            <Segment>
                <Grid>
                    <Grid.Row className={restrContentsChanged}>
                        <Grid.Column width={4}><div className="label">{t('Content restrictions')}</div></Grid.Column>
                        <Grid.Column width={12}>
                            <PureCheckbox toggle key={true && formik.values.params.restr_contents} checked={Boolean(formik.values.params.restr_contents)} 
                                onChange={(e,d) => this.toggleRestrContents(e,d)} disabled={!editing} className={this.lastRestrContents === formik.values.params.restr_contents ? 'changed' : ''}/>
                        </Grid.Column>
                    </Grid.Row>
                    { formik.values.params.restr_contents  ?
                    <>
                        <Grid.Row className={restrContentsChanged}>
                            <Grid.Column width={2}><div className="label">{t('Slots')}</div></Grid.Column>
                            <Grid.Column width={14}>{t('type restriction')}</Grid.Column>
                        </Grid.Row>
                        <FieldArray name='params.restr_contents.slot_type'
                            render={(subformik) => (
                            <>
                            {formik.values.params.restr_contents.slot_type.map((item: any, index: number) => {
                                    return (
                                        <Grid.Row className={restrContentsChanged}>
                                            <Grid.Column width={2}></Grid.Column>
                                            <Grid.Column width={11}>
                                                <Input name={"params.restr_contents.slot_type."+index} inputProps={{disabled: !editing}} 
                                                    validate={v => valuePathPrefix(v, Object.keys(meta['cats']['slot']))}/>
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <Button type="button" icon="angle up" color="blue" size="mini"
                                                    disabled={!editing || index === 0}
                                                    onClick={(e,d) => subformik.swap(index, index-1)}/>
                                                <Button type="button" icon="angle down" color="blue" size="mini"
                                                    disabled={!editing || index === formik.values.params.restr_contents.slot_type.length-1}
                                                    onClick={(e,d) => subformik.swap(index, index+1)}/>
                                                <Button type="button" icon="delete" color="red" size="mini"
                                                    disabled={!editing}
                                                    onClick={(e,d) => subformik.remove(index)}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })
                            }
                            <Grid.Row className={restrContentsChanged}>
                                <Grid.Column width={2}></Grid.Column>
                                <Grid.Column width={4}>
                                    <Button icon="plus" type="button" size='mini' color='blue' 
                                        onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                                </Grid.Column>
                            </Grid.Row>
                            </>
                            )}/>
                    </>
                    : null }
                </Grid>
            </Segment>
            </>
        )
    }

}

export default withTranslation()(ConfigEditorCatCunit)