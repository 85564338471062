import React, { Component, ReactNode } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Cunit, CUView, Meta } from '../../common'
import { Md5 } from 'ts-md5'
import { Table, Select, Modal, Button } from 'semantic-ui-react'
import { caseLabels, numLabels, negLabels, degLabels, genLabels, persLabels, typeLabels } from '../../CNC_common'
import DetailView from './DetailView'
import StatsCatChart from './StatsCatChart'

interface CNCLemmaViewProps extends WithTranslation {
    name: string,
    forms: Array<Cunit>,
    stackvariants: boolean,
    equalize: boolean,
    formFilter: number,
    showZeroFq: boolean,
    meta: Meta,
    params: {}
}

interface CNCLemmaViewState {
    formPageFilter: string,
    modalIsOpen: boolean,
    modalHeader: string,
    modalContent: ReactNode
}

const caseOrder = ['-', '1', '2', '3', '4', '5', '6', '7', 'X'] // 5
const numOrder = ['S', 'D', 'P', 'W', 'X', '-'] //  4
const varOrder = ['-', '1', '2', '3', '4', '5', '6', '7', '8', '9'] // 15
const negOrder = ['A', 'N', '-'] // 11
const degOrder = ['1', '2', '3', '-'] // 10
const genOrder = ['M', 'I', 'F', 'N', 'H', 'Q', 'T', 'X', 'Y', 'Z', '-'] // 3
//const posgenOrder = ['M', 'F', 'Z', 'X', '-'] // 6
//const posnumOrder = ['S', 'P', '-'] // 7
const persOrder = ['-', '1', '2', '3', 'X'] // 8
const tempOrder = ['P', 'H', 'R', 'F', 'X', '-'] // 9
const modeOrder = ['A', 'P', '-'] //12
//const aspOrder = ['I', 'P', 'B', '-'] // 16
const typeOrder = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'H', 'Q', 'R', 'S', 'T', 'U', 
                   'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 
                   'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '7', '6', '8', '9', '!',
                   '*', ',', '.', ':', ';', '=', '?', '^', '}', '~', '@', '-'] // 2

const pagesA = [
    {value: 'AA.......1A', text: 'positive'},
    {value: 'AA.......2A', text: 'comparative'},
    {value: 'AA.......3A', text: 'superlative'},
    {value: 'AA.......1N', text: 'neg. positive'},
    {value: 'AA.......2N', text: 'neg. comparative'},
    {value: 'AA.......3N', text: 'neg. superlative'},
    {value: 'A[^A]', text: 'other'}
]

const pagesAM = [
    {value: 'A.........A', text: 'positive'},
    {value: 'A.........N', text: 'neg. positive'}
]

const pagesV = [
    {value: 'V[^pqcsemif]......[^F]', text: 'present'},
    {value: 'V[^pqcsemif]......F', text: 'future'},
    {value: 'V[^Btcsemif]', text: 'past'},
    {value: 'Vi', text: 'imperative'},
    {value: 'Vf', text: 'infinitive'},
    {value: 'V[s]', text: 'participle'},
    {value: 'V[em]', text: 'transgressive'}
]

const pagesPrel = [
    {value: 'P[^1]', text: 'relative'},
    {value: 'P1...-S', text: 'relative possesive'},
    {value: 'P1...FS', text: 'relative possesive feminine'},
    {value: 'P1....P', text: 'relative possesive plural'},
]

let pagesVbe = JSON.parse(JSON.stringify(pagesV))
pagesVbe.splice(3,0,{value: 'Vc', text: 'conditional'})

const formPagesByPos = {
    'N': null,
    'A': pagesA,
    'AG': null,
    'AM': pagesAM,
    'AU': null,
    'P': null,
    'P_rel': pagesPrel,
    'C': null,
    'V': pagesV,
    'V_be': pagesVbe,
    'D': null,
    'R': null,
    'J': null,
    'T': null,
    'I': null,
    'X': null,
    'Z': null
}

const transgressives = ['e', 'm']

class CNCLemmaView extends Component<CNCLemmaViewProps, CNCLemmaViewState> {

    state = {
        formPageFilter: '',
        modalIsOpen: false,
        modalHeader: '',
        modalContent: null
    }

    extractAttrs(cunit: Cunit|CUView) {
        const fword = cunit['_slots'][0]['_fillers'][0][':form:attr:cnc:w:word']
        const flemma = cunit['_slots'][0]['_fillers'][0][':form:attr:cnc:w:lemma']
        const ftag = cunit['_slots'][0]['_fillers'][0][':form:attr:cnc:w:tag']
        const total = cunit[':stats:fq:abs:cnc:total']
        const fq_spk = cunit[':stats:fq:abs:cnc:mode-SPK']
        const spk = fq_spk ? fq_spk : 0
        const fq_dia = cunit[':stats:fq:abs:cnc:mode-DIA']
        const dia = fq_dia ? fq_dia : 0
        const fq_bel = cunit[':stats:fq:abs:cnc:txtype_group-FIC']
        const bel = fq_bel ? fq_bel : 0
        const fq_odb = cunit[':stats:fq:abs:cnc:txtype_group-NFC']
        const odb = fq_odb ? fq_odb : 0
        const fq_pub = cunit[':stats:fq:abs:cnc:txtype_group-NMG']
        const pub = fq_pub ? fq_pub : 0
        cunit['word'] = fword ? fword : ''
        cunit['lemma'] = flemma ? flemma : ''
        cunit['tag'] = ftag ? ftag : ''
        cunit['total'] = total ? total : 0
        cunit['stats'] = [bel, odb, pub, dia, spk]
        if (cunit[':stats:fq:abs:cnc:txtype_group-FIC'])
            cunit[':stats:fq:abs:cnc:mode-FIC'] = cunit[':stats:fq:abs:cnc:txtype_group-FIC']
        if (cunit[':stats:fq:abs:cnc:txtype_group-NFC'])
            cunit[':stats:fq:abs:cnc:mode-NFC'] = cunit[':stats:fq:abs:cnc:txtype_group-NFC']
        if (cunit[':stats:fq:abs:cnc:txtype_group-NMG'])
            cunit[':stats:fq:abs:cnc:mode-NMG'] = cunit[':stats:fq:abs:cnc:txtype_group-NMG']
        return cunit
    }

    orderN (a: Cunit, b: Cunit) : number {
        const aGen = a['tag'].charAt(2)
        const bGen = b['tag'].charAt(2)
        const genDiff = genOrder.indexOf(aGen) - genOrder.indexOf(bGen)
        if (genDiff !== 0)
            return genDiff
        const aNum = a['tag'].charAt(3)
        const bNum = b['tag'].charAt(3)
        const numDiff = numOrder.indexOf(aNum) - numOrder.indexOf(bNum)
        if (numDiff !== 0)
            return numDiff
        const aCase = a['tag'].charAt(4)
        const bCase = b['tag'].charAt(4)
        const caseDiff = caseOrder.indexOf(aCase) - caseOrder.indexOf(bCase)
        if (caseDiff !== 0)
            return caseDiff
        const aVar = a['tag'].charAt(14)
        const bVar = b['tag'].charAt(14)
        const varDiff = varOrder.indexOf(aVar) - varOrder.indexOf(bVar)
        if (varDiff !== 0)
            return varDiff
        return b['total'] - a['total']
    }

    orderA (a: Cunit, b: Cunit) : number {
        const aType = a['tag'].charAt(1)
        const bType = b['tag'].charAt(1)
        const typeDiff = typeOrder.indexOf(aType) - typeOrder.indexOf(bType)
        if (typeDiff !== 0)
            return typeDiff
        const aNeg = a['tag'].charAt(10)
        const bNeg = b['tag'].charAt(10)
        const negDiff = negOrder.indexOf(aNeg) - negOrder.indexOf(bNeg)
        if (negDiff !== 0)
            return negDiff
        const aDeg = a['tag'].charAt(9)
        const bDeg = b['tag'].charAt(9)
        const degDiff = degOrder.indexOf(aDeg) - degOrder.indexOf(bDeg)
        if (degDiff !== 0)
            return degDiff
        const aGen = a['tag'].charAt(2)
        const bGen = b['tag'].charAt(2)
        const genDiff = genOrder.indexOf(aGen) - genOrder.indexOf(bGen)
        if (genDiff !== 0)
            return genDiff
        const aNum = a['tag'].charAt(3)
        const bNum = b['tag'].charAt(3)
        const numDiff = numOrder.indexOf(aNum) - numOrder.indexOf(bNum)
        if (numDiff !== 0)
            return numDiff
        const aCase = a['tag'].charAt(4)
        const bCase = b['tag'].charAt(4)
        const caseDiff = caseOrder.indexOf(aCase) - caseOrder.indexOf(bCase)
        if (caseDiff !== 0)
            return caseDiff
        const aVar = a['tag'].charAt(14)
        const bVar = b['tag'].charAt(14)
        const varDiff = varOrder.indexOf(aVar) - varOrder.indexOf(bVar)
        if (varDiff !== 0)
            return varDiff
        return b['total'] - a['total']
    }

    orderP (a: Cunit, b: Cunit) : number {
        const aGen = a['tag'].charAt(2)
        const bGen = b['tag'].charAt(2)
        const genDiff = genOrder.indexOf(aGen) - genOrder.indexOf(bGen)
        if (genDiff !== 0)
            return genDiff
        const aNum = a['tag'].charAt(3)
        const bNum = b['tag'].charAt(3)
        const numDiff = numOrder.indexOf(aNum) - numOrder.indexOf(bNum)
        if (numDiff !== 0)
            return numDiff
        const aCase = a['tag'].charAt(4)
        const bCase = b['tag'].charAt(4)
        const caseDiff = caseOrder.indexOf(aCase) - caseOrder.indexOf(bCase)
        if (caseDiff !== 0)
            return caseDiff
        const aType = a['tag'].charAt(1)
        const bType = b['tag'].charAt(1)
        const typeDiff = typeOrder.indexOf(aType) - typeOrder.indexOf(bType)
        if (typeDiff !== 0)
            return typeDiff
        const aVar = a['tag'].charAt(14)
        const bVar = b['tag'].charAt(14)
        const varDiff = varOrder.indexOf(aVar) - varOrder.indexOf(bVar)
        if (varDiff !== 0)
            return varDiff
        return b['total'] - a['total']
    }

    orderV (a: Cunit, b: Cunit) : number {
        const aNeg = a['tag'].charAt(10)
        const bNeg = b['tag'].charAt(10)
        const negDiff = negOrder.indexOf(aNeg) - negOrder.indexOf(bNeg)
        if (negDiff !== 0)
            return negDiff
        const aMode = a['tag'].charAt(11)
        const bMode = b['tag'].charAt(11)
        const modeDiff = modeOrder.indexOf(aMode) - modeOrder.indexOf(bMode)
        if (modeDiff !== 0)
            return modeDiff
        const aTemp = a['tag'].charAt(8)
        const bTemp = b['tag'].charAt(8)
        const tempDiff = tempOrder.indexOf(aTemp) - tempOrder.indexOf(bTemp)
        if (tempDiff !== 0)
            return tempDiff
        const aType = a['tag'].charAt(1)
        const bType = b['tag'].charAt(1)
        const typeDiff = typeOrder.indexOf(aType) - typeOrder.indexOf(bType)
        if (transgressives.includes(aType) && transgressives.includes(bType) && typeDiff !== 0)
            return typeDiff
        const aGen = a['tag'].charAt(2)
        const bGen = b['tag'].charAt(2)
        const genDiff = genOrder.indexOf(aGen) - genOrder.indexOf(bGen)
        if (genDiff !== 0)
            return genDiff
        const aNum = a['tag'].charAt(3)
        const bNum = b['tag'].charAt(3)
        const numDiff = numOrder.indexOf(aNum) - numOrder.indexOf(bNum)
        if (numDiff !== 0)
            return numDiff
        const aPers = a['tag'].charAt(7)
        const bPers = b['tag'].charAt(7)
        const persDiff = persOrder.indexOf(aPers) - persOrder.indexOf(bPers)
        if (persDiff !== 0)
            return persDiff
        const aCase = a['tag'].charAt(4)
        const bCase = b['tag'].charAt(4)
        const caseDiff = caseOrder.indexOf(aCase) - caseOrder.indexOf(bCase)
        if (caseDiff !== 0)
            return caseDiff
        const aVar = a['tag'].charAt(14)
        const bVar = b['tag'].charAt(14)
        const varDiff = varOrder.indexOf(aVar) - varOrder.indexOf(bVar)
        if (varDiff !== 0)
            return varDiff
        return b['total'] - a['total']
    }

    orderD (a: Cunit, b: Cunit) : number {
        const aNeg = a['tag'].charAt(10)
        const bNeg = b['tag'].charAt(10)
        const negDiff = negOrder.indexOf(aNeg) - negOrder.indexOf(bNeg)
        if (negDiff !== 0)
            return negDiff
        const aDeg = a['tag'].charAt(9)
        const bDeg = b['tag'].charAt(9)
        const degDiff = degOrder.indexOf(aDeg) - degOrder.indexOf(bDeg)
        if (degDiff !== 0)
            return degDiff
        const aVar = a['tag'].charAt(14)
        const bVar = b['tag'].charAt(14)
        const varDiff = varOrder.indexOf(aVar) - varOrder.indexOf(bVar)
        if (varDiff !== 0)
            return varDiff
        return b['total'] - a['total']
    }

    orderDefault (a: Cunit, b: Cunit) : number {
        return (a['tag'] === b['tag'] ? 0 : 1)
    }

    headerN (current: Cunit, previous: Cunit) {
        const { t } = this.props
        const gen = current['tag'].charAt(2)
        const c = current['tag'].charAt(4)
        const n = current['tag'].charAt(3)
        //const np = previous['tag'] ? previous['tag'].charAt(3) : null
        const hTag = [gen, n].join('')
        current['hTag'] = hTag
        if (hTag !== previous['hTag']) {
            const hLabel = [t(genLabels[gen]), t(numLabels[n])].join(' - ')
            return [hLabel, t(caseLabels[c])]
        } else {
            return [null, caseLabels[c]]
        }
    }

    headerA (current: Cunit, previous: Cunit) {
        const { t } = this.props
        const ncase = current['tag'].charAt(4)
        const num = current['tag'].charAt(3)
        const type = current['tag'].charAt(1)
        const neg = current['tag'].charAt(10)
        const deg = current['tag'].charAt(9)
        const gen = current['tag'].charAt(2)
        //const np = previous['tag'] ? previous['tag'].charAt(3) : null
        const hTag = [type, neg, deg, gen, num].join('')
        current['hTag'] = hTag
        if (hTag !== previous['hTag']) {
            let hLabel = ''
            if (['A', 'G', 'M', 'U'].includes(type)) {
                hLabel = [t(genLabels[gen]), t(numLabels[num])].filter(Boolean).join(' - ')
            } else {
                hLabel = [t(typeLabels[type]), t(genLabels[gen]), t(numLabels[num])].filter(Boolean).join(' - ')
            }
            return [hLabel, t(caseLabels[ncase])]
        } else {
            return [null, t(caseLabels[ncase])]
        }
    }

    headerP (current: Cunit, previous: Cunit) {
        const { t } = this.props
        const ncase = current['tag'].charAt(4)
        const num = current['tag'].charAt(3)
        const gen = current['tag'].charAt(2)
        const hTag = [gen, num].join('')
        current['hTag'] = hTag
        if (hTag !== previous['hTag']) {
            let hLabel = ''
            hLabel = [t(genLabels[gen]), t(numLabels[num])].filter(Boolean).join(' - ')
            return [hLabel, t(caseLabels[ncase])]
        } else {
            return [null, t(caseLabels[ncase])]
        }
    }

    headerV (current: Cunit, previous: Cunit) {
        const { t } = this.props
        const pers = current['tag'].charAt(7)
        const num = current['tag'].charAt(3)
        let type = current['tag'].charAt(1)
        const neg = current['tag'].charAt(10)
        //const mode = current['tag'].charAt(11)
        //const temp = current['tag'].charAt(8)
        const gen = current['tag'].charAt(2)
        const ncase = current['tag'].charAt(4)
        if (!transgressives.includes(type))
            type = '-'
        const hTag = [neg, type, gen, num].join('')
        current['hTag'] = hTag
        let rLabel = t(persLabels[pers])
        if (caseLabels[ncase])
            rLabel = rLabel + " (" +  caseLabels[ncase] + ")"
        if (hTag !== previous['hTag']) {
            const hLabel = [t(negLabels[neg]), t(typeLabels[type]), t(genLabels[gen]), t(numLabels[num])].filter(Boolean).join(' - ')
            return [hLabel, rLabel]
        } else {
            return [null, rLabel]
        }
    }

    headerD (current: Cunit, previous: Cunit) {
        const { t } = this.props
        const neg = current['tag'].charAt(10)
        const deg = current['tag'].charAt(9)
        const hTag = neg
        current['hTag'] = hTag
        if (hTag !== previous['hTag']) {
            let hLabel = ''
            hLabel = t(negLabels[neg])
            return [hLabel, t(degLabels[deg])]
        } else {
            return [null, t(degLabels[deg])]
        }
    }

    headerDefault (current: Cunit, previous: Cunit) {
        return [null, null]
    }

    changePageFilter(event, data) {
        this.setState({formPageFilter: data.value})
    }

    componentDidMount() {
        let posspec = this.getPosSpec()
        const formPages = formPagesByPos[posspec]
        if (formPages && !this.state.formPageFilter) {
            this.setState({formPageFilter: formPages[0].value})
        }
    }

    applyFilter(u: Cunit|CUView) {
        const re = new RegExp('^'+this.state.formPageFilter+'.*')
        return u['_slots'][0]['_fillers'][0][':form:attr:cnc:w:tag'].match(re) !== null
    }

    getPosSpec() {
        let { name, forms } = this.props
        const pos = name.slice(-1,)
        const lemma = name.slice(0,-2)
        let posspec = pos
        if (pos === 'A') {
            if (forms.length &&  ['G', 'M', 'U'].includes(forms[0]['_slots'][0]['_fillers'][0][':form:attr:cnc:w:tag'][1])) {
                posspec = forms[0]['_slots'][0]['_fillers'][0][':form:attr:cnc:w:tag'].slice(0,2)
            }
        } else if (lemma === 'jenž') {
            posspec = 'P_rel'
        } else if (lemma === 'být') {
            posspec = 'V_be'
        }
        return posspec

    }

    mergeVariantStats(a, b) {
        for (let k in b) {
            if (k.startsWith(':stats:fq:abs:')) {
                if (a[k]) {
                    a[k] += b[k]
                } else {
                    a[k] = b[k]
                }
            }
        }
        // shortcuts
        for (let k in a['stats']) {
            a['stats'][k] += b['stats'][k]
        }
        a['total'] += b['total']
        return a
    }

    mergeIdenticalVariants(variants: CUView[]) : CUView[] {
        let col = {}
        for (let v of variants) {
            let word = v['word']
            let tag_short = v['tag'].substr(0,14)
            let key = word+'_'+tag_short
            if (col[key]) {
                col[key] = this.mergeVariantStats(col[key], v)
            } else {
                let shortened = JSON.parse(JSON.stringify(v))
                shortened['tag'] = tag_short
                col[key] = shortened
            }
        }
        return Object.values(col)
    }

    render() {
        let { name, forms, stackvariants, equalize, formFilter, showZeroFq, params, meta, t } = this.props
        let { formPageFilter, modalHeader, modalContent, modalIsOpen } = this.state

        const modalClose = () => { this.setState({ modalIsOpen: false }) }
        const modalOpen = (header: string, content: ReactNode) => {
            this.setState({ modalIsOpen: true })
            this.setState({ modalHeader: header, modalContent: content })
        }

        //const lemma = name.slice(0,-2)
        const pos = name.slice(-1,)
        let posspec = this.getPosSpec()
        const formPages = formPagesByPos[posspec] ? formPagesByPos[posspec].map(x => ({value: x.value, text: t(x.text)})) : null

        if (formPages && formPageFilter === '') {
            return (null)
        }

        if (!meta['resources']['corpus']) return null
        let subcorp_size = [0,0,0,0,0]
        subcorp_size[0] = meta['resources']['corpus'][':cnc:txtype_group-FIC']['params']['size_tokens']/1000000
        subcorp_size[1] = meta['resources']['corpus'][':cnc:txtype_group-NFC']['params']['size_tokens']/1000000
        subcorp_size[2] = meta['resources']['corpus'][':cnc:txtype_group-NMG']['params']['size_tokens']/1000000
        subcorp_size[3] = meta['resources']['corpus'][':cnc:mode-DIA']['params']['size_tokens']/1000000
        subcorp_size[4] = meta['resources']['corpus'][':cnc:mode-SPK']['params']['size_tokens']/1000000

        meta['resources']['corpus'][':cnc:mode-FIC'] = meta['resources']['corpus'][':cnc:txtype_group-FIC']
        meta['resources']['corpus'][':cnc:mode-NFC'] = meta['resources']['corpus'][':cnc:txtype_group-NFC']
        meta['resources']['corpus'][':cnc:mode-NMG'] = meta['resources']['corpus'][':cnc:txtype_group-NMG']        

        let curforms = Array<Cunit|CUView>()

        if (formPageFilter !== '') {
            curforms = forms.filter((i) => this.applyFilter(i))
        } else {
            curforms = forms
        }

        const sortFunc = {
            'N': this.orderN,
            'A': this.orderA,
            'P': this.orderP,
            'C': this.orderP,
            'V': this.orderV,
            'D': this.orderD,
            'R': this.orderDefault,
            'J': this.orderDefault,
            'T': this.orderDefault,
            'I': this.orderDefault,
            'X': this.orderDefault,
            'Z': this.orderDefault
        }

        const headerFunc = {
            'N': (cur: Cunit, prev: Cunit) => this.headerN(cur, prev),
            'A': (cur: Cunit, prev: Cunit) => this.headerA(cur, prev),
            'P': (cur: Cunit, prev: Cunit) => this.headerP(cur, prev),
            'C': (cur: Cunit, prev: Cunit) => this.headerP(cur, prev),
            'V': (cur: Cunit, prev: Cunit) => this.headerV(cur, prev),
            'D': (cur: Cunit, prev: Cunit) => this.headerD(cur, prev),
            'R': (cur: Cunit, prev: Cunit) => this.headerDefault(cur, prev),
            'J': (cur: Cunit, prev: Cunit) => this.headerDefault(cur, prev),
            'T': (cur: Cunit, prev: Cunit) => this.headerDefault(cur, prev),
            'I': (cur: Cunit, prev: Cunit) => this.headerDefault(cur, prev),
            'X': (cur: Cunit, prev: Cunit) => this.headerDefault(cur, prev),
            'Z': (cur: Cunit, prev: Cunit) => this.headerDefault(cur, prev)
        }

        for (let cunit of curforms) {
            this.extractAttrs(cunit)
        }
        let domain: [number, number|'auto'] = [0, 'auto']

        curforms.sort(sortFunc[pos])
        const getHeaders = headerFunc[pos]

        let prev = {}
        let prevcLabel = ''
        let prevhLabel = ''
        let rowlist: any[] = []
        let variants: CUView[] = []
        const cycles = curforms.length + 1
        for (let n of Array.from(Array(cycles).keys())) {
            let f: CUView = {name:'', type: ''}
            let hLabel = ''
            let cLabel = ''
            if (n < curforms.length) {
                f = curforms[n]
                let ret = getHeaders(f, prev)
                hLabel = ret[0]
                cLabel = ret[1]
            }
            prev = f
            if (cLabel === prevcLabel && hLabel === null) {
                variants.push(f)
                continue
            }
            
            let sumAbsFq : number[] = [0,0,0,0,0]
            let maxAbsFq : number[] = [0,0,0,0,0]

            if (!showZeroFq) variants = variants.filter(x => x['total'])

            // consolidate identical variants with erroneous differences in tag (15th and 16th position)
            variants = this.mergeIdenticalVariants(variants)
            
            let chartData = [
                { name: 'bel'},
                { name: 'obo'},
                { name: 'pub'},
                { name: 'dia'},
                { name: 'mlu'},
            ]
            let chartAbsData = {bel: {}, obo: {}, pub: {}, dia: {},  mlu: {}}

            for (let v of variants) {
                for (let i of [0,1,2,3,4]) {
                    if (v['stats'][i] > maxAbsFq[i]) maxAbsFq[i] = v['stats'][i]
                    sumAbsFq[i] += v['stats'][i]
                    chartData[i][v['_name']] = Math.round(v['stats'][i]/subcorp_size[i]*1000)/1000
                    chartAbsData[chartData[i].name][v['_name']] = v['stats'][i]
                }
            }

            // FILTER categories with interesting variability 
            let accept = true;
            if (formFilter > 0) {
                // accept only categories with at least two variants with FQ>0
                if (variants.length < 2)
                    accept = false
                else {
                    let cnt = 0
                    for (let v of variants) {
                        if (v['total']) cnt++
                    }
                    if (cnt < 2) accept = false
                }
            }
            if (formFilter > 1  && accept) {
                // there is at least one category, where no variant has a majority of more than 99%
                accept = false
                let threshold = 0.99
                if (formFilter > 2) threshold = 0.9
                for (let cat of [0,1,2,3,4]) {
                    let skip = false
                    if (!sumAbsFq[cat]) continue
                    for (let v of variants) {
                        if (v['stats'][cat]/sumAbsFq[cat] > threshold) {
                            //console.log("DISCARD",cat,variant)
                            skip = true
                            break
                        }
                    }
                    if (!skip) {
                        //console.log("ACCEPT nonmajor (cat, variant)", cat, variant,varstats[cat][variant],sumAbsFq[cat])
                        accept = true
                        break
                    }
                    //console.log("NOTHING", cat, variant,varstats[cat][variant],sumAbsFq[cat])
                }
                if (!accept) {
                // OR: get variants having majority within the different text types...
                    let maxvariants : String[] = []
                    for (let cat of [0,1,2,3,4]) {
                        let maxVal = 0
                        let maxvariant: string|null = null
                        for (let vi in variants) {
                            if (variants[vi]['stats'][cat] > maxVal)  {
                                maxVal = variants[vi]['stats'][cat]
                                maxvariant = vi
                            }
                        }
                        //let maxvariant = Object.keys(cat).reduce(function(a, b){ return cat[a] === cat[b] ? '' : (cat[a] > cat[b] ? a : b )})*/
                        if (maxvariant && !maxvariants.includes(maxvariant)) maxvariants.push(maxvariant)
                    }
                    //  ... and see if there is more than just one
                    if (maxvariants.length > 1) {
                        accept = true
                        //console.log("ACCEPT multi", maxvariants)
                    } /*else {
                        console.log("DENIED",maxvariants)
                    }*/
                }
            }

            if (prevhLabel) {
                rowlist.push({type: 'head', label: prevhLabel})
            }
            if (accept) {
                let choice: number[] = []
                if (equalize) {
                    if (stackvariants) choice = sumAbsFq
                    else  choice = maxAbsFq
                    const relFq = choice.map((v,i) => { return v/subcorp_size[i]})
                    const localMaxRel = Math.max(...relFq)
                    if (domain[1] === 'auto' || localMaxRel > domain[1])
                        domain = localMaxRel > 26 ? [0, Math.ceil(localMaxRel/10)*10] : [0, Math.ceil(localMaxRel)]
                }
                rowlist.push({type: 'data', label: prevcLabel, chartData: chartData, chartAbsData: chartAbsData, variants: variants})
            }
            variants = []
            variants.push(f)
            prevhLabel = hLabel
            prevcLabel = cLabel
        }

        let rows: React.ReactNode[] = []
        let key = 0
        const values = {
            'FIC': {label: {cs: 'synchronní: beletrie'}, abbr: {cs: 'bel'}},
            'NFC': {label: {cs: 'synchronní: oborová lit.'}, abbr: {cs: 'obo'}},
            'NMG': {label: {cs: 'synchronní: publicistika'}, abbr: {cs: 'pub'}},
            'DIA': {label: {cs: 'diachronní'}, abbr: {cs: 'dia'}},
            'SPK': {label: {cs: 'mluvený'}, abbr: {cs: 'mlu'}}
        }
        for (let r of rowlist) {
            if (r.type === 'head') {
                rows.push(
                    <Table.Row key={key}>
                        <Table.Cell className='head' colSpan={4}>{r.label}</Table.Cell>
                    </Table.Row>
                )
                key++
            } else {
                let first = true
                for (let unit of r.variants) {
                    let chartcell: React.ReactNode = null
                    if (!stackvariants || first) {
                        let chart: React.ReactNode = null
                        let span = 1
                        let units = [unit['_name']]
                        let height = 100
                        if (stackvariants) {
                            span = r.variants.length
                            units = r.variants.map(v => { return v['total'] !== 0 ? v['_name'] : null}).filter(i => i !== null)
                            height = 150
                        }
                        if (unit['total']!==0) {
                            const key = Md5.hashStr(JSON.stringify(r.chartData)+JSON.stringify(units)+JSON.stringify(domain))
                            chart = (
                                <StatsCatChart key={key} width={600} height={height} legend='right' domain={domain}
                                    directData={r.chartData} directAbsData={r.chartAbsData} directSelect={units}
                                    meta={meta} relative={true} values={values}/>
                            )
                        }
                        chartcell = (
                            <Table.Cell collapsing verticalAlign='top' rowSpan={span}>
                                {chart}
                            </Table.Cell>
                        )
                    }

                    const variants = r.variants.filter((x) => x['total'])

                    const statsView =  variants.length ? (
                        <Button color='blue' icon='chart bar' size='mini'
                            onClick={(e,d) => modalOpen(t('Detailed distribution'), (
                                <DetailView meta={meta} cunits={variants} view={params['detail_view']}/>
                            ))}
                        />
                    ) : null

                    rows.push(
                        <Table.Row key={key}>
                            {first ? <Table.Cell collapsing className='head' verticalAlign='top' rowSpan={r.variants.length}>{r.label}</Table.Cell> : null}
                            <Table.Cell verticalAlign='top' collapsing data-tooltip={unit['tag']} data-position="top left">
                                <span style={unit['total']===0 ? {color: "#999999"} : {}}>{unit['word']} ({unit['total']})</span>
                            </Table.Cell>
                            {chartcell}
                            {first ? <Table.Cell collapsing verticalAlign='top' rowSpan={r.variants.length} textAlign='right'>{statsView}</Table.Cell> : null}
                        </Table.Row>
                    )

                    first = false
                    key++
                }
            }
        }

        return (
            <>
            {(formPages ? (<Select fluid options={formPages} value={formPageFilter}
                                   onChange={(e, d) => {this.changePageFilter(e, d)}}/>) : null)}
            <Modal header={modalHeader} content={modalContent} open={modalIsOpen} onClose={modalClose} centered={false} closeIcon/>
            <Table>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
            </>
        )
    }

}

export default withTranslation()(CNCLemmaView)