import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Divider } from 'semantic-ui-react'
import logoCZ from '../../img/OP_VVV_cz.png'
import logoEN from '../../img/OP_VVV_en.png'

interface CNCLemmaListHelpProps extends WithTranslation {}

class CNCLemmaListHelp extends Component<CNCLemmaListHelpProps> {
    render() {
        const { i18n } = this.props
        if (i18n.language.startsWith('cs'))
            return(
            <>
            <div className="header">Vyhledávání lemmat v databázi korpusových typů</div>
            <div className="content">
            <p>Databáze obsahuje všechny rozpoznané slovní tvary lemmat, která se reálně vyskytují v některém ze zpracovaných korpusů ČNK: <a href="https://wiki.korpus.cz/doku.php/cnk:syn:verze8" target="_blank" rel="noopener noreferrer">SYN v8</a> (současná psaná čeština), <a href="https://wiki.korpus.cz/doku.php/cnk:oral" target="_blank" rel="noopener noreferrer">ORAL v1</a> a <a href="https://wiki.korpus.cz/doku.php/cnk:ortofon" target="_blank" rel="noopener noreferrer">ORTOFON v1</a> (současná mluvená čeština), <a href="https://wiki.korpus.cz/doku.php/cnk:diakorp" target="_blank" rel="noopener noreferrer">Diakorp v6</a> a nezveřejněný korpus textů 19. stol. Protože se lemmatizace a morfologické značkování těchto korpusů mírně liší, byly jako zdroj dat použity jejich interní verze s jednotným zpracováním.</p>

            <p>V této záložce je možné vyhledávat <a href="https://wiki.korpus.cz/doku.php/pojmy:lemma" target="_blank" rel="noopener noreferrer">lemmata</a> a zobrazit všechny jejich tvary, včetně variant. Přepínač vpravo vypíná rozlišování velikosti písmen. Je-li nalezeno více odpovídajících lemmat, zobrazí se seznam záložek s danými lemmaty a zkratkou slovního druhu. Vybraná záložka se otevře kliknutím na její záhlaví.</p>

            <p>Nastavení zobrazení nalezených tvarů je možné po zobrazení výsledků upravovat pod dotazovacím formulářem. Zobrazení je možné přepnout mezi výpisem tvarů podle celkové <strong>frekvence</strong> a přehledem všech <strong>tvarů</strong> celého paradigmatu každého lemmatu. U slovních druhů s velmi rozsáhlým gramatickým paradigmatem (slovesa, přídavná jména apod.) se zobrazí výběr dílčích stránek paradigmatu podle hlavních kategorií.</p>

            <p>Paradigmata jsou zobrazena v podobě tabulky, kde jednotlivé řádky seskupují různé varianty pro každou gramatickou kategorii daného lemmatu (obvykle pád nebo osobu v rámci dané kategorie). Číslo v závorce za tvarem uvádí souhrnnou frekvenci daného tvaru ve všech textech zpracovaných korpusů. Následuje graf zobrazující srovnání relativních frekvencí výskytu daného tvaru v pěti základních skupinách textů: beletrii, oborové literatuře a publicistice v rámci synchronních textů (korpus SYN), v diachronních textech (historické korpusy Diakorp a korpus 19. stol.) a korpusech mluveného jazyka (ORAL, ORTOFON).</p>

            <p>Ve výchozím nastavení je zobrazen graf pro každý slovní tvar zvlášť. Přepínačem "<strong>seskupit varianty</strong>" je možné zobrazit společný srovnávací graf 
            pro různé varianty v rámci každé gramatické kategorie.</p>

            <p>Přepínač "<strong>vyrovnat měřítka</strong>" nastaví shodné měřítko pro všechny grafy na stránce, aby bylo snazší srovnávat jednotlivé gramatické kategorie navzájem.</p>

            <p>Přepínač "<strong>ukázat nedoložené</strong>" zobrazí v tabulce i ty varianty, které nebyly v korpusových datech reálně nalezeny (tj. mají nulovou frekvenci). 
            Jedná se o více či méně hypotetické varianty vytvořené morfologickým generátorem.
            </p>

            <p>Čtyřstupňový <strong>filtr</strong> umožňuje rychle zobrazit jen "zajímavé" gramatické kategorie. Má tyto úrovně:
                <ol type="1">
                    <li>zobrazit všechny kategorie (bez filtru)</li>
                    <li>zobrazit jen kategorie, v nichž existují (alespoň dvě) varianty</li>
                    <li>zobrazit jen kategorie, v nichž nemá jedna jediná varianta většinu ve všech pěti typech textů současně nebo kde nemá ve všech více než 99% většinu</li>
                    <li>odpovídá třetímu stupni, avšak se snížením meze procentuální většiny na více než 90% ve všech typech textů</li>
                </ol>
            </p>

            <p>Ikona vpravo u každé kategorie umožňuje zobrazit detailní grafy poměrů frekvenčních distribucí jednotlivých variant dané morfologické kategorie podle různých
            atributů a jejich hodnot v korpusu psaných textů (SYNv8) a v korpusech mluveného jazyka (ORAL, ORTOFON).</p>

            <p><strong>Poznámka:</strong> V rámci tohoto výpisu variant všech slovních tvarů daného lemmatu byly z důvodu nespolehlivosti značek na 15. a 16. pozici <a href="https://wiki.korpus.cz/doku.php/seznamy:tagy" target="_blank" rel="noopener noreferrer">morfologického tagu</a> spojeny varianty, které se v korpusech liší pouze značkou na těchto pozicích. Morfologický tag (zobrazí se po najetí kurzorem myši nad vybraný slovní tvar) je proto také zkrácen na prvních 14 pozic. V předchozí záložce pro vyhledávání jednotlivých tvarů je možné najít různé podoby odděleně, s kompletním 16-místným tagem.</p>

            <Divider/>

            <p>Databáze je výstupem projektu "Jazyková variabilita v CNC" číslo CZ.02.1.01/0.0/0.0/16_013/0001758 spolufinancovaného z evropských fondů.</p>
            
            <img src={logoCZ} alt="Project logo"/>
            
            </div>
            </>
            )
        else
            return(
                <>
                <div className="header">Searching word forms in the database of corpus types</div>
                <div className="content">

                <p>The database contains all recognized word forms of all lemmata that actually occur in any of the processed CNC corpora: <a href="https://wiki.korpus.cz/doku.php/cnk:syn:verze8" target="_blank" rel="noopener noreferrer">SYN v8</a> (contemporary written Czech), <a href="https://wiki.korpus.cz/doku.php/cnk:oral" target="_blank" rel="noopener noreferrer">ORAL v1</a> and <a href="https://wiki.korpus.cz/doku.php/cnk:ortofon" target="_blank" rel="noopener noreferrer">ORTOFON v1</a> (contemporary spoken Czech), <a href="https://wiki.korpus.cz/doku.php/cnk:diakorp" target="_blank" rel="noopener noreferrer">Diakorp v6</a> and an unpublished corpus of 19th century texts. Since their lemmatization and POS-tagging may differ, internal versions of these corpora have been processed, using a common tagging.</p>

                <p>In this tab, you can search for lemmata and obtain a list of all their morphological forms, including variants. The checkbox on the right side toggles case sensitivity. If several lemmata match the query, a vertical list of tabs is presented and a particular lemma can be opened by clicking on its header (showing the lemma and its part-of-speech).</p>

                <p>Preferences for the presentation of results can be changed below the query form. It is possible to choose between a pure <strong>frequency</strong> list or a list of forms according to the morphological paradigm of the lemma. Morphologically rich words (adjectives, verbs, etc.) are presented on multiple pages by their main morphological categories, which may be selected in an additional menu.</p>

                <p>Morphological paradigms are presented in the form of a table. Table rows group variants of the same morphological category (usually a particular case or person within the given morphological type). Every form (variant) is followed by its total frequency in all the corpora (the number in parentheses). The following chart presents a comparison of its relative frequency in the five main text groups: fiction, non-fiction and newspapers within the contemporary written language (corpus SYN), the diachronnic texts (corpus Diakorp and the corpus of 19th century texts) and in the corpora of the contemporary spoken language (ORAL, ORTOFON).</p>

                <p>By default, a separate chart is presented for each single form (variant). Common charts presenting the comparison of all variants within each morphological category can be shown by using the "<strong>stack variants</strong>" switch.</p>

                <p>The switch "<strong>equalize charts</strong>" turns on a common scale for all charts on the page, so that also different morphological categories can easilly be compared to each other.</p>

                <p>The switch "<strong>show unattested</strong>" displays also variants which did not occur in the processed corpora at all (i.e. those having zero frequency). Usually, they are more or less hypothetical morphological forms generated by the morphological analyzer.
                </p>

                <p>The four level <strong>filter</strong> makes it easy to show just the more "interesting" morphological categories:
                    <ol type="1">
                        <li>shows all categories (no filtering)</li>
                        <li>shows just categories with two or more variants</li>
                        <li>shows only categories, where a single variant does not have a majority in all the five main text types or where it does not have a majority of more than 99% in all the text types</li>
                        <li>corresponds to the third level, but with the threshold lowered to 90%</li>
                    </ol>
                </p>

                <p>The blue icon at the right side of each form can be used to show a detailed frequency distribution of the form according to various text attributes and their values in the corpus of written texts (SYNv8) and according to speaker attributes in the corpus of spoken language (ORAL, ORTOFON).</p>

                <p><strong>Note:</strong> Because of a limited reliability of morhological tags on the 15th and 16th position, forms that only differ in their tag within these positions have been merged on this tab and their frequencies have been summed. The morphological tag (shown on mouseover above the form) is therefore also trimmed to the first 14 positions. In the previous tab for searching for particular forms, the forms can be found and listed separately with the full 16 position tag.</p>

                <Divider/>

                <p>The database is an outcome of the project entitled "Language Variation in the CNC", reg. no. CZ.02.1.01/0.0/0.0/16_013/0001758, co-funded by the European Structural and Investment Funds.</p>

                <img src={logoEN} alt="Project logo"/>

                </div>
                </>
            )
    }
}

export default withTranslation()(CNCLemmaListHelp)