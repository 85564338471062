import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueIsIntNumber, descFiller, descSlot, getRefTargets, Feature, valueNotEmpty, collectFeatureOptions, createDefaultFeatureLabel } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, Dropdown as PDropdown, DropdownItemProps, Grid, Header, Icon, Label, Message, Segment, Tab } from 'semantic-ui-react'
import { EditFormProps, addFeature } from '../Edit'
import Input from './Input'
import {FormikComponentProps, getFieldError, setFieldValue} from './FormikHelpers'
import { FieldArray } from 'formik'
import TextArea from './TextArea'
import Dropdown from './Dropdown'
import Checkbox from './Checkbox'
import InputFeatureValue from './InputFeatureValue'
import InputFeatureType from './InputFeatureType'


interface BatchDeleteFeatureProps extends WithTranslation {
    meta: Meta,
    formik: any,
    f: Feature,
    index: number,
    prefix: string,
    cunitTypes: string[]|null
}

class BatchDeleteFeature extends Component<BatchDeleteFeatureProps> {

    componentDidMount() {
    }

    render() {
        const { f, index, cunitTypes, prefix, formik, meta, t, i18n } = this.props

        if (!meta['cats']) return null
        const fmeta = getmeta(meta, 'feature', f.type)

        const features = collectFeatureOptions(meta, cunitTypes, null, null, true, true, true)
        const typeOptions = features.map(f => ({key: f.type, text: createDefaultFeatureLabel(meta, f.type, true), value: f.type}))
        return(
            <>
            <Grid.Row className="condensed">
                <InputFeatureType name={prefix+'.type'} fmeta={fmeta} options={typeOptions} widthCols={10}/>
                <Grid.Column width={5}>
                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Button icon="delete" type="button" size='mini' color='red' 
                        onClick={(e,d) => formik.remove(index)}/>
                </Grid.Column>
            </Grid.Row>
            </>
        )
    }

}

export default withTranslation()(BatchDeleteFeature)