import React, { Component } from 'react'
import { FillerQueryParams, Meta, valueByFormikPath } from '../../common'
import { FieldArray } from "formik"
import { withTranslation, WithTranslation } from 'react-i18next'
import { Grid, Segment } from 'semantic-ui-react'
import FeatureQuery, { addDefaultFeature } from './FeatureQuery'

export const addDefaultFiller = (query, params) => {
    if (!query.fillers) query.fillers = []
    const filler: any = {}
    if (params.features) addDefaultFeature(filler, params.features)
    query.fillers.push(filler)
}

interface QueryProps extends WithTranslation {
    prefix: string,
    label: string|null,
    params: FillerQueryParams,
    meta: Meta,
    formik: any
}

type FillerQueryProps = QueryProps;

class FillerQuery extends Component<FillerQueryProps> {

    render () {
        const { meta, formik, prefix, params } = this.props

        let values = valueByFormikPath(prefix, formik.values)
        
        if (!values.fillers) return null

        return (
            <React.Fragment>
                <FieldArray name={prefix+'.fillers'}
                    render={(subformik) => (
                    <>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {values.fillers.map((item: any, index: number) => {
                                    return (
                                        <Segment key={index} secondary inverted color='grey'>
                                            <Grid>
                                                <FeatureQuery label={null} params={params.features} meta={meta} 
                                                    prefix={prefix+'.fillers.'+index} formik={formik}/>
                                            </Grid>
                                        </Segment>
                                    )
                                })}
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )} />
            </React.Fragment>
        )
    }
}

export default withTranslation()(FillerQuery)