import React, { Component, createRef } from 'react'
import { FieldArray } from 'formik'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { Modal, Container, Segment, Message, Grid, Pagination, StepTitle, DropdownItemProps, Input as PInput, Dropdown as PDropdown, Dimmer, Header } from 'semantic-ui-react'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, Feature, collectFeatureOptions, createDefaultFeatureLabel } from '../common'
import { Md5 } from 'ts-md5' 
import FeatureQuery, { addDefaultFeature} from './form/FeatureQuery'
import SlotQuery, { addDefaultSlot } from './form/SlotQuery'
import ResultList from './render/ResultList'
import CNCFormList from './render/CNCFormList'
import CNCLemmaList from './render/CNCLemmaList'
import CNCFormListHelp from './render/CNCFormListHelp'
import CNCLemmaListHelp from './render/CNCLemmaListHelp'
import NameQuery, { addDefaultName } from './form/NameQuery'
import Form from './form/Form'
import Button from './form/Button'
import Batch from './Batch'
import InputFeatureType from './form/InputFeatureType'
import Delete from './Delete'
import AcPhraseList from './render/AcPhraseList'
import MenuQuery from './form/MenuQuery'

const stateRevision = "2"

interface SearchProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance,
    checkUpdates: (data: any) => void
}

type ResultListParams = {
    columns: [
        {
            label: {},
            target?: string,
            path: string,
            link?: string
        }
    ],
    view?: boolean,
    edit?: boolean
}

export interface ResultListProps extends WithTranslation, RouteComponentProps {
    response: RatatoskResponse,
    pageInfo: PageInfo,
    meta: Meta,
    params: ResultListParams,
    directRequest?: boolean,
    loading: boolean,
    selectId: (id: string, select: boolean) => void | null,
    selectedIds: string[],
    viewOptionsOpen: () => void
}

interface SearchState {
    type: string,
    query_comps: Query[],
    query_sum: string,
    lastQuery: Query|null,
    batchQuery: Query,
    page: RatatoskPage,
    response: RatatoskResponse|null,
    authorized: boolean,
    errorMsg: string,
    searchWarning: string,
    submitting: boolean,
    directRequest: boolean,
    helpIsOpen: boolean,
    newDialogOpen: boolean,
    batchDialogOpen: string|null,
    deleteDialogOpen: string|null,
    batchMessage: string,
    newName: string,
    newType: string,
    selectedIds: string[],
    viewOptionsDialogOpen: boolean,
    customColumns: { path: string }[]
}

interface FormComponent {
    label: LocalizedLabels,
    comp: string,
    params: CustomParams,
    values: {}
}

interface SearchParams {
    search: string,
    request_url: string,
    request_defaults: {}
    form: FormComponent[],
    urlparams: string[],
    render: {},
    help: {}
}

export interface PageInfo {
    pageStart: number,
    pageEnd: number,
    total: number,
    curPage: number,
    totalPages: number,
    pagination: boolean,
    pageChange: (e,d) => void
}


class Search extends Component<SearchProps, SearchState> {

    default_state : SearchState = {
        type: '',
        query_comps: [],
        query_sum: '',
        lastQuery: null,
        batchQuery: {},
        page: { from: 0, size: 100 },
        response: null,
        authorized: true,
        errorMsg: '',
        searchWarning: '',
        submitting: false,
        directRequest: false,
        helpIsOpen: false,
        newDialogOpen: false,
        batchDialogOpen: null,
        deleteDialogOpen: null,
        batchMessage: '',
        newName: '',
        newType: '',
        selectedIds: [],
        viewOptionsDialogOpen: false,
        customColumns: []
    }

    state = this.default_state

    formComponents = {
        NameQuery: NameQuery,
        FeatureQuery: FeatureQuery,
        SlotQuery: SlotQuery,
        MenuQuery: MenuQuery
    }

    queryComposers = {
        NameQuery: addDefaultName,
        FeatureQuery: addDefaultFeature,
        SlotQuery: addDefaultSlot,
        MenuQuery: addDefaultFeature
    }

    renderComponents = {
        ResultList: ResultList,
        CNCFormList: CNCFormList,
        CNCLemmaList: CNCLemmaList,
        AcPhraseList: AcPhraseList
    }

    helpComponents = {
        CNCFormListHelp: CNCFormListHelp,
        CNCLemmaListHelp: CNCLemmaListHelp
    }

    initialized = false

    defaultState(type: string, params: SearchParams & {request_defaults: {[key: string]: any}}) {
        let comps = params.form
        let query_comps : Query[] = []
        for (let comp of comps) {
            const default_query: any = {}
            const composer = this.queryComposers[comp.comp]
            composer(default_query, comp.params)
            query_comps.push(default_query)
        }
        // maybe find a better solution for the "type" constraint - shall we expect a multitype search with type query/selection, too??
        if (params.search) query_comps.push({type: params.search})
        let state = this.default_state
        state.type = type
        state.query_comps = query_comps
        state.page = params.request_defaults.page_size ? {from: 0, size: params.request_defaults.page_size} : state.page
        return state
    }

    saveState() {
        localStorage.setItem('state_search_'+this.state.type, JSON.stringify(this.state))
    }

    loadState(type: string): boolean {
        let storedState : string|null = localStorage.getItem('state_search_'+type)
        if (storedState) {
            this.setState(JSON.parse(storedState))
            return true
        }
        return false
    }

    componentDidMount() {
        this.componentDidUpdate(this.props, this.state)
    }

    componentDidUpdate(prevProps: SearchProps, prevState: SearchState) {
        const { location, meta } = this.props
        
        // not authorized => login
        if (!this.state.authorized) {return(<Redirect to={{ pathname: "/login", state: { from: location } }} />)}

        let urlparams = location.pathname.replace(/^\/search\//,"").split('/')
        let curtype = urlparams.shift() as string
        curtype = curtype.replace('_',':')
        // general (empty) prefix is avoided in URL for simple types
        if (!curtype.includes(':')) { curtype = ':' + curtype }
        if (!this.initialized || location.pathname !== prevProps.location.pathname) {
            
            // get metadata configuration of current form type and reset its state
            urlparams = urlparams.filter((x) => x !== '')
            if (!(meta.uis['search'] && curtype in meta.uis['search'])) {
                return
            }
            this.initialized = true
            let params = meta.uis['search'][curtype]['params']
            
            // direct request - process independently
            if (urlparams.length && 'urlparams' in params) {
                let state = this.defaultState(curtype, params)
                for (let i in params['urlparams']) {
                    let value = urlparams[i]
                    if (value) {
                        state.query_comps = this.updateQuery(state.query_comps, params['urlparams'][i], value)
                    }
                }
                this.runQuery(state, null)
                return
            }

            let sum = Md5.hashStr(JSON.stringify(params)+stateRevision)
            let storedStateSum : string|null = localStorage.getItem('sum_state_search_'+curtype)
            let loaded = false
            if (storedStateSum === sum) {
                loaded = this.loadState(curtype)
            }
            if (!loaded) {
                localStorage.setItem('sum_state_search_'+curtype, sum)
                localStorage.removeItem('state_search_'+curtype)
                this.setState(this.defaultState(curtype, params))
            }
        }
        if (curtype === prevState.type && 
            (
                this.state.page.from !== prevState.page.from || 
                this.state.page.size !== prevState.page.size || 
                (this.state.customColumns.filter(x => !prevState.customColumns.includes(x)).length)
            )) {
            this.sendQuery(this.state, false, false)
        }
    }

    componentWillUnmount() {
        this.initialized = false
    }


    validFeatureQuery = (f: any) => {
        // this should not really happen anymore: f.method === undefined
        if (f.method === 'defined' /*|| f.method === undefined*/ || (f.value !== '' && f.value !== null && f.value !== undefined && !(Array.isArray(f.value) && f.value.length===0)))
            return true
        return false
    }

    consolidateFeatureQuery(f: any) {
        // treat different search methods,
        // (string case-insensitivity (f.ci) is treated by the backend)
        if (f.method) {
            if (f.method === 'defined') {
                delete f.value
            } else if (f.method === 'prefix') {
                f.value += '*'
            } else if (f.method === 'regexp') {
                f.value = {regexp: f.value}
            } else if (f.method === 'allflags') {
                f.value = {fulltext: Array.isArray(f.value) ? f.value.join(" ") : f.value}
                delete f.ci
            } else if (f.method === 'anyflag') {
                f.value = {anyterm: Array.isArray(f.value) ? f.value.join(" ") : f.value}
                delete f.ci
            } else if (f.method === 'fulltext') {
                f.value = {fulltext: f.value}
                delete f.ci
            } else if (['lt', 'lte', 'gt', 'gte'].includes(f.method)) {
                const value = f.value
                f.value = {range: {}}
                f.value.range[f.method] = value
            } else {
                // exact match
                // do not allow the user enforcing a prefix search manually
                if (typeof f.value === 'string' || f.value instanceof String)
                    f.value = f.value.replace(/([^\\])\*$/,"$1\\*")
            }
        }
        if (f.subspec) {
            f.type = [f.type, ...f.subspec].join(':')
        }
        if (f.aggrspec) {
            f.type = [f.type, f.aggrspec].join(':')
        }
        f.type = f.type.replace(/:$/, '')
        delete f.subspec
        delete f.aggrspec
        delete f.method
        const neg = f.neg
        delete f.neg
        if (neg) {
            f = {__not: f}
        }
        return f
    }

    consolidateNameQuery(nameReq: any) {
        let value = nameReq.value
        if (nameReq.method === 'regexp') 
            value = {regexp: value}
        else if (nameReq.method === 'fulltext')
            value = {fulltext: value}
        else if (nameReq.method === 'prefix')
            value = value+'*'
        else value = value.replace(/([^\\])\*$/,"$1\\*")
        if (nameReq.ci && nameReq.method !== 'fulltext') 
            value = {ci: value}
        return value
    }

    consolidateQuery(qComps: Query[]) : Query|null {
        let query: Query = {}
        let validq = false
        query.feats = []
        for (let subQuery of qComps) {
            if (subQuery['name'] && subQuery['name'].value) {
                const f = JSON.parse(JSON.stringify(subQuery['name']))
                query['name'] = this.consolidateNameQuery(f)
                validq = true
            }
            if (subQuery['type']) {
                query['type'] = subQuery['type']
            }
            if (subQuery.feats) {
                for (let f of subQuery.feats) {
                    f = JSON.parse(JSON.stringify(f))
                    if (this.validFeatureQuery(f)) {
                        query.feats.push(this.consolidateFeatureQuery(f))
                        validq = true
                    }
                }
            }
            // consolidate slots
            if (subQuery.slots) {
                for (let s of subQuery.slots) {
                    s = JSON.parse(JSON.stringify(s))
                    if (!query.slots) {
                        query.slots = []
                    }
                    if (!s.pos) delete s['pos']
                    let sfeats: Feature[] = []
                    for (let fi in s.feats) {
                        const f = s.feats[fi]
                        if (this.validFeatureQuery(f)) {
                            sfeats.push(this.consolidateFeatureQuery(f))
                            validq = true
                        }
                    }
                    // consolidate fillers
                    for (let fli in s.fillers) {
                        let ffeats: Feature[] = []
                        for (let fi in s.fillers[fli].feats) {
                            const f = s.fillers[fli].feats[fi]
                            if (this.validFeatureQuery(f)) {
                                ffeats.push(this.consolidateFeatureQuery(f))
                                validq = true
                            }
                        }
                        if (ffeats.length) s.fillers[fli].feats = ffeats
                    }
                    if (sfeats.length) s.feats = sfeats
                    query.slots.push(s)
                }
            }
        }
        if (validq)
            return query
        else
            return null
    }

    updateQuery (comps: Query[], path: string, value: string|number) : Query[] {
        let pathl = path.split('.')
        const last = pathl.pop()
        let target : any = comps
        for (let step of pathl) {
            if (target && step in target) {
                target = target[step]
            } else {
                target = null
            }
        }
        if (last && target !== null) {
            target[last as string] = value
        }
        return comps
    }

    runQuery(state: any, formik: any, values: Query[]|undefined = undefined) {
        if (formik) {
            formik.setSubmitting(true)
        }
        if (values) {
            this.setState({query_comps: values, page: { from: 0, size: state.page.size }, errorMsg: '', searchWarning: '', directRequest: false})
            this.saveState()
            this.sendQuery(this.state, false)
        } else {
            this.setState({type: state.type, errorMsg: '', searchWarning: '', directRequest: true})
            this.sendQuery(state, true)
        }
        if (formik) {
            formik.setSubmitting(false)
        }
    }

    sendQuery(state: any, directRequest = false, newQuery = true) {
        const { meta, checkUpdates, t } = this.props
        const { customColumns } = this.state
        const params = meta.uis['search'][state.type]['params']
        let query = this.consolidateQuery(state.query_comps)
        if (!query) {
            this.setState({searchWarning: t('No search criteria entered.')})
            return
        }
        let request: RatatoskRequest = {query: {}, page: state.page}
        request.sort = []
        if (params.request_defaults) {
            if (params.request_defaults.feats)
                request.feats = params.request_defaults.feats.concat(customColumns.map(f => f.path))
            if (params.request_defaults.page_size)
                request.page.size = params.request_defaults.page_size
            if (params.request_defaults.sort) {
                for (let sort of params.request_defaults.sort) {
                    // TODO: needs testing! (or rather transparent reimplementation in the backend)
                    let feature = sort.feature + (sort.subspec ? ':'+sort.subspec : '')
                    const fmeta = getmeta(meta, 'feature', feature)
                    // common feature value
                    const prefix = featureValueTypes[fmeta.params.value_type].prefix
                    let fvalue = prefix+'value'
                    // agregated feature value
                    if (fmeta.params.aggregate) {
                        let subparts = sort.subspec.split(':')
                        const lastpart = subparts.pop()
                        if (subparts.length && (!fmeta.subspec_required || subparts.length>fmeta.params.subspecs.length) && lastpart!=fmeta.params.aggregate.default_spec) {
                            feature = [sort.feature, ...subparts].join(':')
                            if (fmeta.params.aggregate.indexed) {
                                fvalue = prefix+'sub:'+lastpart
                            } else {
                                fvalue = '_fl_values.'+lastpart
                            }
                        }
                    }
                    let sortitem = {}
                    sortitem['feats.'+fvalue] = {
                        order: sort.order,
                        nested: {
                            path: 'feats',
                            filter: {term: {'feats.type': feature}}
                        }
                    }
                    request.sort.push(sortitem)
                }
            }
        }
        request.sort.push({'name.cs_sort':{'order':'asc'}})
        request.query = query
        request.page = state.page
        request['_client'] = import.meta.env.APP_NAME + '/' + import.meta.env.APP_VERSION
        const query_sum = Md5.hashStr(JSON.stringify(request))
        let newState = {submitting: true, errorMsg: '', directRequest: directRequest, lastQuery: null}
        if (newQuery) newState['selectedIds'] = []
        this.setState(newState)
        const url = params.request_url || 'cunits/_view'
        this.props.http.post(url, request)
            .then(response => {
                this.setState({response: response.data, errorMsg: '', submitting: false, query_sum: query_sum, lastQuery: query})
                checkUpdates(response.data)
                if (!directRequest) {
                    this.saveState()
                }
            }).catch(err => {
                if (err.response) {
                    // UNAUTHORIZED
                    if (err.response.status===401) {
                        this.setState({authorized: false, submitting: false, response: null})
                    } else {
                        this.setState({submitting: false, errorMsg: err.response.data.message, response: null})
                        this.saveState()
                    }
                }
            })
    }


    createNew() {
        const { history, t } = this.props
        const { newName, newType } = this.state
        const url = 'cunits/_new'
        let newCUnit = {
            type: newType,
            name: newName,
            slots: [],
            feats: []
        }
        this.setState({submitting: true})
        this.props.http.put(url, newCUnit)
            .then(response => {
                const newPath = response.data.data[0]['_path']
                history.push('/edit/'+newPath)
                this.setState({response: response.data, newDialogOpen: false, errorMsg: '', submitting: false})
            }).catch(err => {
                if (err.response) {
                    // UNAUTHORIZED
                    if (err.response.status===401) {
                        //this.setState({authorized: false})
                        this.setState({newDialogOpen: false, errorMsg: t('Permission denied.'), submitting: false})
                    } else {
                        console.log(err.response.data.message)
                        this.setState({newDialogOpen: false, errorMsg: err.response.data.message, submitting: false})
                    }
                }
                window.scrollTo(0, 0)
            })
    }

    clearResponse() {
        this.setState({response: null})
    }

    render () {
        const { location, meta, http, i18n, t } = this.props
        const { response, page, query_sum, submitting, helpIsOpen, directRequest, newDialogOpen, batchDialogOpen, deleteDialogOpen, lastQuery,
            batchQuery, batchMessage, selectedIds, customColumns, viewOptionsDialogOpen } = this.state

        if (location.pathname.split('/').filter(x => x!=='').length === 1) {
            const last = localStorage.getItem('last_search')
            if (last)
                return (<Redirect to={last}/>)
            else {
                const first = Object.keys(meta.uis['search'])[0]
                return (<Redirect to={"/search/"+first.replace(/^:/,'').replace(':','_')}/>)
            }
        } else {
            localStorage.setItem('last_search', location.pathname)
        }

        if (!this.state.authorized) {return(<Redirect to={{ pathname: "/login", state: { from: location } }} />)}    

        const curtype = this.state.type
        if (!(meta.uis['search'] && curtype in meta.uis['search'] && meta.cats['cunit'])) {
            return(null)
        }
        const params = meta.uis['search'][curtype]['params']
        if (!this.state.query_comps.length) {
            return(null)
        }
        
        const ResultRenderer = this.renderComponents[params['render']['comp']]
        let resultRendererParams = {}
        if ('params' in params['render']) {
            resultRendererParams = JSON.parse(JSON.stringify(params['render']['params']))
            resultRendererParams['columns'] = resultRendererParams['columns'] ? resultRendererParams['columns'].concat(customColumns) : customColumns
        }

        const HelpRenderer = params['help'] && params['help']['comp'] ? this.helpComponents[params['help']['comp']] : null

        const pageChange = (e, d) => {
            const { size } = this.state.page
            const pgnum = d.activePage
            const newStart = (pgnum-1) * size
            this.setState({page:{from: newStart, size: size}})
        }

        let pageInfo: PageInfo = {pageStart: page.from + 1, pageEnd: page.from+page.size,  curPage: 0, totalPages: 0, pagination: false, total: -1, pageChange: pageChange}
        if (response && response.total) {
            if (response.total.value > page.size) {
                pageInfo.pagination = true
                // ElasticSearch search API only allows access to the first 10000 results (by standard means)
                pageInfo.totalPages = response.total.value > 10000 ? Math.ceil(10000 / page.size) : Math.ceil(response.total.value / page.size)
                pageInfo.curPage = (page.from / page.size) + 1
            }
            if (page.from+page.size > response.total.value) pageInfo.pageEnd = response.total.value
            if (response.total.relation !== 'gte') pageInfo.total = response.total.value
        }

        const helpClose = () => { this.setState({ helpIsOpen: false }) }
        const helpOpen = () => {
            this.setState({ helpIsOpen: true })
        }
        const helpContent = (<HelpRenderer/>)


        let newUnitTypeOptions: DropdownItemProps[] = []
        let cunitTypes : string[] = []
        /*if (!params.search.endsWith('*')) {
            const i = getmeta(meta, 'cunit', params.search)
            cunitTypes.push(params.search)
            if (i['_can_write'])
                newUnitTypeOptions = [{key: params.search, value: params.search, text: getLocalizedLabel(i.label, i18n.language)}]
        } else {*/
        newUnitTypeOptions = Object.keys(meta.cats['cunit']).reduce((sel, cutype) => {
            const m = meta.cats['cunit'][cutype]
            if (
                (!m.subdef_required) && (
                    (params.search.endsWith('*') && cutype.startsWith(params.search.substr(0,params.search.length-1))) ||
                    (cutype === params.search || cutype.startsWith(params.search+':'))
                )
            ) {
                cunitTypes.push(cutype)
                if (m['_can_write'])
                    sel.push({key: cutype, value: cutype, text: getLocalizedLabel(m.label, i18n.language)})
            }
            return sel
        }, [] as DropdownItemProps[])
        //}

        const rescountstr = " ("+response?.total?.value+")"
        const selcountstr = " ("+selectedIds.length+")"

        let batchOptions = [
            {key: 'batchAll', value: 'batchAll', text: t('Batch update all')+rescountstr, message: t('Batch update all units found by the query')+rescountstr}
        ]
        if (selectedIds.length) { 
            batchOptions.push({key: 'batchSel', value: 'batchSel', text: t('Batch update selected')+selcountstr, message: t('Batch update selected units')+selcountstr})
        }
        batchOptions.push({key: 'deleteAll', value: 'deleteAll', text: t('Delete all units')+rescountstr, message: t('Delete all units found by the query')+rescountstr})
        if (selectedIds.length) { 
            batchOptions.push({key: 'deleteSel', value: 'deleteSel', text: t('Delete selected units')+selcountstr, message: t('Delete selected units')+selcountstr})
        }
        const batchOpen = (action) => { 
            if (lastQuery && action) {
                if (action === 'batchAll') {
                    this.setState({ batchDialogOpen: action, batchMessage: batchOptions.filter(o => o.value===action)[0].message, batchQuery: lastQuery })
                } else if (action === 'batchSel') {
                    let selQuery = {'_id': selectedIds}
                    if (lastQuery && lastQuery['type']) selQuery['type'] = lastQuery['type']
                    this.setState({ batchDialogOpen: action, batchMessage: batchOptions.filter(o => o.value===action)[0].message, batchQuery: selQuery })
                } else if (action === 'deleteAll') {
                    this.setState({ deleteDialogOpen: action, batchMessage: batchOptions.filter(o => o.value===action)[0].message, batchQuery: lastQuery })
                } else if (action === 'deleteSel') {
                    let selQuery = {'_id': selectedIds}
                    if (lastQuery && lastQuery['type']) selQuery['type'] = lastQuery['type']
                    this.setState({ deleteDialogOpen: action, batchMessage: batchOptions.filter(o => o.value===action)[0].message, batchQuery: selQuery })
                }
            }
        }
        const batchClose = (reset: boolean = false) => {
            let state = { batchDialogOpen: null, batchMessage: ''}
            if (reset===true) state['response'] = null
            this.setState(state) 
        }
        const deleteClose = (reset: boolean = false) => {
            let state = { deleteDialogOpen: null, batchMessage: ''}
            if (reset===true) state['response'] = null
            this.setState(state) 
        }
        const selectId = newUnitTypeOptions.length && response && response.total?.value ? // newUnitTypeOptions => user has write permissions
        (id: string, select: boolean) => {
            const { selectedIds } = this.state
            if (select) this.setState({selectedIds: [...selectedIds, id]})
            else this.setState({selectedIds: selectedIds.filter(i => i !== id)})
        } : null
        
        const newClose = () => { this.setState({ newDialogOpen: false }) }
        const newOpen = () => {
            const newType = this.state.newType && newUnitTypeOptions.map(x => x.value).includes(this.state.newType) ?
                this.state.newType : String(newUnitTypeOptions[0].value)
            this.setState({ newDialogOpen: true, newType: newType })
        }

        const viewOptionsClose = () => { this.setState({ viewOptionsDialogOpen: false }) }
        const viewOptionsOpen = () => { this.setState({ viewOptionsDialogOpen: true }) }

        const showSubmit = params['form'].some((item: FormComponent) => !this.formComponents[item.comp]['HIDE_SUBMIT'])
        
        return (
            <Container>
                <Modal content={helpContent} open={helpIsOpen} onClose={helpClose} centered={false} closeIcon/>

                <Modal open={newDialogOpen}>
                    <Modal.Content>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                <PInput label={t('Name')} defaultValue={this.state.newName} fluid={true}
                                    error={!this.state.newName.trim()}
                                    onChange={(e,d)=>this.setState({newName: d.value})}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                <PDropdown label={t('Type')} defaultValue={this.state.newType} fluid={true}
                                    options={newUnitTypeOptions} selection
                                    onChange={(e,d)=>this.setState({newType: String(d.value)})}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="red" onClick={newClose}>{t('Cancel')}</Button>
                        <Button color="blue" disabled={!this.state.newName.trim()}
                            onClick={()=>this.createNew()}>{t('Create')}</Button>
                    </Modal.Actions>
                </Modal>

                { (lastQuery || selectedIds.length) ?
                <>
                <Modal open={!!batchDialogOpen} centered={false} size="fullscreen">
                    <Modal.Content>
                        <Batch {...this.props} meta={meta} http={http} message={batchMessage} batchId={this.state.type} 
                            query={batchQuery} close={batchClose}/>
                    </Modal.Content>
                </Modal>
                <Modal open={!!deleteDialogOpen} centered={false} size="fullscreen">
                    <Modal.Content>
                        <Delete {...this.props} meta={meta} http={http} message={batchMessage} batchId={this.state.type} 
                            query={batchQuery} close={deleteClose}/>
                    </Modal.Content>
                </Modal>
                </>
                : null}

                <Modal size="large" open={!!viewOptionsDialogOpen}>
                    <Modal.Header>{t('View options')}</Modal.Header>
                    <Modal.Content>
                        <Form key={this.state.type+'_view_options'} initialValues={{features: customColumns}} className="searchform"
                            onSubmit={(values, formikApi) => {
                                formikApi.setSubmitting(false)
                                this.setState({customColumns: values.features, viewOptionsDialogOpen: false})
                            }}>
                                {(formik) => {
                            return(
                            <>
                            <Segment color="grey">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={14}>
                                        <Header as='h3'>{t('Additional columns')}</Header>
                                    </Grid.Column>
                                </Grid.Row>
                                <FieldArray
                                    name="features"
                                    render={(subformik) => {
                                    const features = collectFeatureOptions(meta, cunitTypes, null, null, true, true, true)
                                    const typeOptions = features.map(f => ({key: f.type, text: createDefaultFeatureLabel(meta, f.type, true), value: f.type}))
                                    return (
                                        <>
                                        { formik.values.features.map((f, i) => {
                                            const fmeta = getmeta(meta, 'feature', f.type)
                                            return(
                                            <Grid.Row className='condensed'>
                                                <InputFeatureType name={'features.'+i+'.path'} fmeta={fmeta} options={typeOptions} widthCols={10}/>
                                                <Grid.Column width={5}></Grid.Column>
                                                <Grid.Column width={1} textAlign="right">
                                                    <Button type="button" color="red" icon="delete" size='mini' onClick={(e, d) => subformik.remove(i)}/>
                                                </Grid.Column>
                                            </Grid.Row>
                                            )
                                        })
                                        }
                                        <Grid.Row>
                                        <Grid.Column>
                                            <Button icon="plus" type="button" size='mini' color='blue' 
                                                onClick={(e,d) => subformik.push({path: ''})}/>
                                        </Grid.Column>
                                        </Grid.Row>
                                        </>
                                    )
                                    }}/>
                            </Grid>
                            </Segment>
                            <Segment>
                                <Grid>
                                    <Grid.Row>
                                    <Grid.Column width={16} textAlign="left">
                                        <Button.Submit>{t('Apply')}</Button.Submit>
                                        <Button type="button" color="grey" onClick={viewOptionsClose}>{t('Cancel')}</Button>
                                    </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            </>)
                            }}
                        </Form>
                    </Modal.Content>
                </Modal>
                
                <Grid>
                { this.state.errorMsg ?
                <Grid.Row>
                    <Grid.Column>
                    <Message warning visible icon='exclamation triangle' header={t('Error')} content={this.state.errorMsg}/>
                    </Grid.Column>
                </Grid.Row>
                : null}
                { directRequest ? null :
                <>
                <Grid.Row>
                    <Grid.Column>
                <Form key={this.state.type} initialValues={this.state.query_comps} className="searchform"
                    onSubmit={(values: Query[], formikApi) => this.runQuery(this.state, formikApi, values)}>
                    {(formik) => (
                    <Segment color="grey">
                        <Grid>
                        <FieldArray
                        name="query_comps"
                        render={(subformik) => (
                            params['form'].map((item: FormComponent, index: number) => {
                                const Comp = this.formComponents[item.comp]
                                let label = null
                                if (item.label) {
                                    label = getLocalizedLabel(item.label, i18n.language)
                                }
                                let params = {}
                                if (item.params) { params = item.params }
                                return (
                                    <>
                                    { label ?
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <label className="label">{label}</label>
                                        </Grid.Column>
                                    </Grid.Row>
                                    : null }
                                    <Comp key={index} label={label} params={params} meta={meta} 
                                        prefix={index.toString()} formik={formik}
                                        cunitTypes={cunitTypes} clear={() => this.clearResponse()}/>
                                    </>
                                )
                            })
                        )}/>
                        { showSubmit || (params['create'] && newUnitTypeOptions.length) || HelpRenderer ?
                        <Grid.Row>
                            <Grid.Column width="2">
                            { showSubmit ? <Button.Submit className={this.state.submitting ? 'loading': null}>{t('Search')}</Button.Submit> : null }
                            </Grid.Column>
                            <Grid.Column width="10">
                            </Grid.Column>
                            <Grid.Column width="4" textAlign="right">
                                { params['create'] && newUnitTypeOptions.length ? <Button type="button" color="orange" onClick={newOpen}>{t("New unit")}</Button> : null }
                                { HelpRenderer ? <Button type="button" color="blue" icon="help" onClick={(e,d) => helpOpen()} title={t('Help')}/> : null }
                            </Grid.Column>
                        </Grid.Row>
                        : null}
                        </Grid>
                    </Segment>
                    )}
                </Form>
                </Grid.Column>
                </Grid.Row>
                </>
                }
                { ResultRenderer['ALLOW_BATCH'] && newUnitTypeOptions.length && response && response.total?.value ? // newUnitTypeOptions => user has write permissions
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment color="grey">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <PDropdown text={t('Batch operations')} options={batchOptions} onChange={(e,d)=>batchOpen(d.value)} value="" button color="orange"
                                        selectOnBlur={false} selectOnNavigation={false}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                : null}
                { this.state.searchWarning &&
                <Grid.Row>
                    <Grid.Column>
                    <Message error icon='exclamation triangle' content={this.state.searchWarning} hidden={false}/>
                    </Grid.Column>
                </Grid.Row>
                }
                <Grid.Row>
                    <Grid.Column width={16}>
                        { ResultRenderer ? (
                            <ResultRenderer {...this.props} key={query_sum} response={response} meta={meta} pageInfo={pageInfo} viewOptionsOpen={viewOptionsOpen}
                                directRequest={directRequest} params={resultRendererParams} loading={submitting} selectedIds={selectedIds} selectId={selectId}/>
                        ) : null }
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            </Container>
        )
    }
}

export default withTranslation()(Search)
