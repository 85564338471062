import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, CUView, Cunit } from '../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, Dimmer, Grid, Header, Message, Segment, Tab } from 'semantic-ui-react'
import FeatureViewTable from './render/FeatureViewTable'
import ComponentTerminalView from './render/ComponentTerminalView'
import ComponentStrucChart from './render/ComponentStrucChart'
import Loader from './Loader'
import AcPhrasePreview from './render/AcPhrasePreview'
import LemurPreview from './render/LemurPreview'

interface ViewProps extends WithTranslation, RouteComponentProps {
    meta: Meta,
    http: AxiosInstance
}

export interface ViewCompProps extends WithTranslation {
    meta: Meta,
    config: any,
    data: Cunit
}

interface ViewState {
    loading: boolean,
    errorMsg: string,
    response: RatatoskResponse
}

class View extends Component<ViewProps, ViewState> {

    state = {
        loading: true,
        errorMsg: '',
        response: {code: -1, message: '', data: []}
    }

    componentDidMount() {
        //this.componentDidUpdate(this.props, this.state)
        const { location, history, meta, t } = this.props

        const path = location.pathname.replace(/^\/view\//,"")
        //const request = {'_client': packageJson.name + '/' + packageJson.version}
        if (path) {
            const url = 'cunits/'+path
            this.props.http.get(url)
                .then(response => {
                    this.setState({response: response.data, errorMsg: '', loading: false})
                }).catch(err => {
                    if (err.response) {
                        this.setState({errorMsg: t(err.response.data.message), loading: false})
                    } else {
                        this.setState({loading: false})
                    }
                })
        }
    }

    componentDidUpdate(prevProps: ViewProps, prevState: ViewState) {
    }

    render() {
        const { meta, t, i18n, history, location } = this.props
        const { loading, response, errorMsg } = this.state
        
        if (!(meta['cats']['cunit']) || !(meta['uis']['view'])) return null

        if (loading) return (<Container><Segment><Loader/></Segment></Container>)

        const unit = response.data[0]
        const mytype = unit ? unit['type'] as string : ''
        const mymeta = unit ? getmeta(meta, 'cunit', mytype) : null

        const components = {
            FeatureViewTable: FeatureViewTable,
            ComponentTerminalView: ComponentTerminalView,
            ComponentStrucChart: ComponentStrucChart,
            AcPhrasePreview: AcPhrasePreview,
            LemurPreview: LemurPreview
        }
        
        const redirectToEdit = (e,d) => {
            history.push(location.pathname.replace(/^\/view\//,"/edit/"))
            e.stopPropagation()
        }

        const unknownComponent = <Message error icon='exclamation triangle' header={t('Error')} content={t('Unknown component')}/>

        let panes: object[] = []
        for (let vtype of Object.keys(meta['uis']['view'])) {
            const v = meta['uis']['view'][vtype]
            if (v['_can_read'] && v.params?.cunit_type?.filter(x => mytype.startsWith(x)).length) {
                const label = getLocalizedLabel(v['label'], i18n.language)
                let comps: React.ReactNode[] = []
                for (let component of v['params']['components']) {
                    const ViewComponent = components[component.comp]
                    comps.push(ViewComponent ? <ViewComponent {...this.props} meta={meta} data={unit} config={component}/> : unknownComponent)
                }
                panes.push({menuItem: label, render: () => <Tab.Pane>{comps}</Tab.Pane>})
            }
        }
        
        return(
            <Container>
                <Grid>
                    { errorMsg &&
                    <Grid.Row>
                        <Grid.Column>
                        <Message error icon='exclamation triangle' header={t('Error')} content={this.state.errorMsg}/>
                        </Grid.Column>
                    </Grid.Row>}
                    { unit &&
                    <>
                    <Grid.Row>
                        <Grid.Column width="14">
                        <Header as='h1'>
                            <Header.Content>
                                {unit['name']}
                                <Header.Subheader style={{display:'inline-block',marginLeft:'.5em'}}>({getLocalizedLabel(mymeta['label'], i18n.language)})</Header.Subheader>
                            </Header.Content>
                        </Header>
                        </Grid.Column>
                        <Grid.Column width="2" textAlign="right">
                        { mymeta['_can_write'] && <Button onClick={redirectToEdit} color="blue" size="small" type="button">{t('Edit')}</Button> }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                        { panes.length ? <Tab panes={panes}/> : null }
                        </Grid.Column>
                    </Grid.Row>
                    </>
                    }
                </Grid>
            </Container>
        )
    }

}

export default withTranslation()(View)