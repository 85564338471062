import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, getLocalizedLabel, valueNotEmpty, valueUnique, valuePathPrefix, valueIsIntNumber, getmeta } from '../../common'
import { Container, Segment, Tab, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorUiSearchCompProps} from './CEUiSearch'
import PathSpec from '../form/PathSpec'
import { setFieldValue } from '../form/FormikHelpers'
import Button from '../form/Button'

class ConfigEditorUiSearchRenderCNCLemmaList extends Component<ConfigEditorUiSearchCompProps> {

    render() {
        const { meta, editing, locale, t, i18n, params, formik, formikprefix } = this.props
        const lang = i18n.language

        if (!(meta && meta.cats && meta.cats['feature'])) return null

        if (params.detail_view === undefined) {
            setFieldValue(formik, formikprefix+'.detail_view', [], false)
            return null
        }

        const allViewOptions = Object.keys(meta.uis['view']).reduce((res: DropdownItemProps[], type) => {
            const u = meta.uis['view'][type]
            if (type && !u.subdef_required) {
                res.push({key: type, text: getLocalizedLabel(u['label'], lang)+" ["+type+"]", value: type})
            }
            return res
        }, [])
        
        return (
            <>
            <Grid.Row>
                <Grid.Column width={4}><div className="label">{t('Details')}</div></Grid.Column>
            </Grid.Row>
            <FieldArray name={formikprefix+".detail_view"}
                render={(subformik) => (
                    <>
                    {params.detail_view.map((view: any, index: number) => {
                        return (
                            <Grid.Row className="condensed">
                                <Grid.Column width={13}>
                                    <Dropdown name={formikprefix+".detail_view."+index} options={allViewOptions}
                                        inputProps={{disabled: !editing}}/>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    { editing ?
                                    <>
                                    <Button type="button" icon="angle up" color="blue" size="mini"
                                        disabled={!editing || index === 0}
                                        onClick={(e,d) => subformik.swap(index, index-1)}/>
                                    <Button type="button" icon="angle down" color="blue" size="mini"
                                        disabled={!editing || index === params.detail_view.length-1}
                                        onClick={(e,d) => subformik.swap(index, index+1)}/>
                                    <Button type="button" icon="delete" color="red" size="mini"
                                        disabled={!editing}
                                        onClick={(e,d) => subformik.remove(index)}/>
                                    </> : null }
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }
                    )}
                    { editing ? 
                    <Grid.Row className="condensed">
                        <Grid.Column width="16">
                            <Button icon="plus" type="button" size="tiny" color='blue' 
                                onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                        </Grid.Column>
                    </Grid.Row> 
                    : null}
                </>
                )}/>
            </>
        )
    }
}

export default withTranslation()(ConfigEditorUiSearchRenderCNCLemmaList)
