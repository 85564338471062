import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Divider } from 'semantic-ui-react'
import logoCZ from '../../img/OP_VVV_cz.png'
import logoEN from '../../img/OP_VVV_en.png'

interface CNCFormListHelpProps extends WithTranslation {}

class CNCFormListHelp extends Component<CNCFormListHelpProps> {
    render() {
        const { i18n } = this.props
        if (i18n.language.startsWith('cs'))
            return(
            <>
            <div className="header">Vyhledávání slovních tvarů v databázi korpusových typů</div>
            <div className="content">

            <p>Databáze obsahuje všechny rozpoznané slovní tvary lemmat, která se reálně vyskytují v některém ze zpracovaných korpusů ČNK: <a href="https://wiki.korpus.cz/doku.php/cnk:syn:verze8" target="_blank" rel="noopener noreferrer">SYN v8</a> (současná psaná čeština), <a href="https://wiki.korpus.cz/doku.php/cnk:oral" target="_blank" rel="noopener noreferrer">ORAL v1</a> a <a href="https://wiki.korpus.cz/doku.php/cnk:ortofon" target="_blank" rel="noopener noreferrer">ORTOFON v1</a> (současná mluvená čeština), <a href="https://wiki.korpus.cz/doku.php/cnk:diakorp" target="_blank" rel="noopener noreferrer">Diakorp v6</a> a nezveřejněný korpus textů 19. stol. Protože se lemmatizace a morfologické značkování těchto korpusů mírně liší, byly jako zdroj dat použity jejich interní verze s jednotným zpracováním.</p>

            <p>V této záložce je možné vyhledávat konkrétní slovní tvary. Vyhledávat lze také podle začátku slova nebo pomocí regulárního výrazu. Vyhledávání nebere ohled na velikost písmen. Tabulka s výsledky je seřazena podle celkové frekvence výskytu od nejvyšší k nejnižší. (Přístupných je max. prvních 10 tisíc nalezených tvarů.) Kromě frekvence se zobrazuje <a href="https://wiki.korpus.cz/doku.php/seznamy:tagy" target="_blank" rel="noopener noreferrer">morfologická značka (tag)</a> se slovním popisem morfologických kategorií a <a href="https://wiki.korpus.cz/doku.php/pojmy:lemma" target="_blank" rel="noopener noreferrer">lemma</a>, které slouží též jako odkaz na následující záložku aplikace, kde lze zobrazit výpis všech tvarů daného lemmatu. Ikona vpravo u každého tvaru umožňuje otevřít podrobný výpis frekvenčních distribucí daného tvaru podle různých textových atributů a jejich hodnot v korpusu psaných textů (SYNv8) a podle attributů mluvčích v korpusech mluveného jazyka (ORAL, ORTOFON).</p>

            <Divider/>

            <p>Databáze je výstupem projektu "Jazyková variabilita v CNC" číslo CZ.02.1.01/0.0/0.0/16_013/0001758 spolufinancovaného z evropských fondů.</p>

            <img src={logoCZ} alt="Project logo"/>

            </div>
            </>
            )
        else
            return(
                <>
                <div className="header">Searching word forms in the database of corpus types</div>
                <div className="content">

                <p>The database contains all recognized word forms of all lemmata that actually occur in any of the processed CNC corpora: <a href="https://wiki.korpus.cz/doku.php/cnk:syn:verze8" target="_blank" rel="noopener noreferrer">SYN v8</a> (contemporary written Czech), <a href="https://wiki.korpus.cz/doku.php/cnk:oral" target="_blank" rel="noopener noreferrer">ORAL v1</a> and <a href="https://wiki.korpus.cz/doku.php/cnk:ortofon" target="_blank" rel="noopener noreferrer">ORTOFON v1</a> (contemporary spoken Czech), <a href="https://wiki.korpus.cz/doku.php/cnk:diakorp" target="_blank" rel="noopener noreferrer">Diakorp v6</a> and an unpublished corpus of 19th century texts. Since their lemmatization and POS-tagging may differ, internal versions of these corpora have been processed, using a common tagging.</p>

                <p>In this tab, you can search for particular word forms. You can also search by the start of a word or by using regular expressions. The search is case insensitive. The table with results is ordered by the total frequency from highest to lowest. (At most 10000 forms are accessible.) Beside frequency, the morphological tag is shown with a description of the morphological classification, and the lemma, used as a direct link to the following tab, where a complete list of all forms (and their variants) of the lemma can be displayed. The blue icon at the right side can be used to show a detailed frequency distribution of the form according to various text attributes and their values in the corpus of written texts (SYNv8) and according to speaker attributes in the corpus of spoken language (ORAL, ORTOFON).</p>

                <Divider/>

                <p>The database is an outcome of the project entitled "Language Variation in the CNC", reg. no. CZ.02.1.01/0.0/0.0/16_013/0001758, co-funded by the European Structural and Investment Funds.</p>

                <img src={logoEN} alt="Project logo"/>

                </div>
                </>
            )
    }
}

export default withTranslation()(CNCFormListHelp)