import React, { Component } from 'react'
import { Form, Dropdown as SDropdown, Input as SInput, FormDropdownProps, DropdownItemProps, Grid } from 'semantic-ui-react'
import { FastField, Field, getIn } from 'formik'
import ErrorMessage from './ErrorMessage'
import { FormikComponentProps, getFieldError, setFieldValue } from './FormikHelpers'
import { getmeta, Meta, valueIsSimpleId } from '../../common'

export interface PathSpecProps extends FormikComponentProps {
    inputProps?: FormDropdownProps
    options?: DropdownItemProps[]
    placeholder?: string,
    basePath: string
}

class PathSpec extends Component<PathSpecProps> {

    id: string
    _dropdown: React.ReactNode

    constructor(props) {
        super(props)
        const {id, name} = props
        this.id = id || `field_${name}`
    }

  render() {

    const {
        name,
        label,
        options,
        placeholder,
        validate,
        inputProps = {},
        fieldProps = {},
        errorComponent = ErrorMessage,
        fast,
        basePath
    } = this.props

    const curValue = (base: string, spec: string) => {
      return spec === '' ? base : base+':'+spec
    }

    const haveOptions = Boolean(options)
    const myoptions = haveOptions ? options : [{key:basePath, text: basePath, value: basePath}]

    const {onChange, ...safeInputProps} = inputProps
    const DesiredField = fast === true ? FastField : Field
    const newSpecValidation = (str: string) => {
      return valueIsSimpleId(str.substr(basePath.length).replace(/^:+/,''))
    }

    return (
      <Grid>
      <DesiredField name={name} validate={haveOptions ? validate : newSpecValidation}>
        {({field, form, meta}) => {
          const error = getFieldError(field, form)
          const specification: string = field.value ? field.value.substr(basePath.length).replace(/^:+/,'') : ''
          
          return (
            <Grid.Row>
            <Grid.Column width={12}>
            <Form.Field error={!!error} {...fieldProps}>
              <SDropdown
                ref={el => (this._dropdown = el)}
                id={this.id+'_d'}
                name={name}
                options={myoptions}
                placeholder={placeholder}
                selectOnBlur={false}
                selectOnNavigation={false}
                selection
                {...safeInputProps}
                disabled={!haveOptions || safeInputProps.disabled}
                value={basePath}
                className={haveOptions && curValue(basePath, specification) !== meta.initialValue ? 'changed' : ''}
                onChange={(e, {name, value}) => {
                  const newValue = curValue(String(value), specification.replace(/^:+/,''))
                  setFieldValue(form, name, newValue, false)
                  Promise.resolve().then(() => {
                    onChange && onChange(e, {name, newValue})
                  });
                }}
              />
              </Form.Field>
              </Grid.Column>
              <Grid.Column width={4}>
              <Form.Field error={!!error} {...fieldProps}>
              <SInput
                  id={this.id+'_i'}
                  name={name}
                  {...safeInputProps}
                  disabled={basePath.startsWith('_') ? true : safeInputProps.disabled }
                  value={specification}
                  className={(curValue(basePath, specification) !== meta.initialValue) && ( curValue(basePath, specification) !== '' && !meta.initalValue) ? 'changed' : ''}
                  onChange={(e, {name, value}) => {
                    const newValue = curValue(basePath, String(value).replace(/^:+/,''))
                    setFieldValue(form, name, newValue, true)
                    Promise.resolve().then(() => {
                      onChange && onChange(e, {name, newValue})
                    });
                  }}
                  onBlur={form.handleBlur}
                />
                {error && (
                  React.createElement(errorComponent, { message: getIn(form.errors, name) })
                )}
              </Form.Field>
              </Grid.Column>
            </Grid.Row>
          );
        }}
      </DesiredField>
      </Grid>
    );
  }
}

export default PathSpec