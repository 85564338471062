import React, { Component, ReactNode } from 'react'
import { Form, Dropdown as SDropdown, Input as SInput, Button as SButton, FormDropdownProps, DropdownItemProps, Grid, StrictGridColumnProps } from 'semantic-ui-react'
import { FastField, Field, getIn } from 'formik'
import ErrorMessage from './ErrorMessage'
import { FormikComponentProps, getFieldError, setFieldValue } from './FormikHelpers'
import { collectFeatureOptions, getLocalizedLabel, getmeta, Meta, valueIsSimpleId, valueNotEmpty } from '../../common'
import { withTranslation, WithTranslation } from 'react-i18next'

export interface InputFeatureTypeProps extends FormikComponentProps, WithTranslation {
  inputProps?: FormDropdownProps
  options?: DropdownItemProps[]
  placeholder?: string,
  basePath?: string,
  fmeta: any,
  name: string,
  widthCols: number
}

interface InputFeatureTypeState {
  base: string,
  spec: string|null,
  aggrspec: string|null
}

class InputFeatureType extends Component<InputFeatureTypeProps, InputFeatureTypeState> {

    id: string
    _dropdown: React.ReactNode

    state = {
      base: '',
      spec: null,
      aggrspec: null
    }

    constructor(props) {
        super(props)
        const {id, name} = props
        this.id = id || `field_${name}`
    }

  render() {

    const {
        name,
        label,
        options,
        placeholder,
        inputProps = {},
        fieldProps = {},
        errorComponent = ErrorMessage,
        fast,
        basePath,
        i18n,
        t,
        fmeta,
        widthCols
    } = this.props

    const composeNewType = (base: string, specs: string[]|null, aggrspec: string|null) => {
      let type = base
      if (specs!==null && specs!==undefined) {
        type = [type, ...specs].join(':')
      }
      if (aggrspec!==null && aggrspec!==undefined) {
        type = [type, aggrspec].join(':')
      }
      return type
    }

    const haveOptions = Boolean(options)
    const myoptions = haveOptions ? options : [{key: basePath, text: basePath, value: basePath}]

    const {onChange, ...safeInputProps} = inputProps
    const DesiredField = fast === true ? FastField : Field
    const newSpecValidation = (str: string) => {
      return basePath ? valueIsSimpleId(str.substring(basePath.length).replace(/^:+/,'')) : true
    }

    const validate = (s: string) => {
      if (!s || String(s).includes('::') || (String(s).endsWith(':'))) return t('Incomplete specification') 
    }

    return (
      <DesiredField name={name} validate={haveOptions ? validate : newSpecValidation}>
        {({field, form, formmeta}) => {
          const error = getFieldError(field, form)
      
          let columns = 0
          //const fmeta = getmeta(meta, 'feature', field.value)
          const base = fmeta && fmeta.type
          let givenSpecs = !fmeta || field.value.length === fmeta.type.length ? [] : field.value.substring(fmeta.type.length+1).split(':')
          //const specCnt = specs.length
          // aggrspec required for aggregates that do not have a default specification; null => not required; '' => required
          const specs = fmeta && fmeta.subspec_required ?
            fmeta.params.subspecs.map((v,i) => givenSpecs.shift() || '')
            : fmeta && fmeta.params.aggregate && givenSpecs.length < 2 ? null : givenSpecs.slice(0, -1)
          
          const aggrspec = fmeta && fmeta.params.aggregate ? (
              fmeta.params.aggregate.default_spec ? givenSpecs.pop() : givenSpecs.pop() || ''
            ) : null
          //console.log("Before",specs, aggrspec)
          // enforce update for valiadation of incomplete specification
          //const current = composeNewType(fmeta, base, specs, aggrspec)
          //if (field.value != current) setFieldValue(form, name, current, true)
          //console.log("After",specs, aggrspec)
          const allowSubspec = fmeta && specs.length == 0 && (aggrspec === null || typeof(aggrspec) === 'undefined')
      
          const subspecComps: ReactNode[]|null = (specs !== undefined && specs !== null) ?
            specs.map((spec, i) =>
              (
              <Grid.Column width={2}>
              <Form.Field error={spec===''} {...fieldProps}>
              <SInput
                  id={this.id+'_s'+i.toString()}
                  name={name}
                  {...safeInputProps}
                  disabled={safeInputProps.disabled}
                  value={spec}
                  placeholder={fmeta.params.subspecs[i] ? getLocalizedLabel(fmeta.params.subspecs[i].label, i18n.language) : t('specification')}
                  //className={(curValue() !== formmeta.initialValue) && ( curValue() !== '' && !formmeta.initalValue) ? 'changed' : ''}
                  onChange={(e, {name, value}) => {
                    const newSubValue = String(value).trim()
                    const newValue = composeNewType(base, specs.map((s,ii) => ii===i ? newSubValue : s), aggrspec)
                    setFieldValue(form, name, newValue, true)
                    Promise.resolve().then(() => {
                      onChange && onChange(e, {name, newValue})
                    })
                  }}
                  onBlur={form.handleBlur}
                />
                </Form.Field>
              </Grid.Column>
              )
            )
          : allowSubspec ?
            [(
                <Grid.Column width={2}>
                <SButton type="button" size="mini" icon="ellipsis horizontal" title={t('Add specification')}
                    onClick={(e,d) => {setFieldValue(form, name, composeNewType(base, [''], aggrspec), true);e.preventDefault()}}/>
                </Grid.Column>
            )]
          : null
      
          if (subspecComps) columns += 2*subspecComps.length
      
          let aggrspecComp: ReactNode = null
          if (aggrspec !== null && aggrspec !== undefined) {
              const aggrlabel = getLocalizedLabel(fmeta.params.aggregate.label, i18n.language)
              aggrspecComp = (
                  <Grid.Column width={2}>
                  <Form.Field error={aggrspec===''} {...fieldProps}>
                  <SInput
                      id={this.id+'_a'}
                      name={name}
                      {...safeInputProps}
                      disabled={safeInputProps.disabled}
                      value={aggrspec}
                      placeholder={aggrlabel}
                      //className={(curValue() !== formmeta.initialValue) && ( curValue() !== '' && !formmeta.initalValue) ? 'changed' : ''}
                      onChange={(e, {name, value}) => {
                        const newSubValue = String(value).trim()
                        const newValue = composeNewType(base, specs, newSubValue)
                        setFieldValue(form, name, newValue, true)
                        Promise.resolve().then(() => {
                          onChange && onChange(e, {name, newValue})
                        })
                      }}
                      onBlur={form.handleBlur}
                    />
                    </Form.Field>
                  </Grid.Column>
              )
            columns += 2
          }  else if (fmeta && fmeta.params.aggregate) {
            const aggrlabel = getLocalizedLabel(fmeta.params.aggregate.label, i18n.language)
            aggrspecComp = (
              <Grid.Column width={2}>
                {
                (!specs.length || specs[specs.length-1]!=='') ?
                  <SButton type="button" size="mini" icon="ellipsis horizontal" title={t('Add specification')+" '"+aggrlabel+"'"}
                      onClick={(e,d) => {setFieldValue(form, name, composeNewType(base, specs, ''), true);e.preventDefault()}}/> 
                : null 
                }
              </Grid.Column>)
            columns += 2
          }
          
          const width = widthCols - columns

          return (
            <>
              <Grid.Column width={width as StrictGridColumnProps['width']}>
              <Form.Field error={false} {...fieldProps}>
                <SDropdown
                  ref={el => (this._dropdown = el)}
                  id={this.id+'_b'}
                  name={name}
                  options={myoptions}
                  placeholder={placeholder}
                  selectOnBlur={false}
                  selectOnNavigation={false}
                  selection
                  search
                  {...safeInputProps}
                  disabled={!haveOptions || safeInputProps.disabled}
                  value={base}
                  //className={haveOptions && curValue() !== formmeta.initialValue ? 'changed' : ''}
                  onChange={(e, {name, value}) => {
                    const newValue = composeNewType(String(value).trim(), null, null)
                    setFieldValue(form, name, newValue, false)
                    Promise.resolve().then(() => {
                      onChange && onChange(e, {name, newValue})
                    })
                  }}
                />
                {error && (
                  React.createElement(errorComponent, { message: getIn(form.errors, name) })
                )}
              </Form.Field>
              </Grid.Column>
              {subspecComps}
              {aggrspecComp}
              </>
          )
        }}
      </DesiredField>
    )
  }
}

export default  withTranslation()(InputFeatureType)