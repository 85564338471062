import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { CUView, Meta, getLocalizedLabel } from '../../common'
import { Message } from 'semantic-ui-react'
import { AxisDomain } from 'recharts/types/util/types'


export const FqTooltip = (props) => {
    const { active, label, absstats, values, language, t } = props
    if (active) {
        const valuenames = {}

        if (values) {
            for (let i in values) {
                const val = values[i]
                if (val.abbr && val.label) valuenames[getLocalizedLabel(val.abbr, language)] = getLocalizedLabel(val.label, language)
            }
        }

        let mylabel = label
        if (valuenames[label]) mylabel = valuenames[label]
        
        if (!props.payload) return (null)

        return (
        <div className="fq-tooltip">
          <p className="label">{`${mylabel}`}</p>
          {props.payload.map((item: any, index: number) => {
                const variant = item.name
                const val = item.value
                let abs = ""
                if (absstats) abs = absstats[label] ? absstats[label][variant] : "0"
                const unit = item.unit
                const color = item.color
                return(
                <p key={index} style={{color: color}}>
                  {`${variant}: ${val}${unit}, ${t('abs. fq.')} ${abs}`}
                </p>
                )
          })}
        </div>
        )
    } 
    return null
}

export interface extractedAttrValues {
    cunames: string[],
    errors: string[],
    absolute: {},
    fqlist: {}[]
}

export const extractAttrValues = (cunits, params, corpus, attribute, meta, valuedesc, relative, i18n) => {

    const withinConstraints = (constr, value) => {
        if (!constr) return true
        if (constr['min'] && value < constr['min']) return false
        if (constr['max'] && value > constr['max']) return false
        if (constr['exclude'] && constr['exclude'].includes(value)) return false
        return true
    }

    const attrprefix = ':stats:fq:abs:'+corpus+':'+attribute+'-'

    let values: string[] = []
    let ret: extractedAttrValues = {
        cunames:  [],
        errors: [],
        absolute: {},
        fqlist: []
    }

    let haveValues = false
    if (valuedesc) {
        values = Object.keys(valuedesc)
        haveValues = true
    }

    for (let u of cunits) {
        let uname = u['_name']
        ret.cunames.push(uname)
        if (!haveValues) {
            for (let k in u) {
                if (k.startsWith(attrprefix)) {
                    let attrval = k.substring(attrprefix.length)
                    if (!values.includes(attrval)) values.push(attrval)
                }
            }
        }
    }

    values = values.filter((v) => withinConstraints(params['constraints'], v))

    let size = {}
    if (relative) {
        for (let v of values) {
            let subcorp = ':'+corpus+':'+attribute+'-'+v
            if (!(meta['resources']['corpus'][subcorp])) {
                ret.errors.push(`${corpus}:${attribute}-${v}`)
            } else {
                size[v] = meta['resources']['corpus'][subcorp]['params']['size_tokens']/1000000
            }
        }
    }

    if (relative) ret.absolute = {}
    for (let v of values) {
        let abbr = v
        if (valuedesc && valuedesc[v]['abbr']) abbr = getLocalizedLabel(valuedesc[v]['abbr'], i18n.language)
        let category = {name: abbr}
        if (relative) ret.absolute[abbr] = {}
        for (let u of cunits) {
            let uname = u['_name']
            let value = 0
            const feature = attrprefix+v
            if (feature in u) value = u[feature]
            if (relative) {
                ret.absolute[abbr][uname] = value
                value = Math.round(value/size[v]*1000)/1000
            }
            category[uname] = value
        }
        ret.fqlist.push(category)
    }
    return ret
}

export const ErrorList = (errors, t) => {
    return  (
        <Message negative>
            <Message.Header>{t('Error: Missing data to compute relative frequency.')}</Message.Header>
            {errors.map((item, index) => {
                return (<p key={index}>{item}</p>)
            })}
        </Message>
    )
}

export const colors = ['#1ABC9C', '#8E44AD', '#D4AC0D', '#1A5276', '#A93226', '#239B56', '#E67E22', '#3498DB', '#5D6D7E']

export const legendWidth = 200
export const legendStyle = {
    top: 0,
    left: 400,
    width: legendWidth,
    lineHeight: '24px',
    paddingLeft: '20px',
}

export interface StatsChartProps extends WithTranslation {
    width?: number,
    height?: number,
    legend?: 'right'|'bottom'
    cunits?: CUView[],
    meta: Meta,
    corpus?: string,
    attribute?: string,
    relative: boolean,
    values?: {},
    domain?: [number, number|'auto'],
    directData?: {}[],
    directAbsData?: {},
    directSelect?: string[],
    params?: {}
}

class StatsCatChart extends Component<StatsChartProps> {

    fqlist = Array<{}>()
    absolute: {}|false = false
    cunames: string[] = []
    errors: string[] = []

    constructor(props: StatsChartProps) {
        super(props)
        const { cunits, meta, params, corpus, attribute, relative, directData, directAbsData, directSelect, i18n } = props
        const valuedesc = props.values

        if (directData && directAbsData) {
            this.cunames = directSelect ? directSelect : Object.keys(directData[0]).filter(x => x !== 'name')
            this.fqlist = directData
            this.absolute = directAbsData
        } else if (cunits && corpus && attribute) {
            let values = extractAttrValues(cunits, params, corpus, attribute, meta, valuedesc, relative, i18n)
            this.cunames = values.cunames
            this.fqlist = values.fqlist
            this.absolute = values.absolute
            this.errors = values.errors
        }
    }

    render() {
        const { relative, domain, t, i18n, values, width, height, legend } = this.props
        
        const unit = relative ? " i.p.m." : ""
        const mydomain = domain ? domain : [0, 'auto'] as AxisDomain

        if (this.errors.length) return ErrorList(this.errors, t)
        
        let chartWidth = width ? width : "100%"
        let chartHeight = height ? height : 150
        const legendPos = legend ? legend : 'bottom'
        let legendNode: React.ReactNode =  null
        if (this.cunames.length > 1) {
            if (legendPos === 'right') {
                legendNode = (<Legend layout="vertical" verticalAlign="top" align="right" wrapperStyle={legendStyle}/>)
            } else {
                legendNode = (<Legend />)
            }
        } else {
            if (legendPos === 'right' && typeof(chartWidth) === 'number') {
                chartWidth -= legendWidth
            }
        }

        return(
        <ResponsiveContainer width={chartWidth} height={chartHeight}>
        <BarChart data={this.fqlist}>
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis type="number" domain={mydomain}/>
            <XAxis dataKey="name" type="category"/>
            <Tooltip content={<FqTooltip absstats={this.absolute} values={values} language={i18n.language} t={t}/>}/>
            {legendNode}
            {this.cunames.map((name: string, index: number) => {
                return (<Bar key={index} dataKey={name} stackId="a" fill={colors[index]} isAnimationActive={false} unit={unit}/>)
            })}
        </BarChart>
        </ResponsiveContainer>
        )
    }
}

export default withTranslation()(StatsCatChart)