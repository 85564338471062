import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosInstance } from 'axios'
import { Meta, UserInfo, valueNotEmpty, valueUnique, valuePathPrefix, getLocalizedLabel } from '../../common'
import { Container, Segment, Grid, Dropdown as PureDropdown, Checkbox as PureCheckbox, DropdownItemProps, Menu, Label, Header } from 'semantic-ui-react'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import TextArea from '../form/TextArea'
import Dropdown from '../form/Dropdown'
import { Field, FieldArray, Formik } from 'formik'
import {ConfigEditorComponentProps} from './ConfigEditor'
import Button from '../form/Button'

class ConfigEditorCatFiller extends Component<ConfigEditorComponentProps> {

    lastDef = { feats: [] }

    toggleDef(e, d) {
        const { formik } = this.props
        if (d.checked) {
            formik.setFieldValue('params.def', this.lastDef)
        } else {
            this.lastDef = formik.values.params.def
            formik.setFieldValue('params.def', undefined)
        }
    }

    render() {
        const { formik, user, meta, editing, locale, t, i18n } = this.props

        const defChanged = Boolean(formik.values.params.def) !== Boolean(formik.initialValues.params.def) ? 'changed' : ''

        // TODO: filter out also slot-irrelevant features
        const featureOptions = Object.keys(meta.cats['feature']).reduce((res: DropdownItemProps[], type) => {
            const u = meta.cats['feature'][type]
            if (!u.subdef_required) {
               res.push({key: type, value: type, text: getLocalizedLabel(u.label, i18n.language)+' ['+type+']'})
            }
            return res
        }, [])
        
        return (
            <>
            <Segment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                        <div className="label pad">{t('Label by')}</div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                        </Grid.Column>
                    </Grid.Row>
                    <FieldArray name='params.label_feature'
                    render={(subformik) => (
                    <>
                    {formik.values.params.label_feature.map((item: any, index: number) => {
                            return (
                                <Grid.Row className=''>
                                    <Grid.Column width={2}></Grid.Column>
                                    <Grid.Column width={11}>
                                        <Dropdown name={"params.label_feature."+index}
                                            inputProps={{disabled: !editing, placeholder: t('undefined')}} options={featureOptions}/>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Button type="button" icon="angle up" color="blue" size="mini"
                                            disabled={!editing || index === 0}
                                            onClick={(e,d) => subformik.swap(index, index-1)}/>
                                        <Button type="button" icon="angle down" color="blue" size="mini"
                                            disabled={!editing || index === formik.values.params.label_feature.length-1}
                                            onClick={(e,d) => subformik.swap(index, index+1)}/>
                                        <Button type="button" icon="delete" color="red" size="mini"
                                            disabled={!editing}
                                            onClick={(e,d) => subformik.remove(index)}/>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        })
                    }
                    <Grid.Row className=''>
                        <Grid.Column width={2}></Grid.Column>
                        <Grid.Column width={4}>
                            <Button icon="plus" type="button" size='mini' color='blue' 
                                onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                        </Grid.Column>
                    </Grid.Row>
                    </>
                    )}/>
                </Grid>
            </Segment>
            <Segment>
                <Grid>
                    <Grid.Row className={defChanged}>
                        <Grid.Column width={4}><div className="label">{t('Definition')}</div></Grid.Column>
                        <Grid.Column width={12}>
                            <PureCheckbox toggle key={true && formik.values.params.def} checked={Boolean(formik.values.params.def)} 
                                onChange={(e,d) => this.toggleDef(e,d)} disabled={!editing} className={this.lastDef === formik.values.params.def ? 'changed' : ''}/>
                        </Grid.Column>
                    </Grid.Row>
                    { formik.values.params.def  ?
                    <>
                        <Grid.Row className={defChanged}>
                            <Grid.Column width={2}><div className="label">{t('Features')}</div></Grid.Column>
                            <Grid.Column width={14}>{t('type')}</Grid.Column>
                        </Grid.Row>
                        <FieldArray name='params.def.feats'
                            render={(subformik) => (
                            <>
                            {formik.values.params.def.feats.map((item: any, index: number) => {
                                    return (
                                        <Grid.Row className={defChanged}>
                                            <Grid.Column width={2}></Grid.Column>
                                            <Grid.Column width={11}>
                                                <Input name={"params.def.feats."+index+".type"} inputProps={{disabled: !editing}} 
                                                    validate={v => valuePathPrefix(v, Object.keys(meta['cats']['feature']))}/>
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <Button type="button" icon="angle up" color="blue" size="mini"
                                                    disabled={!editing || index === 0}
                                                    onClick={(e,d) => subformik.swap(index, index-1)}/>
                                                <Button type="button" icon="angle down" color="blue" size="mini"
                                                    disabled={!editing || index === formik.values.params.def.feats.length-1}
                                                    onClick={(e,d) => subformik.swap(index, index+1)}/>
                                                <Button type="button" icon="delete" color="red" size="mini"
                                                    disabled={!editing}
                                                    onClick={(e,d) => subformik.remove(index)}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })
                            }
                            <Grid.Row className={defChanged}>
                                <Grid.Column width={2}></Grid.Column>
                                <Grid.Column width={4}>
                                    <Button icon="plus" type="button" size='mini' color='blue' 
                                        onClick={(e,d) => subformik.push('')} disabled={!editing}/>
                                </Grid.Column>
                            </Grid.Row>
                            </>
                            )}/>
                    </>
                    : null }
                </Grid>
            </Segment>
            </>
        )
    }

}

export default withTranslation()(ConfigEditorCatFiller)