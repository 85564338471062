/*
 * from https://github.com/turner-industries/formik-semantic-ui/blob/master/src/Input.js
 */

import React, {Component} from 'react'
import {Form, Input as SInput, FormInputProps} from 'semantic-ui-react'
import {FastField, Field, getIn} from 'formik'
import ErrorMessage from './ErrorMessage'
import {InputRef} from './InputRef'
import {FormikComponentProps, getFieldError, setFieldValue} from './FormikHelpers'

export interface InputComponentProps extends FormikComponentProps {
    inputProps?: FormInputProps
    inputRef?: (el: HTMLInputElement) => void
    noHighlighting?: boolean
}

class Input extends Component<InputComponentProps> {

  id: string

  constructor(props) {
    super(props)
    const {id, name} = props
    this.id = id || `field_input_${name}`
  }

  render() {
    const {
      name,
      label,
      validate,
      inputProps = {},
      fieldProps = {},
      errorComponent = ErrorMessage,
      inputRef,
      fast,
      noHighlighting
    } = this.props
    const {onChange, ...safeInputProps} = inputProps
    const DesiredField = fast === true ? FastField : Field

    return (
      <DesiredField name={name} validate={validate}>
        {({field, form, meta}) => {
          const error = getFieldError(field, form)
          const value = inputProps.type && inputProps.type == "number" ?  Number(field.value) : field.value
          
          return (
            <Form.Field error={!!error} {...fieldProps}>
              {!!label && <label htmlFor={this.id}>{label}</label>}

              <InputRef inputRef={inputRef}>
                <SInput
                  id={this.id}
                  name={name}
                  {...safeInputProps}
                  value={value}
                  className={noHighlighting || (value === meta.initialValue) || (!meta.initalValue && (value === '' || (inputProps.type === "number" && isNaN(value)))) ? '' : 'changed'}
                  onChange={(e, {name, value}) => {
                    if (inputProps.type && inputProps.type == "number") {
                      if (!isNaN(Number(value))) setFieldValue(form, name, Number(value), true)
                    } else {
                      setFieldValue(form, name, value, true)
                    }
                    Promise.resolve().then(() => {
                      onChange && onChange(e, {name, value})
                    });
                  }}
                  onBlur={form.handleBlur}
                />
              </InputRef>

              {error && (
                React.createElement(errorComponent, { message: getIn(form.errors, name) })
              )}
            </Form.Field>
          );
        }}
      </DesiredField>
    );
  }
}

export default Input