import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueInOptions, valueByFormikPath, createDefaultFeatureLabel, valueNotEmpty, valueIsIntNumber, getRefTargets, Feature } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Form, Button, Container, DropdownItemProps, Dropdown as PDropdown, Grid, Header, Message, Segment, Tab, Table } from 'semantic-ui-react'
import { EditFormProps, UserMap, lastChangeLabel } from '../Edit'
import { FieldArray } from 'formik'
import { setFieldValue } from './FormikHelpers'
import Input from './Input'
import Dropdown from './Dropdown'
import { Icon } from 'semantic-ui-react'
import ConfigEditorUiSearchCompSlotQuery from '../ConfigEditor/CEUiSearchCompSlotQuery'
import TextArea from './TextArea'

interface InputFeatureValueProps extends WithTranslation {
    meta: Meta,
    cunitValues: any,
    feature: Feature,
    fmeta: any,
    fvaluepath: string,
    disabled: boolean,
    small?: boolean,
    nonEmpty?: boolean,
    label?: string,
    exLabel?: boolean,
    noHighlighting?: boolean,
    userMap: UserMap
}

class InputFeatureValue extends Component<InputFeatureValueProps> {

    render() {
        const { cunitValues, feature, meta, fmeta, fvaluepath, disabled, label, small, nonEmpty, exLabel, noHighlighting, userMap, t, i18n } = this.props
        const valueType = fmeta.params.value_type
        const options = valueType === 'enum' || valueType === 'flags' ? 
            fmeta.params.enum.map(o => {
                return { key: o.value, text: getLocalizedLabel(o.label, i18n.language), value: o.value}}
            ) : []
        let addInProps = { disabled: disabled }
        if (label && !exLabel) addInProps['label'] = label
        if (small) addInProps['size'] = 'mini'
        const defaultValidation = nonEmpty ? valueNotEmpty : undefined
        const iconSize = small ? 'small' : 'big'
        const shortDesc = small
        const lastChange = lastChangeLabel(feature, userMap) || fmeta.type
        
        return (
            <span title={lastChange}>
            { !valueType && (!feature || feature.delete) ?
                <Form.Field className="checkbox">
                {exLabel ? <label htmlFor={`field_input_${fvaluepath}`}>{label}</label> : null}
                {' '}
                <Icon name="delete" color="red" size={iconSize}/>
                </Form.Field>
            : !valueType && (feature && !feature.delete) ?
                <Form.Field className="checkbox">
                {exLabel ? <label htmlFor={`field_input_${fvaluepath}`}>{label}</label> : null}
                {' '}
                <Icon name="check" color="blue" size={iconSize}/>
                </Form.Field>
            : valueType === 'str' || valueType === 'sstr' ?
                <Input name={fvaluepath} inputProps={{placeholder: t('undefined'), ...addInProps}} validate={defaultValidation} label={exLabel ? label : undefined} noHighlighting={noHighlighting}/>
            : valueType === 'lstr' ?
                <TextArea name={fvaluepath} rows={3} inputProps={{placeholder: t('undefined'), ...addInProps}} validate={defaultValidation} label={exLabel ? label : undefined} noHighlighting={noHighlighting}/>
            : valueType === 'text' ?
                <TextArea name={fvaluepath} rows={8} inputProps={{placeholder: t('undefined'), ...addInProps}} validate={defaultValidation} label={exLabel ? label : undefined} noHighlighting={noHighlighting}/>
            : valueType === 'int' ?
                <Input name={fvaluepath} inputProps={{type: "number", placeholder: t('undefined'), ...addInProps}} validate={valueIsIntNumber} label={exLabel ? label : undefined} noHighlighting={noHighlighting}/>
            : valueType === 'float' ?
                <Input name={fvaluepath} inputProps={{type: "number", placeholder: t('undefined'), ...addInProps}} validate={defaultValidation} label={exLabel ? label : undefined} noHighlighting={noHighlighting}/>
            : valueType === 'enum' ?
                <Dropdown name={fvaluepath} options={options} inputProps={{placeholder: t('undefined'), icon: null, ...addInProps}} validate={defaultValidation} label={label} noHighlighting={noHighlighting}/>
            : valueType === 'flags' ?
                <Dropdown name={fvaluepath} options={options} inputProps={{multiple: true, selection: true, placeholder: t('undefined'), icon: null, ...addInProps}} label={label}
                    validate={defaultValidation} noHighlighting={noHighlighting}/>
            : valueType === 'ref' ?
                ( cunitValues ?
                    <Dropdown name={fvaluepath} inputProps={{placeholder: t('undefined'), icon: null, ...addInProps}} validate={defaultValidation} label={label}
                        noHighlighting={noHighlighting}
                        options={getRefTargets(meta, cunitValues, {
                            slot: fmeta.params.ref_targets && fmeta.params.ref_targets.includes('slot'), 
                            filler: fmeta.params.ref_targets && fmeta.params.ref_targets.includes('filler')
                        }, t, shortDesc)}/>
                :
                <Input name={fvaluepath} inputProps={{placeholder: t('undefined'), ...addInProps}} validate={defaultValidation} label={exLabel ? label : undefined} noHighlighting={noHighlighting}/>
                )
            : valueType === 'multiref' ?
                ( cunitValues ?
                <Dropdown name={fvaluepath} inputProps={{multiple: true, selection: true, placeholder: t('undefined'), icon: null, ...addInProps}} validate={defaultValidation} label={label}
                    noHighlighting={noHighlighting}
                    options={getRefTargets(meta, cunitValues, {
                        slot: fmeta.params.ref_targets && fmeta.params.ref_targets.includes('slot'), 
                        filler: fmeta.params.ref_targets && fmeta.params.ref_targets.includes('filler')
                    }, t, shortDesc)}/>
                :
                    <Input name={fvaluepath} inputProps={{placeholder: t('undefined'), ...addInProps}} validate={defaultValidation} label={exLabel ? label : undefined} noHighlighting={noHighlighting}/>
                )
            : null}
            </span>
        )

    }
}

export default withTranslation()(InputFeatureValue)