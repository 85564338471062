import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RatatoskResponse, Meta } from '../../common'
import { Table, Button, Modal, Grid, Pagination, Container, Dimmer } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { cnctag2labels } from '../../CNC_common'
import DetailView from './DetailView'
import NoResults from './NoResults'
import { PageInfo, ResultListProps } from '../Search'

interface CNCFormListState {
    modalIsOpen: boolean,
    modalHeader: string,
    modalContent: React.ReactNode
}

class CNCFormList extends Component<ResultListProps, CNCFormListState> {

    state = {
        modalIsOpen: false,
        modalHeader: '',
        modalContent: null
    }

    render() {
        let { response, pageInfo, params, meta, t, loading } = this.props
        let { modalHeader, modalContent, modalIsOpen } = this.state

        const modalClose = () => { this.setState({ modalIsOpen: false }) }
        const modalOpen = (header: string, content: React.ReactNode) => {
            this.setState({ modalIsOpen: true })
            this.setState({ modalHeader: header, modalContent: content })
        }

        if (!response) return (null)

        if (!response.data.length) return (<NoResults/>)

        return(
            <>
            <Modal header={modalHeader} content={modalContent} open={modalIsOpen} onClose={modalClose} centered={false} closeIcon/>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                    { pageInfo.pagination ? (
                        <Pagination activePage={pageInfo.curPage} totalPages={pageInfo.totalPages} onPageChange={pageInfo.pageChange} />
                    ) : null }
                    </Grid.Column>
                    <Grid.Column width={6} className="pageRange">
                        <span>{`${pageInfo.pageStart}-${pageInfo.pageEnd} / ${pageInfo.total >= 0 ? pageInfo.total : t('...')}`}</span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Dimmer.Dimmable loading={loading} dimmed={loading} as={Container}>
                        <Dimmer active={loading} inverted />
                        <Table>
                            <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('form')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('lemma')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('tag')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('description')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('frequency')}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            {response.data.map((cunit, index) => {
                                const fword = cunit['_slots'][0]['_fillers'][0][':form:attr:cnc:w:word']
                                const flemma = cunit['_slots'][0]['_fillers'][0][':form:attr:cnc:w:lemma']
                                const ftag = cunit['_slots'][0]['_fillers'][0][':form:attr:cnc:w:tag']
                                const total = cunit[':stats:fq:abs:cnc:total'] ? cunit[':stats:fq:abs:cnc:total'] : 0
                                const desc = cnctag2labels(ftag).map(e => t(e)).join(' - ')
                                const word = fword ? fword : ''
                                const lemma = flemma ? flemma : ''
                                const tag = ftag ? ftag : ''
                                const statsView = (
                                    <Button color='blue' icon='chart bar' size='mini'
                                        onClick={(e,d) => modalOpen(t('Detailed distribution'), (
                                            <DetailView meta={meta} cunits={[cunit]} view={params['detail_view']}/>
                                        ))}
                                    />
                                )
                                return (
                                    <Table.Row key={index}>
                                        <Table.Cell>{word}</Table.Cell>
                                        <Table.Cell><Link to={'/search/lemmas/'+lemma}>{lemma}</Link></Table.Cell>
                                        <Table.Cell>{tag}</Table.Cell>
                                        <Table.Cell>{desc}</Table.Cell>
                                        <Table.Cell>{total}</Table.Cell>
                                        <Table.Cell textAlign='right'>{total ? statsView : null}</Table.Cell>
                                    </Table.Row>
                                )
                            })}
                            </Table.Body>
                        </Table>
                        </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                { pageInfo.pagination ? (
                    <Grid.Row>
                        <Grid.Column>
                            <Pagination activePage={pageInfo.curPage} totalPages={pageInfo.totalPages} onPageChange={pageInfo.pageChange} />
                        </Grid.Column>
                    </Grid.Row>
                ) : null }
            </Grid>
            </>
        )
    }

}

export default withTranslation()(CNCFormList)