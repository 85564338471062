import React, { Component } from 'react'
import { Cunit } from '../../common'
import { withTranslation } from 'react-i18next'
import { Button, Grid, Header, Popup, Segment } from 'semantic-ui-react'
import { ViewCompProps } from '../View'

const pronouns_map = {
    "NĚCO" : "",
    "NĚČEHO" : "[NP]...2..........",
    "NĚČEMU" : "[NP]...3..........",
    "NĚČEM" : "[NP]...6..........",
    "NĚČÍM": "[NP]...7..........",
    "NĚKDO": "",
    "NĚKOHO": "",
    "NĚKÝM": "[NP]...7..........",
    "NĚJAKÝ": "A...1..........",
    "NĚJAK": "",
    "NĚKDE": "",
    "NĚKDY": "",
    "NĚKAM": "",
}
const studie = ["studie", "studii", "studií"]
//const tabulka = ["tabulka", "tabulky", "tabulce","tabulku","tabulkou","tabulek","tabulkám","tabulkách","tabulkami"]
//const obrazek = []

const lemmata_studie = "studie| článek| stať| publikace| monografie| příspěvek| výsledek| práce| text| test| model| experiment| datum| analýza| kniha| příručka| koncepce| sdělení| přehled| referát| výzkum| průzkum| literatura| sonda| plán| úvaha| přístup| studium| sledování| pokus| šetření"
const lemmata_autor = "autor| badatel"
const lemmata_tabulka = "tabulka| tab"
const lemmata_obrazek = "obrázek| obr"
const space_match = "[tag!=\"[Z].*\"]"
const space_match_ex = "[tag!=\"[ZVJ].*\"]"
const space_match_any = "[]"

const getFeatureValue = (parent, ftype) => {
    const f = parent.feats.filter(f => f.type === ftype)
    if (!f.length) return false
    else return f[0].value
}

const TXTPARTS = {
    'intro': 'úvod',
    'methods': '!úvod|závěr|diskuse',
    'concl': 'závěr|diskuse',
    'analysis': '!úvod|závěr|diskuse'
}

interface AcPhraseState {
    filter: string
}

class AcPhrasePreview extends Component<ViewCompProps, AcPhraseState> {

    default_state: AcPhraseState = {
        filter: '*'
    }

    state = this.default_state

    componentDidMount() {
    }

    slotListByName(unit: Cunit) {
        let slotmap = {}
        for (let s of unit.slots) {
            slotmap[s.name] = s
        }
        return slotmap
    }

    buildDescription(slotmap: any, slot_names: string[] = [], truncate = 0, skip_slots: string[] = []): [string, string[]] {
        let components: string[] = []
        for (let sname of slot_names) {
            const s = slotmap[sname]
            if (skip_slots.includes(s.name)) continue
            skip_slots.push(s.name)
            const opt = s.feats.filter(x => x.type == ':syn:comp:optional')
            const optional = opt.length > 0 && opt[0].value == 'opt'
            let component = ''
            if (s.type == ':var') {
                let subcomponents: string[] = []
                let fli = 0
                for (let fl of s.fillers) {
                    if (truncate && fli == truncate) {
                        subcomponents.push('...')
                    }
                    const rfeats = fl.feats.filter(x => x.type == ':form:comps')
                    const references = rfeats.length ? rfeats[0].value : []
                    let [subcomponent, more_to_skip] = this.buildDescription(slotmap, references, truncate, skip_slots)
                    skip_slots = skip_slots.concat(more_to_skip)
                    if (getFeatureValue(fl, ":form:mwe:hidden") !== false) continue
                    if (!truncate || fli < truncate)
                        subcomponents.push(subcomponent)
                    fli++
                }
                // filter out syntactic clausal variants for direct objects
                if (subcomponents.includes('NĚCO')) subcomponents = subcomponents.filter(x => !x.startsWith(','))
                component = subcomponents.join(' / ')
                //if (!optional) component = '{ '+ component+' }'
            } else if (s.type.startsWith(':term')) {
                let tokens: string[] = []
                let fli = 0
                for (let fl of s.fillers) {
                    if (truncate && fli >= truncate) {
                        tokens.push('...')
                        break
                    }
                    if (getFeatureValue(fl, ":form:mwe:hidden") !== false) continue
                    const flabel = fl.feats.filter(x => x.type == ':form:label')
                    if (flabel.length) {
                        tokens.push(flabel[0].value)
                    }
                    fli++
                }
                if (tokens.length > 1) {
                    component = tokens.join(' / ')
                    //if (!optional) component = '{ '+ component+' }'
                } else {
                    component = tokens[0]
                }
            }
            if (component) {
                if (optional) component = "( "+component+" )"
                components.push(component)
            }
        }
        return [components.join(' '), skip_slots]
    }

    slotLen(slot, slotmap) {
        let maxlen = 0
        if (slot['type'] === ":var") {
            for (let filler of slot['fillers']) {
                const references = getFeatureValue(filler, ':form:comps')
                let reflen = 0
                for (let ref of references) {
                    const refslot = slotmap[ref]
                    reflen += this.slotLen(refslot, slotmap)
                    maxlen = reflen > maxlen ? reflen : maxlen
                }
            }
            return maxlen
        } else
            return 1
    }

    filler2cql(filler) {
        let cql: string[] = []
        let label = getFeatureValue(filler, ":form:label")
        const word = getFeatureValue(filler, ":form:attr:cnc:w:word")
        let lemma = getFeatureValue(filler, ":form:attr:cnc:w:lemma")
        const tag = getFeatureValue(filler, ":form:attr:cnc:w:tag")
        const verbtag = getFeatureValue(filler, ":form:attr:cnc:w:verbtag")
        if (label.toLowerCase().startsWith('novák')) {
            const tagquery = tag ? "&tag=\""+tag+"\"" : ""
            return "lemma=\"\\p{Lu}.*\"|(lemma=\""+lemmata_autor+"\""+tagquery+")"
        } else if (studie.includes(label.toLowerCase()))
            lemma = lemmata_studie
        else if (label.toLowerCase() == 'tabulka' || (lemma && lemma.toLowerCase() == 'tabulka'))
            lemma = lemmata_tabulka
        else if (label.toLowerCase() == 'obrázek' || (lemma && lemma.toLowerCase() == 'obrázek'))
            lemma = lemmata_obrazek
        else if (Object.keys(pronouns_map).includes(label)) {
            const tag = pronouns_map[label]
            if (tag !== '') return "tag=\""+tag+"\""
            else return ""
        }
        if (word)
            cql.push("word=\"(?i)"+word+"\"")
        if (lemma)
            cql.push("lemma=\""+lemma+"\"")
        if (tag)
            cql.push("tag=\""+tag+"\"")
        if (verbtag)
            cql.push("verbtag=\""+verbtag+"\"")
        return cql.join("&")
    }

    slot2cql(slot) {
        let cql: string[] = []
        for (let filler of slot['fillers'])
            cql.push(this.filler2cql(filler))
        //if (cql.includes("")) // no, we need to scan for possible punctuation symbols within alternatives in order to know we cannot exclude it in the gaps!
        //    return '[]'
        return cql.length === 1 ? '['+cql[0]+']' : '('+cql.map(x => '['+x+']').join('|')+')'
    }

    slots2cql(slotmap: any, gapsize: number = 2, flexible=false, slotlist: string[] = [], skip_slots: string[] = [], space=space_match) {
        let cqlgroups: string[][] = []
        let cql: string[] = []
        //let skip_slots: string[] = []
        let flexgap = 0
        let fixedgap = 0
        let gap_contents: string[] = []
        let topLevel = false
        if (!slotlist.length) {
            topLevel = true
            slotlist = Object.keys(slotmap)
        }
        const flexiblegrp = flexible && topLevel && slotlist.some(s => getFeatureValue(slotmap[s], ":form:mwe:order:break") !== false)
        let firstSlot = topLevel
        for (let sname of slotlist) {
            let skip = false
            if (skip_slots.includes(sname))
                continue
            skip_slots.push(sname)
            const s = slotmap[sname]
            // flexible word-order
            if (flexiblegrp && getFeatureValue(s, ":form:mwe:order:break") !== false && cql.length) {
                cqlgroups.push(cql)
                cql = []
                firstSlot = topLevel
            }
            // ignored slot
            if (getFeatureValue(s, ":syn:comp:ignore") !== false) {
                flexgap += this.slotLen(s, slotmap)
                skip = true
            }
            // optional slot
            if (getFeatureValue(s, ":syn:comp:optional") !== false) {
                flexgap += this.slotLen(s, slotmap)
                skip = true
            }
            if (s['type'] === ":term:fixed" || s['type'] === ":term:semiopen" || s['type'] === ":term:open") {
                const slotcql = this.slot2cql(s)
                if (!skip) {
                    if (slotcql !== "[]") {
                        if (!firstSlot && !(getFeatureValue(s, ":form:mwe:order:nogap") !== false)) { // ignore space before the first slot and nogap slots
                            const curspace = gap_contents.includes("Z") ? space_match_any : space
                            gap_contents = []
                            if (flexgap || fixedgap > 1)
                                cql.push(curspace+"{"+fixedgap.toString()+','+(fixedgap+flexgap).toString()+"}")
                            else if (fixedgap === 1)
                                cql.push(curspace)
                        }
                        firstSlot = false
                        cql.push(slotcql)
                        fixedgap = 0
                        flexgap = gapsize
                    } else
                        fixedgap += 1
                } else {
                    // optional contents: scan for tokens which would affect restrictions on the gap contents
                    let content_types: string[] = []
                    if (slotcql.search(/word=["'](\(\?i\))?[%,:;]["']/)>=0)
                        content_types.push("Z")
                    gap_contents = gap_contents.concat(content_types)
                }
            //} else if (s['type'] === ":term:open") {
            //    fixedgap += 1
            } else if (s['type'] === ":var") {
                let variants: string[] = []
                for (let filler of s['fillers']) {
                    const references = getFeatureValue(filler, ':form:comps')
                    const [subcql, to_skip] = this.slots2cql(slotmap, gapsize, flexible, references, skip_slots, space)
                    skip_slots = to_skip //skip_slots.concat(to_skip)
                    variants.push(subcql)
                }
                if (!skip) {
                    if (!firstSlot && !(getFeatureValue(s, ":form:mwe:order:nogap") !== false)) { // ignore space before the first slot and nogap slots
                        const curspace = gap_contents.includes("Z") ? space_match_any : space
                        gap_contents = []
                        if (flexgap || fixedgap > 1)
                            cql.push(curspace+"{"+fixedgap.toString()+','+(fixedgap+flexgap).toString()+"}")
                        else if (fixedgap === 1)
                            cql.push(curspace)
                    }
                    firstSlot = false
                    cql.push("("+variants.join("|")+")")
                    fixedgap = 0
                    flexgap = gapsize
                } else {
                    // optional contents: scan for tokens which would affect restrictions on the gap contents (i.e. punctuation symbols)
                    let content_types: string[] = []
                    for (let subcql of variants) {
                        if (subcql.search(/word=["'](\(\?i\))?[%,:;]["']/)>=0)
                            content_types.push("Z")
                    }
                    gap_contents = gap_contents.concat(content_types)
                }
            }
        }
        if (!topLevel) { // ignore space after the last slot
            const curspace = gap_contents.includes("Z") ? space_match_any : space
            gap_contents = []
            if (fixedgap > 1)
                cql.push(curspace+"{" + fixedgap.toString() + ',' + (fixedgap + flexgap).toString() + "}")
            else if (fixedgap === 1)
                cql.push(curspace)
        }
        if (flexiblegrp) {
            if (cql.length)
                cqlgroups.push(cql)
            const strgroups = cqlgroups.map(g => '('+g.join('')+')')
            const container = strgroups.some(s => s.includes('word=","')) ? 's' : 'cl'
            return [(`(<${container}/> containing `+strgroups.join(' containing ')+')'), skip_slots] as const
        } else {
            if (topLevel)
                return [cql.join('') + ' within <s/>', skip_slots] as const
            else
            return [cql.join(''), skip_slots] as const
        }
    }

    render() {
        const { config, meta, data, t, i18n } = this.props
        //const { filter } = this.state
        const slotmap = this.slotListByName(data)
        const slot_names = data.slots.map(s => s.name)
        const [fullDesc, ] = this.buildDescription(slotmap, slot_names)
        //const [shortDesc, ] = this.buildDescription(slotmap, slot_names, 2)
        //const [cql, ] = this.slots2cql(slotmap, 2)
        //const [cqlX, ] = this.slots2cql(slotmap, 6)
        const [cqlF, ] = this.slots2cql(slotmap, 6, true)
        //const [cqlFex, ] = this.slots2cql(slotmap, 6, true, [], [], space_match_ex)
        const txtpart =  TXTPARTS[getFeatureValue(data, ":note:acphrase:scope")]
        // const query = cql + ` within <div class=\"${txtpart}\" />`
        // const corpusUrl = "https://www.korpus.cz/kontext/create_view?q=aword,"+encodeURIComponent(query)+"&corpname=veda&q=D"
        // const queryX = cqlX + ` within <div class=\"${txtpart}\" />`
        // const corpusUrlX = "https://www.korpus.cz/kontext/create_view?q=aword,"+encodeURIComponent(queryX)+"&corpname=veda&q=D"
        const queryF = cqlF + (txtpart ? 
                (txtpart.startsWith("!") ? ` within <div class!=\"${txtpart.substring(1)}\" />` : ` within <div class=\"${txtpart}\" />`)
             : '')
        const corpusUrlF = "https://www.korpus.cz/kontext/create_view?q=aword,"+encodeURIComponent(queryF)+"&corpname=veda&q=D"
        //const queryFex = cqlFex + ` within <div class=\"${txtpart}\" />`
        //const corpusUrlFex = "https://www.korpus.cz/kontext/create_view?q=aword,"+encodeURIComponent(queryFex)+"&corpname=veda&q=D"


        /*const filterOptions = [
            {key: '*', value: '*', text: t('all')},
            {key: 'test', value: 'test', text: t('test')}
        ]*/

        /*const setFilter = (value: string) => {
            this.setState({ filter: value})
        }*/

        return(
            <Segment>
            <Header as="h2">{t("Description")}</Header>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <strong>{t('Description')}</strong>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        {fullDesc}
                    </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row>
                    <Grid.Column width={3}>
                        <strong>{t('Shortened description')}</strong>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        {shortDesc}
                    </Grid.Column>
                </Grid.Row>*/}
                {/*<Grid.Row>
                    <Grid.Column width={3}>
                        <strong>{t('Corpus query')}<br/>(mezery do 2 pozic)</strong>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <a href={corpusUrl} target="_new">{t('concordance')}</a>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Popup content={query} on='click' flowing trigger={<Button size='tiny' content='CQL'/>}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <strong>{t('Corpus query')}<br/>(mezery do 6 pozic)</strong>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <a href={corpusUrlX} target="_new">{t('concordance')}</a>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Popup content={queryX} on='click' flowing trigger={<Button size='tiny' content='CQL'/>}/>
                    </Grid.Column>
                </Grid.Row>*/}
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column width={3}>
                        <strong>{t('Corpus query')}</strong>
                    </Grid.Column>
                    <Grid.Column width={13}>{/*{t('field')}&nbsp;
                        <Dropdown button options={filterOptions} value={filter} onChange={(e, { value }) => setFilter(value as string)}/>*/}
                        <Button color="blue" as="a" href={corpusUrlF} target="_new">{t('Search examples')}</Button>
                        <Popup content={queryF} on='click' trigger={<Button content={t('show CQL query')}/>} wide='very' position='bottom center'/>
                    </Grid.Column>
                </Grid.Row>
                {/* corpusUrlF !== corpusUrlFex ?
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column width={3}>
                        <strong>{t('Corpus query')}</strong> (s vyloučením sloves a spojek v mezerách)
                    </Grid.Column>
                    <Grid.Column width={13}>{/ *{t('field')}&nbsp;
                        <Dropdown button options={filterOptions} value={filter} onChange={(e, { value }) => setFilter(value as string)}/>* /}
                        <Button color="blue" as="a" href={corpusUrlFex} target="_new">{t('Search examples')}</Button>
                        <Popup content={queryFex} on='click' flowing trigger={<Button content={t('show CQL query')}/>}/>
                    </Grid.Column>
                </Grid.Row> : null */}
            </Grid>
            </Segment>
        )
    }

}

export default withTranslation()(AcPhrasePreview)