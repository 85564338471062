import React, { Component } from 'react'
import { NavLink, RouteComponentProps } from 'react-router-dom'
import { CustomParams, Meta, Query, LocalizedLabels, RatatoskRequest, RatatoskPage, RatatoskResponse, getLocalizedLabel, getmeta, featureValueTypes, valueUnique, valueIsIntNumber, descFiller, descSlot, getRefTargets, collectFeatureOptions, Cunit, Feature } from '../../common'
import { AxiosInstance } from 'axios'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Container, Dropdown, DropdownItemProps, Grid, Header, Icon, Label, Message, Popup, Segment, SemanticWIDTHS, Tab } from 'semantic-ui-react'
import { ViewCompProps } from '../View'
import ShowFeatureValue from './ShowFeatureValue'
import { join } from 'path'

const getFeatureValue = (parent, ftype) => {
    const f = parent.feats.filter(f => f.type === ftype)
    if (!f.length) return false
    else return f[0].value
}

interface LemurPreviewState {
    corpus: string
}

class LemurPreview extends Component<ViewCompProps, LemurPreviewState> {

    default_state: LemurPreviewState = {
        corpus: 'syn2020lemur'
    }

    state = this.default_state

    componentDidMount() {
    }

    render() {
        const { config, meta, data, t, i18n } = this.props
        const { corpus } = this.state
        const lemma = getFeatureValue(data, ':form:lemma')
        const cql = `"${lemma}" []{0,15} "${lemma}" within <s/>`
        const url = "https://www.korpus.cz/kontext/create_view?q=amwe_lemma,"+encodeURIComponent(cql)+"&corpname="+corpus+"&q=D"


        const corpusOptions = [
            {key: 'syn2020lemur', value: 'syn2020lemur', text: t('SYN2020lemur')}
        ]

        const setCorpus = (value: string) => {
            this.setState({ corpus: value})
        }

        return(
            <Segment>
            <Header as="h2">{t("Corpus search")}</Header>
            <Grid>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column width={3}>
                        <strong>{t('Corpus query')}</strong>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Button color="blue" as="a" href={url} target="_new">{t('Search examples')}</Button>
                        &nbsp;{t('in corpus')}&nbsp;
                        <Dropdown button options={corpusOptions} value={corpus} onChange={(e, { value }) => setCorpus(value as string)}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>
        )
    }

}

export default withTranslation()(LemurPreview)